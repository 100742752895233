import React, { useContext, useState  } from "react";
import menuIcon from "../assets/menuIcon.svg";
import { useNavigate } from "react-router-dom";
import Bottom from "../Home/Bottom";

import Sidebar from "../Home/Sidebar";
import { Context } from "../..";


import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";

import CourseCard from "./CourseCard";
import { MdOutlineMenu } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import Span from "../Translation/Translate";
import { FaArrowLeftLong } from "react-icons/fa6";

const CourseHistory = () => {

  const { handleShow,handleClose } = useContext(Context);
  const Navigate = useNavigate();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const [isLoading, setIsLoading] = useState(false)


  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);



  const [savedQuizzNames, setSavedQuizzNames] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  
  

  return (
    <>

      <div>
        <div style={{ height: "100vh", width: "100vw" }}>
          <div className="border" style={{ height: "10vh",backgroundColor:"#6f42c1",color:"white" }}>
            <div className="container">
              <div className="row mt-3">
                <div className="col-2 d-flex justify-content-center align-items-center">
                <div className=' col-2'>
                <FaArrowLeftLong onClick={()=>{Navigate("/")}} style={{ fontSize:"30px", cursor: "pointer",color:"white" }}/>
                </div>
                </div>
                <div className="col-8  text-center">
                  <span
                    style={{ fontSize: "1.5rem", fontWeight: "700" }}
                    className="mb-0 "
                  >
                 <Span>Course Plan History</Span> 
                  </span>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                <div className=' col-2'>
                <FaHome style={{ fontSize:"30px", cursor: "pointer",color:"white" }} onClick={()=>{Navigate("/")}}/>
                </div>
                </div>
               
              </div>
            </div>
          </div>
          <div
            className="border"
            style={{ height: "90vh", overflowY: "auto", padding: "0.8rem" }}
          >
            <CourseCard />
          </div>
         
        </div>
      </div>

      {/* Add exam offcanva */}
    
      <Sidebar />

    </>
  );
};

export default CourseHistory;
