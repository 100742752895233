import "./App.css";
import { Routes, Route, BrowserRouter, useNavigate, Navigate } from "react-router-dom";
import Home from "./components/Home/Home";
import MyExams from "./components/Saved/MyExams";
import Registration from "./components/Register/Registration";
import ViewExam from "./components/Saved/ViewExam";
import LiveQuizzes from "./components/Home/LiveQuizzes";
import MyWallet from "./components/Wallet/MyWallet";
// import Rooms from './components/Rooms/Rooms';
import Spent from "./components/Wallet/Spent";
import Earned from "./components/Wallet/Earned";
import Depositmoney from "./components/Wallet/Depositmoney";
import CurrentCard from "./components/Home/CurrentCard";
import Register from "./components/Home/Register";
import Study from "./components/Study/Study";
import StudyMaterial from "./components/Study/StudyMaterial";
import Pdf from "./components/Study/Pdf";
import HistoryMain from "./components/History/HistoryMain";

import { getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { messaging } from "./config/FirebaseConfig";

import { BasicServices, BrainBucksObject } from "./services/BasicServices";
import "react-toastify/dist/ReactToastify.css";

import FreeTrivia from "./components/Home/FreeTrivia";
import CurrentCardTrivia from "./components/Home/CurrentCardTrivia";
import Participate from "./components/Home/Participate";
import MyExamQuiz from "./components/Home/MyExamQuiz";
import Questionppr from "./components/Study/Questionppr";
import Online from "./components/Study/Online";
import Topper from "./components/Study/Topper";
import StudyGuide from "./components/Study/StudyGuide";
import ExamPrep from "./components/Study/ExamPrep";
import Journals from "./components/Study/Journals";
import Personal from "./components/Study/Personal";
import ExamsAdded from "./components/Study/ExamsAdded";
import StartQuiz from "./components/Quiz/StartQuiz";
import AttemptQuiz from "./components/Quiz/AttemptQuiz";
import SavedExamsAdded from "./components/Saved/SavedExamsAdded";
import Enroll from "./components/Home/Enroll";
import DailyUpdate from "./components/DailyUpdation/DailyUpdate";
import Policy from "./components/Privacy/Policy";
import Rules from "./components/Quizeplayrules/Rules";
import Withdraw from "./components/Wallet/Withdraw";
import Withdrawnow from "./components/Wallet/Withdrawnow";
import ExamQuiz from "./components/Home/ExamQuiz";
import Viewall from "./components/Wallet/Viewall";
import Failed from "./components/Wallet/Failed";
import Walletotp from "./components/Wallet/Walletotp";
import Withdrawlreqpen from "./components/Wallet/Withdrawlreqpen";
import Addbank from "./components/Wallet/Addbank";
import Bankform from "./components/Wallet/Bankform";
import Bankaccdetails from "./components/Wallet/Bankaccdetails";
import Baacc from "./components/Wallet/Baacc";
import Accotp from "./components/Wallet/Accotp";
import Addsuccess from "./components/Wallet/Addsuccess";
import QuizResults from "./components/History/QuizResults";
import FreeCourses from "./components/Courses/FreeCourses";
import EnrolledCourses from "./components/Courses/EnrolledCourses";
import PaidCourses from "./components/Courses/PaidCourses";
import Courses from "./components/Courses/Courses";
import AttemptTrivia from "./components/Quiz/AttemptTrivia";
import EditProfile from "./components/user/EditProfile";
import AnswerSheet from "./components/History/AnswerSheet";
import NewReward from "./components/Quiz/NewReward";
import CourseHistory from "./components/CourseHostory/CourseHistory";
import TransactionHistory from "./components/Wallet/TransactionHistory";
import ViewProfile from "./components/user/ViewProfile";
import VideoPlayer from "./components/Courses/VideoPlayer";
import Pending from "./components/Wallet/Pending";
import Success from "./components/Wallet/Success";
import MiddleWarning from "./components/Quiz/MiddleWarning";
import AskForPermission from "./components/Home/AskForPermission";
import About from "./components/About/About";
import CreateChat from "./components/Support/CreateChat";
import Support from "./components/Support/Support";
import DownloadApk from "./DownloadLink/DownloadApk";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Chatbot from "./components/ChatBot/Chatbot";
import ReelTag from "./components/Reels/ReelTag";
import ReelTagData from "./components/Reels/ReelTagData";
import kvPairs from "./config/SecretKeys";
import ReelContainer from "./components/Reels/ReelContainer";
import Rooms from "./components/Rooms/Rooms";
import EnterRoom from "./components/Rooms/EnterRoom";
import RoomsHistory from "./components/Rooms/RoomsHistory";
import CreateEnterRoom from "./components/Rooms/CreateEnterRoom";
import CreateNewRoom from "./components/Rooms/CreateNewRoom";
import ShechduleQuiz from "./components/Rooms/ShechduleQuiz";
import ReelsView from "./components/NewReels/ReelsView";
import CreateQuiz from "./components/Rooms/CreateQuiz";
import Settings from "./components/Rooms/settings";
import RoomNotification from "./components/Rooms/RoomNotification";
import ViewSheduleQuiz from "./components/Rooms/ViewSheduleQuiz";
import RegisterQuizRoom from "./components/Rooms/RegisterQuizRoom";
import LiveQuizRoom from "./components/Rooms/LiveQuizRoom";
import AttemptRoomsQuiz from "./components/Rooms/AttemptRoomquiz";
import RoomsMiddleWarning from "./components/Rooms/RoomsMiddleWarning";
import RoomsResult from "./components/Rooms/RoomsResult";
import RoomsAnswerSheet from "./components/Rooms/RoomsAnswerSheet";
import PaymentStatus from "./components/Wallet/PaymentStatus";
import StudySaved from "./components/Saved/StudySaved";
import { toast, ToastContainer } from "react-toastify";
import Message from "./Message";
function App() {
  const navigate = useNavigate();

  const basicService = new BasicServices();
  const basicObj = new BrainBucksObject();

  

  // Request permission for notifications
  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: kvPairs.VAPID_KEY,
        });
        // console.log("Token generated: ", token);
      } else if (permission === "denied") {
        console.warn("Notifications permission denied");
      }
    } catch (error) {
      console.error("Error requesting notification permission: ", error);
    }
  }

  useEffect(() => {
    requestPermission();
    // Handle foreground messages
    onMessage(messaging, (payload) => {
      console.log("Message received in foreground: ", payload);
      toast(<Message notification={payload.notification} />);
    });
  }, []);

  return (
    
    <Routes>
      <Route path="*" element={<Navigate to="/Register" />} />
      <Route path="/Register" element={<Registration />} />
      <Route path="*" element={<Navigate to="/Register" />} />
      <Route path="/downloadapk" element={<DownloadApk />} />
      <Route path="/Privacy" element={<Policy />} />
      <Route path="/Rules" element={<Rules />} />
      <Route element={<AuthenticatedRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/newreward/:id" element={<NewReward />} />
        <Route path="/myexams" element={<MyExams />} />
        <Route
          path="/viewExam/:exam_id/:exam_name"
          element={<ViewExam />}
        />
        <Route path="/see/livequizzes/" element={<LiveQuizzes />} />
        <Route path="/wallet" element={<MyWallet />} />
        {/* <Route path='/rooms' element={<Rooms />} /> */}

        <Route path="/wallet/earnings" element={<Spent />} />
        <Route path="/wallet/earn" element={<Earned />} />
        <Route path="/wallet/earnin" element={<Depositmoney />} />
        <Route path="/wallet/earni" element={<Withdraw />} />
        <Route path="/wallet/ear" element={<Withdrawnow />} />
        <Route path="/wallet/pend" element={<Withdrawlreqpen />} />
        <Route path="/wallet/bank" element={<Addbank />} />
        <Route path="/wallet/bankfo" element={<Bankform />} />
        <Route path="/wallet/bankdet" element={<Bankaccdetails />} />
        <Route path="/wallet/bacc" element={<Baacc />} />
        <Route path="/wallet/Acc" element={<Accotp />} />
        <Route path="/wallet/success" element={<Addsuccess />} />

        <Route path="/answersheet/:id/:type" element={<AnswerSheet />} />
        <Route path="/wallet/view/all" element={<Viewall />} />
        <Route path="/failed/:id" element={<Failed />} />
        <Route path="/pending/:id" element={<Pending />} />
        <Route path="/success/:id" element={<Success />} />
        <Route path="/wallet/otp" element={<Walletotp />} />
        <Route path="/HistoryMain" element={<HistoryMain />} />
        <Route
          path="/card/current/trivia/:id"
          element={<CurrentCardTrivia />}
        />
        <Route path="/card/current/:id/:prize" element={<CurrentCard />} />
        <Route path="/register/:id" element={<Register />} />
        <Route path="/study" element={<Study />} />
        <Route path="/studysaved/:exam_id" element={<StudySaved />} />
        <Route path="/studymaterial" element={<StudyMaterial />} />
        <Route path="/pdf/:id/:category_name" element={<Pdf />} />
        <Route path="/free/trivia" element={<FreeTrivia />} />
        <Route path="/participate/:id" element={<Participate />} />
        <Route path="/myexam/quiz" element={<MyExamQuiz />} />
        <Route path="/Question/:id/:pdf_type" element={<Questionppr />} />
        <Route path="/online" element={<Online />} />
        <Route path="/topper" element={<Topper />} />
        <Route path="/guide" element={<StudyGuide />} />
        <Route path="/examprep" element={<ExamPrep />} />
        <Route path="/journals" element={<Journals />} />
        <Route path="/personal" element={<Personal />} />
        <Route path="/examsAdded" element={<ExamsAdded />} />
        <Route path="/examquiz/:id" element={<ExamQuiz />} />
        <Route path="/quizResult/:id" element={<QuizResults />} />

        <Route path="/StartQuiz/:id/:type" element={<StartQuiz />} />
        <Route path="/AttemptQuiz/:id" element={<AttemptQuiz />} />
        <Route path="/AttemptTrivia/:id" element={<AttemptTrivia />} />
        <Route path="/Dailyupdate" element={<DailyUpdate />} />
        <Route path="/SavedExamsAdded" element={<SavedExamsAdded />} />
        <Route path="/enroll" element={<Enroll />} />
       
        <Route path="/coursehistory" element={<CourseHistory />} />
        {/* <Route path='/customer' element={<customer/>} /> */}

        <Route path="/PaidCourses" element={<PaidCourses />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/EnrolledCourses" element={<EnrolledCourses />} />
        <Route path="/FreeCourses" element={<FreeCourses />} />

        <Route path="/TransactionHistory" element={<TransactionHistory />} />
        <Route path="/Profile" element={<ViewProfile />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/video" element={<VideoPlayer />} />
        <Route path="/askforpermission" element={<AskForPermission />} />

        <Route path="/warning/:id/:type" element={<MiddleWarning />} />
        <Route path="/about" element={<About />} />
        <Route path="/support" element={<Support />} />
        <Route path="/createchat/:id/:isClosed" element={<CreateChat />} />
        <Route path="/reel/:id" element={<ReelContainer />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/reelTag" element={<ReelTag />} />
        <Route path="/reelTagData" element={<ReelTagData />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/enterroom/:Roomsid" element={<EnterRoom />} />
        <Route path="/roomhistory/:Roomsid" element={<RoomsHistory />} />
        <Route path="/createenterRoom/:Roomsid" element={<CreateEnterRoom />} />
        <Route path="/createnewroom" element={<CreateNewRoom />} />
        <Route path="/schedulequiz/:Roomsid/:RoomName" element={<ShechduleQuiz />} />
        <Route path="/reelsview" element={<ReelsView />} />
        <Route path="/reelsdemo" element={<ReelContainer />} />
        <Route path="/createquiz" element={<CreateQuiz />} />
        <Route path="/viewShedulequiz/:Roomsid/:RoomName" element={<ViewSheduleQuiz />} />
        <Route path="/settings/:Roomsid" element={<Settings />} />
        <Route path="/roomnotification/:Roomsid" element={<RoomNotification />} />
        <Route path="/registerquizRoom/:id" element={<RegisterQuizRoom />} />
        <Route path="/livequizlobby/:id" element={<LiveQuizRoom />} />
        <Route path="/middlewarning/:id" element={<RoomsMiddleWarning />} />
        <Route path="/attemptroomsquiz/:id" element={<AttemptRoomsQuiz />} />
        <Route path="/roomsresult/:id" element={<RoomsResult />} />
        <Route path="/roomsanswersheet/:id" element={<RoomsAnswerSheet />} />
        <Route path="/paymentstatus/:id" element={<PaymentStatus />} />
      </Route>
    </Routes>
  );
}
<ToastContainer />
export default App;
