import React, { useCallback, useContext, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import Span from "../Translation/Translate";
import { useNavigate, useParams } from "react-router-dom";
import { Get_Req_Participants_Quiz } from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";
import { GRAPHQL_URL, IMAGE_URL } from "../../config/urls";
import { ToastContainer, toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import { Context } from "../..";
import { RevolvingDot } from "react-loader-spinner";

const RoomNotification = () => {
    const { createdStoreData } = useContext(Context);
    const {Roomsid} = useParams();
    const Navigate = useNavigate();
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [roomCard, setroomCard] = useState([])
    const { loading, error, data, refetch } = useQuery(Get_Req_Participants_Quiz, {
        variables: { room_id: Roomsid, page: 1 },
    });

    useEffect(() => {
        refetch()
    }, [refetch]);
    
    useEffect(() => {
        if (data) {
            setroomCard((prevQuizzes) =>
                currentPage === 1 ? data?.get_req_participants_of_quiz?.response : [...prevQuizzes, ...data?.get_req_participants_of_quiz?.response]
            );
            setTotalPages(data?.get_req_participants_of_quiz?.totalPages);
        }
    }, [data, currentPage]);

    

    const loadMore = useCallback(() => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
            refetch({ page: currentPage + 1 });
        }
    }, [currentPage, totalPages, refetch]);

    

    const AcceptRequest = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "room_id": Roomsid,
            "user_id": id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${GRAPHQL_URL}/participant/room/accept/request`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.status == 1) {
                    toast(result.msg)
                    refetch()

                } else if (result.status === "TOKEN_ERR") {
                    basic.setJwt("")
                    toast("Logged in other device! Please login again");
                }
                else {
                    console.log(result?.Backend_Error);
                }
            })
            .catch((error) => console.error(error));
    };

    const RejectRequest = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "room_id": Roomsid,
            "user_id": id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${GRAPHQL_URL}/participant/room/reject/request`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.status == 1) {
                    toast(result.msg)
                    refetch()

                } else if (result.status === "TOKEN_ERR") {
                    basic.setJwt("")
                    toast("Logged in other device! Please login again");
                }
                else {
                    console.log(result?.Backend_Error);
                }
            })
            .catch((error) => console.error(error));
    };
    console.log("createdStoreData", createdStoreData);
   
    return (
        <>
            <ToastContainer />
            <div style={{
                height: "100vh", width: "100vw", overflow: "hidden", flexDirection: "column",
                display: "flex", backgroundColor: "white"
            }} >

                <div className='d-flex flex-column' >
                    <div style={{ backgroundColor: "#6f42c1" }}>
                        <div className="container">

                            <div className="row pt-3 d-flex  align-items-center" style={{ height: "10vh" }}>
                                <div className="col-3 d-flex justify-content-center align-items-center">
                                    <IoMdArrowRoundBack onClick={() => Navigate(`/createenterRoom/${Roomsid}`)} style={{ fontSize: "22px", cursor: "pointer", color: "white" }} />
                                </div>
                                <div className="col-6 text-center">
                                    <span style={{ fontSize: "1.5rem", fontWeight: "700", color: "white" }}
                                        className="mb-0 heading "><Span>Notifications</Span> </span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="flex-grow-1 container example " style={{ overflowY: "scroll", overflowX: "hidden" }}>
                <div className="row pb-4 pt-2">
                        {loading && currentPage === 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
  <RevolvingDot
    visible={true}
    height="80"
    width="80"
    color="#8663e0"
    ariaLabel="revolving-dot-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
</div>}
                        {error && <p>Error: {error.message}</p>}
                        {!loading && !error && roomCard?.length === 0 && <span>No Data Found</span>}
                        {!loading && !error && roomCard?.length > 0 && (
                            <div className="col-12">
                                {roomCard?.map((res, index) => (
                                    <div style={{ padding: "12px" }} key={index}>
                                        <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                                            <div className="d-flex gap-2 ">
                                                <img alt="" src={`${IMAGE_URL}${res.image}`} style={{
                                                    borderRadius: "50%",
                                                    width: " 50px",
                                                    height: "50px"
                                                }}></img>
                                                <div className="center-y t3" style={{ fontSize: "16px" }}><span style={{ marginLeft: "2px" }}>{res.name}</span>{""} has requested to join</div>
                                            </div>
                                            <div className="d-flex justify-content-between pe-2" style={{ marginLeft: "80px" }}>
                                                <div className="d-flex gap-2 mb-2">
                                                    <button style={{ backgroundColor: "#EFFFF6", color: "#129C73", border: "1px solid #129C73", width: "100px", borderRadius: "8px", padding: "10px" }} onClick={() => {
                                                        AcceptRequest(res?._id)
                                                    }}>Accept</button> <button style={{ backgroundColor: "#FFEFEF", color: "#DC1111", border: "1px solid #DC1111", width: "100px", borderRadius: "8px", padding: "10px" }} onClick={() => {
                                                        RejectRequest(res?._id)
                                                    }}>Reject</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {currentPage < totalPages && (
                            <div className="d-flex justify-content-center my-3">
                                <button onClick={loadMore} className="btn btn-primary">
                                    Load More
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};


export default RoomNotification;
