import React, { useEffect, useRef, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import bird from "../assets/bird.gif";
import { toast } from "react-toastify";
import { QUIZMICRO } from "../../config/urls";
import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
  import LoaderComponent from "../utils/LoaderComponent";
import Span from "../Translation/Translate";


const SavedExamsAdded = () => {
  const Navigate = useNavigate();
  const [getLatestName, setGetLatestName] = useState([]);

  const page=useRef(1)
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const [isLoading, setIsLoading] = useState(false)

  const savedGetQuizzName = async () => {
    setIsLoading(true)
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZMICRO}/participants/get/saved/exams`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result?.status === 1) {
            setGetLatestName(result?.data);
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    savedGetQuizzName();
  }, [])

  return (
    <>
      <div className="d-flex flex-column" style={{ overflow: "hidden" }}>
        <div className="flex-grow-0">
          <div className="">
            <div className="row mt-2 " style={{ height: "10vh" }}>
              <div className="col-3">
                <IoArrowBackOutline
                  style={{
                    height: "5vh",
                    marginLeft: "1rem",
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Navigate("/myexams");
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="row mt-3 p-2 col-sm-6 col-md-4 d-flex w-100"
            style={{ justifyContent: "center" }}
          >
            <div
              className=" d-flex gap-2 w-100"
              style={{ justifyContent: "center" }}
            >
              <h4 className="mt-1"><Span>Exams Successfully Added</Span></h4>
            </div>
          </div>
        </div>
        <div style={{ flexDirection: "column", justifyContent: "center" }}>
          <div
            className="d-flex "
            style={{ justifyContent: "center", hight: "30vh" }}
          >
            <img src={bird} style={{ width: "35vh" }} alt="icon" />
          </div>
          <div className=" d-flex" style={{ justifyContent: "center" }}>
            <div className="">
            <h4>
            <Span>Now you can participate in</Span> <br />
            <Span>Quizzes from following Exams...</Span>
            </h4>
            </div>
            <div
            className="mt-3 row flex-grow-1  pb-5 "
            style={{ overflowY: "auto" }}
          >
            {(getLatestName?.length === 0 && !isLoading) ? (
              <EmptyContainer
                actionName="Reload"
                action={() =>savedGetQuizzName(page.current)}
                message="No Data Found"
              />
            ) :
              getLatestName?.map((res) => {
              return (
                <>
                  <div className="col-12 p-2">
                    <div className="border p-2 d-flex rounded">
                      <div className="flex-grow-1 d-flex gap-2">
                        {/* <div className="center-data flex-grow-0"
                        style={{
                          width: "40px",
                          height: "40px",
                          border: "1px solid #D9D9D9",
                          borderRadius: "50px",
                          justifyContent: "center",
                          objectFit: "cover",
                           overflow: "auto",
                          
                        }}
                        >
                          <img src={`${IMAGEURL}${res?.image}`} alt="#" style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }} />
                        </div> */}
                        <div className="flex-grow-1 center-y t2">
                        <Span> {res?.category_name}</Span>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </>
              );
            })}
            {isLoading && <LoaderComponent />}
          </div>
          </div>
          <div
            className="w-100  d-flex mt-2"
            style={{ justifyContent: "center" }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default SavedExamsAdded;
