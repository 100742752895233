import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Bottom from './Bottom';
import HomeData from './HomeData';
import './Home.css'
import AuthenticationApiService from '../../services/api/AuthenticationApiService';
import { BasicServices } from '../../services/BasicServices';
import { useNavigate } from 'react-router-dom';
import { FcOnlineSupport } from "react-icons/fc";

//Home page
const Home = () => {
const Navigate = useNavigate();
 

  return (
    <div style={{ height: "100vh", width: "100vw", overflow: "hidden", display: "flex", flexDirection: "column" , backgroundColor:"white"}}>
    {/* Navbar */}
    <div className='p-2' style={{ flex: "0 0 auto" ,backgroundColor:"#6f42c1" }}>
      <Navbar />
    </div>

    {/* Main Content */}
    <div className='example' style={{ flex: "1 1 auto", overflowY: "auto", padding: "20px"   }}>
      <HomeData />

      {/* <div style={{position:"sticky" , bottom:0,float: "right" , zIndex:1000  , width:"fit-content"}}><FcOnlineSupport style={{fontSize: "50px", cursor: "pointer"}} onClick={() => Navigate("/chatbot")} /></div> */}
    </div>

    {/* Bottom Section */}
    <div style={{ flex: "0 0 auto",  backgroundColor:"#6f42c1", padding: "7px" , paddingTop:0, color:"white"}}>
      <Bottom />
    </div>
  </div>
  )
}

export default Home;
