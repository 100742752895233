import React, { useContext, useEffect, useState } from "react";
import menuIcon from "../assets/menuIcon.svg";
import { useNavigate, NavLink } from "react-router-dom";
import Bottom from "../Home/Bottom";

import Sidebar from "../Home/Sidebar";
import { Context } from "../..";
import "../Wallet/Wallet.css";
import { TbWallet } from "react-icons/tb";
import { IoShareSocial } from "react-icons/io5";
import { MdKeyboardArrowRight, MdOutlineMenu } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { FaArrowDownLong } from "react-icons/fa6";
import { FaArrowUpLong } from "react-icons/fa6";
import { LuArrowUpDown } from "react-icons/lu";
import { BsBank2 } from "react-icons/bs";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import WalletApiService from "../../services/api/WalletApiService";
import { FaQuestion } from "react-icons/fa6";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FaLongArrowAltUp } from "react-icons/fa";
import { CgCloseO } from "react-icons/cg";
import { IoMdArrowRoundBack } from "react-icons/io";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";
import { toast } from "react-toastify";

const MyWallet = () => {
  const { handleShow, settransactionData } = useContext(Context);
  const Navigate = useNavigate();
  const walletService = new WalletApiService();
  const [walletData, setWalletData] = useState({});
  const [wallethistory, setWallethistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt


 
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);



useEffect(() => {
  const fetchTransactions = async (page) => {
    const res = await walletService.getTransactions(page);
    if (res?.status === 1) {
      setWalletData(res);
      setWallethistory((prev) => [...prev, ...res.transactions]);
      setTotalPages(res.totalPages);
    } else if (res?.status === "TOKEN_ERR") {
      basic.setJwt("");
      Navigate("/Register");
      toast("Logged in other device! Please login again");
    } else {
      console.log("Error While Retrieving Transactions", res);
    }
  };

  fetchTransactions(currentPage);
}, [currentPage]);


console.log("wallethistory", wallethistory);

  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            height: "100vh",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div
            className=" example"
            style={{
              backgroundColor: "#6f42c1",
              color: "white",
              height: "10vh"
            }}
          >
            <div className="container ">
              <div className="row pt-3  d-flex align-items-center">
                <div className="col-3 d-flex justify-content-center align-items-center ">
                  {/* <img src={menuIcon} onClick={() => Navigate(-1)} alt='jg' style={{ height: "5vh" }} /> */}
                  <IoMdArrowRoundBack
                    onClick={() => Navigate("/")}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  />
                </div>
                <div className="col-6  text-center">
                  <span
                    style={{ fontSize: "1.5rem", fontWeight: "700" }}
                    className="mb-0 "
                  >
                    <Span> My Wallet </Span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" example"
            style={{ height: "88vh", overflowY: "auto" }}
          >
            <section>
              <div className="container p-4">
                <div className="row example boxShadow">
                  <div className="col-lg-12 col-md-12 col-sm-12 boxShadow">
                    <div className="row justify-content-center aling-items-center ">
                      <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className=" ">
                          <div
                            className="card brain"
                            style={{
                              width: "100%",
                              backgroundColor: "#ee4485",
                              border: "none",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="card-body">
                              <div className="row pt-2">
                                <div className="col-5">
                                  <h6
                                    style={{
                                      color: "#FFFFFF",
                                      fontWeight: "600",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    <TbWallet
                                      style={{
                                        width: "30px",
                                        fontSize: "22px",
                                      }}
                                    />{" "}
                                    <Span> My Wallet</Span>
                                  </h6>
                                  <div className="pt-2">
                                    <h4
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: "600",
                                        color: "#FFFFFF",
                                      }}
                                    >
                                      ₹ &nbsp; {walletData.wallet}
                                    </h4>
                                  </div>
                                  {/* <div className="pt-2">
                                    <h6 className="car p-1">+120.56%</h6>
                                  </div> */}
                                </div>
                                <div className="col-7">
                                  {/* <div className="d-flex justify-content-end">
                                    <h6
                                      style={{
                                        color: "#FFFFFF",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <Span>Share</Span> <IoShareSocial />
                                    </h6>
                                  </div> */}
                                  <div
                                    className="d-flex justify-content-end "
                                    style={{ paddingTop: "50px" }}
                                  >
                                    <h6
                                      style={{
                                        marginRight: "4px",
                                        color: "#FFFFFF",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <Span>Spent</Span>
                                    </h6>
                                    <h6
                                      style={{
                                        color: "#FFFFFF",
                                        fontWeight: "600",
                                        fontSize: "20px",
                                      }}
                                    >
                                      ₹ {walletData.investmoney}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="">
                                  <button
                                    onClick={() => {
                                      Navigate("/wallet/earnings");
                                    }}
                                    className="btn-btn brain2 p-2"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: "10px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      color: "#FFFFFF",
                                      fontSize: "18px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {" "}
                                    <span style={{ marginLeft: "10px" }}>
                                      {" "}
                                      <Span>Total Transactions</Span> ₹{" "}
                                      {walletData.redemmoney}
                                    </span>{" "}
                                    <span style={{ marginRight: "5px" }}>
                                      <Span>Details</Span>{" "}
                                      <MdKeyboardArrowRight
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "19px",
                                        }}
                                      />
                                    </span>{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container">
                <div className="row pt-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-3 d-flex justify-content-center">
                        <div
                          className=""
                          onClick={() => {
                            Navigate("/wallet/earnin");
                          }}
                        >
                          <button
                            className="btn-btn"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              border: "none",
                              marginBottom: "15px",
                            }}
                          >
                            <FaArrowDownLong
                              style={{
                                color: "#8A8C94",
                                fontWeight: "900",
                                fontSize: "23px",
                              }}
                            />
                          </button>
                          <h6
                            className="d-flex justify-content-center"
                            style={{ fontWeight: "500", fontSize: "16px" }}
                          >
                            <Span>Deposit</Span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-3 d-flex justify-content-center">
                        <div
                          className=""
                          onClick={() => {
                            Navigate("/wallet/earni");
                          }}
                        >
                          <button
                            className="btn-btn"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              border: "none",
                              marginBottom: "15px",
                            }}
                          >
                            <FaArrowUpLong
                              style={{
                                color: "#8A8C94",
                                fontWeight: "900",
                                fontSize: "23px",
                              }}
                            />
                          </button>
                          <h6
                            className="d-flex justify-content-center"
                            style={{ fontWeight: "500", fontSize: "16px" }}
                          >
                            <Span>Withdraw</Span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-3 d-flex justify-content-center">
                        <div className="">
                          <NavLink to="/TransactionHistory">
                            <button
                              className="btn-btn"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                                border: "none",
                                marginBottom: "15px",
                              }}
                            >
                              <LuArrowUpDown
                                style={{
                                  color: "#8A8C94",
                                  fontWeight: "900",
                                  fontSize: "27px",
                                }}
                              />
                            </button>
                            <h6
                              className="d-flex justify-content-center"
                              style={{ fontWeight: "500", fontSize: "16px" }}
                            >
                              <Span>History</Span>
                            </h6>
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-3 d-flex justify-content-center">
                        <div className="">
                          <button
                            className="btn-btn"
                            onClick={() => {
                              Navigate("/wallet/bank");
                            }}
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              border: "none",
                              marginBottom: "15px",
                            }}
                          >
                            <BsBank2
                              style={{
                                color: "#8A8C94",
                                fontWeight: "900",
                                fontSize: "25px",
                              }}
                            />
                          </button>
                          <h6
                            className="d-flex justify-content-center"
                            style={{ fontWeight: "500", fontSize: "16px" }}
                          >
                            <Span>+Add Bank</Span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </section>

            <section>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="row px-1">
          <div className="col-8">
            <div className="pt-2">
              <h6 style={{ fontWeight: "bold", fontSize: "20px", color: "#000000" }}>
                <Span>Transaction History</Span>
              </h6>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-end">
              <NavLink to="/TransactionHistory">
                <h6
                  className="p-2"
                  style={{
                    backgroundColor: "#F2F2F2",
                    color: "#8A8C94",
                    fontSize: "16px",
                    fontWeight: "500",
                    display: "inline-block",
                    justifyItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <Span>View</Span> <MdArrowForwardIos />
                </h6>
              </NavLink>
            </div>
          </div>
        </div>

        {wallethistory.length > 0 ? (
          <>
            {wallethistory.map((item, index) => (
              <div className="row mt-3" style={{ cursor: "pointer" }} key={index}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div
                    className="card-container"
                    onClick={() => {
                     
                      switch (item.success) {
                        case 0:
                          Navigate(`/failed/${item?._id}`, { state: { ...item } });
                          break;
                        case 1:
                          Navigate(`/success/${item?._id}`, { state: { ...item } });
                          break;
                        case -1:
                          Navigate(`/pending/${item?._id}`, { state: { ...item } });
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <div>
                      {item.success === 1 ? (
                        <div className="icon-wrapper">
                          {item.type === "debit" ? (
                            <FaLongArrowAltDown style={{ color: "green" }} />
                          ) : (
                            <FaLongArrowAltUp style={{ color: "green" }} />
                          )}
                        </div>
                      ) : item.success === -1 ? (
                        <div className="icon-wrapper1" style={{ backgroundColor: "rgba(255, 165, 0,0.2)" }}>
                          {item.type === "debit" ? (
                            <FaLongArrowAltDown style={{ color: "orange" }} />
                          ) : (
                            <FaLongArrowAltUp style={{ color: "orange" }} />
                          )}
                        </div>
                      ) : (
                        <div className="icon-wrapper" style={{ backgroundColor: "rgba(255, 239, 239, 1)" }}>
                          {item.type === "debit" ? (
                            <FaLongArrowAltDown style={{ color: "red" }} />
                          ) : (
                            <FaLongArrowAltUp style={{ color: "red" }} />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="content-wrapper">
                      <h1 className="title">₹ {item.amount}</h1>
                      <p className="subtitle">{item.order_datetime}</p>
                    </div>

                    {item.success === 1 ? (
                      <div className="status-wrapper">
                        <div className="status-icon">
                          <BsFillPatchCheckFill style={{ color: "rgba(18, 156, 115, 1)" }} />
                        </div>
                        <h1 className="status-title mt-2" style={{ color: "rgba(18, 156, 115, 1)" }}>
                          <Span> Success</Span>
                        </h1>
                      </div>
                    ) : item.success === -1 ? (
                      <div className="status-wrapper">
                        <div className="status-icon">
                          <BsFillPatchCheckFill style={{ color: "orange" }} />
                        </div>
                        <h1 className="status-title mt-2" style={{ color: "orange" }}>
                          <Span>Pending</Span>
                        </h1>
                      </div>
                    ) : (
                      <div className="status-wrapper">
                        <div className="status-icon">
                          <CgCloseO style={{ color: "red" }} />
                        </div>
                        <h1 className="status-title mt-2" style={{ color: "red" }}>
                          <Span>Failed</Span>
                        </h1>
                      </div>
                    )}

                    <div
                      style={{ padding: 10, cursor: "pointer" }}
                      onClick={() => {
                        settransactionData({ ...item });
                        switch (item.success) {
                          case 0:
                            Navigate(`/failed`, { state: { ...item } });
                            break;
                          case 1:
                            Navigate(`/success`, { state: { ...item } });
                            break;
                          case -1:
                            Navigate(`/pending`, { state: { ...item } });
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      <FaAngleRight />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {currentPage < totalPages && (
              <div className="row mt-1 mb-3">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      // onClick={() => setCurrentPage((prev) => prev + 1)}
                      onClick={() => Navigate("/TransactionHistory")}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="row mt-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="justify-content-center">
                <p style={{ textAlign: "center" }}>
                  <Span> No wallet history data available</Span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
</section>

          </div>
          <div
            className="example1"
            style={{

              backgroundColor: "#6f42c1",

              color: "white",
            }}
          >
            <Bottom />
          </div>
        </div>
      </div>

      <Sidebar />
    </>
  );
};

function ShowTransaction({ amount, type, time, date, status }) {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12  pt-2">
        <div
          className="row"
          style={{ border: "2px solid #F2F2F2", borderRadius: "6px" }}
        >
          <div className="col-2">
            <div className="mx-3 pt-2">
              <h6
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "70px",
                  backgroundColor: "#EFFFF6",
                  color: "#129C73",
                  paddingTop: "9px",
                  paddingLeft: "12px",
                }}
              >
                <FaArrowDownLong />
              </h6>
            </div>
          </div>
          <div className="col-6">
            <div className="pt-1">
              <h6
                className="m-0 p-0"
                style={{
                  color: "#2E2E2E",
                  fontWeight: "700",
                  fontSize: "17px",
                }}
              >
                ₹ {amount}
              </h6>
              <span
                style={{
                  fontSize: "11px",
                  color: "#8A8A8A",
                  fontWeight: "400",
                }}
              >
                {time} | {date}
              </span>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <h6 className="pt-3">
              <BsFillPatchCheckFill style={{ color: "#129C73" }} />{" "}
              <span style={{ color: "#129C73" }}><Span> Success </Span></span>{" "}
              <MdArrowForwardIos />
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyWallet;
