import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import Span from "../Translation/Translate";
import { FaArrowLeft } from "react-icons/fa6";


const StudySaved = () => {
  const Navigate = useNavigate();
  const { exam_id } = useParams();
  const [showStudyPdf, setShowStudyPdf] = useState([]);
 
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const [isLoading , setIsLoading] = useState(false)

  // api to show study pdf
  const getAllStudyPdf = async () => {
    setIsLoading(true)
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZMICRO}/participants/get/pdfs/of/particular/exam?cat_id=${exam_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result?.status === 1) {
          setShowStudyPdf(result?.send_data);
          
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } 
        else {
          toast(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getAllStudyPdf();
  }, []);

  return (
    <>
      <div style={{ height: "100vh", width: "100vw", overflow: "hidden", }}
        className="d-flex flex-column ">

      <div className="flex-grow-0">
      <div className="d-flex justify-content-between border-bottom p-2 ps-md-4 pb-3" 
          style={{backgroundColor:"#6f42c1",}}>
            <div className="center-data">
              <FaArrowLeft color="white" size={20} cursor="pointer"
                onClick={() => {
                  Navigate("/myexams")
                }}
              />
            </div>

            <div style={{color:"white", fontSize: "22px"}} className="t1"><Span>Study Material</Span></div>
            <div style={{ opacity: 0 }}>hello</div>
          </div>
      </div>

      <div className="d-flex flex-column flex-grow-1 "
      style={{overflowY: "auto", overflowX: "hidden"}}
      >
        <div className="">
          <div className="container mt-2">
            <div className="row">

              {( showStudyPdf?.length ===0 && !isLoading)? (
                <EmptyContainer
                actionName="Reload"
                action={()=>getAllStudyPdf()}
                message="No Data Found"
              />
              ) : (
                showStudyPdf?.map((res1, key1) => {
                  return (
                    <div key={key1} className="col-12 mt-3 ">
                      <span className="card">
                        <div className="d-flex ps-2 pb-2">
                          <span className="col-2">
                            <div
                              className="mt-2 d-flex justify-content-center align-items-center"
                              style={{
                                width: "40px",
                                height: "40px",
                                border: "1px solid #D9D9D9",
                                borderRadius: "50px",
                                justifyContent: "center",
                                objectFit: "cover",
                                overflow: "auto",
                                backgroundColor: "#EFEFEF",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={`${IMAGE_URL}${res1?.logo}`}
                                alt="#"
                              />
                             
                            </div>
                          </span>
                          <div className="col-8 mt-2">
                            <div className="">
                              <span className="ml-5">
                                <p
                                  style={{
                                    marginBottom: "1px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                    color: "#000000",
                                  }}
                                >
                                  <Span>{res1?.name}</Span>
                                </p>
                              </span>
                            </div>
                            <div className="">
                              <span className="" style={{ color: "#7E7E7E" }}>
                                Total{" "}
                                <span className="" style={{ color: "#D92828" }}>
                                  {res1?.count}
                                </span>{" "}
                                <Span> PDFs</Span>
                              </span>
                            </div>
                          </div>
                          <span
                            className="col-2 mt-3"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              
                              Navigate(`/question/${res1?.cat_id}/${res1?.pdf_type}`);
                            }}
                          >
                            <FaArrowRightLong />
                          </span>
                        </div>
                      </span>
                    </div>
                  );
                })
              )}
          {isLoading && <LoaderComponent/>}
           
            </div>
          </div>
        </div>
      </div>

      </div>
    </>
  );
};

export default StudySaved;
