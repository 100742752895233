import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import HistoryApiService from '../../services/api/HistoryApiService';
import { ActiveCard } from './LossHistory';
import { Spinner } from 'react-bootstrap';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from '../utils/LoaderComponent';
import Span from '../Translation/Translate';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { IMAGE_URL } from '../../config/urls';
import coin from "../assets/icons/coin.svg";
import clock from "../assets/icons/clock.svg";
import ticket from "../assets/icons/ticket.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import calender from "../assets/icons/calender.svg";

const WinHistory = ({ order }) => {
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const historyService = new HistoryApiService();
  const page = useRef(1);
  const [totalPages, setTotalPages] = useState(1);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const observer = useRef();

  const callApi = (cur_page) => {
    setIsLoading(true);
    historyService.getWonQuizzes(order, cur_page).then((res) => {
      if (res?.status === 1) {
        setHistoryData(prevData => [...prevData, ...res?.subActiveQuizz]);
        setTotalPages(res?.totalpages);
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("");
        navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        console.log("Error While Retrieving All History Data", res);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const lastElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page.current < totalPages) {
        page.current += 1;
        callApi(page.current);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, totalPages]);

  useEffect(() => {
    page.current = 1;
    setHistoryData([]); // Reset history data on order change
    callApi(1);
  }, [order]);

  return (
    <div className="row">
      {(historyData.length === 0 && !isLoading) ? (
        <EmptyContainer
          actionName="Reload"
          action={() => callApi(page.current)}
          message="No Data Found"
        />
      ) : (
        historyData.map((res, index) => (

          <div ref={index === historyData.length - 1 ? lastElementRef : null} className="col-12 col-md-6 col-lg-4 p-3" key={index + 1}>
            <div className="card2 p-2 d-flex flex-column gap-3 rounded">
              <div className="d-flex gap-2">
                <div className="center-data flex-grow-0 notsaved">
                  <img
                    src={`${IMAGE_URL}${res?.banner}`}
                    alt="exam_icon"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  ></img>
                </div>
                <div className="flex center-y t3">
                  <Span>{res?.quiz_name}</Span>
                </div>
                <div
                  className="flex center-y t3"
                  style={{ margin: "auto", float: "right" }}
                >
                  <Span>
                    {res?.is_res_dec == 1 ? (
                      <span className="" style={{ color: "green" }}>
                        {" "}
                        Result Declared
                      </span>
                    ) : res?.is_res_dec == 0 ? (
                      <span className="" style={{ color: "green" }}>
                        {" "}
                        Pending
                      </span>
                    ) : (
                      <></>
                    )}
                  </Span>
                </div>
              </div>

              <div className="row">
                <div className="col-6 d-flex gap-2">
                  <span className="tgr">
                    <Span>Fees</Span>
                  </span>
                  <img src={coin} alt="fees"></img>
                  <span className="tgo">{res.entryFees}</span>
                </div>
                <div className="col-6 d-flex gap-2">
                  <img src={calender} alt="time" color="#7E7E7E"></img>
                  <span className="tgr">{res.sch_time.split(" ")[0]}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-6 d-flex gap-2">
                  <span className="tgr">
                    <Span>Prize</Span>
                  </span>
                  <img src={coin} alt="prize"></img>
                  <span className="tgo">{res.prize}</span>
                </div>
                <div className="col-6 d-flex gap-2">
                  <img src={clock} alt="time" color="#7E7E7E"></img>
                  <span className="tgr">{res.sch_time.slice(10, 19)}</span>
                </div>
              </div>

              {/* declare time  */}
              <div className="row">
                <div className="col-12 d-flex gap-2">
                  <span className="tgr pe-2">
                    <Span>Declared Time</Span>
                  </span>
                  <img src={calender} alt="time" color="#7E7E7E"></img>
                  <span className="tgr">
                    {res.crontab_result_time.split(" ")[0]}
                  </span>
                  <img src={clock} alt="time" color="#7E7E7E"></img>
                  <span className="tgr">
                    {res.crontab_result_time.slice(10, 19)}
                  </span>
                </div>
                {/* <div className="col-4 d-flex gap-2">
                
            </div> */}
              </div>

              <div>
                <div className="d-flex gap-2 mb-2">
                  <img src={ticket} alt="range"></img>
                  <div style={{ fontSize: "14px" }}>
                    <span>{res.slot_aloted}</span>/
                    <span style={{ color: "#2188E7" }}>{res.slots}</span>
                  </div>
                </div>

                <ProgressBar
                  completed={res.slot_aloted}
                  bgColor="linear-gradient(90.11deg, #59AFFF 1.72%, #2188E7 98%)"
                  height="10px"
                  isLabelVisible={false}
                  baseBgColor="#E4E9EC"
                  labelColor="#e80909"
                  animateOnRender
                  maxCompleted={res.slots}
                />
              </div>

              <div
                style={{ cursor: "pointer" }}
                className="btnanst3 rounded center-data"
                onClick={() => {
                  if (res?.is_res_dec == 1) {
                    navigate(`/quizResult/${res._id}`);
                  }
                }}
              >
                <Span>
                  {res?.is_res_dec == 1 ? "View Result" : "Wait, Result is Pending"}
                </Span>
              </div>
            </div>
          </div>

        ))
      )}

      {isLoading && <LoaderComponent />}
    </div>
  );
};

export default WinHistory;
