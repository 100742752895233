import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import kvPairs from "./SecretKeys";


//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: kvPairs.API_KEY,
  authDomain: kvPairs.AUTH_DOMAIN,
  projectId: kvPairs.PROJECT_ID,
  storageBucket: kvPairs.STORAGE_BUCKET,
  messagingSenderId: kvPairs.MESSAGING_SENDER_ID,
  appId: kvPairs.APP_ID,
  measurementId: kvPairs.MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// if('serviceWorker' in navigator){
//   navigator.serviceWorker.register('service-worker.js')
// }

// Messaging service
export const messaging = getMessaging(app);
console.log(messaging.onBackgroundMessageHandler)

// onMessage(messaging,console.log)


