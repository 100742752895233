import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from "react-icons/io5";
import menuIcon from "../assets/menuIcon.svg";
import {  useNavigate, useParams } from 'react-router-dom';
import sbi from "../assets/icons/sbi.svg";
import { FaArrowRight } from "react-icons/fa6";
import { FaAward } from "react-icons/fa";
import { QUIZMICRO } from '../../config/urls';
import { toast } from 'react-toastify';
import { IMAGE_URL } from '../../config/urls';
import bulb from "../assets/bulb.gif"
import Online from './Online';
import { BasicServices } from '../../services/BasicServices';
import Span from '../Translation/Translate';

// const pdf = [
//     { name: "SBI-PO1" },
//     { name: "SBI-PO2" },
//     { name: "SBI-PO3" },
//     { name: "SBI-PO4" },
//     { name: "SBI-PO5" },
//     { name: "SBI-PO6" },
//     { name: "SBI-PO7" },
//     { name: "SBI-PO17" },
//     { name: "SBI-PO16" },
//     { name: "SBI-PO15" },
//     { name: "SBI-PO14" },
//     { name: "SBI-PO13" },
//     { name: "SBI-PO12" },
//     { name: "SBI-PO11" },
// ];
const Pdf = () => {
    const Navigate = useNavigate();
    const [pdf, setpdf] = useState([])
    const {id,category_name} = useParams();
    const [value,setValue] = useState(0)
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt

    const getPdfType = async () => {
        console.log('called')
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);


            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${QUIZMICRO}/participants/get/pdfs/of/particular/exam?cat_id=${id}`, requestOptions)
            const result = await response.json();
            if (result?.status == 1) {
                setpdf(result.send_data)
            } else if(result.status==0) {
                toast.error("failed")
            }else if (result.status === "TOKEN_ERR") {
                basic.setJwt("");
                Navigate("/Register");
                toast("Logged in other device! Please login again");
              }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPdfType()
    }, [])

    return (
        <>
        <div style={{height:"100vh",width:"100vw", overflow:"hidden"}}>
        <div className='example border' style={{backgroundColor:"#6f42c1",  height: "10vh", width:"100vw"}} >
        <div className=''>
            <div className='row mt-2 example '>
                <div className='col-3 d-flex align-items-center justify-content-center'>

                    <IoArrowBackOutline style={{ height: "5vh", marginLeft: "1rem", fontSize: "30px",cursor:"pointer",color:"white" }} onClick={() => {
                        Navigate('/study')
                    }} />

                </div>
                <div className='col-6 text-center pt-2 '>
                    <h3 style={{color:"white"}} className=''> <Span>Study Materials</Span></h3>
                </div>
                <div className='col-3'></div>

            </div>
        </div>
        </div>
       
            <div className='d-flex flex-column  container ' style={{ overflow: "hidden" , height:"100vh"}}>
                <div className='flex-grow-0 example'>
           

                    {/*<div className='row mt-3 p-2 col-sm-6 col-md-4 '>
                        <div className=' d-flex gap-2'>
                            <img src={sbi} className='ms-4 '></img>
                            <h4 className='mt-1'>{category_name}</h4>
                        </div>
                        <div className='row d-flex  justify-content-center mt-2'>
                            <div className='col-6 mt-2'>
                                <button className={`${value===0?'btn1':'btnn'} p-2 text-center w-100`}  onClick={()=>{setValue(0)}} >
                                    Free PDF
                                </button>
                            </div>

                            <div className='col-6 mt-2' onClick={()=>{setValue(1)}} >
                                <button className={`${value===1?'btn1':'btnn'} p-2 text-center w-100`} >
                                    Online Classes
                                </button>
                            </div>
                        </div>

                            </div>*/}


                            


                </div>
                <div className='container example border'>
                    {
                        value===0?<>
                        
                    <div  style={{ paddingBottom: "2rem", overflowX: "hidden", overflowY: "auto", height: "90vh" }} className='example'>

                        <div className="row example ">
                            {
                                pdf?.map((res) => {
                                    return (
                                        <div className="col-12 col-sm-6 col-md-6  p-3 d-flex example " style={{ lineHeight: "1px" }}>

                                            <div className="flex-grow-1 p-3 pb-4 border boxshadow example d-flex rounded gap-4 ">
                                                <div className="p-2 col-1" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                                                    <img src={IMAGE_URL+res.logo} style={{height:"25px",width:"25px",borderRadius:"50px"}} />
                                                    
                                                </div>
                                                <div className="  col-6" style={{cursor:"pointer"}} onClick={() => {
                                                    Navigate(`/Question/${res.cat_id}/${res.pdf_type}`)
                                                }}>
                                                    <h4><Span>{res.name}</Span> </h4>
                                                    <div className="">
                                                       <Span>Total</Span>  <span style={{ color: "red" }}>{res.count}</span> <Span>PDFs</Span> 
                                                    </div>
                                                </div>

                                                <div className='col-4' style={{ display: "flex", justifyContent: "center", width: "28%",marginRight:"1rem", alignItems:"center"}}>
                                                    <FaArrowRight style={{ fontSize: "25px", }} onClick={()=>{Navigate(`/Question/${res.cat_id}/${res.pdf_type}`)}}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>
                        </>:<>
                       <Online/>
                        </>
                    }
                </div>
            </div>
            
            </div>
        </>
    )
}

export default Pdf
