import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../Home/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../..";
import menuIcon from "../assets/menuIcon.svg";
import sbi from "../assets/icons/sbi.svg";
import "../Study/Study.css";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import "../Study/Study.css";
import { IoIosArrowForward, IoMdArrowRoundBack } from "react-icons/io";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoIosSearch } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { MdOutlineMenu } from "react-icons/md";
import Span from "../Translation/Translate";

const StudyMaterial = () => {
  const [Click, setClick] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const { handleShow } = useContext(Context);
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow2 = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [catname, setCatname] = useState([]);
  const [ExamId, setExamId] = useState([""]);
  const [selectQuizzId, setSelectQuizzId] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');


  const page = useRef(1)
  const [selectedExams, setSelectedExams] = useState([]); // Initialize an empty array

  const handleClick = (res) => {
    const isSelected = selectedExams.includes(res._id); // Check if already selected

    setSelectedExams(
      isSelected
        ? selectedExams.filter((id) => id !== res._id)
        : [...selectedExams, res._id]
    );
  };
  const [card1, setcard1] = useState([]);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt

  const NotSaved = async () => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${token}`
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${QUIZMICRO}/participants/get/not/saved/exams`,
        requestOptions
      );
      const result = await response.json();
      setIsLoading(false)
      if (result?.status == 1) {
        setCatname(result.exams);
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");
        Navigate("/Register/");
        toast("Logged in other device! Please login again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSaved = async (_id) => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${token}`
      );
      console.log("sdfdsf", selectQuizzId);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${QUIZMICRO}/participants/get/saved/exams`,
        requestOptions
      );
      const result = await response.json();
      setIsLoading(false)
      if (result?.status == 1) {
        setcard1(result.data);
      } else if (result.VAL_ERR) {
        toast.error("Failed to retrieve departments");
      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddExams = async () => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${token}`
      );

      const raw = JSON.stringify({
        exam_id: selectedExams,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${QUIZMICRO}/participants/add/exams`,
        requestOptions
      );
      const result = await response.json();
      setIsLoading(false)
      if (result?.status == 1) {
        // toast.success(result.msg)

        Navigate("/examsAdded");
        setSelectedExams([]);
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");

        Navigate("/Register");
        toast("Logged in other device! Please login again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredQuizzes = catname.filter(res => {
    return res.category_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  useEffect(() => {
    getSaved();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div style={{ backgroundColor: "#6f42c1" }}>
        <div className="container">

          <div className="row pt-3 d-flex  align-items-center" style={{ height: "10vh" }}>
            <div className="col-3 d-flex justify-content-center align-items-center">
              <IoMdArrowRoundBack onClick={() => Navigate("/")} style={{ fontSize: "22px", cursor: "pointer", color: "white" }} />
            </div>
            <div className="col-6 text-center">
              <span style={{ fontSize: "1.5rem", fontWeight: "700", color: "white" }}
                className="mb-0 heading "><Span>Study Material</Span> </span>
            </div>
            <div className="col-3  d-flex justify-content-center">
              <button
                type="button"
                className="btn addbtn btn-light "
                onClick={() => {
                  NotSaved();
                  handleShow2();
                }}
              >
                +
                <Span>Add</Span>
              </button>
            </div>


          </div>

        </div>
      </div>
      <hr />



      <div className="flex-grow-1 container example " style={{ overflowY: "auto", overflowX: "hidden" }}>
        <div className="row pb-4 pt-2" >

          {(card1?.length === 0 && !isLoading) ? (
            <EmptyContainer
              actionName="Reload"
              action={() => getSaved(page.current)}
              message="No Data Found"
            />
          ) :
            card1?.map((res, value) => {
              return (
                <>
                  <div className="col-12 col-sm-6 col-md-12 p-2 d-flex " key={value}>
                    <div
                      className=" mx-3 py-4 d-flex rounded boxshadow card"
                      onClick={() =>
                        Navigate(`/pdf/${res._id}/${res.category_name}`)
                      }
                      style={{ lineHeight: "1px", cursor: "pointer" }}
                    >
                      <div className=" d-flex gap-3 ">
                        <div className=" flex-grow-0 notsaved">
                          <img src={IMAGE_URL + res.image} style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '50%',
                          }}></img>
                        </div>
                        <div className="flex-grow-1 center-y t2">
                          <Span>{res.category_name}</Span>
                        </div>
                        <div>
                          <IoIosArrowForward style={{ fontSize: "25px" }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="bottom"
                    style={{ height: "80%" }}
                  >
                    <Offcanvas.Header>
                      <Offcanvas.Title
                        className="d-flex justify-content-center mx-auto "
                        onClick={handleClose}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "2px",
                            background: "#E1E1E1",
                          }}
                        ></div>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body
                      className="pt-3"
                      style={{ overflowY: "auto" }}
                    >
                      <div className="d-flex flex-column ">
                        <div className="flex-grow-0 ">
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <button
                              disabled
                              style={{ opacity: 0 }}
                              className="save"
                              onClick={() => AddExams(res?._id)}
                            >
                              Save
                            </button>
                            <h3><Span>Select Exams</Span> </h3>
                            <button
                              className="save"
                              onClick={() => AddExams(res?._id)}
                            >
                              <Span>Save</Span>
                            </button>
                          </div>


                          <div className="px-4">
                            <div className="center-data pt-2 flex-grow-0">
                              <div
                                className="row align-items-center mx-1 "
                                style={{
                                  backgroundColor: "#EFEFEF",
                                  border: "none",
                                  boxShadow: "4px 4px 4px #EFEFEF",
                                  height: "50px",
                                  borderRadius: "7px",
                                  width: "500px",
                                }}
                              >
                                <div className="col-3 d-flex justify-content-center mt-1">
                                  <IoIosSearch color="#7E7E7E" size={24} />
                                </div>
                                <div
                                  className="col-9 mt-1"
                                  style={{ marginLeft: "-30px" }}
                                >
                                  <input
                                    className="border-0 w-100"
                                    placeholder="Search for Exams"
                                    style={{
                                      textDecoration: "none",
                                      outline: "none",
                                      backgroundColor: "#EFEFEF",
                                      color: "#272727",
                                    }}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div
                          className="flex-grow-1 "
                          style={{ overflowY: "auto", overflowX: "hidden" }}
                        >
                          <div className="row pb-5 ">
                            {(catname?.length === 0 && !isLoading) ? (
                              <EmptyContainer
                                actionName="Reload"
                                action={() => NotSaved(page.current)}
                                message="No Data Found"
                              />
                            ) :
                              filteredQuizzes?.map((res, key) => {
                                return (
                                  <>
                                    <div className="col-12 px-3 pt-2 key={key} ">
                                      <div className="border p-2 d-flex rounded ">
                                        <div className="flex-grow-1 d-flex gap-3">
                                          <div className="center-data notsaved flex-grow-0" >
                                            <img src={IMAGE_URL + res.image} alt="cat_image" style={{
                                              width: '100%',
                                              height: '100%',
                                              objectFit: 'cover',
                                              borderRadius: '50%',
                                            }}></img>
                                          </div>
                                          <div className="flex-grow-1 center-y t2">
                                            <Span>{res?.category_name}</Span>
                                          </div>
                                        </div>

                                        <div className="flex-grow-0 center-data">
                                          <div
                                            onClick={() => handleClick(res)}
                                            className="px-3 py-0"
                                            style={{
                                              color: "#7E7E7E",
                                              backgroundColor:
                                                selectedExams.includes(res._id)
                                                  ? "#2188E7"
                                                  : "#EFEFEF",
                                              fontSize: "2rem",
                                              borderRadius: "100%",
                                              border: "none",
                                              outline: 0,
                                              cursor: "pointer",
                                            }}
                                          >
                                            {selectedExams.includes(res._id) ? (
                                              <FaCheck size={17} color="white" />
                                            ) : (
                                              <>+</>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                </>
              );
            })}
          {isLoading && <LoaderComponent />}
        </div>
      </div>




      <Sidebar />
    </>
  );
};

export default StudyMaterial;
