import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Home/Sidebar";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Context } from "../..";
import Group from "../assets/images/Group.png";
import Bottom from "../Home/Bottom";

import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { BiLogoInstagram } from "react-icons/bi";
import { IoLogoYoutube } from "react-icons/io";
import homeicon from "../assets/icons/homeicon.svg";
import { IoHomeOutline } from "react-icons/io5";
import Span from "../Translation/Translate";
const About = () => {
  const { show } = useContext(Context);
  const Navigate = useNavigate();
  const { handleShow, handleClose } = useContext(Context);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showUpArrow, setShowUpArrow] = useState(true); // State to toggle between up and down arrows

  const toggleArrow = () => {
    setShowUpArrow(!showUpArrow);
  };

  useEffect(() => {
    handleClose();
  }, []);
  return (
    <>
      <div style={{ height: "100vh", width: "100vw", overflowX: "hidden" }}>
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{
            height: "10vh",
            width: "100vw",
            backgroundColor: "#6f42c1",
            color: "white",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-3 d-flex justify-content-center align-items-center ">
                <FaArrowLeftLong
                  onClick={() => {
                    Navigate(-1);
                  }}
                  alt="jg"
                  style={{ height: "6vh", cursor: "pointer" }}
                />
              </div>
              {/* customer Header */}
              <div className="col-6  text-center pt-1 ">
                <span
                  className="MainHeading"
                  style={{ fontSize: "1.3em", fontWeight: "600" }}
                >
                  <Span> About Brain Bucks</Span>
                </span>
              </div>
              {/*customer header end  */}

              <div className="col-3 d-flex justify-content-center align-items-center ">
                <IoHomeOutline
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    Navigate("/");
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "90vh" }}>
          <div className="container">
            <div className="row ">
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                <p
                  style={{
                    marginTop: "23px",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontWeight: "600",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  <Span>
                    It takes tremendous efforts in order to provide you with
                    this wholesome experience on Brain Bucks. Kindly Rate Us,
                    and help us become better
                  </Span>{" "}
                </p>
                {/* mainheding end */}
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    marginTop: "33px",
                    marginLeft: "11px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  <Span>Message from the Desk of CEO</Span>{" "}
                </h1>
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                <img
                  src={Group}
                  style={{ marginLeft: "17px", height: "40vh" }}
                  alt=""
                />
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                <p
                  style={{
                    marginTop: "23px",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  <Span> User, It is a matter of pride and honor for</Span>
                  <Span> having an opportunity to serve such a passionate aspirant like</Span>
                  <Span>you. We at Brain Bucks are working day and night with only</Span>
                  <Span> single vision of helping young age Government Job Aspirants to</Span>
                  <Span> provide them with an open source platform, where they can</Span>
                  <Span> participate in Quiz competitions and get an opportunity to get</Span>
                  <Span>rewarded for the efforts they have put into the preparation of</Span>
                  <Span> all the competitive Exams they have been preparing for. Our</Span>
                  <Span> sole vision to empower every single aspirant to be a part of</Span>
                  <Span>our Brain Bucks family and prove the world that only hitting</Span>
                  <Span>the final milestone does not means success, even you can be</Span>
                  <Span>great and eligible in the path which you are following. As</Span>
                  <Span>said, Brain Bucks is always here to help you students to get</Span>
                  <Span>world class education and preparation methodologies for Free.</Span>
                </p>
              </div>
              {/* Messge From COO */}
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <h1 style={{justifyContent:"center",marginTop:"33px",marginLeft:"11px",fontSize:"20px",fontWeight:"600"}} >Message from the Desk of COO</h1> */}
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <img
src= {Group} style={{marginLeft:"20px"}}
/> */}
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <p style={{marginTop:"23px",fontFamily:"Inter",fontSize:"12px",fontWeight:"600",marginLeft:"10px"}}>Dear User,
We at Brain Bucks always tends to deliver the industry standard and highly relevant Quiz Questions and preparation content.
We at Brain Bucks insures the best level of customer satisfaction, and always tends to keep our services always up to highest marks in the industry.
One thing that we would like all of our users to always keep in mind that, we are always grateful towards all of you for selecting or dearest baby and making it as one of the biggest community of government job aspirants in India.
Our Team is always trying to give all of you the best resources in order to help you to get best education and preparation methodologies absolutely free of cost.
One thing which we are sure about is, no one can provide you with so high quality of education even for a heck load of money.
Once again thank you user very much for showing your Belief in Us.</p> */}
              </div>
              {/* Message From CTO */}
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <h1 style={{justifyContent:"center",marginTop:"33px",marginLeft:"11px",fontSize:"20px",fontWeight:"600"}} >Message from the Desk of CTO</h1> */}
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <img
src= {Group} style={{marginLeft:"20px"}}
/> */}
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <p style={{marginTop:"23px",fontFamily:"Inter",fontSize:"12px",fontWeight:"600",marginLeft:"10px"}}>Dear User,
We at Brain Bucks always tends to deliver the industry standard and highly relevant Quiz Questions and preparation content.
We at Brain Bucks insures the best level of customer satisfaction, and always tends to keep our services always up to highest marks in the industry.
One thing that we would like all of our users to always keep in mind that, we are always grateful towards all of you for selecting or dearest baby and making it as one of the biggest community of government job aspirants in India.
Our Team is always trying to give all of you the best resources in order to help you to get best education and preparation methodologies absolutely free of cost.
One thing which we are sure about is, no one can provide you with so high quality of education even for a heck load of money.
Once again thank you user very much for showing your Belief in Us.</p> */}
              </div>

              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <h1 style={{justifyContent:"center",marginTop:"33px",marginLeft:"11px",fontSize:"20px",fontWeight:"600"}} >Important Peoples of Brain Bucks </h1> */}
              </div>
              <div className="mainheding" style={{ flex: "0 0 auto" }}>
                {/* <p style={{marginTop:"23px",fontFamily:"Inter",fontSize:"12px",fontWeight:"600",marginLeft:"10px"}}>It take tremendous efforts of thousands of " Brain Buskeers" in order to deliver the high quality experience to you. We all should take a moment to thanks and show our gratitude towards the hard work they have put in.
Here is the list of a few key "Brain Buskeers" who have made this platform from scratch and currently taking care of this Platform, where millions of aspirants like you come and carve the initial foundation of their success, and achieving their dreams and converting them into reality.
1. Harsh Kadyan - Chief Operating Officer a. Entire UI/UX
b. Project Management
c. Business Process Automation
1. Saurav Kumawat - Chief Technology Officer a. Managing software Technicalities
b. Business Process Automation
3. Pooja Saxena - Vice President Human Resources a. Setting up new team
b. Content Management
c. Internal Business process handling d. Corporate Relations
4. Chetan Jangid - Front -End
5. Rahul Sharma - Front-End
6. Mahipal Bankawat - Digital Marketing
 and much more..</p> */}
              </div>
              {/* bottom section */}
              <div>
                <div
                  className="container"
                  style={{ height: "100%", borderTop: "1px solid #DEDEDE" }}
                >
                  <div
                    className="row align-items-center m-auto "
                    style={{ height: "100%" }}
                  >
                    <div className="mainheding" style={{ flex: "0 0 auto" }}>
                      <h1
                        style={{
                          justifyContent: "center",
                          marginTop: "33px",
                          marginLeft: "11px",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        <Span>Follow us on</Span> :-
                      </h1>
                    </div>
                    <div className="col-2 ">
                      <div
                        className={` d-flex flex-column align-items-center  `}
                      >
                        <a
                          href="https://www.instagram.com/brainbucks_india/"
                          style={{
                            color:
                              "(141.47deg, #FAFF10 2.3%, #FF1091 37.8%, rgba(133, 25, 218, 0.969856) 71.38%, rgba(181, 33, 251, 0.94) 104.64%)",
                          }}
                        >
                          <BiLogoInstagram
                            style={{
                              fontSize: "22px",
                              marginLeft: "45px",
                              marginBottom: "21px",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div
                        className={` d-flex flex-column align-items-center  `}
                      >
                        <a
                          href="https://www.linkedin.com/company/brainbucksindia/"
                          style={{ color: "#3D5DCD" }}
                        >
                          <FaLinkedinIn
                            style={{
                              fontSize: "22px",
                              marginLeft: "45px",
                              marginBottom: "21px",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div
                        className={` d-flex flex-column align-items-center  `}
                      >
                        <a
                          href="https://www.facebook.com/BrainBucksIndia/ "
                          style={{
                            color: "#3D5DCD",
                          }}
                        >
                          <FaFacebook
                            style={{
                              fontSize: "22px",
                              marginLeft: "45px",
                              marginBottom: "21px",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div
                        className={` d-flex flex-column align-items-center  `}
                      >
                        <a
                          href="https://twitter.com/it_rns?s=08"
                          style={{
                            color: "#3D5DCD",
                          }}
                        >
                    <FaTwitter

                            style={{
                              fontSize: "22px",
                              marginLeft: "45px",
                              marginBottom: "21px",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div
                        className={` d-flex flex-column align-items-center  `}
                      >
                        <a
                          href="https://www.youtube.com/@BrainBucksIndia"
                          style={{ color: "#D92828" }}
                        >
                          <IoLogoYoutube
                            style={{
                              fontSize: "22px",
                              marginLeft: "45px",
                              marginBottom: "21px",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* bootom section end */}
            </div>
          </div>
        </div>
      </div>

      <Sidebar />
    </>
  );
};

export default About;
