import React, { useContext, useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Get_created_rooms } from "../ApolloGraphSql/Queries";
import { BasicServices } from "../../services/BasicServices";
import { ToastContainer, toast } from "react-toastify";
import { GRAPHQL_URL } from "../../config/urls";
import { Context } from "../..";
import { FaRegCopy } from "react-icons/fa";
import { RevolvingDot } from "react-loader-spinner";

const CreatedRooms = () => {

  const { createdStoreData, setCreatedStoreData } = useContext(Context);
  const [show, setShow] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRoomName, setSelectedRoomName] = useState("");
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const { loading, error, data, refetch } = useQuery(Get_created_rooms);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleClose = () => setShow(false);
  const handleShow = () => {

    setShow(true);
  }

  const Navigate = useNavigate();

  if (loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
  <RevolvingDot
    visible={true}
    height="80"
    width="80"
    color="#8663e0"
    ariaLabel="revolving-dot-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
</div>;;
  if (error) return <p> {error.message} </p>

  const quizzes = data.get_created_rooms.response;


  // api to delete created room
  const deleteRoom = async () => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "room_id": selectedRoom
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${GRAPHQL_URL}/participant/room/delete/room`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          toast(result.msg)
          refetch();
          setSelectedRoom("");
          handleClose();
          setSelectedRoomName();

        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("")
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        }
        else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  console.log("createdStoreData", createdStoreData);
  return (

    <>
      <ToastContainer />
      <div className="d-flex flex-wrap">
        {quizzes?.map((res, index) => {
          const copyToClipboard = () => {
            navigator.clipboard.writeText(res.room_hash).then(() => {
              toast('Hash copied to clipboard!');
            }).catch(err => {
              console.error('Could not copy text: ', err);
            });
          };
          return (
            <div
              className="col-12 col-md-6 col-lg-4 p-2"
              key={index + 1}
            >
              <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                <div className="d-flex justify-content-between gap-2">
                  <div className="center-y t3">{res?.room_name}</div>
                  <div
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {res.room_type ? <span>Public</span> : <span>Private</span>}
                  </div>
                </div>

                <div className="d-flex justify-content-between pe-2">
                  <div className="d-flex gap-2 mb-2">
                    <span
                      style={{
                        color: "#8A8A8A",
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      Members
                    </span>
                    <div style={{ fontSize: "15px", fontWeight: "600" }}>
                      <span style={{ color: "#129C73" }}>
                        {res.enrolled_participants_count}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <span
                    style={{
                      color: "#8A8A8A",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Room Hash Code:
                  </span>
                  {res.room_type ? (
                    <span className="ps-2" style={{ color: "#129C73" }}>
                      NA
                    </span>
                  ) : (
                    <span className="ps-2" style={{ color: "#129C73" }}>
                      {res?.room_hash}
                      <FaRegCopy
                        style={{
                          marginLeft: "25px",
                          marginBottom: "10px",
                          cursor: "pointer"
                        }}
                        onClick={copyToClipboard}
                      />
                    </span>
                  )}
                </div>

                <div className="d-flex">
                  <div
                    className="btn2 rounded me-2 center-data"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCreatedStoreData(res);
                      Navigate(`/createenterRoom/${res?._id}`);
                    }}
                  >
                    Enter Room
                  </div>
                  <div
                    className="btnr2 rounded ms-2 leave-rmdata"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleShow();
                      setSelectedRoom(res?._id);
                      setSelectedRoomName(res?.room_name);
                    }}
                  >
                    <RiDeleteBin6Line
                      className="me-2"
                      style={{ fontSize: "18px" }}
                    />
                    Delete Room
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* Modal for delete */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Delete <span style={{ fontWeight: "600" }}>{selectedRoomName}</span>?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteRoom}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </>
  );
};

export default CreatedRooms;
