import { Navigate, Outlet } from "react-router-dom";

const AuthenticatedRoute = () => {
    const brainbucksObject = localStorage.getItem("brainbucks_object");

    // Check if brainbucksObject is not null
    if (!brainbucksObject) {
        return <Navigate to="/Register" />;
    }

    let parsedObject;
    try {
        parsedObject = JSON.parse(brainbucksObject);
    } catch (e) {
        console.error("Failed to parse brainbucks_object from localStorage", e);
        return <Navigate to="/Register" />;
    }

    // Check if parsedObject exists and has a jwt property
    if (!parsedObject || !parsedObject.jwt) {
        return <Navigate to="/Register" />;
    }

    return <Outlet />;
};

export default AuthenticatedRoute;
