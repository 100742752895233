import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../..';
import { CiMenuFries } from 'react-icons/ci';
import homeicon from "../assets/icons/homeicon.svg";
import PM from "../assets/PM.png";
import { CiShare2 } from "react-icons/ci";
import { FaArrowRight, FaHome } from "react-icons/fa";
import menuIcon from "../assets/menuIcon.svg";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineMenu, MdOutlineTranslate } from "react-icons/md";
import Sidebar from '../Home/Sidebar';
import { NavLink, useNavigate } from 'react-router-dom';
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import { BasicServices } from '../../services/BasicServices';
import { ToastContainer, toast } from "react-toastify";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { IoHomeOutline } from 'react-icons/io5';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Span from '../Translation/Translate';


const DailyUpdate = () => {
  const Navigate = useNavigate();
  const { handleShow, handleClose } = useContext(Context);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const page = useRef(1)
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const [showUpArrow, setShowUpArrow] = useState(true);
  const [update, setupdate] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(0);// State to toggle between up and down arrows

  const toggleArrow = () => {
    setShowUpArrow(!showUpArrow);
  }
  //     const data = [
  //       {
  //           img: PM,
  //           Heading: 'Japanese Prime Minister Shinjo Abe Shot Dead at a public gathering in Tokyo',
  //           content: 'According to the report the shooter was a spy from China, developing the conspiracy to kill the prime minister from past 1 month. According to some locals , he was being getting threat warnings from the internal agencies, but prime minister declared a no worry, and on the day of 12th september 2022, he was shot dead while attending one of the most general public meetings without special protection.',
  //           subcontent:'The media proclaims the death of Delegate to be directly linked to the latest deals he was going to sign with the Republic of India, keeping China in extreme points of danger.The hot matter will be pushed to the office of UN and will be taken to central authorities by the end of the month.'
  //       },
  //       {
  //           img: PM,
  //           Heading: 'जापान के प्रधानमंत्री शिंजो आबे की टोक्यो में एक जनसभा के दौरान गोली मारकर हत्या कर दी गई',
  //           content: 'रिपोर्ट के मुताबिक हमलावर चीन का जासूस था, जो पिछले एक महीने से प्रधानमंत्री की हत्या की साजिश रच रहा था. कुछ स्थानीय लोगों के अनुसार, उन्हें आंतरिक एजेंसियों से धमकी की चेतावनी मिल रही थी, लेकिन प्रधान मंत्री ने कोई चिंता नहीं की घोषणा की, और 12 सितंबर 2022 के दिन, विशेष सुरक्षा के बिना सबसे आम जनसभाओं में से एक में भाग लेने के दौरान उनकी गोली मारकर हत्या कर दी गई। ',
  //           subcontent:' मीडिया ने प्रतिनिधि की मौत को चीन को खतरे के चरम बिंदु पर रखते हुए भारत गणराज्य के साथ हस्ताक्षर किए जाने वाले नवीनतम सौदों से सीधे तौर पर जुड़ा होने की घोषणा की। मामले को संयुक्त राष्ट्र के कार्यालय में धकेल दिया जाएगा और महीने के अंत तक केंद्रीय अधिकारियों के पास ले जाया जाएगा। '
  //       },
  //       {
  //           img: PM,
  //           Heading: 'Japanese Prime Minister Shinjo Abe Shot Dead at a public gathering in Tokyo',
  //           content: 'According to the report, the shooter was a spy from China, developing the conspiracy to kill the prime minister from the last month. According to some locals, he was getting threat warnings from the internal agencies. Our prime minister declared no worry. and on the day of 12th September 2022, he was shot dead while attending one of the most general public meetings without special protection.'
  //       },
  //       {
  //           img: PM,
  //           Heading: 'Japanese Prime Minister Shinjo Abe Shot Dead at a public gathering in Tokyo',
  //           content: 'According to the report, the shooter was a spy from China, developing the conspiracy to kill the prime minister from the last month. According to some locals, he was getting threat warnings from the internal agencies. Our prime minister declared no worry. and on the day of 12th September 2022, he was shot dead while attending one of the most general public meetings without special protection.'
  //       },
  //   ];

  const Daily = async () => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token} `);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZMICRO}/participants/get/daily/updates?page=${currentPage}`, requestOptions)
      const result = await response.json();
      setIsLoading(false)
      if (result?.status == 1) {
        setupdate(result.data)
        setTotalPages(result.totalPages);
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status === "TOKEN_ERR") {
        basic.setLocalObject("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error);
      }
    } catch (error) {
      console.log();
    }
  }

  const handleNextTab = () => {
    setCurrentIndex(prevIndex => (prevIndex === update.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePreviousTab = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? update.length - 1 : prevIndex - 1));
  };
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    Daily()
  }, [currentPage])
  return (
    <>
      <div className='' style={{ backgroundColor: "#6f42c1", color: "white" }}>
        <div className='mx-3 p-3 d-flex  align-items-center ' >
          <div className=' col-2'>
            <IoMdArrowRoundBack onClick={() => Navigate(-1)} style={{ fontSize: "22px", cursor: "pointer" }} />
          </div>
          <div className=' col-8 d-flex justify-content-center 000 pt-2'>
            <h4 style={{ color: "white" }}><Span>DailyUpdate</Span> </h4>
          </div>
          <div className='col-2 justify-content-evenly d-flex '>
            {/* <div className=' '>
          <MdOutlineTranslate style={{ fontSize: "20px", marginRight:"1rem" }} />
        </div> */}
            <div className=' '>
              <FaHome style={{ color: "white", fontSize: "1.5rem" }} onClick={() => { Navigate("/") }} />
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>

      {(update?.length === 0 && !isLoading) ? (
        <EmptyContainer
          actionName="Reload"
          action={() => Daily(page.current)}
          message="No Data Found"
        />
      ) :
        update?.map((res, index) => {
          const [datePart, timePart] = res.scheduled_time.split(" ");
          return (
            <>
              <div className='d-flex gap-2 align-items-center justify-content-center my-4'>
                <div className='' onClick={handlePreviousPage} disabled={currentPage === 1}><FaArrowLeft cursor="pointer" /></div>
                <span className=''>{currentPage} / {totalPages}</span>
                <div className='' onClick={handleNextPage} disabled={currentPage === totalPages}><FaArrowRight /></div>
              </div>
              <div className='container w-100 h-100'>
                <div className='' style={{ width: "100%", borderRadius: "15px 15px 0 0", overflow: "hidden" }}>
                  <img className='pmimg' src={IMAGE_URL + res?.banner_image} style={{
                    width: '100%',
                    height: '30vh',
                    borderRadius: 'inherit',
                    // objectFit: 'cover',
                    objectPosition: 'center'
                  }} alt="PM img" />
                </div>

                <div className='d-flex justify-content-between px-1 pt-2 pb-2' style={{ backgroundColor: "#F2F2F2" }}>
                  <div className='fw-semibold' style={{ color: "#8A8A8A" }}>{datePart} | {timePart}</div>
                  <div className='fw-semibold' style={{ color: "#8A8A8A" }}><Span>Share</Span>  <CiShare2 /></div>
                </div>
                <div>

                  <div className='container' key={index} style={{ display: index === currentIndex ? 'block' : 'none' }}>
                    <h3 className='fs-5 mt-2'><Span>{res.headline}</Span> </h3>
                    <div dangerouslySetInnerHTML={{ __html: `<style> .details-content p { margin: 0; } </style>${res?.details}` }} className="details-content"></div>

                  </div>

                </div>
              </div>
            </>

          )
        })
      } {isLoading && <LoaderComponent />}








      <Sidebar />
    </>
  )
}

export default DailyUpdate