import React, { useContext, useState, useEffect } from "react";
import menuIcon from "../assets/menuIcon.svg";
import { useNavigate } from "react-router-dom";
import Bottom from "../Home/Bottom";
import MyExamsCard from "./MyExamsCard";
import Sidebar from "../Home/Sidebar";
import { Context } from "../..";
import "./saved.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import downarrow from "../assets/icons/arrow-down.svg";

import { IoIosSearch, IoMdArrowRoundBack } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { Spinner, Toast } from "react-bootstrap";
import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import { MdOutlineMenu } from "react-icons/md";
import Span from "../Translation/Translate";

const MyExams = () => {

  const { handleShow, handleClose } = useContext(Context);
  const Navigate = useNavigate();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const [isLoading, setIsLoading] = useState(false)


  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);



  const [savedQuizzNames, setSavedQuizzNames] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle selection/deselection of an option
  const handleSelection = (id) => {
    setSelectedOptions(prevSelectedOptions => {
      if (prevSelectedOptions.includes(id)) {
        return prevSelectedOptions.filter(optionId => optionId !== id); // Deselect the option
      } else {
        return [...prevSelectedOptions, id]; // Select the option
      }
    });
  };

  // Filter the data based on the search query
  const filteredQuizzes = savedQuizzNames.filter(res => {
    return res.category_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const savedGetQuizzName = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${QUIZMICRO}/participants/get/not/saved/exams`, requestOptions);
        const result = await response.json();
        setIsLoading(false);

        if (result?.status === 1) {
            setSavedQuizzNames(result?.exams);
        } else if (result.status === "TOKEN_ERR") {
            basic.setJwt("");
            Navigate("/Register");
            toast("Logged in other device! Please login again");
        } else {
            console.log(result?.Backend_Error);
        }
    } catch (error) {
        console.error(error);
       
    }
};

const addQuizName = async () => {
  setIsLoading(true);
  if (selectedOptions.length === 0) {
    // If no option selected, display toast message
    toast("Please select at least one exam to add.");
    setIsLoading(false);
    return;
  }
  
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    "exam_id": selectedOptions
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch(`${QUIZMICRO}/participants/add/exams`, requestOptions);
    const result = await response.json();
    
    
    if (result?.status === 1) {
      setIsLoading(true);
      Navigate("/ExamsAdded");
    } else if (result.status === "TOKEN_ERR") {
      basic.setJwt("");
      Navigate("/Register");
      toast("Logged in other device! Please login again");
    } else {
      toast(result?.Backend_Error);
    }
  } catch (error) {
    console.error(error);
    setIsLoading(false);
    toast("An error occurred. Please try again.");
  }
};
  useEffect(() => {
    handleClose();
  }, [])



  return (
    <>
      <ToastContainer />
      <>
        <div className="example" style={{
          height: "100vh", width: "100vw", flexDirection: "column",
          display: "flex"
        }}>
          <div className="" style={{ backgroundColor: "#6f42c1", color: "white", height: "10vh", padding: "10px" }}>
            <div className="container">
              <div className="row pt-2">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack onClick={() => Navigate("/")} style={{ fontSize: "22px", cursor: "pointer" }} />
                </div>
                <div className="col-6  text-center">
                  <span
                    style={{ fontSize: "1.5rem", fontWeight: "700" }}
                    className="mb-0 "
                  >
                    <Span>My Exams</Span>
                  </span>
                </div>
                <div className="col-3 d-flex justify-content-center ">
                  <button
                    type="button"
                    className="btn btn-light"
                    style={{
                      color: "#367CFF",
                      backgroundColor: "#EFF5FF",
                      border: "1px solid #367CFF",
                    }}
                    onClick={() => {
                      handleShow1();
                      savedGetQuizzName();
                    }}
                  >
                    <Span>+Add</Span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className=" "
            style={{ height: "88vh", overflowY: "auto", padding: "0.8rem" }}
          >
            <MyExamsCard />
          </div>
          <div className="example1" style={{ flex: "0 0 auto", position: "sticky", backgroundColor: "#6f42c1", paddingTop: 0, color: "white" }}>
            <Bottom />
          </div>
        </div>
      </>

      {/* Add exam offcanva */}
      <Offcanvas
        style={{ height: "80%" }}
        show={show1}
        onHide={handleClose1}
        placement="bottom"
      >
        <Offcanvas.Header
          // closeButton
          className="m-0 b-0"
        >
          <div className="flex-grow-1 d-flex flex-column gap-2">
            <div className="center-data t0">
              <img src={downarrow} onClick={handleClose1} alt="close" />
            </div>

            <div className="d-flex justify-content-between">
              <div style={{ opacity: 0 }}><Span>hello</Span></div>
              <div className="t1"><Span>Add Exam</Span></div>

              <div className="center-data">
                <button className="center-data btn1 rounded p-1 px-4 py-2"
                  style={{
                    fontSize: 15,
                    backgroundColor: "#EDF7FF",
                    padding: "8px 17px",
                    border: "1px solid #2188E7",
                    borderRadius: 10,
                    color: "#2188E7",
                   
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    addQuizName();
                    savedGetQuizzName();
                  }}>
                    {isLoading ?    <Spinner/> : <Span>Save</Span> }
               
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body style={{ display: "flex", flexDirection: "column" }}>
          <div className="center-data flex-grow-0">
            <div
              className="row align-items-center mx-1 "
              style={{
                backgroundColor: "#EFEFEF",
                border: "none",
                boxShadow: "4px 4px 4px #EFEFEF",
                height: "50px",
                borderRadius: "7px",
                width: "500px",
              }}
            >
              <div className="col-3 d-flex justify-content-center mt-1">
                <IoIosSearch color="#7E7E7E" size={24} />
              </div>
              <div className="col-9 mt-1" style={{ marginLeft: "-30px" }}>
                <input
                  className="border-0 w-100"
                  placeholder="Search for Exams"
                  style={{
                    textDecoration: "none",
                    outline: "none",
                    backgroundColor: "#EFEFEF",
                    color: "#272727",
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div
            className="mt-3 row flex-grow-1  pb-5 "
            style={{ overflowY: "auto" }}
          >
            {(filteredQuizzes?.length === 0 && !isLoading) ? (
              <EmptyContainer
                actionName="Reload"
                action={() => savedGetQuizzName()}
                message="No Data Found"
              />
            ) : (
              filteredQuizzes?.map((res) => {
                return (
                  <>
                    <div className="col-12 col-sm-6 col-md-4 p-2">
                      <div className="border p-2 d-flex rounded">
                        <div className="flex-grow-1 d-flex gap-2">
                          <div className="center-data flex-grow-0"
                            style={{
                              width: "50px",
                              height: "50px",
                              border: "1px solid #D9D9D9",
                              borderRadius: "50px",
                              justifyContent: "center",
                              objectFit: "cover",
                              overflow: "auto",

                            }}
                          >
                            <img src={`${IMAGE_URL}${res?.image}`} alt="#" style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }} />
                          </div>
                          <div className="flex-grow-1 center-y t2">
                            <Span>{res?.category_name}</Span>
                          </div>
                        </div>

                        <div className="flex-grow-0 center-data">
                          <div
                            onClick={() => {

                              handleSelection(res?._id);
                            }}
                            className="px-3 py-0"
                            style={{
                              color: "#7E7E7E",
                              backgroundColor: selectedOptions.includes(res?._id) ? "#2188E7" : "#EFEFEF",
                              fontSize: "2rem",
                              borderRadius: "100%",
                              border: "none",
                              outline: 0,
                              cursor: "pointer",
                            }}
                          >
                            {selectedOptions.includes(res?._id) ? <FaCheck size={17} color="white" /> : "+"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
            {isLoading && <LoaderComponent />}

          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Sidebar />

    </>
  );
};

export default MyExams;
