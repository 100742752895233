import axios from "axios";
import { AUTHMICRO, QUIZMICRO } from "../../config/urls";
import { BasicServices } from "../BasicServices";
import { toast } from "react-toastify";

class AuthenticationApiService {
  constructor() {
    this.basic = new BasicServices();
    this.authUrl = AUTHMICRO;
  }

  // cehck token validity
 



  async sendOtp(number) {
    try {
      number = "+91" + number;
      console.log("sending otp to " + number);
      const response = await axios({
        method: "post",
        url: `${this.authUrl}/auth/participant/send/otp`,
        data: {
          phone: number,
        },
      });
      console.log("OTP RESPONSE", response);
      return response.data;
    }
    catch (err) { toast(err.message) }
  }

  async verifyOtpAndRegister(phone, otp) {
    try {
      phone = "+91" + phone;
      let fcm = this?.basic?.getLocalObject()?.fcm;
      const response = await axios({
        method: "post",
        url: `${this.authUrl}/auth/participant/verify/otp`,
        data: {
          phone: phone,
          fcm_key: fcm,
          otp: otp,
        },
      });

      this.basic.setJwt(response?.data?.token);
      return response?.data;
    }
    catch (err) { console.error(err.message);}
  }

  async getExams() {
   try{ const response = await axios({
      method: "get",
      url: `${QUIZMICRO}/formfill/get/category?search=`,
    });
    return response.data;}
    catch (err) { toast(err.message) }
  }

  async registerUser(phone, name, gender, category, otpInput) {
   try {phone = "+91" + phone;
    let fcm = this.basic.getLocalObject().fcm;
    const response = await axios({
      method: "post",
      url: `${this.authUrl}/auth/participant/registor`,
      data: {
        phone: phone,
        name: name,
        gender: gender,
        category: category,
        fcm_key: fcm,
        otp: otpInput,
      },
    });
    if (response?.data.status === 1) {
      this.basic.setGender(gender);
      this.basic.setJwt(response.data.token);
      this.basic.setName(name);
      this.basic.setNumber(phone);
    }
    return response.data;}
    catch (err) { toast(err.message) }
  }


  async getUserProfile() {
  try { let token = "Bearer " + this.basic.getLocalObject().jwt;

    let url = `${this.authUrl}/auth/participant/view/profile`;
    let headers = { "content-type": "application/json", "authorization": token };
    let options = {
      method: "get",
      headers: headers,
      url,
    };
    const response = await axios(options);

    // Check if response and user_details exist
    if (response.data && response.data.user_details) {
      const userId = response.data.user_details._id;
      // Store the userId in local storage
      localStorage.setItem('userId', userId);
    }

    return response.data;}
    catch (err) { toast(err.message) }
  }

  async editProfile(gender, name, phone) {
  try { let token = "Bearer " + this.basic.getLocalObject().jwt;
    let url = `${this.authUrl}/auth/participant/edit/profile`;
    let data = JSON.stringify({ gender: gender, name: name, phone: phone });
    let headers = { "content-type": "application/json", "authorization": token };
    let options = {
      method: "post",
      headers: headers,
      data: data,
      url,
    };
    const response = await axios(options);
    return response.data;
  }
    catch (err) { toast(err.message) }
  }

  async uploadProfile(picture) {
   try{ const formdata = new FormData();
    formdata.append("profile", picture);
    let token = "Bearer " + this.basic.getLocalObject().jwt;
    let url = `${this.authUrl}/auth/participant/upload/photo`;
    let headers = { "content-type": "multipart/form-data", "authorization": token };
    let options = {
      method: "post",
      headers: headers,
      data: formdata,
      url,
    };
    const response = await axios(options);
    return response.data;}
    catch (err) { toast(err.message) }
  }

  async logout() {
   try{ let token = "Bearer " + this.basic.getLocalObject().jwt;
    let url = `${this.authUrl}/auth/participant/logout`;
    let headers = { "content-type": "application/json", "authorization": token };
    let options = {
      method: "post",
      headers: headers,
      data: "{ }",
      url,
    };
    const response = await axios(options);
    return response.data;}
    catch (err) { toast(err.message) }
  }
}

export default AuthenticationApiService;
