import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BasicServices } from "../../services/BasicServices";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoHomeOutline } from "react-icons/io5";
import { MdSend } from "react-icons/md";
import io from "socket.io-client";
import { NOTIFY_URL, SOCKET_URL } from "../../config/urls";
import { Context } from "../..";

const CreateChat = () => {
  const { id, isClosed: isClosedParam  } = useParams();
  const isClosed = isClosedParam === "true";
  console.log("isClosed", isClosed)
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const userId = localStorage.getItem("userId");
  const socketRef = useRef();
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function formatDate(dateString) {
    if (!dateString || isNaN(new Date(dateString))) return '';
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday?.getDate() - 1);

    const options = { hour: "numeric", minute: "numeric", hour12: true };

    const day = ("0" + date?.getDate())?.slice(-2);
    const month = ("0" + (date.getMonth() + 1))?.slice(-2);
    const year = date?.getFullYear();
    const formattedDate = month + "/" + day + "/" + year;
    if (date?.toLocaleDateString() === today?.toLocaleDateString()) {

      return "Today " + date?.toLocaleTimeString("en-US", options);
    } else if (date?.toLocaleDateString() === yesterday?.toLocaleDateString()) {

      return "Yesterday " + date.toLocaleTimeString("en-US", options);
    } else {

      return formattedDate + " " + date.toLocaleTimeString("en-US", options);
    }
  }

  const getChatHistory = async () => {
    try {
      const response = await axios.post(
        `${NOTIFY_URL}/partichat/get/chat/of/particular/ticket`,
        { chat_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data; // Axios handles the response JSON parsing automatically
      setChatHistory(data?.messages);
    } catch (error) {
      console.error("Error fetching chat history:", error);
      if (error.response && error.response.data) {
        // Handle error status codes and messages here
        console.error("Error status code:", error.response.status);
        console.error("Error message:", error.response.data.message);
      }
    }
  };

  useEffect(() => {
    // Initialize socket connection without the sid parameter
    socketRef.current = io(SOCKET_URL, {
      query: {
        token: token,
        user_type: "Participants",
      },
      transports: ["websocket"],
    });

    socketRef.current.on("connect", () => {
      console.log("Socket connected:", socketRef.current.id);
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socketRef?.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    socketRef.current.on("ticket_response", (newMessage) => {
      console.log("newMessage", newMessage);
      let msg = JSON.parse(newMessage);
      if (msg?.chat_id == id) {
        setChatHistory((prev) => [...prev, { ...msg, align: "left", send_time: msg.time }]);
      }
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const handleSendMessage = () => {
    if (message.trim()) {
      // Emit message through socket
      const newMessage = {
        chat_id: id,
        user_id: userId,
        message: message,
      };
      try {
        socketRef.current.emit(
          "participant_msg_to_ticket",
          JSON.stringify(newMessage)
        );
        let msg = {
          chat_id: id,
          content: message,
          sender_id: userId,
          send_time: new Date().toISOString(), // Change: Use ISO string for send_time
          _id: new Date().toISOString(), // Change: Use ISO string for _id
          align: "right",
        };
        setChatHistory((prev) => [...prev, msg]);
      } catch (err) {
        console.log("error in send", err);
      }

      console.log("Sent message:", newMessage); // Log the sent message
      // setSentMessages(prevMessages => [...prevMessages, message]); // Add the sent message to the array
      setMessage("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);


  useEffect(() => {
    getChatHistory();
  }, []);

  return (
    <>
      <div style={{ height: "100vh", width: "100vw", overflowX: "hidden" }}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "10vh", backgroundColor: "#6f42c1", color: "white" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-3 d-flex justify-content-center align-items-center">
                <FaArrowLeftLong
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ height: "5vh", cursor: "pointer" }}
                />
              </div>
              <div className="col-6 text-center pt-2">
                <span
                  className="MainHeading"
                  style={{ fontSize: "1.1em", fontWeight: "600" }}
                >
                  <span style={{ fontSize: "19px" }}>Help & Support</span>
                </span>
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <IoHomeOutline
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="container d-flex flex-column"
          style={{ height: "90vh", overflow: "hidden" }}
        >
          <div
            className="flex-grow-1 rounded px-2"
            style={{
              overflowY: "auto",
              backgroundColor: "#ececec",
              border: "1px solid #dab8b8",
              overflowX: "hidden"
            }}
          >
            {chatHistory?.map((res, index) => (
              <div
                className="row"
                key={res._id || index}
                style={{ justifyContent: res.align === 'left' ? 'flex-start' : 'flex-end' }} // Align row based on message alignment
              >
                <div
                  className="col-8 mt-3"
                  style={{ display: 'flex', justifyContent: res.align === 'left' ? 'flex-start' : 'flex-end' }} // Align message content based on alignment
                >
                  <div
                    style={{
                      backgroundColor: res.align === 'left' ? '#fff' : '#367CFF',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      color: res.align === 'left' ? '#8a8a8a' : '#fff',
                      padding: '10px',
                      maxWidth: '75%',
                    }}
                  >
                    <p style={{ flex: '1', margin: '0' }}>{res.content}</p>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: res.align === 'left' ? '#8a8a8a' : '#fff',
                        marginLeft: '20px',
                      }}
                    >
                      {formatDate(res.send_time)}
                    </span>
                  </div>
                </div>
              </div>
            ))}



            <div ref={messagesEndRef} />
          </div>

          <div className="flex-grow-0 pb-3">
            <div className="row" style={{ position: "relative" }}>
              <div className="col-12 d-flex">
                {!isClosed && (
                  <div className="input-group" style={{ width: "100%", border: "1px solid" }}>
                    <input
                      type="text"
                      className="form-control py-2 custom-input"
                      placeholder="Type your message here"
                      style={{ zIndex: 1, fontSize: "19px" }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <div className="input-group-append" style={{ display: "flex", alignItems: "center" }}>
                      <MdSend
                        style={{
                          backgroundColor: "#8a8a8a",
                          padding: "8px",
                          borderRadius: "8px",
                          fontSize: "34px",
                          color: "#fff",
                          fontWeight: "600",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                        onClick={handleSendMessage}
                      />
                    </div>
                  </div>
                )}


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateChat;
