import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CourseApiService from "../../services/api/CourseApiService";
import { toast } from "react-toastify";
import LoaderComponent from "../utils/LoaderComponent";
import shaka from "shaka-player";
import { FaArrowLeft } from "react-icons/fa6";
import "../Courses/Courses.css";
import { BasicServices } from "../../services/BasicServices";
import ReactPlayer from "react-player";

export default function VideoPlayer() {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const courseServ = new CourseApiService();
  const [videoLinks, setVideoLinks] = useState({});
  const [currentTime, setCurrentTime] = useState(0); // State to store current time
  const [mountTime, setMountTime] = useState(Date.now()); // State to store mount time
  const videoRef = useRef();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;

  // Define updateTime function outside of useEffect
  const updateTime = () => {
    setCurrentTime(videoRef.current.currentTime);
    console.log("Current Time:", videoRef.current.currentTime); // Console log the current time
  };

  useEffect(() => {
    courseServ
      .startVideo(state.course_id, state.video_id)
      .then((res) => {
        console.log("Video ", res);
        if (res?.status === 1) {
          setVideoLinks(res);
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((error) => toast(error.message));

   // Cleanup function to set unmount time and remove event listener
return () => {
  const unmountTime = Date.now();
  const timeSpent = (unmountTime - mountTime) / 1000; // Convert milliseconds to seconds
  console.log("Component was mounted for", timeSpent, "seconds");
  
  // Remove the event listener when unmounting if videoRef is not null
  // if (videoRef.current) {
  //   videoRef.current.removeEventListener("timeupdate", updateTime);
  // }
};
  }, []);

  // useEffect(() => {
  //   if (videoLinks.token) {
  //     const initPlayer = async () => {
  //       const videoElement = videoRef.current;
  //       const player = new shaka.Player(videoElement);
  //       shaka.polyfill.installAll();

  //       const protection = {
  //         drm: {
  //           servers: {
  //             "com.widevine.alpha":
  //               "https://drm-widevine-licensing.axprod.net/AcquireLicense",
  //           },
  //         },
  //       };

  //       player.configure(protection);

  //       player.getNetworkingEngine().registerRequestFilter(function (
  //         type,
  //         request
  //       ) {
  //         console.log(
  //           type,
  //           request,
  //           type === shaka.net.NetworkingEngine.RequestType.LICENSE,
  //           "kajal"
  //         );
  //         if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
  //           request.headers["X-AxDRM-Message"] = `${videoLinks.token}`;
  //         }
  //       });

  //       // Update currentTime state on timeupdate
  //       videoElement.addEventListener("timeupdate", updateTime);

  //       try {
  //         console.log("ITEM???SD", videoLinks);
  //         await player.load(`${videoLinks.url}`);
  //       } catch (error) {
  //         console.error("Error loading video:", error);
  //       }
  //     };

  //     initPlayer();
  //   }
  // }, [videoLinks]);

  // console.log("videoLinks", videoLinks);

  return (
    <div
      className="d-flex justify-content-center align-items-center bg-dark"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div className="video-container"> 
        <div className="arrow-container m-4"> 
          <FaArrowLeft
            color="white"
            size={20}
            cursor="pointer"
            onClick={() => {
              Navigate(-1);
            }}
            style={{backgroundColor: "#777793", width: "25px", borderRadius:"50%"}}
          />
        </div>
        <div>
          {/* <video className="mb-4" src={videoLinks.url} controls height="450" style={{width: "100%"}}></video> */}
          <ReactPlayer
            ref={videoRef}
            className="mb-4"
            url={videoLinks?.url}
            controls
            height="90%"
            width="100%"
            onProgress={updateTime}
          />
        </div>
      </div>
    </div>
  );
}
