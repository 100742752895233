import React, { useEffect } from 'react'
import { BasicServices, BrainBucksObject } from '../../services/BasicServices';
import kvPairs from '../../config/SecretKeys';
import { getToken } from 'firebase/messaging';
import { messaging } from '../../config/FirebaseConfig';
import { useNavigate } from 'react-router-dom';

export default function AskForPermission() {

  // const Navigate = useNavigate();

  // useEffect(()=>{
  //   if(Notification.permission==='granted'){
  //     Navigate("/", {replace:true})
  //   }
  // },[])

    // const basicService = new BasicServices()

    // async function requestPermissionAndSaveFcm() {
    //     let permission = null;
    //     if (Notification.permission !== 'granted') {
    //       permission = await Notification.requestPermission();
    //     } else {
    //       permission = Notification.permission;
    //     }
    
    //     const localObj = (basicService.getLocalObject());
    
    //     if (localObj && localObj.fcm !== '') {
    //       return;
    //     }
    
    //     if (permission === 'granted') {
    //       const token = await getToken(messaging, {
    //         vapidKey: kvPairs.VAPID_KEY
    //       }
    //       );
    
    //       if (token) {
    //         basicService.setFcm(token)
    //       }
    //     }else{
    //       let objectToSave = new BrainBucksObject()
    //         if (localObj) {
    //           objectToSave.setObject(localObj)
    //         }
    //         else {
    //           objectToSave.setFcm('')
    //         }
    //         basicService.setLocalObject(objectToSave);
    //     }
    //   }

  return (
    <div style={{background:"purple", display:"flex", flexDirection:"column" , justifyContent:"center", alignItems:"center", color:"white", width:"100vw", height:"100vh"}}>
        <h1>You have to Accept The Notification Permission First</h1>
        {/* <button onClick={requestPermissionAndSaveFcm} className='btn btn-primary'>Allow</button> */}
    </div>
  )
}
