import React, { useEffect, useState, useContext } from "react";
import user from "../assets/user.webp";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink, Navlink, useNavigate } from "react-router-dom";
import { FaRegBell, FaWallet } from "react-icons/fa";
import { CiSearch, CiWallet } from "react-icons/ci";
import { CiMenuFries } from "react-icons/ci";
import "../../App.css";
import { BasicServices, BrainBucksObject } from "../../services/BasicServices";
import { IoMenuOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import menuIcon from "../assets/menuIcon.svg";
import logoo from "../assets/lgbb.png";
import "../../App.css";
// import Sidebar from './Sidebar';
import { Context } from "../..";
import Sidebar from "./Sidebar";
import { MdOutlineMenu } from "react-icons/md";
import Form from "react-bootstrap/Form";
import { getToken } from "firebase/messaging";
import kvPairs from "../../config/SecretKeys";
import { messaging } from "../../config/FirebaseConfig";
import { GiWallet } from "react-icons/gi";
// import context from 'react-bootstrap/esm/AccordionContext';

const Navbar = () => {
  const navigate = useNavigate();
  const { handleToggleSidebar } = useContext(Context);
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const { handleShow } = useContext(Context);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 768px)" });



  const handleToggleNotifications = () =>
    setShowNotifications(!showNotifications);



  return (
    <>
      <div className="container">
        <div className="row align-items-center mt-3">
          <div className="col-2">
            <MdOutlineMenu
              onClick={handleShow}
              style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
            />

            {/* <img
                            src={menuIcon}
                            onClick={handleShow}
                            alt="jg"
                            style={{ height: "5vh", cursor: "pointer",color:"white" }}
                        /> */}
          </div>

          <div className="col-8 text-center ">
            <div className="search-container">
              <div className="input-group d-flex justify-content-center mb-1">
                <img
                  src={logoo}
                  // onClick={handleShow}
                  alt="jg"
                  style={{ maxWidth: "230px", cursor: "pointer", color: "white" }}
                />
              </div>
            </div>
          </div>

          <div className="col-2 d-flex justify-content-end">

            <GiWallet onClick={() => navigate('/wallet')}
              style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
            /> 
          </div>
        </div>

        {/* Offcanvas Sidebar */}
        <div
          className={`offcanvas ${show ? "show" : ""}`}
          tabIndex="-1"
          id="offcanvasSidebar"
          aria-labelledby="offcanvasSidebarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasSidebarLabel">
              Sidebar
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={handleToggleSidebar}
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">{/* Sidebar Content */}</div>
        </div>

        {/* Notifications */}
        <div
          className={`offcanvas ${showNotifications ? "show" : ""}`}
          tabIndex="-1"
          id="offcanvasNotifications"
          aria-labelledby="offcanvasNotificationsLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNotificationsLabel">
              Notifications
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={handleToggleNotifications}
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">{/* Notifications Content */}</div>
        </div>
      </div>

      <Sidebar />
    </>
  );
};

export default Navbar;
