class BasicServices {
    setLocalObject(brainBucksObject) { 
        localStorage.setItem("brainbucks_object", JSON.stringify(brainBucksObject))
    }
getLocalObject() {
        return JSON.parse(localStorage.getItem("brainbucks_object"));
    }

    setJwt(jwt){
        let localObj = this.getLocalObject()
        let objectToSave = new BrainBucksObject()
        if (localObj) {
          localObj.jwt = jwt+'';
          objectToSave.setObject(localObj)
        }
        else {
          objectToSave.setJwt(jwt)
        }
        this.setLocalObject(objectToSave);
    }
    setFcm(fcm){
        let localObj = this.getLocalObject()
        let objectToSave = new BrainBucksObject()
        if (localObj) {
          localObj.fcm = fcm+'';
          objectToSave.setObject(localObj)
        }
        else {
          objectToSave.setFcm(fcm)
        }
        this.setLocalObject(objectToSave);
    }
    setGender(gender){
        let localObj = this.getLocalObject()
        let objectToSave = new BrainBucksObject()
        if (localObj) {
          localObj.gender = gender+'';
          objectToSave.setObject(localObj)
        }
        else {
          objectToSave.setGender(gender)
        }
        this.setLocalObject(objectToSave);
    }
    setName(name){
        let localObj = this.getLocalObject()
        let objectToSave = new BrainBucksObject()
        if (localObj) {
          localObj.name = name+'';
          objectToSave.setObject(localObj)
        }
        else {
          objectToSave.setName(name)
        }
        this.setLocalObject(objectToSave);
    }
    setNumber(number){
        let localObj = this.getLocalObject()
        let objectToSave = new BrainBucksObject()
        if (localObj) {
          localObj.number = number+'';
          objectToSave.setObject(localObj)
        }
        else {
          objectToSave.setNumber(number)
        }
        this.setLocalObject(objectToSave);
    }

}

class BrainBucksObject {
    constructor(fcm, jwt, number, name,gender) {
        this.fcm = fcm;
        this.jwt = jwt;
        this.number = number;
        this.name = name;
        this.gender = gender;
    }

    setObject(obj){
        this.fcm = obj.fcm;
        this.jwt = obj.jwt;
        this.number = obj.number;
        this.name = obj.name;
        this.gender=obj.gender;
    }

    getObject(){
        return {
            fcm:this.fcm,
            jwt:this.jwt,
            number:this.number,
            name:this.name,
            gender:this.gender
        }
    }
    
    setGender(gender){
        this.gender=gender
    }
    getGender(){
        return this.gender
    }

    setJwt(jwt){
        this.jwt=jwt
    }
    getJwt(){
        return this.jwt
    }

    setFcm(fcm){
        this.fcm=fcm
    }
    getFcm(){
        return this.fcm
    }

    setNumber(number){
        this.number=number
    }
    getNumber(){
        return this.number
    }

    setName(name){
        this.name=name
    }
    getName(){
        return this.name
    }
    
}

module.exports={
    BrainBucksObject, BasicServices
}
