import React, { useCallback, useEffect, useRef, useState } from 'react';
import sbi from "../assets/icons/sbi.svg";
import coin from "../assets/icons/coin.svg";
import calender from "../assets/icons/calender.svg";
import clock from "../assets/icons/clock.svg";
import trophy from "../assets/icons/trophy.svg";
import { useNavigate } from 'react-router-dom';
import HistoryApiService from '../../services/api/HistoryApiService';

import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from '../utils/LoaderComponent';
import { IMAGE_URL } from '../../config/urls';
import Span from '../Translation/Translate';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { ProgressBar } from 'react-bootstrap';

const FreeHistory = ({order}) => {
  const Navigate = useNavigate();

  const [historyData, setHistoryData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const historyService = new HistoryApiService();
  const page = useRef(1)
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [totalPages, setTotalPages] = useState(1);
  const observer = useRef();

  const callApi = (cur_page) => {
    setIsLoading(true)
    historyService.getFreeQuizzes(order,cur_page).then((res) => {
      if (res?.status === 1) {
        setHistoryData(res?.triviaquizes)
        setTotalPages(res?.totalpages)
      }else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
         Navigate("/Register");
         toast("Logged in other device! Please login again");
       } 
       else {
        console.log("Error While Reterieving More Data From Free History Data", res)
      }
    }).finally(()=>{
      setIsLoading(false)
      })
  };



  const handleLoad = () => {
    page.current += 1;
    callApi(page.current)
  }


  useEffect(() => {
    page.current=1
    callApi(1)
  }, [order])

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page.current < totalPages) {
          page.current += 1;
          callApi(page.current);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, totalPages]
  );


  return (
    <>
      <div className="row">
        {(historyData.length === 0 && !isLoading) ? (
          <EmptyContainer
          actionName="Reload"
          action={() => callApi(page.current)}
          message="No Data Found"
        />
        ) : (
            <>
              {historyData?.map((res, index) => {
               <div className="col-12 col-md-6 col-lg-4 p-3" key={index + 1} ref={index === historyData.length - 1 ? lastElementRef : null}>
               <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                 <div className="d-flex gap-2">
                   <div className="center-data flex-grow-0 notsaved">
                     <img src={`${IMAGE_URL}${res?.banner}`} alt="quiz_icon" style={{
                               width: "100%",
                               height: "100%",
                               objectFit: "cover",
                               borderRadius: "50%",
                             }}></img>
                   </div>
                   <div className="flex-grow-1 center-y t3"><Span>{res.quiz_name}</Span></div>
                 </div>
       
                 <div className="row">
                   <div className="col-6 d-flex gap-2">
                     <Span className="tgr">Prize</Span>
                     <img src={coin} alt="fees"></img>
                     <span className="tgo">{res.reward}</span>
                   </div>
                   {/* <div className="col-6 d-flex gap-2">
                     <Span className="tgr">Prize</Span>
                     <img src={coin} alt="orize"></img>
                     <Span className="tgo">{res.reward}</Span>
                   </div> */}
                 </div>
       
                 <div>
                   <div className="row mb-2">
                     <div className="col-3 d-flex gap-2 mb-2">
                       <img style={{height: "20px", width: "20px"}} src={trophy} alt="trophy"></img>
                       <div style={{ fontSize: "14px" }}>
                        
                         <span style={{ color: "#2188E7" }}>{res?.min_reward_per}%</span>
                       </div>
                     </div>
                     <div className="col-5 d-flex gap-2">
                       <img style={{height: "20px", width: "20px"}} src={calender} alt="time" color="#7E7E7E"></img>
                       <span className="tgr">{res.sch_time.split(" ")[0]}</span>
                     </div>
                     <div className="col-4 d-flex gap-2">
                       <img style={{height: "20px", width: "20px"}} src={clock} alt="time" color="#7E7E7E"></img>
                       <span className="tgr">{res.sch_time.slice(10, 19)}</span>
                     </div>
                     
       
                   </div>
                   
                 </div>
               
                <div
                   style={{cursor:"pointer"}}
                   className="btn3 rounded center-data"
                   onClick={() => {
                     Navigate(`/newreward/${res?._id}`)
                   }}
                 >
                  <Span>  View Result </Span>
                 </div>
                
               </div>
             </div>
              })
              }
      
                    </>
        )}
      </div>
    </>
  )
}

export const FreeCard = (res, index, Navigate) => {
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 p-3" key={index + 1}>
        <div className="card2 p-2 d-flex flex-column gap-3 rounded">
          <div className="d-flex gap-2">
            <div className="center-data flex-grow-0 notsaved">
              <img src={`${IMAGE_URL}${res?.banner}`} alt="quiz_icon" style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}></img>
            </div>
            <div className="flex-grow-1 center-y t3"><Span>{res.quiz_name}</Span></div>
          </div>

          <div className="row">
            <div className="col-6 d-flex gap-2">
              <Span className="tgr">Prize</Span>
              <img src={coin} alt="fees"></img>
              <span className="tgo">{res.reward}</span>
            </div>
            {/* <div className="col-6 d-flex gap-2">
              <Span className="tgr">Prize</Span>
              <img src={coin} alt="orize"></img>
              <Span className="tgo">{res.reward}</Span>
            </div> */}
          </div>

          <div>
            <div className="row mb-2">
              <div className="col-3 d-flex gap-2 mb-2">
                <img style={{height: "20px", width: "20px"}} src={trophy} alt="trophy"></img>
                <div style={{ fontSize: "14px" }}>
                 
                  <span style={{ color: "#2188E7" }}>{res?.min_reward_per}%</span>
                </div>
              </div>
              <div className="col-5 d-flex gap-2">
                <img style={{height: "20px", width: "20px"}} src={calender} alt="time" color="#7E7E7E"></img>
                <span className="tgr">{res?.sch_time?.split(" ")[0]}</span>
              </div>
              <div className="col-4 d-flex gap-2">
                <img style={{height: "20px", width: "20px"}} src={clock} alt="time" color="#7E7E7E"></img>
                <span className="tgr">{res?.sch_time?.slice(10, 19)}</span>
              </div>
              

            </div>
            
          </div>
        
         <div
            style={{cursor:"pointer"}}
            className="btn3 rounded center-data"
            onClick={() => {
              Navigate(`/newreward/${res?._id}`)
            }}
          >
           <Span>  View Result </Span>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default FreeHistory;
