import React, { useEffect, useRef, useState } from "react";
import coin from "../assets/icons/coin.svg";
import calender from "../assets/icons/calender.svg";
import clock from "../assets/icons/clock.svg";
import ticket from "../assets/icons/ticket.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { SiWhatsapp } from "react-icons/si";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import { GRAPHQL_URL } from "../../config/urls";
import { IMAGE_URL } from "../../config/urls";
import Span from "../Translation/Translate";
import { useNavigate } from "react-router-dom";
import { BasicServices } from "../../services/BasicServices";
import { toast } from "react-toastify";
import { Get_Live_Quizes } from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";

const CreateLiveQuizz = ({ Roomsid }) => {
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  console.log(Roomsid)
  const page = useRef(1);
  const [totalPages, setTotalPages] = useState(1);
  const { data, refetch } = useQuery(Get_Live_Quizes, {
    variables: { room_id: Roomsid }
  });
  const liveQuizData = data?.live_quizes?.response || [];

  useEffect(() => {
    refetch();
  }, [refetch]);


  return (
    <div className="row mx-2">
      {liveQuizData?.length === 0 && !isLoading ? (
        <EmptyContainer
          // actionName={<Span>Reload</Span>}
          // action={() => callApi(page.current)}
          message="No Data Found"
        />
      ) : (
        liveQuizData?.map((res, index) => {
          const [datePart, timePart] = res?.sch_time.split(" ");
          return (
            <>
              <div className="col-12 col-md-6 col-lg-4 p-3" key={index + 1}>
                <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                  <div className="d-flex gap-2">
                    <div className="center-data flex-grow-0">
                      <img
                        src={`${IMAGE_URL}${res?.category_image}`}
                        alt="quiz_icon"
                        style={{ height: "30px", borderRadius: "50%" }}
                      />
                    </div>
                    <div className="flex-grow-1 center-y t3">
                      <Span>{res?.category_name}</Span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 d-flex gap-2">
                      <span className="tgr">
                        <Span>Entry Fees</Span>
                      </span>
                      <img src={coin} alt="fees" />
                      <span className="tgo">{res?.entryFees}</span>
                    </div>
                    <div className="col-6 d-flex gap-2">
                      <img src={calender} color="#7E7E7E" alt="calender" />
                      <span className="tgr">{datePart}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 d-flex gap-2">
                      <span className="tgr">
                        <Span>Reward</Span>
                      </span>

                      <img src={coin} alt="prize" />
                      <span className="tgo">{res?.prize}</span>
                    </div>
                    <div className="col-6 d-flex gap-2">
                      <img src={clock} color="#7E7E7E" alt="clock" />
                      <span className="tgr">{timePart}</span>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex gap-2 mb-2">
                      <img src={ticket} alt="ticket" />
                      <div style={{ fontSize: "14px" }}>
                        <span>{res?.slot_aloted}</span>/
                        <span style={{ color: "#2188E7" }}>{res?.slots}</span>
                      </div>
                    </div>

                    <ProgressBar
                      completed={res?.slot_aloted}
                      bgColor="linear-gradient(90.11deg, #59AFFF 1.72%, #2188E7 98%)"
                      height="10px"
                      isLabelVisible={false}
                      baseBgColor="#E4E9EC"
                      labelColor="#e80909"
                      animateOnRender
                      maxCompleted={res?.slots}
                    />
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <div
                      className="col-6 btn2_room_quiz rounded center-data"
                      style={{ cursor: "pointer" }}
                      // onClick={() => JoinQuiz(res._id)}
                      onClick={() => {
                        Navigate(`/livequizlobby/${res?._id}`);
                      }}
                    >
                      <span style={{ fontSize: "17px" }}>
                        <Span>Enter Lobby</Span>
                      </span>
                    </div>
                    {/* <div
                      className="col-6 btn3_room_quiz rounded center-data"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Navigate(`/register/${res?._id}`);
                      }}
                    >
                      <SiWhatsapp style={{ fontSize: "19px" }} />{" "}
                      <span style={{ fontSize: "17px", paddingLeft: "10px" }}>
                        <Span>Invite</Span>
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          );
        })
      )}

      {isLoading && <LoaderComponent />}

      <div className="center-data">
        {totalPages != page.current && liveQuizData?.length != 0 && (
          <button
            className="load-button center-data p-3 px-4 rounded mt-3"
          //   onClick={handleLoad}
          >
            <Span>Load More</Span>
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateLiveQuizz;
