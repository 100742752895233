import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import braint from "../assets/brainotp.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import WalletApiService from "../../services/api/WalletApiService";
import { ToastContainer, toast } from "react-toastify";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";

const Accotp = () => {
  const [otpInput, setOtpInput] = useState("");
  const [isActive, setIsActive] = useState(true);
  const walletService = new WalletApiService();
  const Navigate = useNavigate();
  const { state } = useLocation();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt


  useEffect(()=>{toast(state?.otp)},[])

  function resendOtp() {
    setIsActive(false);
    walletService
      .sendOtp()
      .then((res) => {
        if (res?.status === 1) {
          console.log("Resent OTP", res);
          toast("Otp sent succesfully: "+res.otp);
        }  else if (res.status === "TOKEN_ERR") {
          // localStorage.removeItem("api_url");
                    basic.setJwt("")

          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } 
         else {
          toast(res.msg);
        }
      })
      .finally(() => {
        setIsActive(true);
      });
  }

  function confirmOtp() {

    if(!otpInput) {
      toast("Please enter OTP")
      return
    }
    
    if ( otpInput.length !== 4) {
      toast("Enter Correct OTP");
      return;
    }

    setIsActive(false);

    walletService
      .addBank(
        state.ifsc,
        state.bankName,
        state.accNumber,
        state.accHolder,
        parseInt(otpInput)
      )
      .then((res) => {
        if (res?.status === 1) {
          console.log("dfddfd", res);
          Navigate("/wallet/success", {
            replace: true,
            state: {
              ifsc: state.ifsc,
              accHolder: state.accHolder,
              accNumber: state.accNumber,
              bankName: state.bankName,
            },
          });
        } else {
          if (res.status === "INT_ERR") {
            console.log("res", res);
            toast("Check your details and try again");
          } else if (res.status === "TOKEN_ERR") {
            // localStorage.removeItem("api_url");
                      basic.setJwt("")

            Navigate("/Register");
            toast("Logged in other device! Please login again");
          } 
          
          else {
            console.log("res", res);
            toast(res.Backend_Error);
          }
        }
      })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsActive(true);
      });
  }

  return (
    <>
    <ToastContainer/>
      <div
        className="d-flex flex-column"
        style={{ width: "100%", height: "100%" }}
      >
        <div className="my-4 pt-5 pb-5 flex-grow-0">
          <div className="logo text-center">
            <img className="logo_img" src={braint} alt="logo" />
          </div>
        </div>

        <div className="container-fluid mbregister_colr flex-grow-1">
          <div className="top_otpcont">
            <div className="enter_otp text-center">
              <h4 className="pt-5 pb-5" style={{ color: "white" }}>
                <Span>Enter OTP sent to your Registered Number</Span>{" "}
              </h4>
            </div>
            <div className="otp-container">
              <OTPInput
                value={otpInput}
                onChange={setOtpInput}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="number"
                    class="otp-input"
                    style={{ border: "1px solid grey" }}
                  />
                )}
              />
            </div>
            <div className="container text-center pt-5">
              <button
                // disabled={!isActive}
                type="button"
                onClick={confirmOtp}
                className="btn btn-light done_lang"
              >
                {" "}
                <Span>Confirm</Span>{" "}
              </button>
            </div>
            <div className="container text-center pt-3">
              <p style={{ color: "white" }} onClick={resendOtp}>
                Did not received OTP ?{" "}
                <span className="otp_colr" style={{ color: "#FAFF10" }}>
                  {" "}
                  <Span>Resend OTP</Span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accotp;
