import React, { useState } from "react";


import { FaArrowLeft } from "react-icons/fa6";
import "./saved.css";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Quizzes from "./Quizzes";
import FreeTrivia from "./FreeTrivia";

import Study from "./StudySaved";
import { useNavigate, useParams } from "react-router-dom";
import EnrolledQuizzes from "./EnrolledQuizzes";
import Span from "../Translation/Translate";

const ViewExam = () => {
  const Navigate = useNavigate();


  const { active_quizes, triviaQuizes, enrolled_quizes, exam_name } = useParams();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };






  return (
    <>
      <div
        style={{ height: "100vh", width: "100vw", overflow: "hidden", }}
        className="d-flex flex-column"
      >
        <div className="flex-grow-0" >
          <div className="d-flex justify-content-between border-bottom pb-3 p-2 ps-md-4" 
          style={{backgroundColor:"#6f42c1",}}>
            <div className="center-data">
              <FaArrowLeft color="white" size={20} cursor="pointer"
                onClick={() => {
                  Navigate("/myexams")
                }}
              />
            </div>

            <div style={{color:"white", fontSize: "22px"}} className="t1"><Span>{exam_name}</Span></div>
            <div style={{ opacity: 0 }}>hello</div>
          </div>

          {/* <div className="p-2 px-md-4">
            <div className="  d-flex align-items-center  gap-2">
              <h6 className="some-txt"><Span>{exam_name}</Span></h6>
            </div>

            <div className=" mt-3 center-y gap-2 ">
              <div className=" blu d-flex gap-1 p-2 center-y" style={{ border: "none" }}>
                <span style={{ fontSize: "15px" }}><Span>Active Quizzes</Span></span>
                <span className="imp" style={{ fontSize: "15px" }}>
                  {active_quizes}
                </span>
              </div>
              <div className=" grn center-y p-2 gap-2" style={{ border: "none" }}>
                <div style={{ fontSize: "15px" }}><Span>Trivia Quizes</Span></div>
                <span className="imp" style={{ fontSize: "15px" }}>
                  {triviaQuizes}
                </span>
              </div>
            </div>

            <div className=" mt-3 center-y gap-2 d-flex" >
              <div className=" blu1 d-flex gap-1 p-2 center-y" style={{ border: "none" }}>
                <span style={{ fontSize: "15px", }}><Span>Enrolled Quizes</Span></span>
                <span className="imp" style={{ fontSize: "15px" }}>
                  {enrolled_quizes}
                </span>
              </div>

            </div>
          </div> */}

          <Box
            className="w-100 my-2 mx-md-2"
            sx={{
              borderColor: "divider",
              borderBottom: "1px solid #E4E9EC",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={<Span>Quizzes</Span>}
                style={{
                  color: "#8A8A8A",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              />

              <Tab
                label={<Span>Free Trivia</Span>}
                style={{
                  color: "#8A8A8A",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              />
              <Tab
                label={<Span>Enrolled Quizes</Span>}
                style={{
                  color: "#8A8A8A",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              />
              {/* <Tab
                label={<Span>Study</Span>}
                style={{
                  color: "#8A8A8A",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              /> */}
            </Tabs>
          </Box>
        </div>

        <div
          className="flex-grow-1 mb-2 pb-5"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            display: value === 3 ? "none" : "block",
          }}
        >
          {value === 0 && <Quizzes />}
          {value === 1 && <FreeTrivia />}
          {value === 2 && <EnrolledQuizzes />}
        </div>

        {/* <div
          className="flex-grow-1 mb-2 pb-5"
          style={{ overflow: "auto", display: value === 3 ? "block" : "none" }}
        >
          <Study />
        </div> */}
      </div>
    </>
  );
};

export default ViewExam;
