import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import calender from "../assets/icons/calender.svg";
import clock from "../assets/icons/clock.svg";
import "../CourseHostory/CourseHistory.css";
import Span from "../Translation/Translate";

const CourseCard = () => {
  const savedData1 = [
    {
      _id: "663dcb50fce8ad5ad6747854",
      participant_id: "66345b079a3bb80988345202",
      amount: 4426.11,
      expire_time: "24-05-2024 12:52:56",
      registor_time: "10-05-2024 12:52:56",
      course_name: "Paid Courses 1",
      instructor_name: "yashu",
      is_expire: 0
    }
  ]

  const Navigate = useNavigate();
  const [savedData, setSavedData] = useState([]);

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [isLoading, setIsLoading] = useState(false);



  const savedGetData = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://prerec.brainbucks.in/participant/buycourseplan/history", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          setSavedData(result?.transactions);
        } else if (result.status === "TOKEN_ERR") {
          basic.setLocalObject("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    savedGetData();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row gap-1 justify-content-center">
          {savedData?.length === 0 && !isLoading ? (
            <EmptyContainer
              actionName="Reload"
              action={() => savedGetData()}
              message="No Data Found"
            />
          ) : (
            savedData?.map((res) => {
              return (
                <div className="col-lg-3 cardCourseHistory1 mt-3 p-3">

                  <div className=" mt-1 center-y gap-2">
                    <div className="  d-flex gap-1 p-1 center-y">
                      <span style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#8A8A8A",
                      }}><Span>Course Name:</Span></span>
                      <span className="imp" style={{

                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#5a4d4d",
                      }}>
                        <Span> {res?.course_name}</Span>
                      </span>
                    </div>

                  </div>

                  <div className=" mt-1 center-y gap-2">
                    <div className="  d-flex gap-1 p-1 center-y">
                      <span style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#8A8A8A",
                      }}><Span>Instructor Name:</Span></span>
                      <span className="imp" style={{

                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#5a4d4d",
                      }}>
                        <Span>{res?.instructor_name}</Span>
                      </span>
                    </div>

                  </div>

                  <div className=" mt-1 center-y gap-2">
                    <div className="  d-flex gap-1 p-1 center-y">
                      <span style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#8A8A8A",
                      }}><Span>Price:</Span></span>
                      <span className="imp" style={{

                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#5a4d4d",
                      }}>
                        {res?.amount}
                      </span>
                    </div>

                  </div>

                  <div className=" mt-1 center-y gap-2">
                    {/* <div className="  d-flex gap-1 p-1 center-y">
                      <span style={{
                               fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#8A8A8A",
                              }}><Span>Course Status</Span></span>
                      <span className="imp" style={{
                                 
                                 fontStyle: "normal",
                                 fontWeight: "400",
                                 fontSize: "16px",
                                 color: "#5a4d4d",
                               }}>
                        {res?.is_expire}
                      </span>
                    </div> */}

                  </div>
                  <div className="  d-flex gap-1 p-1 center-y">
                    <span style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#8A8A8A",
                    }}><Span>Course purchased Time</Span> </span>

                  </div>
                  <div className=" mt-1 center-y gap-2">

                    <div className="row mb-2">
                      <div className="col-7 d-flex gap-2">
                        <img style={{ height: "20px", width: "20px" }} src={calender} alt="time" color="#7E7E7E"></img>
                        <span className="tgr" style={{

                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#5a4d4d",
                        }}>{res.registor_time.split(" ")[0]}</span>
                      </div>
                      <div className="col-5 d-flex gap-2">
                        <img style={{ height: "20px", width: "20px" }} src={clock} alt="time" color="#7E7E7E"></img>
                        <span className="tgr" style={{

                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#5a4d4d",
                        }}>{res.registor_time.slice(10, 19)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="  d-flex gap-1 p-1 center-y">
                    <span style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#8A8A8A",
                    }}><Span>Course End Time</Span> </span>

                  </div>
                  <div className=" mt-1 center-y gap-2">

                    <div className="row mb-2">
                      <div className="col-7 d-flex gap-2">
                        <img style={{ height: "20px", width: "20px" }} src={calender} alt="time" color="#7E7E7E"></img>
                        <span className="tgr" style={{

                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#5a4d4d",
                        }}>{res.expire_time.split(" ")[0]}</span>
                      </div>
                      <div className="col-5 d-flex gap-2">
                        <img style={{ height: "20px", width: "20px" }} src={clock} alt="time" color="#7E7E7E"></img>
                        <span className="tgr" style={{

                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#5a4d4d",
                        }}>{res.expire_time.slice(10, 19)}</span>
                      </div>
                    </div>
                  </div>

                </div>
              );
            })
          )}
          {isLoading && <LoaderComponent />}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
