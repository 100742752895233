import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { MdArrowForwardIos, MdOutlineKeyboardArrowRight } from "react-icons/md";
import "../Wallet/Wallet.css";
import { Navigate, useNavigate } from "react-router-dom";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaArrowDownLong } from "react-icons/fa6";
import Span from "../Translation/Translate";

const Viewall = () => {
  const Navigate = useNavigate();
  const data = [
    {
      p: "15,555",
      t: "12:34",
      d: "20 DEC 2022",
      r: "Failed",
    },
    {
      p: "15,555",
      t: "12:34",
      d: "20 DEC 2022",
      r: "Failed",
    },
    {
      p: "15,555",
      t: "12:34",
      d: "20 DEC 2022",
      r: "Failed",
    },
    {
      p: "15,555",
      t: "12:34",
      d: "20 DEC 2022",
      r: "Failed",
    },
  ];
  return (
    <div>
      <div>
        <div className="container allfamily">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex justify-content-between pt-2">
                <div>
                  {" "}
                  <FaArrowLeft
                    onClick={() => {
                      Navigate("/wallet");
                    }}
                  />{" "}
                </div>
                <h3 className="mx-auto">
                  <Span>Transaction History</Span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4 ">
          <div className="row">
            {data.map((i) => {
              return (
                <div className="col-lg-12 col-md-6 col-sm-12  pt-2">
                  <div
                    className="row"
                    style={{ border: "2px solid #F2F2F2", borderRadius: "6px" }}
                  >
                    <div className="col-2">
                      <div className="mx-3 pt-2">
                        <h6
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "70px",
                            backgroundColor: "#EFFFF6",
                            color: "#129C73",
                            paddingTop: "9px",
                            paddingLeft: "12px",
                          }}
                        >
                          <FaArrowDownLong />
                        </h6>
                      </div>
                    </div>
                    <div className="col-5 ">
                      <div className="pt-1">
                        <h6
                          className="m-0 p-0"
                          style={{
                            color: "#2E2E2E",
                            fontWeight: "700",
                            fontSize: "17px",
                          }}
                        >
                          ₹ 399
                        </h6>
                        <span
                          style={{
                            fontSize: "11px",
                            color: "#8A8A8A",
                            fontWeight: "400",
                          }}
                        >
                          12:34 | 12 Dec 2022
                        </span>
                      </div>
                    </div>
                    <div className="col-5 d-flex justify-content-between align-items-center ">
                      <div className=" d-flex align-items-center gap-2">
                        <BsFillPatchCheckFill style={{ color: "#129C73" }} />
                        <h6 className="pt-2">
                          {" "}
                          <span style={{ color: "#129C73" }}>
                            {" "}
                            <Span> Success</Span>
                          </span>{" "}
                        </h6>
                      </div>
                      <MdArrowForwardIos
                        style={{ fontSize: "20px" }}
                        onClick={() => {
                          Navigate("/fail");
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewall;
