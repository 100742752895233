import React, { useState } from "react";
import OTPInput from "react-otp-input";
import braint from "../assets/brainotp.png";
import { padding } from "@mui/system";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WalletApiService from "../../services/api/WalletApiService";
import Span from "../Translation/Translate";

const Walletotp = () => {
  const [otpInput, setOtpInput] = useState("");
  const [isActive, setIsActive] = useState(true);
  const Navigate = useNavigate();
  const { state } = useLocation();
  let walletService = new WalletApiService();

  function resendOtp() {
    setIsActive(false);
    walletService
      .sendOtp()
      .then((res) => {
        if (res?.status === 1) {
          toast(res.otp + " sent again");
          console.log("OTP SENT Again", res);
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setIsActive(true);
      });
  }

  function confirmOtp() {
    if(!otpInput) {
      toast("Please Enter OTP First")
      return
    }
    // if (otpInput.length !== 4) {
    //   toast("Enter Correct OTP");
    //   return;
    // }
    setIsActive(false);
    // console.log("TOPTSD",state);
    walletService
      .withdrawMoney(parseInt(state.amount), parseInt(otpInput), state.bank._id)
      .then((res) => {
        if (res.status === 1) {
          console.log(res, "WIthdraw done");
          Navigate("/Wallet/pend ", {
            replace: true,
            state: {
              bank: state.bank,
              amount: state.amount,
            },
          });
        } 
        else {
          toast("Enter Correct OTP");
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setIsActive(true);
      });
  }

  return (
    <>
    <ToastContainer />
      <div
        className="d-flex flex-column"
        style={{ width: "100%", height: "100%" }}
      >
        <div className="my-4 pt-5 pb-5 flex-grow-0">
          <div className="logo text-center">
            <img className="logo_img" src={braint} alt="logo" />
          </div>
        </div>

        <div className="container-fluid mbregister_colr flex-grow-1">
          <div className="top_otpcont">
            <div className="enter_otp text-center">
              <h4 className="pt-5 pb-5" style={{ color: "white" }}>
                <Span>
                  {" "}
                  Enter the OTP sent to your Registered Number to confirm
                  withdrawal
                </Span>{" "}
              </h4>
            </div>
            <div className="otp-container">
              <OTPInput
                value={otpInput}
                onChange={setOtpInput}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="number"
                    class="otp-input"
                    style={{ border: "1px solid grey" }}
                  />
                )}
              />
            </div>
            <div className="container text-center pt-5">
              <button
                type="button"
                onClick={confirmOtp}
                className="btn btn-light done_lang"
              >
                <Span> Confirm </Span>
              </button>
            </div>
            <div className="container text-center pt-3">
              <p style={{ color: "white" }} onClick={resendOtp}>
                Did not received OTP ?{" "}
                <span className="otp_colr" style={{ color: "#FAFF10" }}>
                  {" "}
                  <Span> Resend OTP</Span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Walletotp;
