import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import {  useLocation, useNavigate } from "react-router-dom";
import fardail from "../assets/fadrailbank.png";
import WalletApiService from "../../services/api/WalletApiService";
import { toast } from "react-toastify";
import Span from "../Translation/Translate";

const Withdrawnow = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  let walletService = new WalletApiService();

  function confirm() {
    walletService.sendOtp().then((res) => {
      if (res?.status === 1) {
        toast(res.otp + " is the otp");
        console.log("OTP SENT  ", res);
        Navigate("/Wallet/otp", {
          replace: true,
          state: {
            amount: state.amount,
            bank: state.bank,
          },
        });
      } else {
        toast(res.Backend_Error);
      }
    });
  }

  return (
    <>
      <div>
        <div>
          <div className="" style={{ height: "10vh" }}>
            <div className="container">
              <div className="row mt-2">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate(-1)}
                    style={{ fontSize: "22px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="">
                        <h6
                          className=""
                          style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            color: "#2E2E2E",
                          }}
                        >
                          ₹ {state.amount}
                        </h6>
                        <h6
                          className="pt-2"
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span>will be deposited in the following</Span> <br />{" "}
                          <Span>Bank Account</Span>{" "}
                        </h6>
                        <h6
                          className="pt-2"
                          style={{
                            fontWeight: "500",
                            fontSize: "12px",
                            color: "#D92828",
                          }}
                        >
                          <Span>
                            {" "}
                            it can take upto 2 Business days for the payment
                          </Span>{" "}
                          <br /> <Span>to be credited</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span> Name of Bank</Span>
                        </h6>
                        <div className="d-flex justify-content-start">
                          <img src={fardail} alt="" style={{ width: "46px" }} />
                          <h6
                            className="pt-1"
                            style={{ fontWeight: "500", fontSize: "30px" }}
                          >
                            <Span>{state.bank.bank_name}</Span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span> Beneficiary Name</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span> {state.bank.acc_holder_name}</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span>Account Number</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span> {state.bank.bank_acc_no}</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span> IFSC Code</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span>{state.bank.ifsc_code}</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className=" pt-4">
                        <div class="d-grid gap-2">
                          <button
                            class="btn-btn p-2"
                            onClick={confirm}
                            style={{
                              backgroundColor: "#701DDB",
                              borderRadius: "6px",
                              fontWeight: "600",
                              fontSize: "17px",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            type="button"
                          >
                            <Span>Confirm</Span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Withdrawnow;
