import React, { useEffect, useRef, useState } from "react";
import coin from "../assets/icons/coin.svg";
import calender from "../assets/icons/calender.svg";
import clock from "../assets/icons/clock.svg";
import ticket from "../assets/icons/ticket.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import Span from "../Translation/Translate";



const EnrolledQuizzes = () => {
  const Navigate = useNavigate();
  const { exam_id } = useParams(); 
  const [getAllQuizz, setGetAllQuizz] = useState([]);
  
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt

  const [isLoading , setIsLoading] = useState(false)
  const page  = useRef(1)
  const [totalPages, setTotalPages] = useState(1);

  const savedGetQuizzes = async (cur_page) => {
    setIsLoading(true)
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${QUIZMICRO}/participants/particular/exam/enrolled/quizes?category_id=${exam_id}&page=${cur_page}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result?.status === 1) {
         setGetAllQuizz((datas) => [...datas, ...result?.enrolled_quizes])
          setTotalPages(result?.totalpages)
          } 
          else if (result?.status === "TOKEN_ERR") {
            basic.setJwt("");
            Navigate("/Register");
            toast("Logged in other device! Please login again");
          } else {
            console.log(result?.Backend_Error);
          }
      })
      .catch((error) => console.error(error));
  };

  const handleLoad = () =>{
    page.current += 1 ;
    savedGetQuizzes(page.current)
}


  useEffect(() => {
    savedGetQuizzes(page.current);
  }, [])

  console.log("getAllQuizz", getAllQuizz);

  return (
    <>
    
      <div className="row" >

      {( getAllQuizz?.length === 0 && !isLoading)? (
         <EmptyContainer
         actionName= "Reload"
         action={()=>savedGetQuizzes(page?.current)}
         message="No Data Found"
       />
      ): (
        getAllQuizz?.map((res, index) => {
          if (!res) return null;
          const [datePart, timePart] = res?.sch_time.split(" ");
         return (
           <>
             <div className="col-12 col-md-6 col-lg-4 p-3" key={index + 1}>
               <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                 <div className="d-flex gap-2">
                   <div className="center-data flex-grow-0">
                     <img src={`${IMAGE_URL}${res?.banner}`} alt="quiz_icon" 
                     style={{height: "30px", borderRadius: "50%"}}
                     />
                   </div>
                   <div className="flex-grow-1 center-y t3"><Span>{res?.quiz_name}</Span></div>
                 </div>

                 <div className="row">
                   <div className="col-6 d-flex gap-2">
                     <span className="tgr"><Span>Fees</Span></span>
                     <img src={coin} alt="fees" />
                     <span className="tgo">{res?.entryFees}</span>
                   </div>
                   <div className="col-6 d-flex gap-2">
                     <img src={calender} color="#7E7E7E" alt="calender" />
                     <span className="tgr">{datePart}</span>
                   </div>
                 </div>

                 <div className="row">
                   <div className="col-6 d-flex gap-2">
                     <span className="tgr"><Span>Prize</Span></span>
                     <img src={coin} alt="prize" />
                     <span className="tgo">{res?.prize}</span>
                   </div>
                   <div className="col-6 d-flex gap-2">
                     <img src={clock} color="#7E7E7E" alt="clock" />
                     <span className="tgr">{timePart}</span>
                   </div>
                 </div>

                 <div>
                   <div className="d-flex gap-2 mb-2">
                     <img src={ticket} alt="ticket"  />
                     <div style={{ fontSize: "14px" }}>
                       <span>{res?.slot_aloted}</span>/
                       <span style={{ color: "#2188E7" }}>{res?.slots}</span>
                     </div>
                   </div>

                   <ProgressBar
                     completed={res?.slot_aloted}
                     bgColor="linear-gradient(90.11deg, #59AFFF 1.72%, #2188E7 98%)"
                     height="10px"
                     isLabelVisible={false}
                     baseBgColor="#E4E9EC"
                     labelColor="#e80909"
                     animateOnRender
                     maxCompleted={res?.slots}
                   />
                 </div>

                 <div className="btn2 rounded center-data" style={{cursor: "pointer"}} onClick={ () => {Navigate(`/card/current/${res?._id}`)}}><Span>Join Now</Span></div>
               </div>
             </div>
           </>
         );
       })
      )}
        {isLoading && <LoaderComponent/>}
       
        <div className="center-data">


{( ( totalPages != 0 ) && ( getAllQuizz?.length !=0 ) )&&
<button className="load-button center-data p-3 px-4 rounded mt-3" onClick={handleLoad}>
<Span> Load More</Span>
</button>
}
  

</div>

      </div>
    </>
  );
};

export default EnrolledQuizzes;
