import React, { useState, useEffect, useCallback, useRef } from "react";
import play from "../assets/play.jpeg";
import image4 from "../assets/image5.png";
import coin from "../assets/coin.png";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProgressBar from "@ramonak/react-progress-bar";
import { LuTicket } from "react-icons/lu";
import { FaRegClock } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaCrown } from "react-icons/fa6";
import LoaderComponent from "../utils/LoaderComponent";
import { BasicServices } from "../../services/BasicServices";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import { toast, ToastContainer } from "react-toastify";
import { convertTimeToAMPM } from "../utils/dateTime";
import Span from "../Translation/Translate";
import InfiniteScroll from "react-infinite-scroller";

const CurrentCard = () => {

  const { id, prize } = useParams();
  const handleChange = (event, newValue) => {
    setfirst(newValue);
  };
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;

  const navigate = useNavigate();
  const [first, setfirst] = useState(0);
  const [quizData, setQuizData] = useState({});
  const [rewards, setRewards] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const getData = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZMICRO}/participants/view/detail/of/active/quiz?id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          setQuizData(result?.data);
        } else if (result.status === "TOKEN_ERR") {
          // localStorage.removeItem("api_url");
          basic.setJwt("")

          navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const getParticipants = async (page) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      subactivequiz_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${QUIZMICRO}/participants/particpants/in/active/quiz?page=${page}`,
        requestOptions
      );
      const result = await response.json();

      if (result?.status === 1) {
        setParticipants(prevParticipants => [...prevParticipants, ...result?.participantNames]);
        setTotalPages(result?.totalPages);
        if (page >= result?.totalPages) {
          setHasMore(false);
        }
      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");
        navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        console.log(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewards = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      subactivequiz_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${QUIZMICRO}/participants/reward/in/active/quiz?page=${page}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          setRewards(result?.send_rewards);
          setTotalPages(result?.totalPages)
        } else if (result.status === "TOKEN_ERR") {
          // localStorage.removeItem("api_url");
          navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };


  const validateJoin = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const raw = JSON.stringify({
      subactivequiz_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${QUIZMICRO}/participants/validate/join/in/active/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          navigate(`/StartQuiz/${id}/quiz`)
        }
        else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          navigate("/Register");
          toast.error("Logged in other device! Please login again");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };
  console.log("quizData", quizData);


  const lastElementRef = useCallback(node => { // Added: Callback for the last element
    if (isLoading) return;
    if (observer.current) observer.current.disconnect(); // Disconnect the previous observer
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node); // Observe the last element
  }, [isLoading, page, totalPages]);

  useEffect(() => {
    getData();
    getParticipants(page);
    getRewards(page);
  }, [page]);

  const loadMore = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12  border pt-2">
                  <div>
                    <div className="" style={{ position: "relative" }}>
                      <img
                        src={`${IMAGE_URL}${quizData?.image}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "20vh",
                          objectFit: "cover",
                          // objectPosition: "top",
                        }}
                        alt="icon"
                      ></img>
                    </div>
                    <div
                      className="backbtn mt-4 ms-4"
                      style={{ position: "absolute", top: 0, left: 0 }}
                    >
                      <IoArrowBack
                        className="p-1"
                        style={{
                          fontSize: "4vh",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className=" col-lg-12 col-md-6 col-sm-12">
                      <div className="card p-2">
                        <div
                          className=" mt-1"
                          style={{ cursor: "pointer" }}

                        >
                          <div className="d-flex align-items-center gap-1">
                            <div
                              style={{
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                                border: "1px solid #808080",
                              }}
                            >
                              <img
                                src={`${IMAGE_URL}${quizData?.image}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                                alt="img"
                              />
                            </div>
                            <h6 className="some-txt"><Span>{quizData?.quiz_name}</Span> </h6>
                          </div>
                          <div className=" mt-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-2">
                              <h6 className="txx"><Span>Cost</Span> </h6>
                              <div className="d-flex gap-2">
                                <img src={coin} style={{ height: "3vh" }}></img>
                                <h6 className="new-txt">
                                  {quizData?.entryFees}
                                </h6>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <MdOutlineCalendarMonth
                                className="txx"
                                style={{ fontSize: "20px" }}
                              />
                              <span className="txx">
                                {quizData?.sch_time?.split(" ")[0]}
                              </span>
                            </div>
                          </div>
                          <div className=" mt-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-2">
                              <h6 className="txx"><Span>Reward</Span> </h6>
                              <div className="d-flex gap-2">
                                <img src={coin} style={{ height: "3vh" }}></img>
                                <h6 className="new-txt">{prize}</h6>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <FaRegClock
                                className="txx"
                                style={{ fontSize: "18px" }}
                              />
                              <span className="txx">
                                {convertTimeToAMPM(
                                  quizData?.sch_time?.split(" ")[1]
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="mt-1 d-flex align-items-center gap-2 ms-1">
                            <div>
                              <LuTicket style={{ fontSize: "3vh" }} className="mb-1" />
                            </div>
                            <div className="d-flex mt-1" >
                              <h6 className="alpha">{quizData?.slot_aloted}</h6>
                              <h6>/{quizData?.slots}</h6>
                            </div>
                          </div>
                          <div className="mt-1">
                            <ProgressBar
                              completed={quizData?.slot_aloted}
                              bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                              height="10px"
                              isLabelVisible={false}
                              baseBgColor="#E4E9EC"
                              labelColor="#e80909"
                              animateOnRender
                              maxCompleted={quizData?.slots}
                            />
                          </div>



                          <div className=" mt-2 d-flex justify-content-center gap-3">
                            <button className="registerButtonClass p-2 text-center w-100" onClick={validateJoin} >
                              <Span>{quizData?.button_value}</Span>
                              {/* {isTimeUp ? "Join Now" : timeLeft} */}
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <Box
                    className="w-100 my-2 mx-md-2"
                    sx={{
                      borderColor: "divider",
                      borderBottom: "1px solid #E4E9EC",
                    }}
                  >
                    <Tabs
                      value={first}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Participants"
                        style={{
                          color: "#8A8A8A",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      />

                      <Tab
                        label="Reward"
                        style={{
                          color: "#8A8A8A",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      />
                      <Tab
                        label="Rules"
                        style={{
                          color: "#8A8A8A",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      />
                    </Tabs>
                  </Box>
                  <div className="row " style={{}}>
                    <div className="col-lg-12">
                      {first == 0 ? (
                        <>
                          <div className=" container mt-3 ">

                            <InfiniteScroll
                              pageStart={0}
                              loadMore={loadMore}
                              hasMore={hasMore}
                              loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                              <ul>
                                {participants.map((participant, index) => (
                                  <li key={index}>{participant}</li>
                                ))}
                              </ul>
                            </InfiniteScroll>
                          </div>
                        </>
                      ) : first == 1 ? (
                        <>
                          <div className="container">
                            <div className="row mt-3 text-center">
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-3 d-flex ">
                                    <h6><Span>Rank</Span> </h6>
                                  </div>
                                  <div className="col-3 d-flex">
                                    <h6><Span>Reward</Span> </h6>
                                  </div>
                                </div>

                                {rewards?.map((item, index) => {
                                  return (
                                    <div className="row" key={index} ref={lastElementRef}>
                                      <div className="col-3">
                                        <div className="d-flex align-items-center gap-2">
                                          <FaCrown
                                            className="num"
                                            style={{ fontSize: "15px" }}
                                          />
                                          <span>{item?.Rank}</span>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="d-flex align-items-center gap-2 ">
                                          <img
                                            src={coin}
                                            style={{ height: "2vh" }}
                                          ></img>
                                          <span style={{ fontWeight: "600" }}>
                                            {item?.reward}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : first == 2 ? (
                        <>
                          <div className=" container mt-2 d-flex flex-column gap-3">
                            {quizData?.rules?.map((res, index) => {
                              return (
                                <span>
                                  {index + 1}. {res}
                                </span>
                              );
                            })}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CurrentCard;