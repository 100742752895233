import React, { useState, useEffect, useContext } from 'react'
import { IoMdLogOut } from "react-icons/io";
import { FaArrowLeftLong } from "react-icons/fa6";
import '../user/User.css'
import user from '../img/user.png'
import { FaArrowRightLong } from "react-icons/fa6";
import log from '../img/chakr.png'
import log1 from '../img/privacypolicyblack.png'
import log2 from '../img/SecurityBlack.png'
import log3 from '../img/bbimg.png'
import { Button, Modal, NavLink, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BasicServices, } from "../../services/BasicServices";
import { BLOBURL } from "../../config/urls";
import AuthenticationApiService from '../../services/api/AuthenticationApiService';
import { FaHome } from 'react-icons/fa';
import { Context } from 'react-responsive';
import Span from '../Translation/Translate';





const ViewProfile = () => {
  const navigate = useNavigate();
  const auth = new AuthenticationApiService();
  let basic = new BasicServices();
  const [user_detail, setuser_details] = useState({})
  const [user, setuser] = useState({})
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleShow = () => setShowModel(true);
  const [loading, setLoading] = useState(false);
  async function getUserProfile() {
    const response = await auth.getUserProfile()
    //  console.log(response.user_details.name,'response edit profile');
    setuser_details(response?.user_details)
    setuser(response)
  }


  async function logout() {
    setLoading(true); 
    const response = await auth.logout()
    setLoading(false); 
    console.log(response, 'response logout');
    if (response?.status === 1) {
      basic.setJwt("")
      navigate('/')
    }
  }

  useEffect(() => {
    getUserProfile()
  }, [])


  return (

    <div style={{ margin: 10, backgroundColor: '#ffffff', cursor: 'pointer', overflowX: "hidden" , filter: showModel ? 'blur(5px)' : 'none', }}>
      <Modal show={showModel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Span>Confirm Logout</Span> </Modal.Title>
        </Modal.Header>
        <Modal.Body><Span>Are you sure you want to logout?</Span> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
           <Span>Cancel</Span> 
          </Button>
          <Button variant="danger" onClick={logout} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : <span>Yes, Logout</span>}
            </Button>
        </Modal.Footer>
      </Modal>
      <div className='row px-1 mt-2'>
        <div className="col-lg-12 col-md-12 col-lg-12 d-flex justify-content-between ">
          <div style={{ display: "flex", alignItems: "center", backgroundColor: 'rgba(245, 245, 245, 1)', padding: 10, borderRadius: 50, cursor: 'pointer' }}>
            <FaHome onClick={() => { navigate("/") }}
              style={{ display: "flex", alignItems: "center", color: '#000' }}
            />

          </div>
          <div onClick={() => setShowModel(true)} style={{ display: "flex", alignItems: "center", backgroundColor: 'rgba(245, 245, 245, 1)', padding: 10, borderRadius: 50, cursor: 'pointer' }}>
            <IoMdLogOut 
              style={{ display: "flex", alignItems: "center", color: 'red' }} 
            />
          </div>
        </div>
      </div>
      <button type="button"
        onClick={() => { navigate('/editProfile') }}
        class="btn float-end m-2" style={{ background: '#000', color: '#fff', fontSize: 12, cursor: 'pointer' }}><Span>Edit Profile</Span> </button>


      <div class="container mt-5">
        <div class="span3 well">
          <center>
            {
              user_detail?.image ?
                <a href="#aboutModal" data-toggle="modal" data-target="#myModal">

                  <img
                    src={BLOBURL + user_detail.image}
                    name="aboutme"
                    width="140"
                    height="140"
                    class="img-circle"
                    style={{ objectFit: "cover" }}
                   alt='icon'
                  />
                </a>
                :
                <a href="#aboutModal" data-toggle="modal" data-target="#myModal">

                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMIYPQpAHWfspO8qrIdSO7Pkx-JpkIU03WVA&usqp=CAU'
                    name="aboutme"
                    width="140"
                    height="140"
                    class="img-circle"
                    style={{ objectFit: "cover" }}
                    alt='icon'
                  />
                </a>
            }
            <h3>{user_detail?.name} </h3>
            <em>{user_detail?.phone}</em>
          </center>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-2 col-md-12 col-sm-12'></div>
        <div className='col-lg-4 col-md-12 col-sm-12  '>
          <div className='imgbg m-0 mt-2'>
            <div className='p-3'>
              <h3 style={{ color: '#fff' }}><Span>Total quiz Participate</Span> </h3>
              <h3 style={{ color: '#fff' }}>{user?.totalquizplayed}</h3>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-12 col-sm-12'>
          <div className='imgbg1 mt-2'>
            <div className='p-3'>
              <h3 style={{ color: '#fff' }}><Span>Refer & Earn upto</Span> </h3>
              <h3 style={{ color: '#fff' }}>56,555</h3>
            </div>
          </div>
        </div>

      </div>


      <div className="row mt-3">
        <div className="col-lg-12 col-md-12 col-sm-12" >
          <div className="card-container" onClick={() => { navigate('/support') }}>
            <div>
              <div className="icon-wrapper">
                <img src={log} resizeMode='contain' style={{ width: 40, height: 40 }} />
              </div>
            </div>
            <div className="content-wrapper">
              <span className="TextSupport"><Span>Help and Support</Span> </span>
            </div>
            <div style={{ padding: 10 }}>
              <FaArrowRightLong size={24} color="black" style={{ flex: 0.12 }} />
            </div>

          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-12 col-md-12 col-sm-12" >
          <div className="card-container" onClick={() => { navigate('/Privacy') }}>
            <div>
              <div className="icon-wrapper">
                <img src={log1} resizeMode='contain' style={{ width: 40, height: 40 }} />
              </div>
            </div>
            <div className="content-wrapper">
              <span className="TextSupport"><Span>Privacy Policy</Span> </span>
            </div>
            <div style={{ padding: 10 }}>
              <FaArrowRightLong size={24} color="black" style={{ flex: 0.12 }} />
            </div>

          </div>
        </div>
      </div>


      <div className="row mt-1">
        <div className="col-lg-12 col-md-12 col-sm-12" >
          <div className="card-container" onClick={() => { navigate('/Rules') }}>
            <div>
              <div className="icon-wrapper">
                <img src={log2} resizeMode='contain' style={{ width: 40, height: 40 }} />
              </div>
            </div>
            <div className="content-wrapper">
              <span className="TextSupport"><Span>Rules & Regulations</Span> </span>
            </div>
            <div style={{ padding: 10 }}>
              <FaArrowRightLong size={24} color="black" style={{ flex: 0.12 }} />
            </div>

          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-12 col-md-12 col-sm-12" >
          <div className="card-container" onClick={() => { navigate('/about') }}>
            <div>
              <div className="icon-wrapper">
                <img src={log3} resizeMode='contain' style={{ width: 40, height: 40 }} />
              </div>
            </div>
            <div className="content-wrapper">
              <span className="TextSupport"><Span>About Brain Bucks</Span> </span>
            </div>
            <div style={{ padding: 10 }}>
              <FaArrowRightLong size={24} color="black" style={{ flex: 0.12 }} />
            </div>

          </div>
        </div>
      </div>
    </div>

  )

}


export default ViewProfile
