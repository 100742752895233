import React, { useContext, useEffect, useState } from "react";
import { Context } from "../..";
import { CiMenuFries } from "react-icons/ci";
import homeicon from "../assets/icons/homeicon.svg";
import { MdOutlineMenu, MdOutlineTranslate } from "react-icons/md";
import Sidebar from "../Home/Sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import Span from "../Translation/Translate";
const Rules = () => {
  const { show, handleClose } = useContext(Context);
  const Navigate = useNavigate();
  const { handleShow } = useContext(Context);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showUpArrow, setShowUpArrow] = useState(true); // State to toggle between up and down arrows

  const toggleArrow = () => {
    setShowUpArrow(!showUpArrow);
  };

  useEffect(() => {
    handleClose();
  }, []);
  return (
    <>
      <div>
        <div className="" style={{ height: "100vh", width: "100vw" }}>
          <div
            className=" d-flex justify-content-center align-items-center"
            style={{
              height: "10vh",
              backgroundColor: "#6f42c1",
              color: "white",
              width: "100vw",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-3 d-flex justify-content-center align-items-center ">
                  <IoMdArrowRoundBack
                    onClick={() => {
                      Navigate(-1);
                    }}
                    alt="jg"
                    style={{ height: "5vh", cursor: "pointer" }}
                  />
                </div>
                {/* customer Header */}
                <div className="col-6  text-center pt-2">
                  <span
                    className="MainHeading"
                    style={{ fontSize: "1.1em", fontWeight: "600" }}
                  >
                    <Span>Rules</Span>
                  </span>
                </div>
                {/*customer header end  */}

                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoHomeOutline
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      Navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "90vh" }}>
            <div className="container">
              <div className="row">
                {/* Privacy Poliy Hedding */}
                <div className="" style={{ flex: "0 0 auto" }}>
                  <h1
                    style={{
                      justifyContent: "center",
                      marginTop: "33px",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    <Span>Brain Bucks Quiz Rules and Terms</Span>{" "}
                  </h1>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <p
                    style={{
                      marginTop: "23px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      marginLeft: "12px",
                    }}
                  >
                    1.{" "}
                    <Span>
                      This is "Not a gambling app" but contains financial risks.
                    </Span>
                    <br />
                    <Span>
                      {" "}
                      2 Rewards in regular contests and quizzes are based upon
                      the number of participants that join the contests
                    </Span>
                    .<br />
                    3. <Span> There are two types of contests</Span>:
                  </p>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <p
                    style={{
                      marginTop: "13px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      marginLeft: "12px",
                    }}
                  >
                    1. <Span>Free to Join</Span> ,<br />
                    2.{" "}
                    <Span>
                      Paid (Includes an Entry Fee) There are also some live
                      contests to join and
                    </Span>
                    <br />
                    3. <Span>participate in in real-time</Span>.
                  </p>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <p
                    style={{
                      marginTop: "23px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      marginLeft: "10px",
                    }}
                  >
                    <Span> For the Paid Contests, the entry fee varies
                    from contest to contest. Rules for selecting a winner of the
                    quiz and distributing the prize pool: Total of top 20%
                    participants are considered as Winning group of a quiz</Span>, <Span>who
                    receives the cash prize at the end of the quiz.</Span> <Span>Formula for
                    calculating the Winners is</Span>:  <Span>r = (20* TP)/100 r = Number of
                    Winners TP = Total number of participants participating in
                    the quiz.</Span>
                  </p>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <p
                    style={{
                      marginTop: "23px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "500",
                      marginLeft: "11px",
                    }}
                  >
                    <Span>The winners are determined by the accuracy of the answers
                    they give to the questions asked in the quiz, accuracy is
                    determined by the following formula</Span>: <Span>A</Span> = (( Nc )/ Nq)*100
                    <Span> Where, A = Accuracy Nc = Total number of questions answered
                    correctly Nq = Total number of questions asked. For example:
                    the total amount of players participating in a quiz are
                    total 100, and the entry fees for each participant is 10 BB
                    Coins, then the</Span>
                  </p>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <p
                    style={{
                      marginTop: "23px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "500",
                      marginLeft: "11px",
                    }}
                  >
                    <Span>The winners are determined by the accuracy of the answers</Span>
                    <Span>they give to the questions asked in the quiz, accuracy is</Span>
                    <Span>determined by the following formula: A = (( Nc )/ Nq)*100</Span>
                    <Span>Where, A = Accuracy Nc = Total number of questions answered</Span>
                    <Span>correctly Nq = Total number of questions asked. For example:</Span>
                    <Span>the total amount of players participating in a quiz are</Span>
                    <Span>total 100, and the entry fees for each participant is 10 BB</Span>
                    <Span>Coins, then the</Span>
                  </p>
                </div>
                {/* end Section of Rules  */}
                {/* start Bootom Section */}
                {/* <div className="row mt-0">
                  <div
                    class="form-check"
                    style={{ marginLeft: "23px", textDecoration: "none" }}
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      class="form-check-label"
                      for="exampleCheck1"
                      style={{
                        MarginLeft: "9px",
                        justifyContent: "center",
                        fontSize: "11px",
                        margiTop: "23px",
                        fontWeight: "600",
                      }}
                    >
                      <Span>
                        {" "}
                        I hereby agree to the Privacy Policy of the Brain Bucks
                        app
                      </Span>
                    </label>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sidebar />
    </>
  );
};

export default Rules;
