import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../..";
import Sidebar from "../Home/Sidebar";
import { CiMenuFries } from "react-icons/ci";
import homeicon from "../assets/icons/homeicon.svg";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WinHistory from "./WinHistory";
import LossHistory, { ActiveCard } from "./LossHistory";
import FreeHistory, { FreeCard } from "./FreeHistory";
import { SlCalender } from "react-icons/sl";
import { IoMdArrowRoundBack, IoMdArrowUp } from "react-icons/io";
import { IoMdArrowRoundDown } from "react-icons/io";
import HistoryApiService from "../../services/api/HistoryApiService";
import { Navigate, useNavigate } from "react-router-dom";

import AllHistory from "./AllHistory";
import { IoHomeOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { MdOutlineMenu } from "react-icons/md";
import Span from "../Translation/Translate";

const HistoryMain = () => {
  const { handleShow } = useContext(Context);
  const {value, setValue} = useContext(Context)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showUpArrow0, setshowUpArrow0] = useState(true);
  const [showUpArrow1, setshowUpArrow1] = useState(true);
  const [showUpArrow2, setshowUpArrow2] = useState(true);
  const [showUpArrow3, setshowUpArrow3] = useState(true);
 const Navigate = useNavigate()

  const toggleArrow0 = () => {
    setshowUpArrow0((prev) => !prev);
  };
  const toggleArrow1 = () => {
    setshowUpArrow1((prev) => !prev);
  };
  const toggleArrow2 = () => {
    setshowUpArrow2((prev) => !prev);
  };
  const toggleArrow3 = () => {
    setshowUpArrow3((prev) => !prev);
  };


  return (
    <>
      <div
        className="overflow-hidden d-flex flex-column"
        style={{ height: "100vh", width: "100vw", }}
      >
      <div style={{backgroundColor:"#6f42c1",}}>
      <div className="top_hist mx-3 mt-3 d-flex justify-content-between">
        <div className="">
        <IoMdArrowRoundBack onClick={() => Navigate(-1)} style={{ fontSize: "22px" , cursor:"pointer",color:"white" }} />
        </div>
        <div className="">
          <h4 style={{color:"white"}}><Span>My History</Span></h4>
        </div>
        <div className=''>
        <FaHome style={{color:"white",fontSize:"1.5rem", cursor:"pointer"}} onClick={()=>{Navigate("/")}}/>
        </div>
      </div>
      </div>



        <div className='bot_hist ms-3 d-flex align-items-center'>
          <div className='d-flex overflow-auto me-3'>
            <Box
              className="w-100 my-2 mx-md-2 "
              sx={{
                borderColor: "divider",
                borderBottom: "1px solid #E4E9EC",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={<Span>All</Span>}
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />

                <Tab
                  label={<Span>Win</Span>}
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
                <Tab
                  label={<Span>Lost</Span>}
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
                <Tab
                  label={<Span>Free</Span>}
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
                {/* <Tab
                  label="Rooms"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                /> */}
              </Tabs>
            </Box>
          </div>
          <div className='d-flex align-items-center overflow-auto' style={{ cursor: "pointer" }}>
            <SlCalender />
            {
              value == 0 &&
              <>
                {
                  showUpArrow0 ? <IoMdArrowUp onClick={toggleArrow0} /> : <IoMdArrowRoundDown onClick={toggleArrow0} />
                }
              </>
            }
            {
              value == 1 &&
              <>
                {
                  showUpArrow1 ? <IoMdArrowUp onClick={toggleArrow1} /> : <IoMdArrowRoundDown onClick={toggleArrow1} />
                }
              </>
            }
            {
              value == 2 &&
              <>
                {
                  showUpArrow2 ? <IoMdArrowUp onClick={toggleArrow2} /> : <IoMdArrowRoundDown onClick={toggleArrow2} />
                }
              </>
            }

            {
              value == 3 &&
              <>
                {
                  showUpArrow3 ? <IoMdArrowUp onClick={toggleArrow3} /> : <IoMdArrowRoundDown onClick={toggleArrow3} />
                }
              </>
            }

          </div>
        </div>




        <div
          className="flex-grow-1 mb-2 pb-5"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >

          {value === 0 && <AllHistory order={showUpArrow0 ? 0 : 1} />}
          {value === 1 && <WinHistory order={showUpArrow1 ? 0 : 1} />}
          {value === 2 && <LossHistory order={showUpArrow2 ? 0 : 1} />}
          {value === 3 && <FreeHistory order={showUpArrow3 ? 0 : 1} />}

        </div>
      </div>

      <Sidebar />
    </>
  );
};

export default HistoryMain;