import React from 'react'
import sbi from "../assets/icons/sbi.svg";
import { FaArrowRight } from "react-icons/fa6";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import bird from "../assets/bird.gif"
import Span from '../Translation/Translate';

const ExamsAdded = () => {
   
    const Navigate = useNavigate();
  return (
   <>
   <div className='d-flex flex-column' style={{ overflow: "hidden" }}>
   <div className='flex-grow-0'>

       <div className=''>
           <div className='row mt-2 ' style={{ height: "10vh" }}>
               <div className='col-3'>

                   <IoArrowBackOutline style={{ height: "5vh", marginLeft: "1rem", fontSize: "30px", cursor: "pointer" }} onClick={() => {
                       Navigate(-1)
                   }} />

               </div>
              

           </div>
       </div>

       <div className='row mt-3 p-2 col-sm-6 col-md-4 d-flex w-100' style={{justifyContent:"center"}}>
           <div className=' d-flex gap-2 w-100' style={{justifyContent:"center"}}>
              
               <h4 className='mt-1'><Span>Exams Successfully Added</Span> </h4>
           </div>
        



       </div>

       

   </div>

   <div style={{ flexDirection: "column", justifyContent: "center", }} >
   <div className='d-flex ' style={{ justifyContent: "center",hight:"30vh" }}>
       <img src={bird} style={{ width: "35vh" }}></img>
   </div>
   <div className=' d-flex' style={{ justifyContent: "center", }}>
       <h4>
      <Span>Now you can participate in</Span> <br/>
     <Span> Quizzes from following Exams</Span> ...</h4>
   </div>
  <div className='w-100  d-flex mt-2' style={{justifyContent:"center"}}>
  
  </div>
</div>


</div>
   </>
  )
}

export default ExamsAdded
