import React, { useCallback, useEffect, useRef, useState } from 'react'
import Bottom from './Bottom'
import { IoArrowBack } from "react-icons/io5";
import image4 from '../assets/image5.png';
import coin from '../assets/coin.png';
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from 'react-router-dom';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { LuTicket } from 'react-icons/lu';
import { ToastContainer, toast } from "react-toastify";
import { FaRegClock } from 'react-icons/fa6';
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import { BasicServices } from '../../services/BasicServices';
import LoaderComponent from '../utils/LoaderComponent';
import EmptyContainer from '../utils/EmptyContainer';
import Span from "../Translation/Translate";
const LiveQuizzes = () => {
    const Navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [live, setlive] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt
    const observer = useRef();
    const LiveQuiz = async (page) => {
        try {
            setIsLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${QUIZMICRO}/home/get/live/quizes?page=${page}&limit=25`, requestOptions)
            const result = await response.json();
            setIsLoading(false)
            if (result?.status == 1) {
                setlive((datas) => [...datas, ...result?.activequizes])
                setTotalPages(result?.totalpages)

            } else if (result.status == "VAL_ERR") {
                toast.error(result.Backend_Error);
            } else if (result.status == "CUSTOM_ERR") {
                toast.error(result.Backend_Error);
            } else if (result.status === "TOKEN_ERR") {
                basic.setJwt("");
                Navigate("/Register");
                toast("Logged in other device! Please login again");
            }
        } catch (error) {
            console.log(error);
        }
    }

   

    const lastElementRef = useCallback(node => { // Added: Callback for the last element
        if (isLoading) return;
        if (observer.current) observer.current.disconnect(); // Disconnect the previous observer
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && page <= totalPages ) {
                setPage((prevPage) => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node); // Observe the last element
    }, [isLoading]);


    useEffect(() => {
        LiveQuiz(page)
    }, [page])

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div style={{ height: "100vh", width: "100vw" , overflowX: "hidden"}}>
                <div className='border' style={{ height: "10vh",backgroundColor:"#6f42c1",color:"white", padding: "5px" }} >
                  
                    <div className='container '>
                        <div className='row mt-3'>
                            <div className='col-3 d-flex justify-content-center align-items-center' >
                                <IoArrowBack style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => { Navigate("/") }} />
                            </div>
                            <div className='col-6  text-center'>
                                <span style={{ fontSize: "1.5rem", fontWeight: "700" }} className="mb-0">
                                   <Span>All Live Quizzes</Span>
                                </span>
                            </div>
                            <div className='col-3' >

                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='container example' style={{ height: "91vh", overflowY: "scroll", }}>

                    <div className='container'>
                        <div className='row ' >

                            {(live?.length === 0 && !isLoading) ? (
                                <EmptyContainer
                                    actionName="Reload"
                                    action={() => LiveQuiz(page)}
                                    message="No Data Found"
                                />
                            ) : (
                                live?.map((res, index) => {
                                    const [datePart, timePart] = res.sch_time.split(" ");
                                    return (
                                        <div ref={index === live.length - 1 ? lastElementRef : null} className='col-lg-4 col-md-6 col-sm-12 mt-3 rounded border boxshadow p-3'>

                                            <div className='d-flex align-items-center gap-2'>
                                                <div
                                                    style={{
                                                        borderRadius: "50%",
                                                        width: "50px",
                                                        height: "50px",
                                                        border: "1px solid #808080",
                                                    }}
                                                >
                                                    <img
                                                        src={IMAGE_URL + res.banner}
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                            borderRadius: "50%",
                                                        }}
                                                        alt=''
                                                    />
                                                </div>
                                                <h6 className='some-txt'><Span>{res.quiz_name}</Span></h6>
                                            </div>
                                            <div className=' mt-2 d-flex align-items-center justify-content-between'
                                                style={{ cursor: "pointer" }} >
                                                <div className='d-flex align-items-center gap-2'>
                                                    <h6 className='txx'><Span>Fees</Span></h6>
                                                    <div className='d-flex gap-2'>
                                                        <img src={coin} style={{ height: "3vh" }} alt=''></img>
                                                        <h6 className='new-txt'>{res.entryFees}</h6>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <MdOutlineCalendarMonth className='txx' style={{ fontSize: "20px" }} />
                                                    <span className='txx'>{datePart}</span>
                                                </div>
                                            </div>
                                            <div className=' mt-2 d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <h6 className='txx'><Span>Reward</Span></h6>
                                                    <div className='d-flex gap-2'>
                                                        <img src={coin} style={{ height: "3vh" }} alt=''></img>
                                                        <h6 className='new-txt'>{res.prize}</h6>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <FaRegClock className='txx' style={{ fontSize: "18px" }} />
                                                    <span className='txx'>{timePart}</span>
                                                </div>

                                            </div>
                                            <div className='mt-2 d-flex align-items-center gap-2 ms-1'>
                                                <div >
                                                    <LuTicket style={{ fontSize: "3vh" }} />
                                                </div>
                                                <div className='d-flex mt-2'>
                                                    <h6 className='alpha'>{res.slot_aloted}</h6>
                                                    <h6>/{res.slots}</h6>
                                                </div>
                                            </div>
                                            <div className='mt-2'>
                                                <ProgressBar
                                                    completed={res?.slot_aloted}
                                                    bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                                                    height="10px"
                                                    isLabelVisible={false}
                                                    baseBgColor="#E4E9EC"
                                                    labelColor="#e80909"
                                                    animateOnRender
                                                    maxCompleted={res?.slots}
                                                />
                                            </div>
                                            <div className=' mt-3 d-flex justify-content-center'>
                                                <button className='registerButtonClass p-2 mb-1 text-center w-100' onClick={() => { Navigate(`/register/${res?._id}`) }}>
                                                   <Span>Register Now</Span> 
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })

                            )}
                       
                            {isLoading && <LoaderComponent />}
                            {/* <div className="center-data">


                                {((totalPages != page.current) && (live?.length != 0)) &&
                                    <button className="load-button center-data p-3 px-4 rounded mt-3" onClick={handleLoad}>
                                       <Span>Load More</Span> 
                                    </button>
                                }


                            </div> */}

                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}



export default LiveQuizzes
