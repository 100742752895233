import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import vector from "../assets/Vector (1).png";
import fardail from "../assets/fadrailbank.png";
import ban from "../assets/banner.png";
import Span from "../Translation/Translate";
import { BsBank2 } from "react-icons/bs";

const Addsuccess = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <div style={{ backgroundColor: "#701DDB" }}>
        <div>
          <section className="pb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center pt-5">
                        {/* <h6 className='' style={{fontSize:"45px",fontWeight:"600",color:"#FFFFFF"}}>₹ 15,600</h6> */}
                        <h6
                          className="pt-3"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                            lineHeight: "2px",
                          }}
                        >
                          {" "}
                          <img
                            src={vector}
                            style={{ width: "50px", marginRight: "10px" }}
                            alt=""
                          />{" "}
                          <Span>Add Bank Request Successfully</Span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              backgroundColor: "#FFFFFF",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#A1A2AD",
                          }}
                        >
                         <Span>Beneficiary Details</Span> 
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      <div
                        className="border 1px solid p-2"
                        style={{ borderRadius: "5px" }}
                      >
                        <div className="d-flex justify-content-start">
                          <BsBank2
                          className="me-2"
                            style={{ width: "40px", height:"36px" }}
                          />
                          <h6
                            className="pt-1"
                            style={{ fontWeight: "500", fontSize: "25px" }}
                          >
                            {" "}
                            <Span>{state.bankName}</Span>
                          </h6>
                        </div>
                        <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <span style={{ color: "#7E7E7E" }}>
                          <Span> Account Holder</Span>
                          </span>
                          <Span> {state.accHolder}{" "}</Span>
                        </h6>
                        <div className="d-flex justify-content-between">
                          <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                            <span style={{ color: "#7E7E7E" }}>Acc </span>{" "}
                            <Span>{state.accNumber}</Span>
                          </h6>
                          <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                            {" "}
                            <span style={{ color: "#7E7E7E" }}><Span>IFSC</Span> </span>{" "}
                            {state.ifsc}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="" style={{ paddingTop: "8%" }}>
                        <div class="d-grid gap-2">
                          <button
                            class="btn-btn p-2"
                            onClick={() => {
                              Navigate("/wallet/bank", { replace: true });
                            }}
                            style={{
                              backgroundColor: "#701DDB",
                              borderRadius: "6px",
                              fontWeight: "600",
                              fontSize: "17px",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            type="button"
                          >
                            <Span>Back To Add Bank</Span>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Addsuccess;
