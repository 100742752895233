import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import WalletApiService from "../../services/api/WalletApiService";
import { ToastContainer, toast } from "react-toastify";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";
import { load } from "@cashfreepayments/cashfree-js";


const Depositmoney = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(3);
  const [amount, setAmount] = useState();
  const [isActive, setActive] = useState(true);
  const walletService = new WalletApiService();
  const basic = new BasicServices();

  const deposit = async () => {
    if (!amount) {
      toast("Please enter a value first");
      return;
    }
    setActive(false);
    try {
      const res = await walletService.createOrder(amount);
      console.log("Order Response:", res);

      if (res?.status === 1) {
        try {
          const cashfree = await load({ mode: "sandbox" }); // Use "PROD" for production
          console.log("Cashfree Loaded:", cashfree);
          cashfree.checkout({
            paymentSessionId: res?.payment_session_id,
            onSuccess: (data) => {
              console.log("Payment Success:", data);
              // navigate(`/paymentstatus?order_id=${res?.order_id}`);
            },
            onFailure: (error) => {
              console.error("Cashfree Payment Error:", error);
              setActive(true);
              toast(error.message);
              // navigate(`/paymentstatus?order_id=${res?.order_id}`);
            },
            onClose: (data) => {
              console.log("Payment Form Closed:", data);
              setActive(true);
            },
          });
        } catch (error) {
          console.error("Cashfree Initialization Error:", error);
          setActive(true);
          toast(error.message);
        }
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("");
        navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        toast(res.message);
        setActive(true);
      }
    } catch (error) {
      console.error("Order Creation Error:", error);
      toast(error.message);
      setActive(true);
    }
  };

  return (
    <>
      <div>
        <ToastContainer />
        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <div style={{ backgroundColor: "#6f42c1", color: "white" }}>
            <div className="" style={{ height: "10vh" }}>
              <div className="container p-2">
                <div className="row">
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <IoMdArrowRoundBack
                      onClick={() => navigate("/wallet")}
                      style={{ fontSize: "22px", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-6 text-center">
                    <span
                      style={{ fontSize: "22px", fontWeight: "700" }}
                      className="pt-6"
                    >
                      <Span>Deposit Money</Span>
                    </span>
                    <br />
                    <span
                      className=""
                      style={{ fontSize: "12px", lineHeight: "2px" }}
                    >
                      <Span>In my Brain Bucks Wallet</Span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border" style={{ height: "78vh", overflowY: "auto" }}>
            <section className="h-100">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="pt-5">
                          <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                            <Span>Enter Amount</Span>
                          </h6>
                          <input
                            type="text"
                            className="p-1"
                            style={{
                              fontSize: "24px",
                              fontWeight: "700",
                              width: "100%",
                              boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.5)",
                            }}
                            value={amount}
                            placeholder="₹ 500"
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row pt-4">
                        <div className="col-3">
                          <h6
                            onClick={() => {
                              setValue(3);
                              setAmount(50);
                            }}
                            className="p-2 text-center"
                            style={{
                              backgroundColor: value === 3 ? "#8258B8" : "#610ECD",
                              color: "#FFFFFF",
                              borderRadius: "16px",
                              fontWeight: "600",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          >
                            ₹ 50
                          </h6>
                        </div>
                        <div className="col-3">
                          <h6
                            onClick={() => setAmount(100)}
                            className="p-2 text-center"
                            style={{
                              backgroundColor: value === 3 ? "#701DDB" : "#F2F2F2",
                              color: "#FFFFFF",
                              borderRadius: "16px",
                              fontWeight: "600",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          >
                            ₹ 100
                          </h6>
                        </div>
                        <div className="col-3">
                          <h6
                            onClick={() => setAmount(500)}
                            className="p-2 text-center"
                            style={{
                              backgroundColor: "#610ECD",
                              color: "#FFFFFF",
                              borderRadius: "16px",
                              fontWeight: "600",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          >
                            ₹ 500
                          </h6>
                        </div>
                        <div className="col-3">
                          <h6
                            onClick={() => setAmount(1000)}
                            className="p-2 text-center cursor-pointer"
                            style={{
                              backgroundColor: "#8258B8",
                              color: "#FFFFFF",
                              borderRadius: "16px",
                              fontWeight: "600",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          >
                            ₹ 1000
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center w-100 mb-4 align-items-center align-self-end">
                    {isActive ? (
                      <button
                        className="w-100 btn btn-primary"
                        onClick={deposit}
                      >
                        <Span>Deposit Now</Span>
                      </button>
                    ) : (
                      <div className="spinner-grow" role="status"></div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Depositmoney;
