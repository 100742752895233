import React, { useCallback, useEffect, useRef, useState } from 'react'
import Bottom from './Bottom'
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

import coin from '../assets/coin.png';
import ProgressBar from "@ramonak/react-progress-bar";
import { MdOutlineCalendarMonth } from 'react-icons/md';

import { BsTrophyFill } from 'react-icons/bs';
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import { toast } from "react-toastify";
import { BasicServices } from '../../services/BasicServices';
import LoaderComponent from '../utils/LoaderComponent';
import EmptyContainer from '../utils/EmptyContainer';
import Span from "../Translation/Translate";
import { FaRegClock } from 'react-icons/fa';
const FreeTrivia = () => {
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt
    const Navigate = useNavigate();
    const [trivia, settrivia] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const observer = useRef();

    const SeeTrivia = async (page = 1) => {
        try {
            setIsLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${QUIZMICRO}/home/get/trivia/quizes?page=${page}&limit=25`, requestOptions)
            const result = await response.json();
            setIsLoading(false)
            if (result?.status === 1) {
                settrivia((datas) => [...datas, ...result?.triviaquizes])
                setTotalPages(result?.totalpages)

            } else if (result?.status == "VAL_ERR") {
                toast.error(result?.Backend_Error);
            } else if (result?.status == "CUSTOM_ERR") {
                toast.error(result?.Backend_Error);
            } else if (result.status === "TOKEN_ERR") {
                basic.setJwt("");
                Navigate("/Register");
                toast("Logged in other device! Please login again");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const lastTransactionElementRef = useCallback((node) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && page < totalPages){
                setPage((prevPage) => prevPage + 1); // Increment page number when the last element is visible
            }
        });
        if (node) observer.current.observe(node); // Observe the last transaction element
    }, [isLoading, totalPages]);

    useEffect(() => {
        SeeTrivia(page)
    }, [page])

    return (
        <>
            <div style={{ height: "100vh",}}>
                <div className='border' style={{ height: "10vh", backgroundColor: "#6f42c1", color: "white" }} >
                    <div className='container'>
                        <div className='row mt-3'>
                            <div className='col-3 d-flex justify-content-center align-items-center' >
                                <IoArrowBack style={{ fontSize: "4vh", cursor: "pointer" }} onClick={() => { Navigate("/") }} />
                            </div>
                            <div className='col-6  text-center'>
                                <span style={{ fontSize: "1.5rem", fontWeight: "700" }} className="mb-0">
                                    <Span>All Free Trivias</Span>
                                </span>
                            </div>
                            <div className='col-3' >

                            </div>
                        </div>


                    </div>
                </div>
                <div className='container example' style={{ height: "90vh" }}>

                    <div className='container '>
                        <div className='row'>
                            {(trivia?.length === 0 && !isLoading) ? (
                                <EmptyContainer
                                    actionName="Reload"
                                    action={() => SeeTrivia(page.current)}
                                    message="No Data Found"
                                />
                            ) : (
                                trivia?.map((res, index) => {
                                    const [datePart, timePart] = res.sch_time.split(" ");
                                    return (
                                        <div className='col-lg-4 col-md-6 col-sm-12 mt-3 border boxshadow' key={index}
                                            ref={index === trivia.length - 1 ? lastTransactionElementRef : null}>

                                            <div className=' mt-3 p-3' >

                                                <div className='d-flex align-items-center gap-2'>
                                                    <img src={IMAGE_URL + res.banner} style={{ height: "4vh" }}></img>
                                                    <h6 className='some-txt'><Span>{res.quiz_name}</Span> </h6>
                                                </div>
                                                <div className=' mt-2 d-flex align-items-center justify-content-between'
                                                    style={{ cursor: "pointer" }}>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <h6 className='txx'><Span>Prize</Span></h6>
                                                        <div className='d-flex gap-2'>
                                                            <img src={coin} style={{ height: "3vh" }}></img>
                                                            <h6 className='new-txt'>{res.reward}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <h6 className='txx'></h6>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <MdOutlineCalendarMonth className='txx' style={{ fontSize: "20px" }} />
                                                            <span className='txx'>{datePart}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='mt-2 d-flex align-items-center justify-content-between gap-2 ms-1'>
                                                    <div className='d-flex gap-2'>
                                                        <BsTrophyFill className='trophy' />
                                                        <h6 className='persent'>{res.min_reward_per}%</h6>
                                                    </div>

                                                    <div className='d-flex align-items-center gap-2'>
                                                        <FaRegClock className='txx' style={{ fontSize: "20px" }} />
                                                        <span className='txx'>{timePart}</span>
                                                    </div>

                                                </div>
                                                <div className='mt-2'>
                                                    <ProgressBar
                                                        completed={res?.min_reward_per}
                                                        height="10px"
                                                        isLabelVisible={false}
                                                        baseBgColor="#E4E9EC"
                                                        labelColor="#e80909"
                                                        animateOnRender
                                                        // maxCompleted={res?.reward}
                                                        className='persent'
                                                    />
                                                </div>
                                                <div className=' mt-3 d-flex justify-content-center'>
                                                    <button className='participateButtonClass p-2 text-center w-100'
                                                        onClick={() => Navigate(`/participate/${res?._id}`)}>
                                                        <Span>Participate Now</Span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                            {isLoading && <LoaderComponent />}


                            <div className="center-data">


                                {/* {((totalPages != page.current) && (trivia?.length != 0)) &&
                                    <button className="load-button center-data p-3 px-4 rounded mt-3" onClick={handleLoad}>
                                       <Span>Load More</Span> 
                                    </button>
                                } */}


                            </div>

                        </div>

                    </div>


                </div>

                {/* <div className='border' style={{ height: "12vh" }}>
                    <Bottom />
                </div> */}
            </div>
        </>
    )
}

export default FreeTrivia
