import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import Span from "../Translation/Translate";
import user from "../assets/user.webp";
import { IoIosArrowForward } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { BasicServices } from "../../services/BasicServices";
import { NOTIFY_URL } from "../../config/urls";
import { ToastContainer, toast } from "react-toastify";
import { RevolvingDot } from "react-loader-spinner";

const Support = () => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [chatting, setChatting] = useState([]);
  const [chatId, setChatid] = useState('');
  const [isClosed, setIsClosed] = useState(null);
  const navigate = useNavigate();
  const [isChatLoading, setIsChatLoading] = useState(false);

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;

  const handleClose = () => setShow(false);

  const handleSendMessage = async () => {
    if (title.trim() === '') {
      toast('Complain Title is required.');
      return;
    }

    try {
      const response = await axios.post(
        `${NOTIFY_URL}/partichat/create/ticket`,
        { title: title },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log('Ticket created successfully:', response.data);
      setTitle('');
      handleClose();
      getPreviousTickets();
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  const getPreviousTickets = async () => {
    setIsChatLoading(true);
    try {
      const response = await axios.get(
        `${NOTIFY_URL}/partichat/get/previous/ticket`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const tickets = response.data.Tickets || [];
      setChatting(tickets);
      setIsClosed(tickets[0]?.is_closed ?? "");
      console.log('dataclosed', tickets[0]?.is_closed ?? "");
      // Set chatId if tickets exist

      if (tickets.length > 0) {
        setChatid(tickets[0]._id);
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
    finally{
      setIsChatLoading(false);
    }
  };

  const handleShow = () => {
    if (isClosed === false) {
      toast.error('Ticket is already created.');
      return;
    }
    setShow(true);
  };

  useEffect(() => {
    getPreviousTickets();
  }, []);

  return (
    <>
      <ToastContainer />
      <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        {/* Carrier of customer section start */}
        <div style={{ flexShrink: 0 }}>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "10vh",
              backgroundColor: "#6f42c1",
              color: "white",
              position: "fixed",
              width: "100%",
              top: 0,
              zIndex: 1000,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <FaArrowLeftLong
                    onClick={() => {
                      navigate(-1);
                    }}
                    alt="back"
                    style={{ height: "5vh", cursor: "pointer" }}
                  />
                </div>
                <div className="col-6 text-center pt-2">
                  <span
                    className="MainHeading"
                    style={{ fontSize: "1.1em", fontWeight: "600" }}
                  >
                    <Span style={{ fontSize: "19px" }}>Help & Support</Span>
                  </span>
                </div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoHomeOutline
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create New Ticket button */}
        <div
          className="d-flex justify-content-end pe-4 py-1"
          style={{
            flexShrink: 0,
            marginTop: "10vh", // To offset the fixed top bar
            position: "fixed",
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 1000,
          }}
        >
          <button type="button" className="btn addbtn btn-light" onClick={handleShow}>
            +<Span>Create New Ticket</Span>
          </button>
        </div>

        {/* Chat history */}
        {isChatLoading ? (
        <div>Loading....</div>
        ): (
<div
          style={{
            flexGrow: 1,
            overflowY: "auto",
            marginTop: "15vh", // Offset for top bar and button
          }}
        >
          {chatting.map((res, chat) => (
            <div className="col-12 col-sm-6 col-md-12 p-2 d-flex mt-3" key={chat}>
              <div
                className="mx-3 py-4 d-flex rounded boxshadow card"
                style={{ lineHeight: "1px", cursor: "pointer" }}
              >
                <div className="d-flex gap-3" onClick={() =>
                  navigate(`/createchat/${res?._id}/${res.is_closed}`)
                }>
                  <div className="flex-grow-0 notsaved">
                    <img
                      src={user}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt="icon"
                    />
                  </div>
                  <div className="flex-grow-1 center-y t2" style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}>
                    <Span>
                      {(() => {
                        const words = res.title.split(" ");
                        console.log('words', words);
                        return words?.length > 3 ? words.slice(0, 2).join(" ") + "..." : res.title;
                      })()}
                    </Span>
                  </div>
                  <div className="d-flex align-items-center rounded"
                    style={{
                      backgroundColor: res?.is_closed ? 'red' : 'grey',
                      color: res?.is_closed ? 'white' : 'black',
                      padding: "0 12px 10px 12px",
                      textAlign: "center",
                      position: "relative"
                    }}
                  >
                    {res?.is_closed ? (
                      <>
                        <div>Closed On</div>
                        <div style={{
                          fontSize: "12px",
                          position: "absolute",
                          bottom: "8px",
                          left: 0,
                          right: 0,
                          textAlign: "center"
                        }}>
                          {res.closed_on}
                        </div>
                      </>
                    ) : (
                      <div>Open</div>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <IoIosArrowForward style={{ fontSize: "25px" }} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        )}
        

        {/* Modal to enter title */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter Complain Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="exampleFormControlTextarea1" className="form-label">Complain Title</label>
              <textarea
                id="complainTitle"
                className="form-control"
                rows="3"
                style={{ fontSize: "19px", fontWeight: "500" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSendMessage}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Support;
