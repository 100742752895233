import React, {  useEffect, useRef, useState } from 'react'
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from "../utils/LoaderComponent";
import { Carousel } from 'react-bootstrap';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Span from '../Translation/Translate';
import ProgressBar from "@ramonak/react-progress-bar";
import coin from "../assets/coin.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsTrophyFill } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa6";

const HomeFreeTrivia = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [trivia, settrivia] = useState([]);
    const page = useRef(1);
    const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();

    const getHomeTrivia = async () => {
        try {
          setIsLoading(true);
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);
    
          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
    
          const response = await fetch(
            `${QUIZMICRO}/home/get/trivia/quizes?page=1&limit=10`,
            requestOptions
          );
          const result = await response.json();
          setIsLoading(false);
          if (result?.status == 1) {
            settrivia(result.triviaquizes);
           
    
          
          } else if (result.status === "TOKEN_ERR") {
            basic.setJwt("");
            Navigate("/Register");
            toast("Logged in other device! Please login again");
          }
        } catch (error) {
          console.log(error);
        }
      };


      useEffect(() => {
        getHomeTrivia();
      }, [])
  return (
    <>
    <div className="col-lg-12 col-md-12 col-sm-12 ">
                <div className=" d-flex align-items-center justify-content-between">
                  <h6 className="txt">
                    <Span>Free Trivia</Span>{" "}
                  </h6>

                  <div className="">
                    <span
                      className="see"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Navigate("/free/trivia");
                      }}
                    >
                      <Span>See All</Span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 p-1 boxshadow card">
                  <Carousel className="custom-carousel">
                    {trivia?.length === 0 && !isLoading ? (
                      <EmptyContainer
                        actionName="Reload"
                        action={() => getHomeTrivia(page.current)}
                        message="No Data Found"
                      />
                    ) : (
                      trivia?.map((res) => {
                        const [datepart1, timepart1] = res.sch_time.split(" ");
                        return (
                          <Carousel.Item>
                            <div className="p-3 ">
                              <div className="mt-3 ">
                                <div className="d-flex align-items-center gap-3">
                                  <div
                                    className="boxshadow"
                                    style={{
                                      borderRadius: "50%",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  >
                                    <img
                                      src={IMAGE_URL + res.banner}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <h6 className="some-txt">
                                    <Span>{res.quiz_name}</Span>
                                  </h6>
                                </div>
                                <div className="mt-2 d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center gap-2">
                                    <h6 className="txx">
                                      <Span>Reward</Span>
                                    </h6>
                                    <div className="d-flex gap-2">
                                      <img
                                        src={coin}
                                        style={{ height: "3vh" }}
                                        alt="Coin"
                                      />
                                      <h6 className="new-txt">{res.reward}</h6>
                                    </div>

                                  </div>
                                  <div className="d-flex align-items-center gap-2">
                                    <MdOutlineCalendarMonth
                                      className="txx"
                                      style={{ fontSize: "20px" }}
                                    />
                                    <span className="txx">{datepart1}</span>
                                  </div>
                                </div>
                                <div className="mt-2 d-flex align-items-center justify-content-between">
                                  <div className="mt-3 d-flex align-items-center gap-2 ms-1">
                                    <div className="d-flex gap-2">
                                      <BsTrophyFill className="trophy" />
                                      <h6 className="persent">
                                        {res.min_reward_per}%
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center gap-2">
                                    <FaRegClock
                                      className="txx"
                                      style={{ fontSize: "18px" }}
                                    />
                                    <span className="txx">
                                      <Span>{timepart1}</Span>
                                    </span>
                                  </div>
                                </div>


                                <div className="mt-2">
                                  <ProgressBar
                                    completed={res?.min_reward_per}
                                    height="10px"
                                    isLabelVisible={false}
                                    baseBgColor="#E4E9EC"
                                    labelColor="#e80909"
                                    animateOnRender
                                    // maxCompleted={res?.reward}
                                    className="persent"
                                  />
                                </div>
                                <div
                                  className="mt-3 d-flex justify-content-center"
                                  onClick={() =>
                                    Navigate(`/participate/${res?._id}`)
                                  }
                                >
                                  <button className="participateButtonClass p-2 text-center w-100">
                                    <Span>Participate Now</Span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Carousel.Item>
                        );
                      })
                    )}{" "}
                    {isLoading && <LoaderComponent />}
                  </Carousel>
                </div>
              </div>
    </>
  )
}

export default HomeFreeTrivia