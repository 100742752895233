import React, { useState } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding: 10px; /* Add padding to make it more spacious */
  background-color: #f9f9f9; /* Add background color for better visibility */

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 480px) {
    padding: 6px;
  }
`;

const TextInput = styled.input`
  flex: 1;
  padding: 5px; /* Increase padding */
  border: none;
  outline: none;
  font-size: 18px; /* Increase font size */
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 12px; /* Adjust padding for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    padding: 15px; /* Adjust padding for smallest screens */
    font-size: 14px; /* Adjust font size for smallest screens */
  }
`;

const SendButton = styled.button`
  padding: 12px 20px; /* Increase padding for better usability */
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  outline: none;
  border-radius: 5px; /* Add some border radius for better look */
  margin-left: 10px; /* Add margin to separate from input */

  @media (max-width: 768px) {
    padding: 10px 16px;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
  }
`;

const InputBox = ({ onSendMessage }) => {
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      onSendMessage(input);
      setInput('');
    }
  };

  return (
    <InputContainer>
      <TextInput
        type="text"
        value={input}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && handleSend()}
        placeholder="Type a message..."
      />
      <SendButton onClick={handleSend}>Send</SendButton>
    </InputContainer>
  );
};

export default InputBox;
