import React, { useState } from 'react';
import { FaArrowLeftLong } from "react-icons/fa6";
import "../Rooms/Rooms.css";
import room1 from "../assets/roomcreate-removebg-preview.png";
import Span from '../Translation/Translate';
import { GRAPHQL_URL } from '../../config/urls';
import { BasicServices } from '../../services/BasicServices';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
const CreateNewRoom = () => {
  const Navigate = useNavigate()
  const [quizTab, setQuiztab] = useState(1);
  const [name, setName] = useState('');
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "room_name": name,
      "room_type": 0
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    setIsLoading(false);
    try {
      const response = await fetch(`${GRAPHQL_URL}/participant/room/create/room`, requestOptions);
      const result = await response.json();

      if (result?.status == 1) {
        await toast(result?.msg);  // Ensure the toast is displayed before navigation
        Navigate("/rooms");
      } else if (result.status === "TOKEN_ERR") {
        await toast("Logged in on another device! Please login again.");
        basic.setJwt("");
        Navigate("/Register");
      } else if (result.status === "VAL_ERR") {
        await toast(result.error);
      } else if (result.status === "CUSTOM_ERR") {
        await toast(result.error);
      }  
      else {
        console.log(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false)
    }
  };
  return (
    <>
      <ToastContainer />
      <div className='d-flex flex-column' style={{ height: "100vh", width: "100wh", overflowy: "auto" }}>
        <div className='top_creat_new_rom flex-grow-1 m-4'>
          <div className='arrow_create_rom' >
            <FaArrowLeftLong style={{ fontSize: "25px", cursor: "pointer" }} onClick={() => { Navigate("/rooms") }} />
          </div>
          <div className='mt-4' style={{ fontSize: "19px", fontWeight: "600" }}>
            <span>Create Room & Compete</span><br />
            <span>with your friends</span>
          </div>
          <div className='d-flex justify-content-center'>
            <img src={room1} alt='room' />
          </div>
          <div className=''>
            <div className=''>
              <span style={{ fontSize: "17px", fontWeight: "600" }}>Room Type</span>
            </div>
            <div className=" d-flex  mt-4 rounded">
              {/* <div className="col-6">
                <div
                  onClick={() => setQuiztab(1)}
                  className="text-center p-2"
                  style={{
                    backgroundColor: quizTab == 1 ? "#701DDB" : "#F2F2F2",
                    color: quizTab == 1 ? "white" : "blue",
                    borderRadius: "8px",
                    cursor: "pointer"
                  }}
                >
                  <h5
                    className="text-center"
                    style={{ color: quizTab === 1 ? "white" : "black" }}
                  >
                    {" "}
                    <Span style={{ fontSize: "16px" }}>Public</Span>
                  </h5>
                </div>
              </div> */}
              <div className="col">
                <div
                 
                  className="text-center p-2"
                  style={{
                    backgroundColor:  "#701DDB" ,
                    color:  "#FFFFFF" ,
                    borderRadius: "8px",
                    
                  }}
                >
                  <h5
                    className="pt-1 text-center"
                    style={{ color: "white"  }}
                  >
                    {" "}
                    <Span style={{ fontSize: "16px" }}>Private</Span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <span style={{ fontSize: "17px", fontWeight: "600", paddingTop: "15px" }}>Enter the name of room</span>
            <input type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required placeholder='Ex. My upsc room' style={{ marginLeft: "10px", fontSize: "16px", padding: "15px", backgroundColor: "#F2F2F2", border: "none" }} />
          </div>
          {/* <div className='bot_creat_new_rom flex-grow-0' onClick={handleSubmit}>
            <div className='d-flex align-items-center justify-content-center'>
              <span style={{ paddingTop: "11px", cursor: "pointer" }}>Create Room</span>
            </div>
          </div> */}
        </div>

        <div className='bot_creat_new_rom flex-grow-0' onClick={handleSubmit}>
          <div className='d-flex align-items-center justify-content-center'>
            <span style={{ paddingTop: "11px", cursor: "pointer" }}>{isLoading ? "PLease Wait" : "Create Room"}</span>
          </div>
        </div>

      </div>
    </>
  )
}
// 
export default CreateNewRoom;