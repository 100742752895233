import React, { useContext, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { CiShare2 } from "react-icons/ci";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import RoomLiveQuizz from "./RoomLiveQuizz";
import RoomSheduleQuizz from "./RoomSheduleQuizz";
import Span from "../Translation/Translate";
import { Context } from "../..";

const EnterRoom = () => {
  const participants = [
    {
      name: "ram"
    },
    {
      name: "ram"
    },
    {
      name: "ram"
    },
    {
      name: "ram"
    },
    {
      name: "ram"
    },
    {
      name: "ram"
    },



  ]

  const{ Roomsid} = useParams()
  const Navigate = useNavigate();
  const { joinedStoreData } = useContext(Context);;
  const [quizTab, setQuiztab] = useState(1);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ background: "#701ddb", height: "100vh", width: "100vw", overflow: "hidden" }}
      >
        <div className="top_enter flex-grow-0" >
          {/* top menu */}
          <div className="d-flex p-4 justify-content-between">
            <div
              className=""
              style={{
                background: "#ffffff42",
                borderRadius: "50%",
                padding: "9px",
                cursor: "pointer",
              }}
              onClick={() => Navigate("/rooms")}
            >
              <FaArrowLeftLong style={{ color: "#fff", fontSize: "19px" }} />
            </div>
            <div
              className="rounded p-1"
              style={{ backgroundColor: "#ffffff42", cursor: "pointer" }}
              onClick={() => Navigate(`/roomhistory/${Roomsid}`)}
            >
              <IoTimeOutline style={{ fontSize: "25px", color: "#fff" }} />
              <span className="" style={{ color: "#fff", fontSize: "20px" }}>
                History
              </span>
            </div>
          </div>
          {/* quizz data  */}
          <div className="ps-4 pt-4 ">
            <span
              className=""
              style={{ fontSize: "25px", color: "#fff", fontWeight: "600" }}
            >
              {joinedStoreData.room_name}
            </span>
          </div>
          <div className="mt-2 ps-4">
            <span className="" style={{ color: "#fff" }}>
              Members <span style={{ color: "#04FBB1" }}>{joinedStoreData.enrolled_participants_count}</span>
            </span>
          </div>
          <div className="d-flex  ps-4 pt-4">
            {/* <div
              className="col-3"
              style={{
                backgroundColor: "#ffffff42",
                borderRadius: "20px",
                padding: "3px 0 3px 5px",
              }}
            >
              <CiShare2 style={{ color: "#fff", fontSize: "17px" }} />{" "}
              <span style={{ color: "#fff", fontWeight: "600" }}>Invite</span>
            </div> */}
            <div className="col-3 ms-4" style={{ padding: "3px 0 3px 5px" }}>
              <span style={{ color: "#fff", fontWeight: "600" }}>
                {joinedStoreData.room_type == true ? (
                  <span>Public</span>) : (
                  <span>Private</span>
                )}

              </span>
            </div>
          </div>
          {/* tabs of quiz and particpiants */}
          {/* <div className=" d-flex mx-4 mt-4 rounded">
            <div
              className="col-6 p-2 d-flex justify-content-center"
              onClick={() => setQuiztab(1)}
              style={{
                backgroundColor: quizTab == 1 ? "#fff" : "#ffffff42",
                color: quizTab == 1 ? "#701DDB" : "#fff",
                fontSize: "20px",
                fontWeight: "600",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Quizes
            </div>
            <div
              className="col-6 p-2 d-flex justify-content-center"
              onClick={() => setQuiztab(2)}
              style={{
                backgroundColor: quizTab == 2 ? "#fff" : "#ffffff42",
                color: quizTab == 2 ? "#701DDB" : "#fff",
                fontSize: "20px",
                fontWeight: "600",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Participants
            </div>
          </div> */}
        </div>

        {/* bottom quizz details starts  */}
        <div
          className="bottom_part_enter mt-3 flex-grow-1"
          style={{ backgroundColor: "#fff", borderRadius: "30px 30px 0 0", overflowY: "auto" }}
        >
          {quizTab == 1 ? (
            <>
              <div className="">
                <Box
                  className="mx-md-2"
                  sx={{
                    borderColor: "divider",
                    borderBottom: "1px solid #E4E9EC",
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    style={{ marginLeft: "25px" }}
                  >
                    <Tab
                      label={
                        <div>
                          Live Quizzes{" "}
                          {/* <span
                            className="p-1"
                            style={{
                              color: "white",
                              backgroundColor: "#D92828",
                              borderRadius: "50%",
                            }}
                          >
                            9+
                          </span> */}
                        </div>
                      }
                      style={{
                        color: "#8A8A8A",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    />

                    <Tab
                      label={
                        <div>
                          Schedule Quizzes{" "}
                          {/* <span
                            className="p-1"
                            style={{
                              color: "white",
                              backgroundColor: "#D92828",
                              borderRadius: "50%",
                            }}
                          >
                            9+
                          </span> */}
                        </div>
                      }
                      style={{
                        color: "#8A8A8A",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    />
                  </Tabs>
                </Box>

                {value === 0 && <RoomLiveQuizz Roomsid = {Roomsid} />}
                {value === 1 && <RoomSheduleQuizz Roomsid = {Roomsid} />}
              </div>
            </>
          ) : (
            <>
              <div className=" container mt-3 d-flex flex-column">
                <h4>List of Participants</h4>
                {participants?.map((res, index) => {
                  return (
                    <div className="" style={{ fontSize: "15px", fontWeight: "600" }}>
                      <span>
                        {index + 1}.
                      </span>

                      <Span>{res?.name}</Span>

                    </div>
                  )
                })}
              </div>

            </>
          )}


        </div>
      </div>
    </>
  );
};

export default EnterRoom;
