import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import vector from "../assets/Vector (1).png";
import fardail from "../assets/fadrailbank.png";
import ban from "../assets/banner.png";
import Span from "../Translation/Translate";

const Withdrawlreqpen = () => {
  const Navigate = useNavigate();

  const { state } = useLocation();

  return (
    <>
      <div style={{ backgroundColor: "#701DDB" }}>
        <div>
          <div className="" style={{ height: "10vh" }}>
            <div className="container">
              <div className="row mt-2">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate('/wallet')}
                    style={{ fontSize: "22px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <section className="pb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center">
                        <h6
                          className=""
                          style={{
                            fontSize: "45px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                          }}
                        >
                          ₹ {state.amount}
                        </h6>
                        <h6
                          className="pt-3"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                            lineHeight: "2px",
                          }}
                        >
                          {" "}
                          <img
                            src={vector}
                            style={{ width: "50px", marginRight: "10px" }}
                            alt=""
                          />{" "}
                          <Span> Withdrawal Request Placed Successfully</Span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              backgroundColor: "#FFFFFF",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-12 pt-4">
                      {/* <div className="">
                                        <h6 style={{fontSize:"16px",fontWeight:"500",color:"#A1A2AD",lineHeight:"15px"}}>Transaction ID</h6>
                                        <h6 style={{fontSize:"22px",fontWeight:"600",color:"#A1A2AD"}}>#htnOP1256BHIPOK569822</h6>
                                    </div>
                                    <div className="pt-3 pb-3">
                                        <h6 style={{fontSize:"20px",fontWeight:"600",color:"#A1A2AD"}}>20 Dec 2022 | 12:34 IST</h6>
                                    </div>
                                    <div className="">
                                        <h6 style={{fontSize:"16px",fontWeight:"500",color:"#A1A2AD"}}>Beneficiary Details</h6>
                                    </div> */}
                    </div>
                    <div className="col-12 pb-3">
                      <div
                        className="border 1px solid p-2"
                        style={{ borderRadius: "5px" }}
                      >
                        <div className="d-flex justify-content-start">
                          <img
                            src={fardail}
                            alt=""
                            style={{ width: "40px", backgroundColor: "blue" }}
                          />
                          <h6
                            className="pt-1"
                            style={{ fontWeight: "500", fontSize: "25px" }}
                          >
                            {" "}
                            <Span> {state.bank.bank_name}</Span>
                          </h6>
                        </div>
                        <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <span style={{ color: "#7E7E7E" }}>Acc. Holder </span>
                          <Span> {state.bank.acc_holder_name}</Span>
                        </h6>
                        <div className="d-flex justify-content-between">
                          <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                            <span style={{ color: "#7E7E7E" }}>
                              <Span> Acc. Number </Span>
                            </span>{" "}
                            <Span>{state.bank.bank_acc_no}</Span>
                          </h6>
                          <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                            {" "}
                            <span style={{ color: "#7E7E7E" }}>
                              <Span>IFSC</Span>{" "}
                            </span>{" "}
                            <Span>{state.bank.ifsc_code}</Span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <div className="d-flex justify-content-flex-start gap-5">
                          <h6 className="pt-2" style={{ fontSize: "16px" }}>
                            <Span> Status</Span>
                          </h6>
                          <h6
                            className="p-2"
                            style={{
                              backgroundColor: "#FFF2F2",
                              color: "#D92828",
                              borderRadius: "5px",
                            }}
                          >
                            <Span>Pending</Span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <img
                          src={ban}
                          alt=""
                          style={{ width: "100%", height: "10%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Withdrawlreqpen;
