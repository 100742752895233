import React, { useContext, useEffect, useRef, useState } from 'react'
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from "../utils/LoaderComponent";
import { Carousel } from 'react-bootstrap';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Span from '../Translation/Translate';
import { Context } from "../../index";



const HomeMyExams = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { examData, setExamData } = useContext(Context);
  const page = useRef(1);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();

  const getHomeMyExams = async () => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${QUIZMICRO}/home/get/exams?page=1&limit=10`,
        requestOptions
      );
      const result = await response.json();
      setIsLoading(false);
      if (result?.status == 1) {
        setExamData(result?.exams);



      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getHomeMyExams();
  }, [])

  return (
    <>
        <div className=" col-lg-12 col-md-12 col-sm-12">
        <div className=" d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center">
            <h6 className="txt">
              <Span>My Exam</Span>
            </h6>
          </div>
          <div className="">
            <span
              className="see"
              style={{ cursor: "pointer" }}
              onClick={() => {
                Navigate("/myexam/quiz");
              }}
            >
              <Span>See All</Span>
            </span>
          </div>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-12 card p-3 boxshadow"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate("/myexam/quiz")}
        >
          <Carousel
            interval={3000}
            pause="hover"
            className="custom-carousel"
          >
            {examData?.length === 0 && !isLoading ? (
              <EmptyContainer
                actionName="Reload"
                action={() => getHomeMyExams(page.current)}
                message="No Data Found"
              />
            ) : (
              examData?.map((res, value) => {
                return (
                  <Carousel.Item key={value}>
                    <div className="row mt-5 d-flex justify-content-center">
                      <div className="col-md-5 mb-4">
                        <div className="d-flex flex-column align-items-center ">
                          <div className="notsaved">
                            <img
                              src={IMAGE_URL + res.image}
                              alt="SBI-PO Logo"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <h6
                            className="some-txt text-center mt-2"
                            style={{ fontSize: "20px" }}
                          >
                            <Span>{res.category_name}</Span>
                          </h6>
                          <h6
                            className="txx text-center"
                            style={{ fontSize: "18px" }}
                          >
                            <Span>Active Quizzes</Span>
                          </h6>
                          <h2
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {res.total_active_quiz}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })
            )}{" "}
            {isLoading && <LoaderComponent />}
          </Carousel>
        </div>
      </div>

    </>
  )
}

export default HomeMyExams