import axios from "axios";
import { QUIZMICRO } from "../../config/urls"
import { BasicServices } from "../BasicServices";
import { toast } from "react-toastify";

class HistoryApiService {
    constructor() {
        this.quizUrl = QUIZMICRO
        this.basic = new BasicServices()
    }

    async getFullHistory(order, page) {
      try { let token = `Bearer ` + this.basic.getLocalObject().jwt;
        let url = `${this.quizUrl}/participants/all/history/quiz?order=${order}&page=${page}`;
        let headers = { "content-type": "application/json", authorization: token };
        let options = {
            method: "get",
            headers: headers,
            url,
        };
        const response = await axios(options);
        return response.data}
        catch (err) { toast(err.message) }
    }

    async getFreeQuizzes(order,page) {
      try { let token = `Bearer ` + this.basic.getLocalObject().jwt;
        let url = `${this.quizUrl}/participants/free/history/quiz?order=${order}&page=${page}`;
        let headers = { "content-type": "application/json", authorization: token };
        let options = {
            method: "get",
            headers: headers,
            url,
        };
        const response = await axios(options);
        return response.data}
        catch (err) { toast(err.message) }
    }
    async getWonQuizzes(order,page) {
    try{    let token = `Bearer ` + this.basic.getLocalObject().jwt;
        let url = `${this.quizUrl}/participants/win/history/quiz?order=${order}&page=${page}`;
        let headers = { "content-type": "application/json", authorization: token };
        let options = {
            method: "get",
            headers: headers,
            url,
        };
        const response = await axios(options);
        return response.data}
        catch (err) { toast(err.message) }
    }
    async getLostQuizzes(order,page) { 
       try{ let token = `Bearer ` + this.basic.getLocalObject().jwt;
        let url = `${this.quizUrl}/participants/loss/history/quiz?order=${order}&page=${page}`;
        let headers = { "content-type": "application/json", authorization: token };
        let options = {
            method: "get",
            headers: headers,
            url,
        };
        const response = await axios(options);
        return response.data}
        catch (err) { toast(err.message) }
    }

}

export default HistoryApiService;