import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import {  useNavigate, useParams } from "react-router-dom";
import { IoCloudDownloadOutline } from "react-icons/io5";
import "./history.css";
import { GoHome } from "react-icons/go";
import { BasicServices } from "../../services/BasicServices";
import { QUIZMICRO } from "../../config/urls";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import { downloadExcel } from 'react-export-table-to-excel';
import { MdSupportAgent } from "react-icons/md";

const AnswerSheet = () => {

  const { id , type} = useParams();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [data, setData] = useState([]);

  const getQuizData = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "SubActive_id": id
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${QUIZMICRO}/participants/view/scoreboard/of/active/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          setData(result?.answer_sheet);
        } else if (result.status === "TOKEN_ERR") {
         basic.setJwt("")
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        }else if (result.status === "TOKEN_ERR") {
          basic.setJwt("")
           Navigate("/Register");
           toast("Logged in other device! Please login again");
         } 
         else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };


  const getTriviaData = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "subtrivia_id": id
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${QUIZMICRO}/participants/view/scoreboard/in/trivia/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          setData(result?.answer_sheet);
        } else if (result.status === "TOKEN_ERR") {
         basic.setJwt("")
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };


  
  const exportToExcel = () => {
    if (data?.length === 0) {
      toast.error('No data to export');
      return;
    }

    // Prepare data for export
    const excelData = data?.map(lead => [
      lead.question_no , 
      lead.my_answer <= 0 ? "-" : lead.my_answer, 
      lead.correct_answer ,
      lead.marks 
    ]);

    // Export data to Excel
    downloadExcel({
      fileName: 'MyScorecard',
      sheet: 'leads',
      tablePayload: {
        header: ['QNo', 'My Answer', 'Correct Answer' , 'Marks'],
        body: excelData
      }
    });
  };


  useEffect(() => {
    if (type == "quiz") {
      getQuizData()
      
    } else {
      getTriviaData()
    }
  }, []);



  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          backgroundColor: "#701DDB",
          color: "#FFFFFF",
        }}
      >
        {/* My Scorecard title  */}
        <div
          style={{ height: "10vh", alignItems: "center" }}
          className="d-flex justify-content-between px-2 px-md-4"
        >
          <div className="arrowb center-data">
            <FaLongArrowAltLeft size={20} color="#FFFFFF" cursor="pointer" 
            onClick={() => {
              Navigate(-1);
            }}
            />
          </div>

          <div className="center-y h4 m-0 text-white">My ScoreCard</div>

          <div className="arrowb center-data">
          <MdSupportAgent
            size={20}
            color="#FFFFFF"
            cursor="pointer"
            onClick={() => {
              Navigate("/support");
            }}
          />
          </div>
        </div>

        {/* Download file button */}

        <div
          style={{ height: "10vh", alignItems: "center" }}
          className="d-flex justify-content-between px-2 px-md-4"
        >
          <div
            className="btnanst2 rounded center-data"
            style={{ cursor: "pointer", fontSize: "17px" }}
            onClick={() => {
              exportToExcel()
            }}
          >
            Download Answer Sheet
            <span className="ps-3">
              <IoCloudDownloadOutline />
            </span>
          </div>
        </div>
        {/* Answer Sheet Table Start */}
        <div
          style={{ height: "80vh", alignItems: "center", backgroundColor: "#ffff", overflowY: "scroll" }}
          className=" px-2 px-md-4"
        >
         <div className="h4 text-black text-center py-1 py-md-2 ">Answer Sheet</div>

         <Table  responsive className="border text-nowrap mytable">
                      <thead>
                        <tr  style={{ fontSize: "0.9rem" }}>
                          <th>QNo.</th>
                          <th>My Answer</th>
                          <th>Correct Answer</th>
                          <th>Marks</th>
                        </tr>
                      </thead>

                        <tbody className="border">
                          
                          {data?.map((res, key) => {
                            return (
                              <tr
                                className="table1"
                                key={key}
                                style={{
                                  color: "#989898",
                                  backgroundColor: "#3d70f52b",
                                }}
                              >
                                <td>
                                  <p
                                    style={{
                                      color: "#3c3c3c",
                                      fontWeight: "400",
                                      fontSize: "1rem",
                                    }}
                                    className="mb-0 "
                                  >
                                    {res?.question_no}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      color: res?.colour,
                                      fontWeight: "400",
                                      fontSize: "1rem",
                                    }}
                                    className="mb-0 "
                                  >
                                    {res?.my_answer == -1 ? "-" : res?.my_answer}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      color: "#3c3c3c",
                                      fontWeight: "400",
                                      fontSize: "1rem",
                                    }}
                                    className="mb-0 "
                                  >
                                    {res?.correct_answer}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      color: res?.marks > 0  ? "green" : "red",
                                      fontWeight: "400",
                                      fontSize: "1rem",
                                    }}
                                    className="mb-0 "
                                  >
                                    {res?.marks }
                                  </p>
                                </td>


                              </tr>
                            );
                          })}
                        </tbody>
                
                    </Table>

        </div>


        {/* Answer Sheet Table Ends */}
      </div>
    </>
  );
};

export default AnswerSheet;
