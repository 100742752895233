import React, { useEffect, useRef, useState } from "react";
import "./quiz.css";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import {  useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { CgDanger } from "react-icons/cg";
import { BasicServices } from "../../services/BasicServices";
import LoaderComponent from "../utils/LoaderComponent";
import mail from "../assets/email.png"



const AttemptTrivia = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const [question, setQuestion] = useState({});
  const answer = useRef(0)
  localStorage.setItem('isBack','1')
  const intervalRef = useRef(null); 
  const afterRef = useRef(null); 
  const total_que = parseInt(localStorage.getItem('total_que'))
  const submitResult = JSON.parse(localStorage.getItem('result'))
  // const [submitResult, setSubmitResult] = useState({});
  const index = parseInt(localStorage.getItem('index'))



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingq, setIsLoadingq] = useState(false)


  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt

  const [timeLeft, setTimeLeft] = useState(() => parseInt(localStorage.getItem('timer')));
  // const [aftertimeLeft, setAfterTimeLeft] = useState(() => parseInt(localStorage.getItem('after_timer')));
  const [aftertimeLeft, setAfterTimeLeft] = useState(5);

  const submitQuiz = async () => {
    handleClose()
    setIsLoading(true)
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    const timetaken = parseInt(localStorage.getItem('total_time')) - parseInt(localStorage.getItem('timer'))
    localStorage.setItem('time_taken', String(timetaken))

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const raw = JSON.stringify({
      "subtrivia_id": id,
      "submit_time_period": timetaken,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZMICRO}/participants/submit/in/trivia/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        localStorage.setItem('isBack', String(2))
        if (result?.status == 1) {
          localStorage.setItem('result', JSON.stringify(result?.arr))
          localStorage.setItem('slide','1')
          initAfterTimer()

        } else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          navigate("/Register");
          toast.error("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const getQues = async (index_page) => {
    setIsLoadingq(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const raw = JSON.stringify({
      "subtrivia_id": id,
      "page": index_page
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZMICRO}/participants/get/question/bypage/in/trivia/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoadingq(false)
        if (result?.status == 1) {
          setQuestion(result?.question)
          answer.current = result?.selected_ans

        } else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          navigate("/Register");
          toast.error("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const saveandnext = async () => {
    setIsLoadingq(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const raw = JSON.stringify({
      "subtrivia_id": id,
      "page": index,
      "ans": answer.current
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZMICRO}/participants/update/question/bypage/in/trivia/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoadingq(false)
        if (result?.status == 1) {
          handleNext()
        } else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          navigate("/Register");
          toast.error("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const skipQue = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const raw = JSON.stringify({
      "subtrivia_id": id,
      "page": index,
      "ans": 0
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZMICRO}/participants/update/question/bypage/in/trivia/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          getQues(index)
        } else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          navigate("/Register");
          toast.error("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };


  const handleNext = () => {

    if (index=== total_que) {
      handleShow()
    } else {
      localStorage.setItem('index', String(index+ 1));
      getQues(index+ 1)
    }

  };

  const handlePrev = () => {

    if (index=== 1) {
      toast.error("This is first question")
    } else {
      localStorage.setItem('index', String(index- 1));
      getQues(index- 1)
    }

  };

  const handleSelect = (ans) => {
    switch (ans) {
      case 1:
        answer.current = 1
        break;
      case 2:
        answer.current = 2
        break;
      case 3:
        answer.current = 3
        break;
      case 4:
        answer.current = 4
        break;

      default:
        break;
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const formatTime1 = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes} min :${formattedSeconds} sec`;
  };


  useEffect(() => {


    if (localStorage.getItem('slide') == '0') {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            submitQuiz();
            clearInterval(intervalRef.current);
            return;
          }
  
          const currentTimer = parseInt(localStorage.getItem('timer'))
          localStorage.setItem('timer', String(currentTimer - 1));
          return prevTimeLeft - 1;
        });
      }, 1000);
    getQues(index)
    return () => clearInterval(intervalRef.current)
    }
  }, []);
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === 'c' || e.key === 'x' || e.key === 'v')) {
        e.preventDefault();
      }
    };
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const initAfterTimer = () =>{
    if (localStorage.getItem('slide') == '1') {
      afterRef.current = setInterval(() => {
        setAfterTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft == 0) {
            localStorage.setItem('slide', '2')
            clearInterval(afterRef.current)
            return;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    }

  }




  useEffect(() => {
initAfterTimer()
  }, []);

  useEffect(()=>{
    if (localStorage.getItem('slide') == '2') {
      clearInterval(intervalRef.current)
    }
  },[])



  return (
    <>
      <ToastContainer />

      {isLoading ? <LoaderComponent /> :
        <>

          {
            localStorage.getItem('slide') == 0 &&
            <div  className=" no-select no-highlight" style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
              <div style={{ height: "90vh", overflow: "hidden" }} className="d-flex flex-column">
                <div className="flex-grow-0">
                  <div
                    className="center-y justify-content-between px-4 py-3"
                    style={{ backgroundColor: "#2E2E2E" }}
                  >
                    <button
                      className="btnq1 px-4 rounded"
                      // style={{ backgroundColor: "#404040" }}
                      onClick={handleShow}
                    >
                      Quit
                    </button>

                    <button
                      disabled
                      className="btnq1 px-4 rounded"
                      // style={{ backgroundColor: "#404040" }}
                    >
                      {formatTime(timeLeft)}
                    </button>


                    <button
                      className="btnq1 px-4 rounded"
                      // style={{ backgroundColor: "#404040" }}
                      onClick={submitQuiz}
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    style={{
                      height: "8px",
                      width: "100%",
                      backgroundColor: "#6B24D6",
                    }}
                  ></div>

                  <div
                    className="center-y justify-content-between px-4 py-3"
                    style={{ backgroundColor: "#F3F3F3" }}
                  >
                    <div className="center-y"  style={{ cursor: index > 1 ? 'pointer' : 'default', opacity: index > 1 ? 1 : 0.5 }} onClick={handlePrev}>
                      <span className="center-data ">
                        <FaLongArrowAltLeft size={18} />
                      </span>
                      <span className="ms-1 ">Previous</span>
                    </div>

                    <div style={{ fontSize: "20px", fontWeight: "600" }}>
                      <span>{index}/</span>
                      <span style={{ fontSize: "16px", fontWeight: "500" }}>{total_que}</span>
                    </div>

                    <div className="center-y"  style={{ cursor: index < total_que ? 'pointer' : 'default', opacity: index < total_que  ? 1 : 0.5 }} onClick={index < total_que ? handleNext : null}>
                      <span className="me-1 " style={{ opacity: 0 }}>
                        Next
                      </span>
                      <span className="me-1 ">Next</span>
                      <span className="center-data ">
                        <FaLongArrowAltRight size={18} />
                      </span>
                    </div>
                  </div>
                </div>



                {
                  isLoadingq ? <LoaderComponent /> :
                    <div
                      className="flex-grow-1 p-2 p-md-4"
                      style={{ overflowY: "auto", overflowX: "hidden" , backgroundColor:"#FFFFFF" }}
                    >
                      <div className="d-flex flex-column gap-2">

                        <div className="que" >
                          {question?.question}
                        </div>
                        {
                          question?.is_ques_img == 1 &&
                          <div>
                            <img style={{ height: "172px", width: "100%" }} src={`${IMAGE_URL}${question?.question_url}`} alt="que"></img>
                          </div>
                        }

                      </div>

                      <div className="mt-4 d-flex flex-column gap-2 gap-md-3 ">



                        <div className="d-flex gap-2 rounded p-2 p-md-4 optCard" onClick={() => handleSelect(1)}
                          style={{
                            backgroundColor: answer.current == 1 ? "#EFF5FF" : "#FFFF",
                            border: answer.current == 1 ? "2px solid #2188E7" : "2px solid #F2F2F2"
                          }}
                        >

                          <div className="opt" style={{ fontWeight: 700 }}>(A)</div>
                          <div>
                            {
                              question?.is_opt_img == 0 ?
                                <div className="opt " >
                                  {question?.option1}
                                </div>
                                :
                                <div >
                                  <img style={{ height: "172px", width: "100%" }} src={`${IMAGE_URL}${question?.option1}`} alt="optA"></img>
                                </div>
                            }
                          </div>

                        </div>





                        <div className="d-flex gap-2 rounded p-2 p-md-4 optCard" onClick={() => handleSelect(2)}
                          style={{
                            backgroundColor: answer.current == 2 ? "#EFF5FF" : "#FFFF",
                            border: answer.current == 2 ? "2px solid #2188E7" : "2px solid #F2F2F2"
                          }}
                        >

                          <div className="opt" style={{ fontWeight: 700 }}>(B)</div>
                          <div>
                            {
                              question?.is_opt_img == 0 ?
                                <div className="opt " >
                                  {question?.option2}
                                </div>
                                :
                                <div >
                                  <img style={{ height: "172px", width: "100%" }} src={`${IMAGE_URL}${question?.option2}`} alt="optA"></img>
                                </div>
                            }
                          </div>

                        </div>




                        <div className="d-flex gap-2 rounded p-2 p-md-4 optCard" onClick={() => handleSelect(3)}
                          style={{
                            backgroundColor: answer.current == 3 ? "#EFF5FF" : "#FFFF",
                            border: answer.current == 3 ? "2px solid #2188E7" : "2px solid #F2F2F2"
                          }}
                        >

                          <div className="opt" style={{ fontWeight: 700 }}>(C)</div>
                          <div>
                            {
                              question?.is_opt_img == 0 ?
                                <div className="opt " >
                                  {question?.option3}
                                </div>
                                :
                                <div >
                                  <img style={{ height: "172px", width: "100%" }} src={`${IMAGE_URL}${question?.option3}`} alt="optA"></img>
                                </div>
                            }
                          </div>

                        </div>





                        <div className="d-flex gap-2 rounded p-2 p-md-4 optCard" onClick={() => handleSelect(4)}
                          style={{
                            backgroundColor: answer.current == 4 ? "#EFF5FF" : "#FFFF",
                            border: answer.current == 4 ? "2px solid #2188E7" : "2px solid #F2F2F2"
                          }}
                        >

                          <div className="opt" style={{ fontWeight: 700 }}>(D)</div>
                          <div>
                            {
                              question?.is_opt_img == 0 ?
                                <div className="opt " >
                                  {question?.option4}
                                </div>
                                :
                                <div >
                                  <img style={{ height: "172px", width: "100%" }} src={`${IMAGE_URL}${question?.option4}`} alt="optD"></img>
                                </div>
                            }
                          </div>

                        </div>




                      </div>

                    </div>
                }






              </div>

              <div
                style={{ height: "10vh", overflow: "hidden" }}
                className="center-y justify-content-between px-4"
              >
                <button 
                className="btnq2 px-2 rounded" onClick={()=>{
                  if (answer.current !== 0) {
                    skipQue()
                  }
                  else{
                    toast.error("No option is selected !")
                  }
                }}>Clear Response</button>


                <button className="btnq2 px-4 rounded " onClick={()=>{
                  if (answer.current !== 0) {
                    saveandnext()
                  }
                  else{
                    toast.error("No option is selected !")
                  }
                }}>Save & Next</button>
              </div>
            </div>
          }




          {
            localStorage.getItem('slide') == 1 &&
            <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }} className="center-data">

              <div className="p-2 p-md-5">

                <div className=" center-x text-center tq1">
                  Answers Submitted <br /> Succesfully
                </div>

                <div className="center-x">
                  <img src={mail}></img>
                </div>

                <div className="center-x text-center tq2">
                  Result will be  <br /> Declared in
                </div>

                <div className="center-x mt-3">
                  <div className="cardblue center-data rounded" >
                    {aftertimeLeft} secs
                  </div>
                </div>

              </div>
            </div>

          }


          {
            localStorage.getItem('slide') == 2 &&
            <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }} >

              <div className="p-2 p-md-5">

                <div className="center-x text-center tq1">Review, after <br /> Submission</div>

                <div className="row mt-4">
                  <div className="col-6 center-data">
                    <div >
                      <div className="textt center-data ms-2">Total question</div>
                      <div className="mt-1 textz center-data" >{submitResult?.correct + submitResult?.unattempt + submitResult?.incorrect}</div>
                    </div>
                  </div>

                  <div className="col-6 center-data">
                    <div>
                      <div className="textt center-data">Correct</div>
                      <div className="mt-1 textz center-data" style={{ color: "green" }}>{submitResult?.correct}</div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-6 center-data ">
                    <div>
                      <div className="textt center-data">Unattempt</div>
                      <div className="mt-1 textz center-data" style={{ color: "gray" }}>{submitResult?.unattempt}</div>
                    </div>
                  </div>


                  <div className="col-6 center-data">
                    <div>
                      <div className="textt center-data">Incorrect</div>
                      <div className="mt-1 textz center-data" style={{ color: "red" }}>{submitResult?.incorrect}</div>
                    </div>
                  </div>
                </div>


                <div className="center-data mt-4">
                  <div>
                    <div className="textt center-data">Time</div>
                    <div className="mt-1 textz center-data" style={{ color: "#7E7E7E" }}>{formatTime1(submitResult?.submit_time_period)}</div>
                  </div>
                </div>


                <div className="mt-4 center-data">

                  <button className="btnq2 rounded p-2 " onClick={() => {
                    navigate(`/newreward/${id}`)
                    // totalTimeTaken.current = 0
                  }}>
                    View Result
                  </button>

                </div>



              </div>


            </div>
          }

        </>
      }




      <Modal show={show} onHide={handleClose} centered backdrop="static" >
        <div className="p-3 rounded" >

          <div className="center-data mb-1 h4">
            Trivia will be submitted ?
          </div>

          <div className="center-data mb-3">
            <CgDanger className="center-data " color="red" size="5rem" />
          </div>

          <div className='d-flex flex-column gap-3 '>
            <div className="center-data">
              <button className="btnq1 px-4 rounded " onClick={handleClose}>Back to Trivia</button>
            </div>
            <div className="center-data">
              <button className="btnq2 px-4 rounded" onClick={submitQuiz}>Submit Trivia</button>
            </div>
          </div>
        </div>
      </Modal>
    </>



  );
};

export default AttemptTrivia;
