import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import image1 from "../assets/image.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image4.gif";
import image4 from "../assets/image5.png";
import coin from "../assets/coin.png";
import map1 from "../assets/map1.jpg";
import ProgressBar from "@ramonak/react-progress-bar";
import { FaRegClock } from "react-icons/fa6";
import { LuTicket } from "react-icons/lu";
import { BsTrophyFill } from "react-icons/bs";
import { PiMapPinFill } from "react-icons/pi";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Offcanvas from "react-bootstrap/Offcanvas";
import LiveQuizzes from "./LiveQuizzes";
import { style } from "@mui/system";
import { Carousel, Toast } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HomeData.css";
import { IMAGE_URL, NOTIFY_URL, QUIZMICRO } from "../../config/urls";
import { BasicServices } from "../../services/BasicServices";
import { Context } from "../../index";
import { convertTimeToAMPM } from "../utils/dateTime";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { ToastContainer, toast } from "react-toastify";
import Span from "../Translation/Translate";
// import { useSwipeable } from 'react-swipeable';
import axios from "axios";
import "../Home/CraouselCustomize.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeBannners from "./HomeBannners";
import HomeEnrolledQuiz from "./HomeEnrolledQuiz";
import HomeMyExams from "./HomeMyExams";
import HomeFreeTrivia from "./HomeFreeTrivia";
import HomeLiveQuiz from "./HomeLiveQuiz";
import HomeCourses from "./HomeCourses";




const HomeData = () => {
  // const carouselRef = useRef(null);
  // const [show, setShow] = useState(false);
  // const [banner, setbanner] = useState([]);
  // const [live, setlive] = useState([]);
  // const [trivia, settrivia] = useState([]);
  // const { examData, setExamData } = useContext(Context);
  // const [enroll, setenroll] = useState([]);
  const page = useRef(1);
  const Navigate = useNavigate();

  const [reels, setreels] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;


  const [index, setIndex] = useState(0);
  // states to manage videos slider
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Track current slide index
  const [videoPlaying, setVideoPlaying] = useState(false); // Track video playback state


  const handleSlideStart = (selectedIndex, e) => {
    // Pause video when the carousel slides
    if (videoPlaying) {
      setVideoPlaying(false);
      const video = document.getElementById('carousel-video');
      if (video) {
        video.pause();
      }
    }
    setCurrentSlideIndex(selectedIndex); // Update current slide index
  };





  // const getHome = async () => {
  //   try {
  //     setIsLoading(true);
  //     const myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${token}`);

  //     const requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     const response = await fetch(
  //       `${QUIZMICRO}/home/page/overview`,
  //       requestOptions
  //     );
  //     const result = await response.json();
  //     setIsLoading(false);
  //     if (result?.status == 1) {
  //       setbanner(result.banners);
  //       setlive(result.activequizes);
  //       settrivia(result.triviaquizes);
  //       setExamData(result.exams);

  //       setenroll(result.enrolledquizes);
  //     } else if (result.status === "TOKEN_ERR") {
  //       basic.setJwt("");
  //       Navigate("/Register");
  //       toast("Logged in other device! Please login again");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getreelsData = async () => {
    try {
      const response = await axios.get(
        `${NOTIFY_URL}/participants/reels/get/popular/reels/for/homepage`,
        // { tag_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data; // Axios handles the response JSON parsing automatically
      setreels(data?.reels);
    } catch (error) {
      console.error("Error fetching chat history:", error);
      if (error.response && error.response.data) {
        // Handle error status codes and messages here
        console.error("Error status code:", error.response.status);
        console.error("Error message:", error.response.data.message);
      }
    }
  };


  useEffect(() => {
    getreelsData();
    // getHome();
  }, []);




  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlideIndex,
    beforeChange: (current, next) => handleSlideStart(next),
    pauseOnHover: true,
    prevArrow: null, // Remove previous button
    nextArrow: null,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container" style={{ backgroundColor: "white" }}>
        <div className="row">

          <div className="col-lg-12 col-md-12 col-sm-12 " >

            <HomeBannners />

            {/* Live Quizes------------------------------------------------------------------------- */}
            <div className="row  mt-4 ">
              <HomeCourses />
              <HomeLiveQuiz />

              {/* Free Trivia ---------------------------------------------------------------- */}

              <HomeFreeTrivia />

              <HomeMyExams />

              {/* Enrolled quiz------------------------------------------------------------------------------ */}

              <HomeEnrolledQuiz />
            </div>

            <div className="row mt-4">
            <Carousel interval={3000} pause={false} className="custom-carousel">
            {reels?.length === 0 && !isLoading ? (
              <EmptyContainer
                actionName="Reload"
                action={() => getreelsData(page.current)}
                message="No Data Found"
              />
            ) : (
              reels?.map((res) => {
                return (

                  <Carousel.Item>
                    <div className="row align-items-center p-3"
                      onClick={() => {
                        Navigate("/reelsview", { state: { homereel: res } })
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`${IMAGE_URL}${res.banner}`}
                        className="img-fluid "
                        alt="Product"
                        style={{ height: "200px", width: "100%" }}
                      />
                      <button
                        className="btn position-absolute d-flex justify-content-center align-items-center"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 2,
                          borderRadius: "50%",
                          color: "#fff",
                          width: "50px", // Set width
                          height: "50px", // Set height
                          backgroundColor: "#00000080"
                        }}
                      >
                        &#9658;
                      </button>

                    </div>
                  </Carousel.Item>
                );
              })
            )}{" "}
            {isLoading && <LoaderComponent />}

          </Carousel>
          </div>
            {/* MY Exams ------------------------------------------------------------------------- */}


            {/* Bannner crouseel start here */}


            {/* <HomeBannners /> */}



          </div>
         
        </div>
      </div>


    </div>
  );
};

export default HomeData;


