import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { CgDanger } from "react-icons/cg";
import { GRAPHQL_URL, QUIZMICRO } from "../../config/urls";
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';



const RoomsMiddleWarning = () => {
    const { id, type } = useParams()
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(() => parseInt(localStorage.getItem('timer')));
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt
    const altTimer = useRef(null)

    const submitQuiz = async () => {
        const timetaken = parseInt(localStorage.getItem('total_time')) - parseInt(localStorage.getItem('timer'))
        localStorage.setItem('time_taken', String(timetaken))
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            `Bearer ${token}`
        );

        const raw = JSON.stringify({
            "roomquiz_id": id,
            "submit_time_period": timetaken,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${GRAPHQL_URL}/participant/room/quiz/submit/quiz`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.status == 1) {

                    localStorage.setItem('result', JSON.stringify(result?.arr))
                    localStorage.setItem('slide', '2')
                    navigate(`/AttemptRoomsQuiz/${id}`);

                } else if (result.status === "TOKEN_ERR") {
                    localStorage.removeItem("api_url");
                    navigate("/Register");
                    toast.error("Logged in other device! Please login again");
                } else {
                    console.log(result?.Backend_Error);
                }
            })
            .catch((error) => console.error(error));
    };



    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    
  useEffect(() => {
    if (localStorage.getItem('isBack') == '1' && localStorage.getItem('slide') == '0') {
      altTimer.current= setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
              submitQuiz()
            clearInterval(altTimer.current);
            return;
          }

          const currentTimer = parseInt(localStorage.getItem('timer'))
          localStorage.setItem('timer', String(currentTimer - 1));
          return prevTimeLeft - 1;
        });
      }, 1000);
    } 
  
    else {
      
      navigate(`/AttemptRoomsQuiz/${id}`);
    }

    return () => clearInterval(altTimer.current)
  }, [])

    useEffect(() => {
        if (localStorage.getItem('slide') == '2') {
            clearInterval(altTimer.current)
        }
    }, [])

    return (
        localStorage.getItem('isBack') == '1' &&
        <>
            <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>

                <div className='row h-100'>

                    <div className='col-0 col-md-3'></div>


                    <div className='col-12 col-md-6 center-data'>



                        <div className="p-3 m-2 rounded" style={{ border: "2px solid red" }} >

                            <div className="center-data mb-3 h3" style={{ color: "red", fontWeight: 700 }}>
                                DO NOT PRESS <br /> BACK BUTTON
                            </div>


                            <div className="center-data mb-3">
                                <CgDanger className="center-data " color="red" size="7rem" />
                            </div>


                            <div className="center-data mb-2 " style={{ color: "black", fontSize: "15px" }}>
                                Going back from quiz is restricted and against the rules. It can lead to potential loss of saved answers and can interrupt your entire quiz !!!
                            </div>

                            <div className="center-data mb-3" style={{ color: "black", fontSize: "12px" }}>
                                Note : You can submit the quiz before time if you want in live quiz.
                            </div>


                            <div className='d-flex flex-column gap-3 '>

                                <div className="center-data">
                                    <button className="btnq2 px-4 rounded " onClick={() => {
                                        navigate(`/AttemptRoomsQuiz/${id}`)

                                    }}>Back to Quiz Safely</button>
                                </div>

                                <div className="center-data">
                                    <button
                                        disabled
                                        className="btnq1 px-4 rounded"
                                        style={{ backgroundColor: "#404040" }}
                                    >
                                        {formatTime(timeLeft)}
                                    </button>
                                </div>
                            </div>
                        </div>



                    </div>



                    <div className='col-0 col-md-3'></div>
                </div>




            </div>
        </>
    )
}

export default RoomsMiddleWarning