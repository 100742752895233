import React, { useCallback, useContext, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdDelete, MdHistory } from "react-icons/md";
import { Get_Enroll_Participants_Quiz } from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";
import { GRAPHQL_URL, IMAGE_URL } from "../../config/urls";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Context } from "../..";
import InfiniteScroll from "react-infinite-scroller";
import LoaderComponent from "../utils/LoaderComponent";

const Settings = () => {
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { Roomsid } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [enrollQuiz, setenrollQuiz] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const { createdStoreData } = useContext(Context);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { loading, error, data, refetch } = useQuery(Get_Enroll_Participants_Quiz, {
    variables: { room_id: Roomsid, page: currentPage },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      setenrollQuiz((prevQuizzes) =>
        currentPage === 1
          ? data.get_enrl_participants_of_quiz.response
          : [...prevQuizzes, ...data.get_enrl_participants_of_quiz.response]
      );
      setTotalPages(data.get_enrl_participants_of_quiz.totalPages);
    }
  }, [data, currentPage]);

  const deleteRoom = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_id: Roomsid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${GRAPHQL_URL}/participant/room/delete/room`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          toast(result.msg);
          handleClose();
          navigate("/rooms");
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const loadMore = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (currentPage > 1) {
      refetch({ room_id: Roomsid, page: currentPage });
    }
  }, [currentPage, refetch, Roomsid]);

  return (
    <>
      <ToastContainer />
      <div style={{ height: "100vh", backgroundColor: "#fff", overflow: "hidden" }}>
        <div className="container p-2">
          <div className="d-flex justify-content-between align-items-center">
            <IoIosArrowRoundBack
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => navigate(`/createenterRoom/${Roomsid}`)}
            />
            <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>Settings</h4>
          </div>
        </div>
        <hr />
        <div className="container">
          <p style={{ fontSize: "16px", color: "#8A8A8A" }}>Name</p>
          <div
            style={{
              backgroundColor: "#F2F2F2",
              border: "2px solid #F2F2F2",
              borderRadius: "5px",
              padding: "15px",
            }}
          >
            <h4 style={{ color: "#7E7E7E", fontSize: "24px" }}>
              {createdStoreData.room_name}
            </h4>
          </div>
          <div className="d-flex" style={{ paddingTop: "15px" }}>
            <div
              className="rounded me-2 center-data"
              style={{
                cursor: "pointer",
                backgroundColor: "#8D4AE2",
                border: "none",
                padding: "15px",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#fff",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => navigate(`/roomhistory/${Roomsid}`)}
            >
              <MdHistory style={{ fontSize: "17px", marginRight: "10px" }} />
              History
            </div>
            <div
              className="rounded ms-2 leave-rmdata"
              onClick={handleShow}
              style={{
                cursor: "pointer",
                backgroundColor: "#FFF4F4",
                border: "2px solid #D92828",
                padding: "15px",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#D92828",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MdDelete style={{ fontSize: "17px", marginRight: "10px" }} />
              Delete Room
            </div>
          </div>

          <h2 style={{ fontSize: "18px", paddingTop: "20px" }}>Room Type</h2>
          <div className="row">
            <div className="col-6">
              <div
                className="text-center p-2"
                style={{
                  backgroundColor: createdStoreData.room_type == 1 ? "#701DDB" : "#F2F2F2",
                  color: createdStoreData.room_type == 1 ? "white" : "blue",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              >
                <h5
                  className="text-center"
                  style={{
                    color: createdStoreData.room_type == 1 ? "white" : "black",
                    fontSize: "17px",
                  }}
                >
                  <span>Public</span>
                </h5>
              </div>
            </div>
            <div className="col-6">
              <div
                className="text-center p-2"
                style={{
                  backgroundColor: createdStoreData.room_type == 0 ? "#701DDB" : "#F2F2F2",
                  color: createdStoreData.room_type == 0 ? "#FFFFFF" : "#8A8A8A",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <h5
                  className="pt-1 text-center"
                  style={{
                    color: createdStoreData.room_type == 0 ? "white" : "black",
                    fontSize: "17px",
                  }}
                >
                  <span>Private</span>
                </h5>
              </div>
            </div>
          </div>
          <div className="row pt-3" style={{ overflowY: "scroll", height: "46vh" }}>
            <h2 style={{ fontSize: "18px", paddingTop: "20px" }}>
              Members {createdStoreData.enrolled_participants_count}
            </h2>
            {loading && currentPage === 1 ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error: {error.message}</p>
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={currentPage}
                useWindow={false}
              >
                {enrollQuiz.map((res, index) => (
                  <div className="container" key={index}>
                    <div className="row p-2">
                      <div
                        className="col-12"
                        style={{
                          backgroundColor: "#fff",
                          border: "2px solid #EFEFEF",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          padding: "20px",
                          width: "100%",
                          maxWidth: "600px",
                        }}
                      >
                        <img
                          alt=""
                          src={`${IMAGE_URL}${res.image}`}
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                        <div style={{ marginLeft: "15px" }}>
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#2E2E2E",
                              margin: 0,
                            }}
                          >
                            {res.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>

        {/* Model for delete */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Delete <span style={{ fontWeight: "600" }}>{createdStoreData.room_name}</span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteRoom}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Settings;
