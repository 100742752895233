import React from 'react'
import styled from 'styled-components';

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  max-width: 60%;
  padding: 10px;
  border-radius: 15px;
  background-color: ${props => (props.sender === 'user' ? '#007bff' : '#e5e5ea')};
  color: ${props => (props.sender === 'user' ? 'white' : '#000')}; /* Change text color for user */
`;

const Message = ({ text, sender }) => {
  return (
    <MessageContainer sender={sender}>
    <MessageBubble sender={sender}>
      {text}
    </MessageBubble>
  </MessageContainer>
  )
}

export default Message
