import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { AUTHMICRO } from '../../config/urls';
import Lottie from 'react-lottie';
import successAnimation from '../assets/success.json';
import failureAnimation from '../assets/failure.json';
import "../Wallet/Wallet.css";

const PaymentStatus = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [status, setStatus] = useState(null); // State to store payment status

    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt;

    // API to show payment status
    const getPaymentStatus = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${AUTHMICRO}/sales/verify/order/${id}`,
                requestOptions
            );
            const result = await response.json();

            if (result?.status === 1) {
                setStatus(true); // Set status to true for success
            } else if (result.status === "TOKEN_ERR") {
                basic.setJwt("");
                navigate("/Register");
                toast("Logged in other device! Please login again");
            } else {
                setStatus(false); // Set status to false for failure
                toast(result?.Backend_Error);
            }
        } catch (error) {
            console.error(error);
            setStatus(false); // Set status to false in case of error
        }
    };

    useEffect(() => {
        getPaymentStatus();
    }, []);

    const successOptions = {
        loop: true,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const failureOptions = {
        loop: true,
        autoplay: true,
        animationData: failureAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="payment-status-container">
            {status === null ? (
                <div>Loading...</div>
            ) : status ? (
                <div className="status-content">
                    <Lottie options={successOptions} height={200} width={200} />
                    <h1 className="success-message">Payment Successful</h1>
                    <button className="home-button" onClick={() => navigate('/wallet')}>Go to Wallet</button>
                </div>
            ) : (
                <div className="status-content">
                    <Lottie options={failureOptions} height={200} width={200} />
                    <h1 className="failure-message">Payment Failed</h1>
                    <button className="home-button" onClick={() => navigate('/wallet')}>Go to Wallet</button>
                </div>
            )}
        </div>
    );
};

export default PaymentStatus;
