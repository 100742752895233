import React, { useEffect, useRef, useState } from 'react'
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from "../utils/LoaderComponent";
import { Carousel } from 'react-bootstrap';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const HomeBannners = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [banner, setbanner] = useState([]);
    const page = useRef(1);
    const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();

//    api to call banners 
const getHome = async () => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${QUIZMICRO}/home/get/banners`,
        requestOptions
      );
      const result = await response.json();
      setIsLoading(false);
      if (result?.status == 1) {
        setbanner(result.banners);
       

      
      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHome();
  }, [])


  return (
    <>
    <div className="row">
              <Carousel interval={3000} pause={false} className="custom-carousel">
                {banner?.length === 0 && !isLoading ? (
                  <EmptyContainer
                    actionName="Reload"
                    action={() => getHome(page.current)}
                    message="No Data Found"
                  />
                ) : (
                  banner?.map((res) => {
                    return (
                      <Carousel.Item>
                        <div className="row align-items-center ">
                          <img
                            src={`${IMAGE_URL}${res.banner}`}
                            className="img-fluid "
                            alt="Product"
                            style={{  width: "100%" , height: "200px"}}
                          />

                        </div>
                      </Carousel.Item>
                    );
                  })
                )}{" "}
                {isLoading && <LoaderComponent />}

              </Carousel>
            </div>
    </>
  )
}

export default HomeBannners