import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import "../Home/Home.css";
import arrow from "../assets/arrow.gif";
import image4 from "../assets/image5.png";
import coin from "../assets/coin.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaRegClock } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { Modal } from "react-bootstrap";
import { BasicServices } from "../../services/BasicServices";
import { GRAPHQL_URL, IMAGE_URL, QUIZMICRO } from "../../config/urls";
import LoaderComponent from "../utils/LoaderComponent";
import { toast, ToastContainer } from "react-toastify";
import { convertTimeToAMPM } from "../utils/dateTime";
import Span from "../Translation/Translate";
import { Get_RoomQuiz_Detail, Get_Schedule_Quizes } from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";

const RegisterQuizRoom = () => {
  const Navigate = useNavigate();
  const { id } = useParams();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [isLoading, setIsLoading] = useState(false);


  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const { data, refetch } = useQuery(Get_RoomQuiz_Detail, {
    variables: { roomquiz_id: id }
  });
  const roomquizDetail = data?.view_detail_of_roomquiz?.response || [];
  console.log(roomquizDetail)

  const registerQuiz = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      roomquiz_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${GRAPHQL_URL}/participant/room/quiz/registor/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          handleShow1();
          toast(result?.msg)
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");

          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast.error(result?.error);
        }
      })
      .catch((error) => console.error(error));
  };


  return (
    <div>
      <ToastContainer />
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title
            style={{ flex: 1, textAlign: "center" }}
            className="congrats"
          >
            <Span>Congratulations!</Span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">
            <div className=" d-flex justify-content-center">
              {/* <img src={arrow} style={{ height: "20vh" }} alt=""></img> */}
            </div>
            <h6 className="text-center">
              <Span>Successfully Registered for</Span>{" "}
            </h6>
            <div className="d-flex justify-content-center mt-3  gap-2">
              {/* <img src={`${IMAGE_URL}${image}`} style={{ height: "5vh" }} alt=""></img> */}
              <div className=" pt-2">
                <h6>
                  {/* <Span>{quizName}</Span> */}
                </h6>
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-center">
              <button
                className="continueButtonClass p-2 text-center w-100"
                onClick={() => Navigate(-1)}
              >
                <Span>Continue</Span>
              </button>
            </div>
            <div className=" pt-2 text-center">
              <span className="newww">
                <Span>We will notify you before Exam Starts</Span>
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div style={{ height: "100vh", width: "100vw" }}>
          <div
            className="border"
            style={{
              height: "10vh",
              backgroundColor: "#6f42c1",
              color: "white",
            }}
          >
            <div className="container">
              <div className="row mt-3">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <IoArrowBack
                    style={{ fontSize: "4vh", cursor: "pointer" }}
                    onClick={() => {
                      Navigate(-1);
                    }}
                  />
                </div>
                <div className="col-8  text-center">
                  <span
                    style={{ fontSize: "1.2rem", fontWeight: "700" }}
                    className="mb-0"
                  >
                    <Span>Quiz Details</Span>
                  </span>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
          <div style={{ height: "78vh", overflowY: "scroll" }}>

            <div className="container">
          
              
                  <>
                    <div
                      className=" mt-1 p-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Navigate("/card/current");
                      }}
                    >
                      <span className="crd">
                        <Span>Name of Exam</Span>{" "}
                      </span>
                      <div className="d-flex align-items-center gap-2">
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            border: "1px solid #808080",
                          }}
                        >
                          <img
                         src={`${IMAGE_URL}${roomquizDetail?.category_image}`} 
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                        </div>
                        {/* <img src={`${IMAGE_URL}${roomquizDetail?.category_image}`} style={{ height: "5vh", borderRadius: "50%" }} alt='icon'></img> */}
                        <h6 className="some-txt">
                          <Span>{roomquizDetail.category_name}</Span>
                        </h6>
                      </div>
                      <div className=" mt-1">
                        <span className="crd">
                          <Span>Timing</Span>
                        </span>
                      </div>
                      <div className=" mt-1 d-flex align-items-center gap-4">
                        <div className="d-flex align-items-center gap-2">
                          <MdOutlineCalendarMonth
                            className="txx"
                            style={{ fontSize: "20px" }}
                          />
                          <span className="txx">{roomquizDetail?.sch_time?.split(" ")[0]}</span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <FaRegClock className="txx" style={{ fontSize: "18px" }} />
                          <span className="txx">
                            {convertTimeToAMPM(roomquizDetail?.sch_time?.split(" ")[1])}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 ">
                        <span className="crd">
                          <Span>Entry Fees</Span>
                        </span>
                      </div>
                      <div className="mt-1">
                        <div className="d-flex gap-2">
                          <img src={coin} style={{ height: "3vh" }}
                            alt=""
                          ></img>
                          <h6 className="new-txt">{roomquizDetail?.entryFees}</h6>
                        </div>
                      </div>
                    </div>
                  </>
                
             


              <hr></hr>


            </div>
          </div>

          <div className="border px-4" style={{ height: "12vh" }}>
            <button
              className="agreeregisterButtonClass  p-2 text-center w-100"
              onClick={registerQuiz}
            >
              <Span>Agree & Register</Span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterQuizRoom;
