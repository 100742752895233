import React, { useState, useEffect } from "react";
import { GoHome } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { BasicServices } from "../../services/BasicServices";
import { toast } from "react-toastify";
import { QUIZMICRO } from "../../config/urls";
import LoaderComponent from "../utils/LoaderComponent";
import { FaLongArrowAltLeft } from "react-icons/fa";

import bbtoken from "../assets/coin.png"
import user from "../assets/user.webp"
import { IoHomeOutline } from "react-icons/io5";


const NewReward = () => {
  const { id } = useParams();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [quizData, setQuizData] = useState({});

  const getData = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "quiz_id": id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZMICRO}/participants/view/result/in/trivia/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          setQuizData(result);
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("")
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>

      {
        isLoading ? <LoaderComponent /> :
          <div
            style={{
              height: "100vh",
              width: "100vw",
              overflow: "hidden",
              backgroundColor: "#701DDB",
              color: "#FFFFFF",
            }}
          >
            {/* Tiitle reward */}
            <div
              style={{ height: "10vh", alignItems: "center" }}
              className="d-flex justify-content-between px-2 px-md-4"
            >
              {/* <div className="arrowb center-data">
          <GoHome
            size={20}
            color="#FFFFFF"
            cursor="pointer"
            onClick={() => {
              Navigate("/Home");
            }}
          />
        </div> */}


              <div
                style={{ height: "10vh", alignItems: "center" }}
                className="d-flex justify-content-between px-2 px-md-4 w-100"
              >
                <div className=" center-data" >
                  <FaLongArrowAltLeft size={20} color="#FFFFFF" cursor="pointer" onClick={() => {
                    Navigate(-1);
                  }}
                  />
                </div>
                <div className=" center-data">
                  <IoHomeOutline
                    size={20}
                    color="#FFFFFF"
                    cursor="pointer"
                    onClick={() => {
                      Navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Reward Money */}
            <div
              style={{ height: "90vh" }}
              className="d-flex w-100 mt-4"
            >
              <div className=" w-100">
                <div className="center-x">
                  <h4 style={{ color: "white" }}>{quizData?.reward === 0 ? " " : "Congratulation"}</h4>
                </div>
                <div className="center-x mb-4">
                  <h3 style={{ color: "white" }}>{quizData?.self_name}</h3>
                </div>
                <div className="center-x py-4">
                  <img

                    src={quizData?.self_profile_photo || user}
                    alt="winner"
                    style={{ border: "5px solid #F5B807", borderRadius: "50%", height: "150px" }}
                  />
                </div>
                <div className="center-x mt-4">
                  <h3 style={{ color: "white" }}>{quizData?.reward === 0 ? "Better Luck Next Time " : "You won"}</h3>
                </div>
                <div className="center-x mt-3">
                  <img src={bbtoken} style={{ height: "30px" }} alt="coin" />
                  <h2 className="ms-3" style={{ color: "#FAFF10" }}>
                    {quizData?.reward}
                  </h2>
                </div>

                <div className="center-x">
                  <div
                    className="center-data mt-4 rounded-2"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "17px",
                      fontWeight: "700",
                      width: "50%",
                      padding: "15px",
                      background: "linear-gradient(91.08deg, #54ACFD 4.74%, #2289E7 91.89%)",
                    }}
                    onClick={() => {
                      Navigate(`/answeraheet/${id}/trivia`);
                    }}
                  >
                    <div style={{ marginBottom: "10px", textAlign: "center" }}>
                      <span>Score: {quizData?.obtain_marks}/{quizData?.tot_marks}</span>
                    </div>
                    <div style={{ marginBottom: "10px", textAlign: "center" }}>
                      <span>Percentage: {quizData?.obtain_persentage}%</span>
                    </div>
                    <button style={{    border: "none",
    padding: "10px",
    fontWeight: "600",
    backgroundColor: "#fff",
    borderRadius:" 7px"}}>View Score Card</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }

    </>
  );
};

export default NewReward;
