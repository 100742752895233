import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import vector6 from "../assets/Vector6.png";
import coin from "../assets/coin.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { LuTicket } from "react-icons/lu";
import { ProgressBar } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import { Get_Schedule_Quizes } from '../ApolloGraphSql/Queries';

const ViewSheduleQuiz = () => {
    const {Roomsid, RoomName} = useParams();
    const Navigate = useNavigate();
    const {data, refetch} = useQuery(Get_Schedule_Quizes, {
        variables: { room_id: Roomsid, page: 1}
    })

    useEffect(() => {
        refetch();
      }, [refetch]);

      const ViewSheduleQuiz = data?.schedule_quizes?.response || [] ;
      console.log("ViewSheduleQuiz", ViewSheduleQuiz);

  return (
    <>

          <div
            className=""
            style={{
              display: "block" ,
              overflow: "hidden",
              height: "100vh",
              width: "100vw",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                background: "rgb(112, 29, 219)",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
              }}
            >
              <div
                className=""
                style={{
                
                  borderRadius: "50%",
                  padding: "9px",
                  cursor: "pointer",
                }}
                onClick={() => Navigate(`/schedulequiz/${Roomsid}/${RoomName}`)}
              >
                <FaArrowLeftLong style={{ color: "#fff", fontSize: "19px" }} />
              </div>
              <img
                src={vector6}
                alt="Top Right"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              />

              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  position: "relative",
                  padding: "20px",
                }}
              >
                <div
                  className="header"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    color: " #fff",
                    marginTop: "70px",
                    fontSize: "20px",
                    fontWeight: 600,
                    padding: "40px",
                  }}
                >
                  <p>
                    New Live Quiz “SBI PO - Prelims”
                    <br /> Created Successfully
                  </p>
                </div>
                <div
                  className="p-2 "
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                 
                >
                  <div className="mt-3 " style={{ padding: "10px" }}>
                    <div className="d-flex align-items-center gap-3 ">
                      <div
                        className="boxshadow"
                        style={{
                          borderRadius: "50%",
                          width: "52px",
                          height: "50px",
                        }}
                      >
                        {/* <img
                           src={IMAGE_URL + res.banner}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        /> */}
                      </div>
                      {/* <img src={IMAGE_URL + res.banner} style={{ height: "7vh", border:"1px solid red" }} alt="SBI-PO Current Affairs" /> */}
                      <h6 className="some-txt">SBI-PO Current Affairs</h6>
                    </div>
                    <div className="mt-2 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <h6 className="txx">Entry Fees</h6>
                        <div className="d-flex gap-2">
                          <img
                            src={coin}
                            style={{ height: "3vh" }}
                            alt="Coin"
                          />
                          <h6 className="new-txt"> {ViewSheduleQuiz.entryFees}</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <MdOutlineCalendarMonth
                          className="txx"
                          style={{ fontSize: "20px" }}
                        />
                        <span className="txx">{ViewSheduleQuiz.sch_time}</span>
                      </div>
                    </div>
                    <div className="mt-2 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <h6 className="txx">Reward</h6>
                        <div className="d-flex gap-2">
                          <img
                            src={coin}
                            style={{ height: "3vh" }}
                            alt="Coin"
                          />
                          <h6 className="new-txt">{ViewSheduleQuiz?.prize}</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <FaRegClock
                          className="txx"
                          style={{ fontSize: "18px" }}
                        />
                        <span className="txx">
                          <span>12:30</span>
                          {/* <Span>{timepart}</Span> */}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 d-flex align-items-center gap-2 ms-1">
                      <div>
                        <LuTicket style={{ fontSize: "3vh" }} />
                      </div>
                      <div className="d-flex mt-2">
                        <h6 className="alpha">
                          {/* {res.slot_aloted}{" "} */}
                        </h6>
                        {/* <h6> /{res.slots}</h6> */}
                      </div>
                    </div>
                    <div className="mt-2">
                      <ProgressBar
                        // completed={res.slot_aloted}
                        bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                        height="10px"
                        isLabelVisible={false}
                        baseBgColor="#E4E9EC"
                        labelColor="#e80909"
                        animateOnRender
                        // maxCompleted={res.slots}
                      />
                    </div>
                  </div>
                </div>
                
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                  }}
                >
                  <button
                    className="proceed-button"
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      border: "none",
                      borderRadius: "8px",
                      color: "#701DDB",
                      fontSize: "16px",
                      fontWeight: 600,
                      width: "100%",
                    }}
                  >
                    View Quiz
                  </button>
                </div>
              </div>
            </div>
          </div>
        
    </>
  
  )
}

export default ViewSheduleQuiz;