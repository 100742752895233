import React, { useEffect, useRef, useState } from "react";

import "./saved.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import LoaderComponent from "../utils/LoaderComponent";
import Span from "../Translation/Translate";

const MyExamsCard = () => {
  const Navigate = useNavigate();
  const [savedData, setSavedData] = useState([]);
  const page = useRef(1);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [isLoading, setIsLoading] = useState(false);

  const savedGetData = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${QUIZMICRO}/participants/get/saved/exams`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result?.status === 1) {
          setSavedData(result?.data);
        } else if (result.status === "TOKEN_ERR") {
          basic.jwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    savedGetData();
  }, []);

  return (
    <div>
      <div className="container ">
        <div className="row gap-1 justify-content-center">
          {savedData?.length === 0 && !isLoading ? (
            <EmptyContainer
              actionName="Reload"
              action={() => savedGetData()}
              message="No Data Found"
            />
          ) : (
            savedData?.map((res) => {
              return (
                <div className="col-lg-3 card1 mt-3 p-3 border boxShadow">
                  <div className="  d-flex align-items-center gap-2 notsaved">
                    <img
                      src={`${IMAGE_URL}${res?.image}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt="quizz_icon"
                    ></img>
                    <h6 className="some-txt"><Span>{res?.category_name}</Span></h6>
                  </div>
                  <div className="d-flex mt-3">
                        <div
                          className="btn2 rounded me-2 center-data"
                          style={{ cursor: "pointer" }}
                          key={res?._id}
                          onClick={() =>
                            Navigate(
                              `/viewExam/${res?._id}/${res?.category_name}`
                            )
                          }
    
                        >
                          View Details
                        </div>
                        <div
                          className="btnr2_myexamsstudy rounded ms-2 leave-rmdata"
                          style={{ cursor: "pointer" }}
                          onClick={() => Navigate(`/StudySaved/${res?._id}`)}
                          
                        >
                          Study Material
                       
                        </div>
                      </div>

                  
                 
                </div>
              );
            })
          )}
          {isLoading && <LoaderComponent />}
        </div>
      </div>
    </div>
  );
};

export default MyExamsCard;
