import React from 'react'
import ReelTagData from './ReelTagData'
import Bottom from '../Home/Bottom'

const ReelTag = () => {
  return (
    <div style={{height:"100vh",width:"100vw", overflow:"hidden",  flexDirection: "column",
        display: "flex", backgroundColor:"white" }} >
      
        <div style={{height:"90vh",overflow:"hidden"}}  className='d-flex flex-column' >
         <ReelTagData/>
        </div>
        <div className = "example1" style={{ flex: "0 0 auto",  backgroundColor:"#6f42c1" , paddingTop:0, color:"white"}}>
        <Bottom/>
        </div>
    </div>
  )
}

export default ReelTag

