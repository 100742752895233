import React, { useContext, useEffect, useState } from "react";
import "../Wallet/Wallet.css";
import { BsCoin } from "react-icons/bs";

import { IoArrowBack } from "react-icons/io5";
import img2 from "../assets/vector.png";

import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Context } from "../..";
import { MdPending } from "react-icons/md";
import { orange } from "@mui/material/colors";
import Span from "../Translation/Translate";
import { toast } from "react-toastify";
import { AUTHMICRO } from "../../config/urls";
import { BasicServices } from "../../services/BasicServices";
import fad from "../assets/fadrailbank.png";

const Pending = () => {
  const Navigate = useNavigate();
  const { id } = useParams();
  const { handleShow, settransactionData } = useContext(Context);

  const { state } = useLocation();
  console.log(state);

  const [allDetails, setAllDetails] = useState("");

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;

  const viewParticularDetail = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      transaction_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${AUTHMICRO}/sales/view/payment/details`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          setAllDetails(result?.sendata);
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    viewParticularDetail();
  }, []);

  return (
    <div>
      <div className="" style={{ background: "orange" }}>
        <div
          className="text-center py-3 text-white "
          style={{ background: "orange" }}
        >
          <div className="d-flex justify-content-between">
            <button
              className="text-start fs-2 btn"
              onClick={() => {
                Navigate(-1);
              }}
            >
              <IoArrowBack />
            </button>
            <h2 className="text-center mx-auto">
              <Span>Transaction Details</Span>
            </h2>
          </div>
          <p className="fs-2 ">
            <span>
              <BsCoin className="bg-warning rounded " />
            </span>
            {allDetails?.amount} <span className="rupee-symbol">₹</span>
          </p>
          <p>
            <span>
              <MdPending />
            </span>
            {state.type.charAt(0).toString().toLocaleUpperCase()}
            {state.type.slice(1)} <Span>Pending</Span>
          </p>
        </div>
        <div className="transaction-wrapper ">
          <div className=" text-start text-secondary">
            <p className="code0">
              <Span>Transaction ID</Span>
            </p>
            <p className="code">{allDetails?.order_id}</p>
            <p className="code1">
              <Span>Payment Type</Span>:
            </p>
            <p>
              {/* <img src={img} className='img-fluid' alt='' /> */}
              <Span>{allDetails?.type}</Span>
            </p>
            <p>
              <img src={img2} className="img-fluid px-2" alt="" />
              {allDetails.order_datetime}
            </p>
          </div>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="row pt-3 example"
                    style={{ height: "38vh", overflowY: "auto" }}
                  >
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 pb-3">
                                {allDetails?.type == "credit" && (
                                  <div
                                    className="border p-2"
                                    style={{ borderRadius: "5px" }}
                                  >
                                    <div className="d-flex justify-content-start align-items-center">
                                      <img
                                        src={fad}
                                        alt="bank logo"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          backgroundColor: "blue",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      <h6
                                        className="pt-1"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "25px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {allDetails?.bankdetails?.bank_name}
                                      </h6>
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "left",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Acc. Holder:{" "}
                                        </span>
                                        {
                                          allDetails?.bankdetails
                                            ?.acc_holder_name
                                        }
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Account Number:{" "}
                                        </span>
                                        {allDetails?.bankdetails?.bank_acc_no}
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Verification Status:{" "}
                                        </span>
                                        Pending
                                      </h6>
                                    </div>
                                  </div>
                                )}

                                <p className="py-auto">Reason for Pending </p>
                                <div className="bg-body-secondary  p-3  fs-3 text-secondary code">
                                  <Span>Bank Processing</Span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Pending;
