import React, { useEffect, useRef, useState } from 'react';
import { IMAGE_URL } from '../../config/urls';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from '../utils/LoaderComponent';
import { Carousel } from 'react-bootstrap';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CourseApiService from '../../services/api/CourseApiService';

const HomeCourses = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [paidCourses, setPaidCourses] = useState([]);
    const [isBuyable, setIsBuyable] = useState(true);
    const [courseIdToBuy, setCourseIdToBuy] = useState('');
    const page = useRef(1);
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt;
    const navigate = useNavigate();
    const courseServ = new CourseApiService();
    const [show, setShow] = useState(false);

    const showModal = (courseId) => {
        setCourseIdToBuy(courseId);
        setShow(true);
    };

    const hideModal = () => {
        setShow(false);
        setCourseIdToBuy('');
    };

    useEffect(() => {
        setIsLoading(true);
        courseServ.getPaidCourses().then(res => {
            if (res?.status === 1) {
                setPaidCourses(prevCourses => [...prevCourses, ...res?.data]);
            } else if (res.status === "TOKEN_ERR") {
                basic.setJwt("");
                navigate("/Register");
                toast("Logged in other device! Please login again");
            } else {
                toast(res.Backend_Error);
            }
        }).catch((error) => toast(error.message)).finally(() => {
            setIsLoading(false);
        });
    }, [page, navigate]);

    const buyCourse = (courseId) => {
        setIsBuyable(false);
        courseServ
            .buyCourse(courseId)
            .then((res) => {
                if (res?.status === 1) {
                    setPaidCourses(t => t.filter(i => i._id !== courseId));
                    toast("Course Bought Successfully");
                } else if (res.status === "TOKEN_ERR") {
                    basic.setJwt("");
                    navigate("/Register");
                    toast("Logged in other device! Please login again");
                } else {
                    toast(res.Backend_Error);
                }
            })
            .catch((err) => toast(err.message))
            .finally(() => {
                setIsBuyable(true);
                setShow(false);
            });
    };

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <h6 className="txt">Courses</h6>
                    </div>
                    <div>
                        <span
                            className="see"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/courses")}
                        >
                            See All
                        </span>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 p-1 boxshadow card">
                    <Carousel interval={3000} pause={false} className="custom-carousel">
                        {paidCourses?.length === 0 && !isLoading ? (
                            <EmptyContainer
                                actionName="Reload"
                                message="No Data Found"
                            />
                        ) : (
                            paidCourses?.map((res) => (
                                <Carousel.Item key={res._id}>
                                    <div className="p-2">
                                        <img
                                            src={`${IMAGE_URL}${res.banner}`}
                                            className="img-fluid"
                                            alt="Product"
                                            style={{ height: "200px", width: "100vw" , objectFit: "contain"}}
                                        />
                                        <div className="d-flex justify-content-center p-2">
                                            <h6 style={{ fontSize: "23px" }}>{res.cou_name}</h6>
                                        </div>
                                        <div className="d-flex justify-content-between p-2">
                                            <p>Price: <span style={{ color: "#7e7e7e" }}>₹{res.amount}</span></p>
                                            <button style={{ backgroundColor: "#efc64db8", borderRadius: "5px", padding: "5px", border: "none" }}>Duration: <span style={{ color: "#7e7e7e" }}>{res.Duration}</span></button>
                                        </div>
                                        <div className="d-flex justify-content-between p-2">
                                            <p>Price: <span style={{ color: "#7e7e7e" }}>₹{res.final_amount}</span></p>
                                            <button style={{ backgroundColor: "#008000", borderRadius: "5px", padding: "5px", border: "none" }}><span style={{ color: "#fff" }}>{res.discount}% off</span></button>
                                        </div>
                                        <div className="d-flex justify-content-between p-2">
                                            <p>Video Count:<span style={{ color: "#7e7e7e" }}>{res.videos_count}</span></p>
                                            <p>Attachment:<span style={{ color: "#7e7e7e" }}>{res.attachment_count}</span></p>
                                        </div>
                                        <div className="d-flex justify-content-between p-2">
                                            <button style={{ border: "none", padding: "7px", backgroundColor: "#d8d6dd", color: "#7041be", fontSize: "18px", borderRadius: "10px", fontWeight: "600" }} onClick={() => showModal(res._id)}>Buy course</button>
                                            <button style={{ border: "none", padding: "7px", backgroundColor: "#d8d6dd", color: "#7041be", fontSize: "18px", borderRadius: "10px", fontWeight: "600" }}>View course</button>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))
                        )}
                        {isLoading && <LoaderComponent />}
                    </Carousel>
                </div>
            </div>

            {show && (
                <div className="modal display-block">
                    <div className="modal-main">
                        <p>Do you want to buy this course?</p>
                        <button onClick={() => buyCourse(courseIdToBuy)}>Yes</button>
                        <button onClick={hideModal}>No</button>
                    </div>
                </div>
            )}

            <style jsx>{`
                .modal {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .modal-main {
                    background: white;
                    border-radius: 10px;
                    padding: 20px;
                    max-width: 500px;
                    margin: auto;
                    text-align: center;
                }

                .modal-main button {
                    margin: 10px;
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .modal-main button:first-of-type {
                    background-color: #7041be;
                    color: white;
                }

                .modal-main button:last-of-type {
                    background-color: #d8d6dd;
                    color: #7041be;
                }
            `}</style>
        </>
    );
};

export default HomeCourses;
