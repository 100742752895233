import React, { useCallback, useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { MdOndemandVideo } from "react-icons/md";
import { SlNotebook } from "react-icons/sl";
import CourseApiService from '../../services/api/CourseApiService'
import { ToastContainer, toast } from 'react-toastify';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from '../utils/LoaderComponent';
import { BLOBURL } from '../../config/urls';
import { useNavigate } from 'react-router-dom';
import { BasicServices } from '../../services/BasicServices';

const EnrolledCourses = () => {
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isMaterialLoading, setIsMaterialLoading] = useState(false);
  const [enrolledCourses, setEnrolledCourses] = useState([])
  const [videos, setVideos] = useState({});
  const [materials, setMaterials] = useState({})
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const observer = useRef();
  const courseServ = new CourseApiService();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const navigate = useNavigate()

  useEffect(() => {
    setIsCourseLoading(true)
    courseServ.getEnrolledCourses(page).then(res => {
      if (res?.status === 1) {
        setEnrolledCourses((prevEnrolled) => [...prevEnrolled, ...res.data]);
        setTotalPages(res.totalPages);
      }else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
         navigate("/Register");
         toast("Logged in other device! Please login again");
       }  
      else {
        toast(res.Backend_Error)
      }
    }).catch((error) => toast(error.message)).finally(() => {
      setIsCourseLoading(false)
    })
  }, [page])
  const lastElementRef = useCallback(node => { // Added: Callback for the last element
    if (isCourseLoading) return;
    if (observer.current) observer.current.disconnect(); // Disconnect the previous observer
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node); // Observe the last element
  }, [isCourseLoading,page, totalPages]);
  function getVideosForCourse(course_id) {
    setIsVideoLoading(true)
    courseServ.getVideos(course_id).then(res => {
      if (res.status === 1) {
        setVideos(v => {
          let t = { ...v };
          t[course_id] = res.videos
          return t;
        })
      }else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
         navigate("/Register");
         toast("Logged in other device! Please login again");
       }  
      else {
        toast(res.Backend_Error)
      }
    }).catch((err) => toast(err.message)).finally(() => {
      setIsVideoLoading(false)
    })
  }

  function getStudyMaterial(course_id, video_id) {
    setIsMaterialLoading(true)
    courseServ.getStudyMaterial(course_id, video_id).then(res => {
      if (res?.status === 1) {
        setMaterials(
          v => {
            let t = { ...v }
            t[video_id] = res.study_materials
            return t;
          }
        )
      }else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
         navigate("/Register");
         toast("Logged in other device! Please login again");
       }  
      else {
        toast(res.Backend_Error)
      }
    })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsMaterialLoading(false)
      })
  }

  function reloadEnrolledCourses() {
    setIsCourseLoading(true)
    courseServ.getEnrolledCourses().then(res => {
      if (res?.status === 1) {
        setEnrolledCourses(res.data)
      }else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
         navigate("/Register");
         toast("Logged in other device! Please login again");
       }  
      else {
        toast(res.Backend_Error)
      }
    }).catch((error) => toast(error.message)).finally(() => {
      setIsCourseLoading(false)
    })
  }

  const handleBuyNowClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="container-fluid p-3">
      <ToastContainer />

      <div className="row">
        <div className=' brown ' style={{ height: "78vh", overflowY: "auto" }}>
          <div className="col-lg-12 col-sm-12 col-md-12 ">
            {
              isCourseLoading ? <LoaderComponent /> :
                enrolledCourses.length <= 0 ?
                  <EmptyContainer message={"No Enrolled Courses Found"} action={reloadEnrolledCourses} actionName={"Reload"}></EmptyContainer>
                  :
                  enrolledCourses.map((res, index) => (
                    <Accordion key={res._id} ref={index === enrolledCourses.length - 1 ? lastElementRef : null} flush>
                      <Accordion.Item eventKey={res._id} style={{ marginTop: '5%' }} onClick={() => { getVideosForCourse(res._id) }}>
                        <Accordion.Header>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="">
                                  <h6 style={{ fontSize: "16px", fontWeight: "800" }}> <span style={{ fontSize: "20px", fontWeight: "600", color: '#61a3ad' }}>{index + 1}.</span> {res.cou_name}</h6>

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="" style={{ marginLeft: "30px", lineHeight: "10%" }}>
                                  <h6 style={{ color: "#61a3ad" }}>Duration: {res.Duration}(s), <span>{res.attachment_count} Attachment(s), </span> <span>{res.videos_count} Video(s)</span></h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="container">

                            <div className="row">
                              <div className="col-lg-12 col-md-6 col-sm-12">

                                <div className="row">
                                  <div className=' brown ' style={{ height: "max-content", maxHeight: "40vh", overflowY: "auto" }}>
                                    <div className="col-12">
                                      <div className="">
                                        {
                                          (isVideoLoading && !videos[res._id])
                                            ?
                                            <LoaderComponent></LoaderComponent>
                                            :
                                            videos[res._id]?.map(video =>
                                              <Accordion key={video._id} flush>
                                                <Accordion.Item eventKey={video._id} onClick={() => { getStudyMaterial(res._id, video._id) }}>
                                                  <Accordion.Header>
                                                    <div className="container">
                                                      <div className="row">
                                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                                          <div className="row">
                                                            <div className="col-12">
                                                              <div className="d-flex align-items-center justify-content-between">
                                                                <h6 className='d-flex gap-3'><MdOndemandVideo style={{ color: "#61a3ad", fontSize: "20px" }} />{video.title}</h6>
                                                                <button className='btn-btn p-2' style={{ backgroundColor: "#61a3ad", color: "white", border: "none", borderRadius: "6px", paddingTop: "10%" }}
                                                                  onClick={() => navigate("/video", {
                                                                    state: {
                                                                      "course_id": res._id,
                                                                      "video_id": video._id
                                                                    }
                                                                  })
                                                                  }

                                                                >Play video</button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <div className="container">
                                                      <div className="row">
                                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                                          <div className="row">
                                                            <div className="col-12">
                                                              {
                                                                (isMaterialLoading && !materials[video._id])
                                                                  ?
                                                                  <LoaderComponent></LoaderComponent>
                                                                  :
                                                                  materials[video._id]?.map(item =>
                                                                    <div key={item._id} className="d-flex align-items-center justify-content-between">
                                                                      <h6 className='d-flex gap-2' style={{ marginLeft: "35px" }}><SlNotebook style={{ color: "#61a3ad", fontSize: "20px" }} />{item.title}</h6>
                                                                      <button className='btn-btn p-1' style={{ color: "white", border: "none", borderRadius: "6px", backgroundColor: "#60bdd6" }} onClick={handleBuyNowClick}><a href={BLOBURL + item.pdf_url} target='_blank'>Perview</a></button>
                                                                    </div>
                                                                  )
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>)
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrolledCourses;