import React, { useState, useEffect, useRef } from "react";
import { IoMdLogOut } from "react-icons/io";
import { FaArrowLeftLong } from "react-icons/fa6";
import "../user/User.css";
import { FaArrowRightLong } from "react-icons/fa6";
import user from "../img/user.png";
import log1 from "../img/privacypolicyblack.png";
import log2 from "../img/SecurityBlack.png";
import log3 from "../img/bbimg.png";
import { NavLink, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import AuthenticationApiService from "../../services/api/AuthenticationApiService";
import { toast, ToastContainer } from 'react-toastify';
import { BasicServices, } from "../../services/BasicServices";
import { BLOBURL } from "../../config/urls";
import Span from "../Translation/Translate";


const EditProfile = () => {
  const navigate = useNavigate();
  const [gender, setGender] = useState('no say')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const auth = new AuthenticationApiService();
  let basic = new BasicServices();
  const [user_detail, setuser_details] = useState({})
  const [user, setuser] = useState({})
  const [selectedImage, setSelectedImage] = useState()
  const [imageFile, setImageFile] = useState()
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [editLoading, setEditLoading] = useState(false);

  const [profilepic, setProfilePic] = useState('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMIYPQpAHWfspO8qrIdSO7Pkx-JpkIU03WVA&usqp=CAU')

  const inputFileRef = useRef();

  async function uploadImage() {
    console.log(selectedImage, imageFile, "FILE");
    if (!selectedImage || !imageFile) {
      toast("Select A File First")
      return;
    }
    setIsFileUploading(true)

    auth.uploadProfile(imageFile).then((res) => {
      if (res?.status === 1) {
        console.log("Image Uploaded", res);
        toast("Image Uploaded Successfully")
      } else {
        toast(res?.Backend_Error)
      }
    }).finally(() => { setIsFileUploading(false) })
  }

  async function getUserProfile() {
    const response = await auth.getUserProfile()
    // console.log(response.user_details, 'response edit profile');
    setuser_details(response?.user_details)
    setName(response?.user_details?.name)
    setPhone(response?.user_details?.phone)
    setuser(response)
    setProfilePic(response?.user_details?.image)
    setGender(response?.user_details?.gender)
  }

  useEffect(() => {
    getUserProfile()
  }, [])

  async function editUserProfile() {
    setEditLoading(true);
    try {
      const response = await auth.editProfile(gender, name, phone);
      console.log(response, 'response edit profile');
      if (response?.status == 1) {
        basic.setName(name);
        basic.setNumber(phone);
        basic.setGender(gender);
        toast(response.msg);
        
      }else {
        toast(response.Backend_Error);
      }
    } catch (error) {
      console.error('Error editing profile:', error);
      // Display an error toast message
      toast('An error occurred while editing the profile.');
    }
    finally {
      setEditLoading(false);
    }
  }


  console.log("phone", phone);
  
  
  return (
    <div style={{ margin: 10, backgroundColor: "#ffffff", overflowX: "hidden" }}>
      <div style={{ padding: 10 }}>
        <ToastContainer />
      </div>
      <div className="row px-1 mt-2">
        <div className="col-lg-12 col-md-12 col-lg-12 d-flex justify-content-between ">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(245, 245, 245, 1)",
              padding: 10,
              borderRadius: 50,
            }}
          >
            <FaArrowLeftLong onClick={() => { navigate(-1) }}
              style={{ display: "flex", alignItems: "center", color: "#000" , cursor:"pointer"}}
            />
          </div>
          <div
          className="rounded"
            style={{
              cursor: 'pointer',
              padding: "5px 10px 0 10px",
              backgroundColor: "grey"
            }}
          >
            <span onClick={editUserProfile} style={{ color: "white" }}><Span>{editLoading? <Spinner /> : "Save"}</Span> </span>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="span3 well">
          <center>
            {
              selectedImage ?
                <a href="#aboutModal" data-toggle="modal" data-target="#myModal">
                  <img
                    src={selectedImage}
                    name="aboutme"
                    width="140"
                    height="140"
                    class="img-circle"
                    style={{objectFit:"cover"}}
                  />
                </a> :
                profilepic ?
                <a href="#aboutModal" data-toggle="modal" data-target="#myModal">

                  <img
                    src={BLOBURL+profilepic}
                    name="aboutme"
                    width="140"
                    height="140"
                    class="img-circle"
                    style={{objectFit:"cover"}}

                  />
                </a>
                :
                <a href="#aboutModal" data-toggle="modal" data-target="#myModal">

                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMIYPQpAHWfspO8qrIdSO7Pkx-JpkIU03WVA&usqp=CAU'
                    name="aboutme"
                    width="140"
                    height="140"
                    class="img-circle"
                    style={{objectFit:"cover"}}

                  />
                </a>
            }
          </center>
        </div>
        <div className="d-flex justify-content-center align-items-center m-3" style={{ gap: "20px" }}>

          <input
            type='file'
            id='file'
            ref={inputFileRef}
            style={{ display: 'none' }}
            accept=".png,.jpg"
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                setImageFile(event.target.files[0]);
                setSelectedImage(URL.createObjectURL(event.target.files[0]));
              }
              console.log(selectedImage);
            }} />
          <button
            disabled={isFileUploading}
            onClick={(e) => { inputFileRef.current.click() }}
            className="btn btn-primary"><Span>Change</Span> </button>
          <button
            disabled={isFileUploading}
            onClick={uploadImage}
            className="btn btn-secondary">
           <Span>Save</Span> 
          </button>
        </div>
      </div>

      <div className="m-2">
        <p class="name"><Span>Name</Span> </p>

        <div class="input-group">
          <input
            type="text"
            value={name}
            class="form-control"
            onChange={(e) => { setName(e.target.value) }}
            placeholder="name"
            style={{ borderRight: "none", padding: 10 }}
          />
          <span
            class="input-group-text"
            id="basic-addon2"
            style={{ borderLeft: "none", backgroundColor: "transparent" }}
          >
            {/* <FaPen /> */}
          </span>
        </div>

        <p class="name mt-4"><Span>Contact Number</Span> </p>
        <div class="input-group">
          <input
            type="text"
            value={phone}
            onChange={(e) => { setPhone(e.target.value) }}
            class="form-control"
            placeholder="text"
            maxLength={13}
            style={{ borderRight: "none", padding: 10 }}
          />
          <span
            class="input-group-text"
            id="basic-addon2"
            style={{ borderLeft: "none", backgroundColor: "transparent" }}
          >
            {/* <FaPen /> */}
          </span>
        </div>
      </div>

      <div className="container">
        <div className="mt-3">
          <p className="name"
          >
          <Span>My Gender</Span>  
          </p>
        </div>
        <div className="container">
          <div className="row my-3">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="row">
                <div onClick={() => setGender('boy')} className={["col-4 pt-2 d-flex my-10 flex-column align-items-center", gender === 'boy' && 'active-gender'].join(" ")}>
                  <img
                    className="ps-1 user_imgcat"
                    src="./image/man.png"
                    alt="man"
                  />
                  <p className="text-center"><Span>Men</Span> </p>
                </div>
                <div onClick={() => setGender('girl')} className={["col-4 pt-2 d-flex flex-column align-items-center", gender === 'girl' && 'active-gender'].join(" ")}>
                  <img
                    className="pe-1 user_imgcat"
                    src="./image/woman.png"
                    alt="Woman"
                  />
                  <p className="text-center"><Span>Woman</Span> </p>
                </div>
                <div onClick={() => setGender('no say')} className={["col-4 pt-2 d-flex flex-column align-items-center", gender === 'no say' && 'active-gender'].join(" ")}>
                  <img
                    className="ps-1 user_imgcat"
                    src="./image/user.png"
                    alt="No Say"
                  />
                  <p className="text-center"><Span>No Say</Span> </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-0 col-sm-0 "></div>
            <div className="col-lg-3  col-md-0 col-sm-0 "></div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default EditProfile;
