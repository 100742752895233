import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import menuIcon from "../assets/menuIcon.svg";
import { GiReceiveMoney } from "react-icons/gi";
import coin from "../assets/coin.png";
import image4 from "../assets/image5.png";
import Earned from "./Earned";
import WalletApiService from "../../services/api/WalletApiService";
import { IMAGE_URL } from "../../config/urls";
import { toast } from "react-toastify";
import { speedDialIconClasses } from "@mui/material";
import { MdOutlineMenu } from "react-icons/md";
import { Context } from "../..";
import { IoMdArrowRoundBack } from "react-icons/io";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";

const Myearnings = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [spent, setSpent] = useState([]);
  const [spentHistory, setspentHistory] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const { handleShow, handleClose } = useContext(Context);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const walletService = new WalletApiService();
  const [page, setPage] = useState(1);
  const observer = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    walletService.getSpentMoney(page).then((res) => {
      if (res?.status === 1) {
        setSpent((prevSpent) => [...prevSpent, ...res.mashup]);
        setspentHistory((prevHistory) => [...prevHistory, ...res.mashup]);
        setTotalPages(res.totalPages); 
      } else if (res?.status === "TOKEN_ERR") {
        basic.setJwt("");
        navigate("/Register");
        toast.error("Logged in other device! Please login again");
      } else {
        toast.error(res?.message);
      }
    }).finally(() => setIsLoading(false));
  }, [page, navigate]);

  const lastTransactionElementRef = useCallback((node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage) => prevPage + 1); // Increment page number when the last element is visible and not beyond total pages
      }
    });
    if (node) observer.current.observe(node); // Observe the last transaction element
  }, [isLoading, page, totalPages]);

  return (
    <>
      <div>
        <div style={{ height: "100vh", width: "100vw" }}>
          <div
            className="border"
            style={{
              height: "10vh",
              backgroundColor: "#6f42c1",
              color: "white",
            }}
          >
            <div className="container">
              <div className="row mt-3">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    style={{ fontSize: "22px", cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                </div>
                <div className="col-6 text-center">
                  <span
                    style={{ fontSize: "1.5rem", fontWeight: "700" }}
                    className="mb-0"
                  >
                    <span>My Earnings</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row pb-3 pt-3">
                    <div className="col-6">
                      <div
                        onClick={() => setValue(1)}
                        className="text-center p-2"
                        style={{
                          backgroundColor: value === 1 ? "#701DDB" : "#F2F2F2",
                          color: value === 1 ? "white" : "blue",
                          borderRadius: "8px",
                        }}
                      >
                        <h5
                          className="text-center"
                          style={{ color: value === 1 ? "white" : "black" }}
                        >
                          <GiReceiveMoney style={{ fontSize: "19px" }} />
                          <span>Spent</span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        onClick={() => setValue(2)}
                        className="text-center p-2"
                        style={{
                          backgroundColor: value === 2 ? "#701DDB" : "#F2F2F2",
                          color: value === 2 ? "#FFFFFF" : "#8A8A8A",
                          borderRadius: "8px",
                        }}
                      >
                        <h5
                          className="pt-1 text-center"
                          style={{ color: value === 2 ? "white" : "black" }}
                        >
                          <span style={{ color: "yellow" }}>🤑</span>
                          <span>Earned</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border" style={{ height: "78vh", overflowY: "auto" }}>
            {value === 1 ? (
              <>
                <section>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="row d-flex gap-2 justify-content-center">
                          {spent.length === 0 && !isLoading ? (
                            <div>
                              {/* todo: add no data found in here and also add the loader while isLoading*/}
                              <p className="h2 text-center">
                                <span>No Money Spent Yet</span>
                              </p>
                            </div>
                          ) : (
                            spent.map((res, index) => (
                              <div
                                key={index}
                                ref={index === spent.length - 1 ? lastTransactionElementRef : null} // Attach the ref to the last element
                                className="col-lg-12 card mt-3"
                                style={{ border: "none" }}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row pt-2">
                                      <div className="col-1 pt-1">
                                        <img
                                          src={coin}
                                          style={{ height: "5vh" }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-6 px-4">
                                        <h6
                                          style={{
                                            fontWeight: "700",
                                            fontSize: "24px",
                                            color: "#DC1111",
                                          }}
                                        >
                                          {res.amount}
                                        </h6>
                                        <h6
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            color: "#8A8C94",
                                            lineHeight: "2px",
                                          }}
                                        >
                                          {res.date}
                                        </h6>
                                      </div>
                                      <div className="col-5">
                                        <span
                                          className="d-flex justify-content-end"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {res.type}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gap-2 pt-3">
                                    <h6
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "400",
                                        color: "#8A8C94",
                                      }}
                                    >
                                      <span>Spent For</span>
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-2 pb-1">
                                  <img
                                    src={IMAGE_URL + res.banner}
                                    style={{ height: "3vh" }}
                                    alt=""
                                  />
                                  <h6
                                    className="pt-2"
                                    style={{
                                      fontWeight: "700",
                                      fontSize: "16px",
                                      color: "#2E2E2E",
                                    }}
                                  >
                                    <span>{res.name}</span>
                                  </h6>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                    {isLoading && (
                      <div className="text-center">
                        <span>Loading...</span>
                      </div>
                    )}
                  </div>
                </section>
              </>
            ) : (
              <>
                <Earned />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Myearnings;
