const kvPairs = {
    API_KEY: "AIzaSyAB2qggwL_tGupZBN9dPtiGVhyMG1cx2Xs",
    AUTH_DOMAIN: "intellix-c1f9c.firebaseapp.com",
    PROJECT_ID: "intellix-c1f9c",
    STORAGE_BUCKET: "intellix-c1f9c.appspot.com",
    MESSAGING_SENDER_ID: "730661590456",
    APP_ID: "1:730661590456:web:5d320557f300db82b1ab4d",
    MEASUREMENT_ID: "G-Y59HSZQL8V",
    VAPID_KEY: "BFMRfGtpFh6PFQgR0jErExOhjXSzv_vjAsu3NkeWROhzXPLh_h8WCL1siE3Ub9lp_nGet09MKORj3lyNRt6smv4"
}

  

export default kvPairs;