import React, { useEffect, useState } from 'react'
import play from '../assets/play.jpeg'
import image4 from '../assets/image5.png';
import coin from '../assets/coin.png';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import ProgressBar from "@ramonak/react-progress-bar";
import { BsTrophyFill } from 'react-icons/bs';
import { BasicServices } from '../../services/BasicServices';
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import { toast } from 'react-toastify';
import LoaderComponent from '../utils/LoaderComponent';
import Span from "../Translation/Translate";
const CurrentCardTrivia = () => {

    const { id } = useParams();
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt;
    const Navigate = useNavigate();

    const [quizData, setQuizData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setIsLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            `${QUIZMICRO}/participants/view/detail/of/trivia/quiz?id=${id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setIsLoading(false);
                if (result?.status === 1) {
                    setQuizData(result?.data);
                } else if (result.status === "TOKEN_ERR") {
                    // localStorage.removeItem("api_url");
                    basic.setJwt("")

                    Navigate("/Register");
                    toast("Logged in other device! Please login again");
                } else {
                    console.log(result?.Backend_Error);
                }
            })
            .catch((error) => console.error(error));
    };


    useEffect(() => {
        getData();
    }, []);

    return (

        <>
            {isLoading ?
                <LoaderComponent />
                :




                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div
                                    className='col-12  border pt-2'>
                                    <div>
                                        <div className='' style={{ position: "relative" }}>
                                            <img src={play} style={{ width: "100%", height: "100%", maxHeight: "20vh", objectFit: "cover", objectPosition: "top" }} alt='icon'></img>
                                        </div>
                                        <div className='backbtn mt-4 ms-4' style={{ position: "absolute", top: 0, left: 0 }}>
                                            <IoArrowBack className='p-1' style={{ fontSize: "4vh", color: "white", cursor: "pointer", }} onClick={() => { Navigate(-1) }} />
                                        </div>

                                    </div>
                                    <div className='row d-flex justify-content-between mt-1'>

                                        <div className=' col-lg-12 col-md-6 col-sm-12'>
                                            <div className='card p-2' >
                                                <div className=' mt-3' >

                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div
                                                            style={{
                                                                borderRadius: "50%",
                                                                width: "50px",
                                                                height: "50px",
                                                                border: "1px solid #808080",
                                                            }}
                                                        >
                                                            <img
                                                                src={`${IMAGE_URL}${quizData?.image}`}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "50%",
                                                                }}
                                                            />
                                                        </div>
                                                        <h6 className='some-txt'><Span>{quizData?.quiz_name}</Span> </h6>
                                                    </div>
                                                    <div className=' mt-2 d-flex align-items-center justify-content-between'>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <h6 className='txx'><Span>Cost</Span> </h6>
                                                            <div className='d-flex gap-2'>
                                                                <img src={coin} style={{ height: "3vh" }}></img>
                                                                <h6 className='new-txt'>0</h6>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <h6 className='txx'> <Span> Reward</Span> </h6>
                                                            <div className='d-flex gap-2'>
                                                                <img src={coin} style={{ height: "3vh" }}></img>
                                                                <h6 className='new-txt'>{quizData?.reward}</h6>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='mt-2 d-flex align-items-center gap-2 ms-1'>
                                                        <div className='d-flex gap-2'>
                                                            <BsTrophyFill className='trophy' />
                                                            <h6 className='persent'>{quizData?.min_reward_per}%</h6>
                                                        </div>

                                                    </div>
                                                    <div className='mt-2'>
                                                        <ProgressBar
                                                            completed={quizData?.min_reward_per}
                                                            height="10px"
                                                            isLabelVisible={false}
                                                            baseBgColor="#E4E9EC"
                                                            labelColor="#e80909"
                                                            animateOnRender
                                                            maxCompleted={quizData?.reward}
                                                            className='persent'
                                                        />
                                                    </div>
                                                    <div className=' mt-3 d-flex justify-content-center'>
                                                        <button className='participateButtonClass p-2 text-center w-100' onClick={() => { Navigate(`/participate/${id}`) }}>
                                                         <Span>Participate Now</Span>   
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mt-3'>
                                    <span className='question '><Span> Rules Of Participation</Span></span>
                                    <div className=' container mt-2 d-flex flex-column gap-3 rule'>
                                        {quizData?.rules?.map((res, index) => {
                                            return (
                                                <span>
                                                    {index + 1}. <Span>{res}</Span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            }
        </>

    )
}

export default CurrentCardTrivia
