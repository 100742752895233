import React, { useState, useEffect, useRef, useCallback } from "react";
import coin from "../assets/coin.png";
import image4 from "../assets/image5.png";
import WalletApiService from "../../services/api/WalletApiService";
import { IMAGE_URL } from "../../config/urls";
import { toast } from "react-toastify";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";
import { useNavigate } from "react-router-dom";

const Earned = () => {
  const [earned, setEarned] = useState([]);
  const [earneHistory, setearnedHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const Navigate = useNavigate()
  const walletService = new WalletApiService();
  const [page, setPage] = useState(1);
  const observer = useRef();

  useEffect(() => {
    setLoading(true);
    walletService
      .getEarnedMoney(page)
      .then((res) => {
        if (res?.status === 1) {
          setEarned((prevEarned) => [...prevEarned, ...res.mashup]);
          setearnedHistory((prevHistory) => [...prevHistory, ...res.mashup]);
          setTotalPages(res?.totalPages)
        }
        else if (res?.status === "TOKEN_ERR") {
          // localStorage.removeItem("api_url");
          basic.setJwt("")

          Navigate("/Register");
          toast("Logged in other device! Please login again");
        }
        else {
          toast(res?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  const lastTransactionElementRef = useCallback((node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage) => prevPage + 1); // Increment page number when the last element is visible
      }
    });
    if (node) observer.current.observe(node); // Observe the last transaction element
  }, [isLoading]);
  return (
    <>
      <section>
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="row d-flex gap-2 justify-content-center">
                {earned.length === 0 && !isLoading ? (
                  <div>
                    {/* todo: add no data found in here and also add the loader while isLoading*/}
                    <p className="h3 text-center">
                      <Span>No Money Earned Yet</Span>
                    </p>
                  </div>
                ) : (
                  earned.map((res, index) => {
                    return (
                      <div
                        key={index}
                        ref={index === earned.length - 1 ? lastTransactionElementRef : null}
                        className="col-lg-12 card mt-3 "
                        style={{ border: "none" }}
                      >
                        <div className=" row">
                          <div className="col-12 ">
                            <div className="row pt-2">
                              <div className="col-1 pt-1">
                                <img src={coin} style={{ height: "5vh" }} alt="icon"></img>
                              </div>
                              <div className="col-6 px-4">
                                <h6
                                  className=""
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "24px",
                                    color: "#16AC72",
                                  }}
                                >
                                  {res.amount}
                                </h6>
                                <h6
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    color: "#8A8C94",
                                    lineHeight: "2px",
                                  }}
                                >
                                  {res.date}
                                </h6>
                              </div>
                              <div className="col-5">
                                <span
                                  className="d-flex justify-content-end"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "15px",
                                  }}
                                >
                                  <Span>{res?.type}</Span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className=" gap-2 pt-3">
                            <h6
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#8A8C94",
                              }}
                            >
                              <Span>Earned For</Span>
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2 pb-1">
                          <img
                            src={IMAGE_URL + res.banner}
                            className=""
                            style={{ height: "3vh" }}
                            alt="icon"
                          ></img>
                          <h6
                            className="pt-2"
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              color: "#2E2E2E",
                            }}
                          >
                            <Span> {res.name}</Span>
                          </h6>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Earned;
