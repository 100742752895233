import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { LuBookMarked, LuBrain } from "react-icons/lu";
import { AiOutlineHome } from "react-icons/ai";
import { CiPlay1, CiWallet } from "react-icons/ci";
import { RiHomeHeartLine, RiHomeSmileLine } from 'react-icons/ri';


const Bottom = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const getFontSize = (path) => location.pathname === path ? '16px' : '13px';
    const getFontWeight = (path) => location.pathname === path ? 600 : 400;

    return (
        <div>
            <div className="container" style={{ height: "100%", }}>
                <div className="row align-items-center m-auto" style={{ height: "9vh" }}>
                    <div className="col-3">
                        <div style={{ fontSize: getFontSize('/home'), fontWeight: getFontWeight('/home'), cursor: "pointer" }}
                            className="d-flex flex-column align-items-center"
                            onClick={() => navigate('/')}
                        >
                            <AiOutlineHome fontSize="20px" />
                            <span className="">Home</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div style={{ fontSize: getFontSize('/Courses'), fontWeight: getFontWeight('/Courses'), cursor: "pointer" }}
                            className="d-flex flex-column align-items-center"
                            onClick={() => navigate('/Courses')}
                        >
                            <LuBookMarked fontSize="20px" />
                            <span className="">Courses</span>
                        </div>
                    </div>
                    {/* <div className="col-4">
                        <div
                            className="d-flex flex-column align-items-center "
                            onClick={() => navigate('/Courses')}
                            style={{ position: "relative", cursor: "pointer" }}
                        >
                            <div style={{ position: "relative", bottom: "15px", background: "#F6F8FF", borderRadius: "50px" }}>
                                <img src={lg} style={{ height: "5vh", }} alt=''></img>
                            </div>

                            <span className='px-1' style={{ backgroundColor: "red", borderRadius: "10px" }}>
                                New
                            </span>


                            <span className="" style={{ fontSize: getFontSize('/Courses'), fontWeight: getFontWeight('/Courses') }}>Courses</span>
                        </div>
                    </div> */}
                    <div className="col-3">
                        <div style={{ fontSize: getFontSize('/rooms'), fontWeight: getFontWeight('/rooms'), cursor: "pointer" }}
                            className="d-flex flex-column align-items-center"
                            onClick={() => navigate('/rooms')}
                        >
                            <RiHomeSmileLine fontSize="20px" />
                            <span className="">Rooms</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div style={{ fontSize: getFontSize('/reelsview'), fontWeight: getFontWeight('/reelsview'), cursor: "pointer"  // Media query for smaller screens
           
          }}
                            className="d-flex flex-column align-items-center"
                            onClick={() => navigate('/reelsview')}
                            
                        >
                            <CiPlay1 fontSize="20px" />
                            <span className="">Brain Booster</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div>
        //     <div className="container bottom" style={{ padding: "10px  15px", height: "75px" }} >
        //         <div className="row align-items-center m-auto">
        //             <div className="col-2">
        //                 <div style={{ fontSize: getFontSize('/'), fontWeight: getFontWeight('/'), cursor: "pointer" }}
        //                     className="d-flex flex-column align-items-center"
        //                     onClick={() => navigate('/')}
        //                 >
        //                     <AiOutlineHome style={{ fontSize: "20px" }} />
        //                     <span className="">Home</span>
        //                 </div>
        //             </div>
        //             <div className="col-2">
        //                 <div style={{ fontSize: getFontSize('/study'), fontWeight: getFontWeight('/study'), cursor: "pointer" }}
        //                     className="d-flex flex-column align-items-center"
        //                     onClick={() => navigate('/study')}
        //                 >
        //                     <LuBookMarked style={{ fontSize: "20px" }} />
        //                     <span className="">Study</span>
        //                 </div>
        //             </div>
        //             <div className="col-4">
        //                 <div
        //                     className="d-flex flex-column align-items-center position-relative"
        //                     onClick={() => navigate('/Courses')}
        //                     style={{ cursor: "pointer" }}
        //                 >
        //                     <div style={{
        //                         position: "absolute",
        //                         bottom: "0px", background: "#F6F8FF", borderRadius: "50px", padding: "10px"
        //                     }}>
        //                         <img src={lg} style={{ maxWidth: "30px" }} alt="Logo" />
        //                         <span
        //                             className='px-1'
        //                             style={{
        //                                 backgroundColor: "red",
        //                                 borderRadius: "10px",
        //                                 position: "absolute",
        //                                 top: "35px",
        //                                 right: "10px",
        //                                 // transform: "translate(50%, -50%)",
        //                                 padding: "2px 5px",
        //                                 fontSize: "11px",
        //                                 color: "white"
        //                             }}
        //                         >
        //                             New
        //                         </span>
        //                     </div>
        //                     <span style={{ fontSize: getFontSize('/Courses'), fontWeight: getFontWeight('/Courses'), position: "absolute" }}>Courses</span>
        //                 </div>
        //             </div>
        //             <div className="col-2">
        //                 <div style={{ fontSize: getFontSize('/myexams'), fontWeight: getFontWeight('/myexams'), cursor: "pointer" }}
        //                     className="d-flex flex-column align-items-center"
        //                     onClick={() => navigate('/myexams')}
        //                 >
        //                     <GoHeart style={{ fontSize: "20px" }} />
        //                     <span className="">Saved</span>
        //                 </div>
        //             </div>
        //             <div className="col-2">
        //                 <div style={{ fontSize: getFontSize('/wallet'), fontWeight: getFontWeight('/wallet'), cursor: "pointer" }}
        //                     className="d-flex flex-column align-items-center"
        //                     onClick={() => navigate('/wallet')}
        //                 >
        //                     <CiWallet style={{ fontSize: "23px" }} />
        //                     <span className="">Wallet</span>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Bottom;
