// src/components/ChatWindow.js
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Message from './Message';
import backgroundImage from '../assets/bg7.jpg';

const Window = styled.div`
  flex: 1;
  padding: 10px 20px; /* Default padding for larger screens */
  overflow-y: auto;
  background: rgb(80, 201, 255);
  background: linear-gradient(0deg, rgba(80, 201, 255, 1) 11%, rgba(175, 255, 250, 0.9164915966386554) 44%);
  
  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 8px 16px; /* Adjust padding for medium screens */
  }

  @media (max-width: 480px) {
    padding: 20px 20px; /* Adjust padding for small screens */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 0;
`;

const Button = styled.button`
  background-color: transparent;
  border: 3px solid #007bff;
  border-radius: 10px;
  color: black;
  font-size: 14px;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  max-width: 70%;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const ChatWindow = ({ messages, buttons, onButtonClick }) => {
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Window>
      {messages.map((msg, index) => (
        <React.Fragment key={index}>
          <Message text={msg.text} sender={msg.sender} />
          {index === messages.length - 1 && buttons.length > 0 && (
            <ButtonContainer>
              {buttons.map((button, idx) => (
                <Button key={idx} onClick={() => onButtonClick(button)}>
                  {button.title}
                </Button>
              ))}
            </ButtonContainer>
          )}
        </React.Fragment>
      ))}
      <div ref={chatEndRef} />
    </Window>
  );
};

export default ChatWindow;
