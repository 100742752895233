import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import WalletApiService from "../../services/api/WalletApiService";
import { ToastContainer, toast } from "react-toastify";
import Span from "../Translation/Translate";

const Bankform = () => {
  const [bankName, setBankName] = useState();
  const [accHolder, setAccHolder] = useState();
  const [accNumber, setAccNumber] = useState();
  const [ifsc, setIfsc] = useState();
  const Navigate = useNavigate();
  const walletService = new WalletApiService();

  function showDetails() {
    if (!ifsc || !accHolder || !accNumber || !bankName) {
      console.log("zdsfd", ifsc, accHolder, accNumber, bankName);
      toast("Enter All Details...");
      return;
    }

    if (ifsc.trim().length !== 11) {
      toast("Enter right Ifsc code");
      return;
    }

    if (accNumber.trim().length < 11) {
      toast("Bank Account Number Length can't be less than 11");
      return;
    }

    Navigate("/wallet/bacc", {
      state: {
        ifsc: ifsc.trim(),
        accHolder: accHolder.trim(),
        accNumber: accNumber.trim(),
        bankName: bankName.trim(),
      },
    });
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div>
          <div className="" style={{ height: "10vh" }}>
            <div className="container">
              <div className="row mt-2 pt-4">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate(-1)}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  />
                </div>
                <div className="col-8  text-center">
                  <span
                    style={{ fontSize: "1.3rem", fontWeight: "700" }}
                    className="mb-0 "
                  >
                    <Span> Add Bank Account</Span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="pt-5">
                        <h6 style={{ fontSize: "20px", fontWeight: "600" }}>
                          <Span> Bank Name</Span>
                        </h6>
                        <input
                          type="text"
                          value={bankName}
                          onChange={(e) => {
                            setBankName(e.target.value);
                          }}
                          className="p-2 border"
                          style={{ width: "100%" }}
                          placeholder="Name of Your Bank"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="pt-5">
                        <h6 style={{ fontSize: "16px", fontWeight: "400" }}>
                          <Span> Beneficiary Name</Span>
                        </h6>
                        <input
                          type="text"
                          value={accHolder}
                          onChange={(e) => {
                            setAccHolder(e.target.value);
                          }}
                          className="p-2 border"
                          style={{ width: "100%" }}
                          placeholder="Name of Account Holder"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="pt-5">
                        <h6 style={{ fontSize: "16px", fontWeight: "400" }}>
                          <Span> Account Number</Span>
                        </h6>
                        <input
                          type="number"
                          value={accNumber}
                          onChange={(e) => {
                            if (e.target.value.length <= 16) {
                              setAccNumber(e.target.value);
                            }
                          }}
                          className="p-2 border"
                          style={{ width: "100%" }}
                          placeholder="Enter 16 Digit Account number"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="pt-5">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span> IFSC Code</Span>
                        </h6>
                        <input
                          type="text"
                          value={ifsc}
                          onInput={(e) => {
                            let txt = e.target.value.trim()
                            console.log(e.target.value);
                            if (txt.length <= 11) {
                              setIfsc(txt)
                            }
                          }}
                          className="p-2 border"
                          style={{ width: "100%" }}
                          placeholder="Enter 11 character IFSC Code"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="" style={{ paddingTop: "8%" }}>
                        <div class="d-grid gap-2">
                          <button
                            class="btn-btn p-2"
                            onClick={showDetails}
                            style={{
                              backgroundColor: "#701DDB",
                              borderRadius: "6px",
                              fontWeight: "600",
                              fontSize: "17px",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            type="button"
                          >
                            <Span>Add Account</Span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Bankform;
