import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import fad from "../assets/fadrailbank.png";
import WalletApiService from "../../services/api/WalletApiService";
import { ToastContainer, toast } from "react-toastify";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import Span from "../Translation/Translate";

const Withdraw = () => {
  const Navigate = useNavigate();
  const walletService = new WalletApiService();
  const [verifiedBanks, setVerifiedBanks] = useState([]);
  const [balance, setBalance] = useState();
  const [selectedBank, setSelectedBank] = useState({ _id: 0 });
  const [amount, setAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);

  function reloadData() {
    setIsLoading(true);
    walletService
      .getVerfiedBanks()
      .then((res) => {
        if (res?.status === 1) {
          console.log(res, "Verified Banks");
          setVerifiedBanks(res.banks);
        } else {
          toast("Error while fetching banks. Please Try Again Later");
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    walletService
      .getVerfiedBanks()
      .then((res) => {
        if (res?.status === 1) {
          console.log(res, "Verified Banks");
          setVerifiedBanks(res.banks);
        } else {
          toast("Error while fetching banks. Please Try Again Later");
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    walletService
      .getWalletBalance()
      .then((res) => {
        console.log(res);
        if (res?.status === 1) {
          setBalance(res.wallet);
        } else {
          toast("Could not fetch wallet balance");
        }
      })
      .catch((err) => toast(err.message));
  }, []);

  function askForConfirmation() {
    if(!amount == true) {
      toast("Please Enter specific amount")
      return
    }
    if (selectedBank._id === 0) {
      toast("Please Select A Verified Bank First");
      return;
    }

    if (!amount || amount < 1 || amount > balance) {
      toast("Enter A valid amount");
      return;
    }

    Navigate("/wallet/ear", {
      state: {
        bank: selectedBank,
        amount: amount,
      },
    });
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div>
          <div
            className="border"
            style={{ backgroundColor: "#6f42c1", color: "white" }}
          >
            <div className="container">
              <div className="row mt-2">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate(-1)}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  />
                </div>
                <div className="col-6  text-center">
                  <span
                    style={{ fontSize: "1.3rem", fontWeight: "700" }}
                    className="mb-0 "
                  >
                    <Span>Withdraw Money</Span>
                  </span>
                  <h6
                    style={{
                      fontWeight: "700",
                      fontSize: "10px",
                      color: "white",
                    }}
                  >
                   <span>Transaction fees</span> = ₹3% /<span>Transaction</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row pt-3">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    {/* <div className="col-1"></div> */}
                    <div className="col-12">
                      <div
                        className="border px-3"
                        style={{ borderRadius: "5px" }}
                      >
                        <h6
                          className="pt-3"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#8A8A8A",
                          }}
                        >
                          <Span>Total Redeemable Balance</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "700",
                            fontSize: "40px",
                            color: "#7E7E7E",
                          }}
                        >
                          ₹ {balance}
                        </h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6
                        className="pt-5"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#2E2E2E",
                        }}
                      >
                        <Span>Enter Amount</Span>
                      </h6>
                      <div className="w-100">
                        <input
                          type="number"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="p-1"
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            width: "100%",
                            border: "1px solid #EFEFEF",
                            borderRadius: "6px",
                            color: "#2E2E2E",
                          }}
                          placeholder="Enter Money To Withdraw"
                        />
                      </div>
                      <h6
                        className="pt-2"
                        style={{
                          color: "#7E7E7E",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        <Span>Entered amount should be less than or equal to</Span>{" "}
                        <span
                          style={{
                            color: "#D92828",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          {balance}
                        </span>
                      </h6>
                    </div>
                    <div
                      className=""
                      style={{ height: "45vh", overflowY: "auto" }}
                    >
                      <div className="col-12 pt-5">
                        <div className="row">
                          {isLoading ? (
                            <LoaderComponent></LoaderComponent>
                          ) : verifiedBanks.length <= 0 ? (
                            <>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <Span>Select Bank Account</Span>
                                  </span>
                                </div>
                                <div className="center-data justify-content-end">
                                  <button
                                    className="center-data btn1 rounded p-1 px-4 py-2"
                                    style={{
                                      fontSize: 15,
                                      backgroundColor: "#EDF7FF",
                                      padding: "8px 17px",
                                      border: "1px solid #2188E7",
                                      borderRadius: 10,
                                      color: "#2188E7",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => Navigate("/wallet/bankfo")}
                                  >
                                    <Span>+Add</Span>
                                  </button>
                                </div>
                              </div>
                              <EmptyContainer
                                message={
                                  "No Verified Bank Found. Wait Till Your Bank gets Verified"
                                }
                                action={reloadData}
                                actionName={"Reload"}
                              />
                            </>
                          ) : (
                            verifiedBanks.map((res) => {
                              return (
                                <div
                                  key={res._id}
                                  className="col-12 pb-3"
                                  onClick={() => {
                                    setSelectedBank(res);
                                  }}
                                >
                                  <div
                                    className="p-2"
                                    style={{
                                      borderRadius: "5px",
                                      border:
                                        res._id === selectedBank._id
                                          ? "2px solid green"
                                          : "1px solid gray",
                                    }}
                                  >
                                    <div className="d-flex justify-content-start">
                                      <img
                                        src={fad}
                                        alt=""
                                        style={{
                                          width: "40px",
                                          backgroundColor: "blue",
                                        }}
                                      />
                                      <h6
                                        className="pt-1"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "25px",
                                        }}
                                      >
                                        <Span>{res.bank_acc}</Span>
                                      </h6>
                                    </div>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <span style={{ color: "#7E7E7E" }}>
                                        <Span>Acc. Holder</Span>{" "}
                                      </span>
                                      <Span>{res.acc_holder_name}</Span>
                                    </h6>
                                    <div className="d-flex justify-content-between">
                                      <h6
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          <Span>Account No.</Span>{" "}
                                        </span>{" "}
                                        <Span>{res.bank_acc_no}</Span>
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        <span style={{ color: "#7E7E7E" }}>
                                          <Span>IFSC</Span>{" "}
                                        </span>
                                        <Span>{res.ifsc_code}</Span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <div class="d-grid gap-2">
                          <button
                            class="btn-btn p-2"
                            onClick={askForConfirmation}
                            style={{
                              backgroundColor: "#701DDB",
                              borderRadius: "6px",
                              fontWeight: "600",
                              fontSize: "17px",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            type="button"
                          >
                            <Span>Withdraw Now</Span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
