import React from 'react'
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";

const StudyGuide = () => {
    const Navigate = useNavigate();
    const subData = [
        {
            subject: "REET Grade 3 2017-18",
            totalQuestions: "Question paper",
            state: "Translation Enable",
        },
        {
            subject: "REET Grade 3 2017-18",
            totalQuestions: "Question paper",
            state: "Translation Enable",
        },
        {
            subject: "REET Grade 3 2017-18",
            totalQuestions: "Question paper",
            state: "Translation Enable",
        },
        {
            subject: "REET Grade 3 2017-18",
            totalQuestions: "Question paper",
            state: "Translation Enable",
        },
        {
            subject: "REET Grade 3 2017-18",
            totalQuestions: "Question paper",
            state: "Translation Enable",
        },
        {
            subject: "REET Grade 3 2017-18",
            totalQuestions: "Question paper",
            state: "Translation Enable",
        },

    ]
  return (
   <>
   <div className='d-flex flex-column' style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
   <div className='flex-grow-0'>
   <div className='border'>
       <div className='row mt-2 '>
           <div className='col-3'>

               <IoArrowBackOutline style={{ height: "5vh", marginLeft: "1rem", fontSize: "30px" }} onClick={() => {
                   Navigate('/pdf')
               }} />

           </div>
           <div className='col-6 text-center'>
               <h3 className=''>Study Guide</h3>
           </div>
           <div class="input-group mt-1 container">
               <span class="input-group-text searchbox" id="basic-addon1" style={{ borderRight: 'none', boxShadow: '0px 2px 4px 0px #4747470F', }}><CiSearch style={{ fontSize: "25px", }} /></span>
               <input type="text" class="form-control custom-input py-3" placeholder="Search for Exam" aria-label="Username" aria-describedby="basic-addon1"
                   style={{
                       borderLeft: 'none', boxShadow: 'none', boxShadow: '0px 2px 4px 0px #4747470F', fontWeight: "600"
                   }} />
           </div>
       </div>
   </div>


</div>
<div className='flex-grow-1' style={{ overflowY: "auto" }}>
                    <div className='container'>
                        <div className='row'>
                            {
                                subData?.map((res) => {
                                    return (
                                        <div className='col-sm-6 col-md-4 flex-grow-1'>
                                            <div className='border  mt-2 p-3 border'>
                                                <div>
                                                    <span
                                                        style={{
                                                            color: "#000",
                                                            fontWeight: 600,
                                                            fontStyle: "normal",
                                                            fontSize: "20px"

                                                        }}
                                                    >{res.subject}</span>
                                                </div>

                                                <div>
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                            fontStyle: "normal",
                                                            fontSize: "20px"
                                                        }}
                                                    >
                                                        {res.totalQuestions}
                                                    </span>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <div className='col-6'>
                                                        <p
                                                            style={{
                                                                margin: '0%',
                                                                color: "#4EC53B",

                                                                fontSize: "0.85rem",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                background: "#ECFFE9",
                                                                borderRadius: "10px",
                                                                width: "136px",
                                                            }} className='my-2 mb-2 p-1 px-2'
                                                        >
                                                            {res.state}
                                                        </p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mt-3' style={{ color: "#8A8A8A", fontWeight: "500" }}>12 sep 2022</p>
                                                    </div>
                                                </div>
                                                <div className='row d-flex  justify-content-center mt-2'>
                                                    <div className='col-6 mt-2'>
                                                        <button className='btnn p-2 text-center w-100' >
                                                            View
                                                        </button>
                                                    </div>

                                                    <div className='col-6 mt-2'>
                                                        <button className='btn1 p-2 text-center w-100' >
                                                            Downlode
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>  
   </div>
   </> 
  )
}

export default StudyGuide
