import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BasicServices } from '../../services/BasicServices';
import { GRAPHQL_URL } from '../../config/urls';

// Create an HTTP link to your GraphQL server
const httpLink = createHttpLink({
  uri: `${GRAPHQL_URL}/graphql`,
});

// Instantiate BasicServices
const basicServices = new BasicServices();

// Function to get the token from the brainbucks_object using BasicServices
const getToken = () => {
  const brainBucksObject = basicServices.getLocalObject();
  return brainBucksObject ? brainBucksObject.jwt : '';
};

// Use setContext to set the Authorization header for each request
const authLink = setContext((_, { headers }) => {
  // Get the token from the brainbucks_object
  const token = getToken();

  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    },
  };
});

// Create the Apollo Client instance
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
