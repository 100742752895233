import React, { useEffect, useState } from 'react';
import "../DownloadLink/downloadapk.css";
import { IoCloudDownloadOutline } from "react-icons/io5";
import Span from '../components/Translation/Translate';

const DownloadApk = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(()=>{
    const handleBeforeInstallPrompt=(event)=>{
      event.preventDefault();
      setDeferredPrompt(event);
    }

    window.addEventListener('beforeinstallprompt',handleBeforeInstallPrompt);

    return()=>{
      window.removeEventListener('beforeinstallprompt',handleBeforeInstallPrompt);
    }
  },[])

  const hanldeInstallClick = () => {
    console.log('clicked')
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log("then here")
        if (choiceResult.outcome === 'accepted') {
          console.log('user accepted the install prompt');
        } else {
          console.log('user accepted the install prompt');

        }
        setDeferredPrompt(null);
        console.log("confirmed")
      }) 
    }
    

  }
  return (
    <>
     <div className='dwd_color d-flex justify-content-center' style={{ height: "100vh", width: "100%" }}>
        <div className='d-flex align-items-center'>
       <span className='dwld_app rounded' onClick={hanldeInstallClick}><IoCloudDownloadOutline fontSize= "30px" /><Span>Download App</Span></span>
      
            </div>
        </div>
    </>
   
  )
}

export default DownloadApk