import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaHome, FaThumbsUp, FaComment, FaCheck, FaPlus } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import "../NewReels/ReelsView.css";
import ReactPlayer from "react-player";
import { IMAGE_URL, NOTIFY_URL } from "../../config/urls";
import { ToastContainer, toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaUserAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Form from "react-bootstrap/Form";
import { VscSend } from "react-icons/vsc";
import { RevolvingDot } from "react-loader-spinner";

const ReelsView = () => {
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();
  const [selectedTag, setSelectedTag] = useState([]);
  const [tagsname, setTagsname] = useState([]);
  const playerRefs = useRef([]); // Ref array for players
  const loaderRef = useRef(null); // Loader ref for infinite scroll
  const [videos, setVideos] = useState([]);
  const [seenReelsId, setSeenReelsId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(0); // Track which video is playing
  const [noMoreReels, setNoMoreReels] = useState(false);
  const videoContainerRef = useRef(null); // Container ref for scroll
  const [expandedCaptions, setExpandedCaptions] = useState([]);
  const [isMuted, setIsMuted] = useState(true); // Track mute status
  const [isLiked, setIsLiked] = useState({});
  const [show, setShow] = useState(false);
  const [userComment, setUserComment] = useState([]);
  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [addComment, setAddComment] = useState("");
  const location = useLocation();
  const homeReels = location.state?.homereel || {};
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for storing the error message

  const handleClose = () => {
    setShow(false);
    setIsCommentOpen(false); // Set comment section visibility to false
};
const handleShow = () => {
    setShow(true);
    setIsCommentOpen(true); // Set comment section visibility to true
};

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addCommentApi();
    }
  };

  const handleTagClick = (tag) => {
    if (selectedTag.includes(tag)) {
      setSelectedTag(selectedTag.filter((t) => t !== tag));
    } else {
      setSelectedTag([...selectedTag, tag]);
    }
  };

  const getTagName = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${NOTIFY_URL}participants/reels/get/tags`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        setTagsname(result?.tags);
      } else if (result?.status === "TOKEN_ERR") {
        basic.setLocalObject("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        toast(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRandomVideos = useCallback(async () => {
    if (isLoading || errorMessage) return; // Stop if already loading or if there's an error
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      tags: selectedTag,
      seen_reels: seenReelsId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${NOTIFY_URL}participants/reels/get/random/reels`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        if (result.msg === "Reels Not Found in selected tags") {
          setErrorMessage(result.msg); // Set error message
          setIsLoading(false); // Stop loading
          return; // Exit early
        }
        if (result.reel.length === 0) {
          if (noMoreReels) {
            setSeenReelsId([]); // Reset seenReelsId
            setVideos([]); // Reset videos
            setNoMoreReels(false); // Allow fetching new reels
          } else {
            setNoMoreReels(true); // No more reels to load
          }
        } else {
          setVideos((prevVideos) => [...prevVideos, ...result.reel]);
          setSeenReelsId((prevSeenReelsId) => [
            ...prevSeenReelsId,
            ...result.reel.map((reel) => reel._id),
          ]);
          const newLikedState = {};
          result.reel.forEach((reel) => {
            newLikedState[reel._id] = reel.liked === 1;
          });
          setIsLiked((prevLikedState) => ({
            ...prevLikedState,
            ...newLikedState,
          }));
        }
        setErrorMessage(""); // Clear error message if any
      } else if (result.status === "TOKEN_ERR") {
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        toast(result?.Backend_Error);
        console.log(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedTag, seenReelsId, token, Navigate, isLoading, noMoreReels, errorMessage]);


  const AddLike = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${NOTIFY_URL}participants/reels/add/like/to/reel?reel_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          setIsLiked((prevLikedVideos) => ({
            ...prevLikedVideos,
            [id]: !prevLikedVideos[id], // Toggle the like state
          }));
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const getComment = async (id) => {
    const commentId = id || selectedCommentId;
    if (!commentId) {
      console.error("No comment ID available");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${NOTIFY_URL}participants/reels/get/comments/of/reel?reel_id=${commentId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          setUserComment(result?.comments);
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteComment = async (comment) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      reel_id: selectedCommentId,
      comment: comment,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${NOTIFY_URL}participants/reels/delete/comments/of/reel`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        getComment();
      } else if (result?.status === "TOKEN_ERR") {
        basic.setLocalObject("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        toast(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addCommentApi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      reel_id: selectedCommentId,
      comment: addComment,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${NOTIFY_URL}participants/reels/add/comments/to/reel`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        getComment();
        setAddComment("");
      } else if (result?.status === "TOKEN_ERR") {
        basic.setLocalObject("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        toast(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTagName(); 
  }, []);

  useEffect(() => {
    if (homeReels?._id) {
      setVideos([homeReels]);
    } else if (!errorMessage) {
      getRandomVideos();
    }
  }, []);
  

  useEffect(() => {
    if (errorMessage) return; // Stop observing if there's an error message

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.dataset.index);
          if (entry.isIntersecting) {
            setPlayingIndex(index);
          }
        });

        // Check if the loader is intersecting to load more videos
        if  (
          entries.some(
            (entry) =>
              entry.target === loaderRef.current && entry.isIntersecting
          )
        ) {
          getRandomVideos();
        }
      },
      { threshold: 0.5 }
    );

    const videoElements = document.querySelectorAll(".video-container_reel");
    videoElements.forEach((element) => {
      observer.observe(element);
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      videoElements.forEach((element) => {
        observer.unobserve(element);
      });

      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [videos, getRandomVideos, errorMessage]);

  
  

  const handleEnded = () => {
    if (playingIndex < videos.length - 1 && isCommentOpen ) {
      const nextVideoElement = document.querySelector(
        `.video-container_reel[data-index="${playingIndex + 1}"]`
      );
      if (nextVideoElement) {
        nextVideoElement.scrollIntoView({ behavior: "smooth" });
      }
      setPlayingIndex(playingIndex + 1);
    }
  };

  const handleVolumeChange = (index, volume) => {
    if (volume > 0) {
      setIsMuted(false);
    }
  };

  const handleReadMoreToggle = (index) => {
    setExpandedCaptions((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

   // Add useEffect to refetch videos when selectedTag changes
   useEffect(() => {
    setVideos([]); // Clear existing videos
    setSeenReelsId([]); // Clear seen reels
    setNoMoreReels(false); // Reset noMoreReels state
    setErrorMessage(""); // Clear error message
    getRandomVideos(); // Fetch new videos
  }, [selectedTag]);
  

  return (
    <>
      <ToastContainer />
      <div
        className="d-flex flex-column"
        style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
      >
        {/* header */}
        <div
          className="top_rel_head flex-grow-0 pt-2 pb-2 px-4 d-flex justify-content-between align-items-center"
          style={{ backgroundColor: "#6f42c1" }}
        >
          <IoMdArrowRoundBack
            onClick={() => Navigate("/")}
            style={{ fontSize: "35px", cursor: "pointer", color: "white" }}
            className="top_icons_reel"
          />
          <div className="tags_whole">
            {tagsname.map((res, key) => (
              <div className="contain_tags_text" key={key}>
                <span
                  className="tags_text_slide mx-1"
                  onClick={() => handleTagClick(res?._id)}
                >
                  {selectedTag.includes(res?._id) ? (
                    <FaCheck style={{ fontWeight: "800" }} />
                  ) : (
                    <FaPlus />
                  )}
                  <span className="ms-1">{res?.tag_name}</span>
                </span>
              </div>
            ))}
          </div>
          <FaHome
            style={{ fontSize: "35px", color: "white" }}
            onClick={() => Navigate("/")}
            className="top_icons_reel"
          />
        </div>

        {/* main */}
        <div className="bot_rel_botom" style={{ overflowY: "auto", overflowX: "hidden" }}>
          <div className="row">
            <div
              className="col-12 flex-grow-1 d-flex flex-column"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              { errorMessage ? (
                <div className="error-message-card">
                <div className="error-message-content">
                  <h2>Oops!</h2>
                  <p>{errorMessage}</p>
                </div>
              </div>
              ) : (
              videos?.map((video, index) => (
                <div
                  key={index}
                  className="video-container_reel my-2 "
                  data-index={index}
                  style={{ position: "relative" }}
                >
                  <ReactPlayer
                    ref={(ref) => (playerRefs.current[index] = ref)}
                    url={video?.blobName}
                    playing={playingIndex === index}
                    controls={true}
                    loop = {true}
                    muted={isMuted}
                    width="fit-content"
                    height="90vh"
                    className="video-player"
                    onVolumeChange={(e) =>
                      handleVolumeChange(index, e.target.volume)
                    }
                    onEnded={handleEnded}
                    config={{
                      file: {
                        forceHLS: true,
                        attributes: {
                          accept: "video/*",
                        },
                      },
                    }}
                  />

                  <div className="overlay_reel">
                    <div className="video-details-left">
                      <div className="d-flex caption_reel">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#fff",
                            fontSize: "14px",
                          }}
                        >
                          <img
                            src={`${IMAGE_URL}${video?.banner}`}
                            alt=""
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: "8px",
                              borderRadius: "50%",
                            }}
                          />
                          {video?.name}
                        </span>
                      </div>
                      <div className="tag_reel">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#fff",
                            fontSize: "14px",
                            display: "block",
                            whiteSpace: expandedCaptions[index]
                              ? "normal"
                              : "nowrap",
                            overflow: expandedCaptions[index]
                              ? "visible"
                              : "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {video?.caption}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#fff",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleReadMoreToggle(index)}
                        >
                          {expandedCaptions[index] ? "Read less" : "Read more"}
                        </span>
                      </div>
                      <div className="tag_reel">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#fff",
                            fontSize: "14px",
                          }}
                        >
                          {video.tags}
                        </span>
                      </div>
                    </div>
                    <div className="video-details-right">
                      <FaThumbsUp
                        className="icon_like"
                        style={{
                          color: isLiked[video?._id] ? "blue" : "white",
                        }}
                        onClick={() => AddLike(video?._id)}
                      />
                      <span style={{ fontWeight: "bold", color: "#fff" }}>
                        {video?.likes}
                      </span>{" "}
                      <FaComment
                        className="icon_comment"
                        onClick={() => {
                          setSelectedCommentId(video?._id);
                          getComment(video?._id);
                          handleShow();
                        }}
                      />
                      <span style={{ fontWeight: "bold", color: "#fff" }}>
                        {video?.commentsCount}
                      </span>
                    </div>
                  </div>
                </div>
              )))}
              <div ref={loaderRef} style={{ height: "120px" }}>
                {isLoading && 
             <RevolvingDot />
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Comment offcanvas start here */}
      <Offcanvas
        show={show}
        onHide={() => {
          handleClose();
          setAddComment("");
        }}
        style={{ height: "70%" }}
        placement="bottom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          className="d-flex flex-column"
          style={{ height: "100%" }}
        >
          <div className="flex-grow-1 overflow-auto">
            {userComment?.map((res, key) => {
              return (
                <>
                  <div key={key}>
                    <div
                      className=""
                      style={{ fontSize: "15px", fontWeight: "600" }}
                      
                    >
                      {res?.image === null ? (
                        <FaUserAlt
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                            borderRadius: "50%",
                            backgroundColor: "grey",
                          }}
                        />
                      ) : (
                        <img
                          src={`${IMAGE_URL}${res?.image}`}
                          alt=""
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "8px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {res.name}
                    </div>
                    <div
                      className="d-flex flex-column mt-1 mb-3"
                      style={{ fontSize: "20px", lineHeight: "normal" }}
                    >
                      <div
                        className="d-flex align-items-start"
                        style={{ width: "100%" }}
                      >
                        <div style={{ flex: 1 }}>{res?.comment}</div>
                        {res?.is_deletable && (
                          <div
                            className=""
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={() => {
                              deleteComment(res?.comment);
                            }}
                          >
                            <MdDelete />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="flex-grow-0">
            <Form>
              <Form.Group
                className="d-flex align-items-center"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  style={{
                    borderColor: "GrayText",
                    borderRadius: "0",
                    // borderTopLeftRadius: '4px',
                    // borderBottomLeftRadius: '4px',
                    marginRight: "8px",
                  }}
                  value={addComment}
                  onChange={(e) => setAddComment(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <div
                  className="rounded"
                  style={{
                    backgroundColor: "grey",
                    padding: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                  onClick={addCommentApi}
                >
                  <VscSend style={{ fontSize: "30px", color: "white" }} />
                </div>
              </Form.Group>
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Comment offcanvas end here */}
    </>
  );
};

export default ReelsView;
