import React, { useEffect, useRef, useState } from 'react'
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from "../utils/LoaderComponent";
import { Carousel } from 'react-bootstrap';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Span from '../Translation/Translate';
import image3 from "../assets/image4.gif";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { LuTicket } from "react-icons/lu";
import coin from "../assets/coin.png";
import { FaRegClock } from "react-icons/fa6";
import ProgressBar from "@ramonak/react-progress-bar";

const HomeEnrolledQuiz = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [enroll, setenroll] = useState([]);
  const page = useRef(1);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();

  const getHomeEnrolledQuiz = async () => {
    try {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${QUIZMICRO}/home/get/enrolled/quizes?page=1&limit=10`,
        requestOptions
      );
      const result = await response.json();
      setIsLoading(false);
      if (result?.status == 1) {
        setenroll(result?.enrolled_quizes);



      } else if (result.status === "TOKEN_ERR") {
        basic.setJwt("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getHomeEnrolledQuiz();
  }, [])

  return (
    <>
       <div className=" col-lg-12 col-md-12 col-sm-12">
        <div className=" d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center">
            <h6 className="txt">
              <Span>Enrolled Quiz</Span>
            </h6>
          </div>
          <div className="">
            <span
              className="see"
              style={{ cursor: "pointer" }}
              onClick={() => {
                Navigate("/enroll");
              }}
            >
              <Span>See All</Span>
            </span>
          </div>
        </div>


        <div className=" col-lg-12 col-md-12 col-sm-12 card boxshadow">
          <Carousel className="custom-carousel">
            {enroll?.length === 0 && !isLoading ? (
              <EmptyContainer
                actionName="Reload"
                action={() => getHomeEnrolledQuiz(page.current)}
                message="No Data Found"
              />
            ) : (
              enroll?.map((res) => {
                const [datepart2, timepart2] = res.sch_time.split(" ");
                return (
                  <Carousel.Item>
                    <div
                      className=" "
                      onClick={() =>
                        Navigate(`/card/current/${res._id}/${res?.prize}`)
                      }
                    >
                      <div className="mt-3" style={{ cursor: "pointer" }}>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="boxshadow"
                            style={{
                              borderRadius: "50%",
                              width: "50px",
                              height: "50px",
                            }}
                          >
                            <img
                              src={IMAGE_URL + res.banner}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt=''
                            />
                          </div>
                          <h6 className="some-txt">
                            <Span>{res?.quiz_name}</Span>
                          </h6>
                        </div>
                        <div className="mt-2 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <h6 className="txx">
                              <Span>Fees</Span>
                            </h6>
                            <div className="d-flex gap-2">
                              <img
                                src={coin}
                                style={{ height: "3vh" }}
                                alt="Coin"
                              />
                              <h6 className="new-txt">
                                {res?.entryFees}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <MdOutlineCalendarMonth
                              className="txx"
                              style={{ fontSize: "20px" }}
                            />
                            <span className="txx">{datepart2}</span>
                          </div>
                        </div>
                        <div className="mt-2 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <h6 className="txx">
                              <Span>Prize</Span>
                            </h6>
                            <div className="d-flex gap-2">
                              <img
                                src={coin}
                                style={{ height: "3vh" }}
                                alt="Coin"
                              />
                              <h6 className="new-txt">{res?.prize}</h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <FaRegClock
                              className="txx"
                              style={{ fontSize: "18px" }}
                            />
                            <span className="txx">{timepart2}</span>
                          </div>
                        </div>
                        <div className="mt-2 d-flex align-items-center gap-2 ms-1">
                          <div>
                            <LuTicket style={{ fontSize: "3vh" }} />
                          </div>
                          <div className="d-flex mt-2">
                            <h6 className="alpha">{res?.slot_aloted}</h6>
                            <h6>/{res?.slots}</h6>
                          </div>
                        </div>
                        <div className="mt-2">
                          <ProgressBar
                            completed={res?.slot_aloted}
                            bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                            height="10px"
                            isLabelVisible={false}
                            baseBgColor="#E4E9EC"
                            labelColor="#e80909"
                            animateOnRender
                            maxCompleted={res?.slots}
                          />
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-center">
                        <button
                          className="registerButtonClass p-2 text-center w-100"
                          onClick={() =>
                            Navigate(
                              `/card/current/${res._id}/${res?.prize}`
                            )
                          }
                        >
                          <Span>Join Now</Span>
                        </button>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })
            )}{" "}
            {isLoading && <LoaderComponent />}
            {/* Add more Carousel.Item here for other cards */}
          </Carousel>
        </div>

        {/* ------------------------------------------------------------------- */}
      </div>

    </>
  )
}

export default HomeEnrolledQuiz