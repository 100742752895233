import React, { useContext, useEffect, useRef, useState } from 'react'
import { IoIosArrowForward, IoIosSearch, IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Span from '../Translation/Translate';
import MyExamsCard from '../Saved/MyExamsCard';
import Bottom from '../Home/Bottom';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Sidebar from '../Home/Sidebar';
import LoaderComponent from '../utils/LoaderComponent';
import { FaCheck } from 'react-icons/fa';
import { IMAGE_URL, NOTIFY_URL, QUIZMICRO } from '../../config/urls';
import EmptyContainer from '../utils/EmptyContainer';
import downarrow from "../assets/icons/arrow-down.svg";
import { BasicServices } from '../../services/BasicServices';
import { Context } from "../..";

const ReelTagData = () => {
    const [Click, setClick] = useState(0);
    const [isSelected, setIsSelected] = useState(false);
    
    const Navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [catname, setCatname] = useState([]);
    const [ExamId, setExamId] = useState([""]);
    const [selectQuizzId, setSelectQuizzId] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
  
    const page = useRef(1)
    const [selectedExams, setSelectedExams] = useState([]); // Initialize an empty array
  
    const handleClick = (res) => {
      const isSelected = selectedExams.includes(res._id); // Check if already selected
  
      setSelectedExams(
        isSelected
          ? selectedExams.filter((id) => id !== res._id)
          : [...selectedExams, res._id]
      );
    };

    const [card1, setcard1] = useState([]);
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt;
  
    const NotSaved = async () => {
        try {
            setIsLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(`${NOTIFY_URL}/participants/reels/get/new/tag`, requestOptions);
            const result = await response.json();
            setIsLoading(false);

            if (result?.status === 1) {
                setCatname(result.tags); // Assuming the response structure has tags
            } else if (result.status === "VAL_ERR") {
                toast.error(result.Backend_Error);
            } else if (result.status === "CUSTOM_ERR") {
                toast.error(result.Backend_Error);
            } 
            else if (result.status === "TOKEN_ERR") {
                basic.setJwt("");
                Navigate("/Register/");
                toast("Logged in on another device! Please log in again");
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred while fetching the data.");
            setIsLoading(false);
        }
    };
    console.log("hgfds", catname);
  
    const getSaved = async (_id) => {
        try {
            setIsLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(
                `${NOTIFY_URL}/participants/reels/get/aloted/tag`,
                requestOptions
            );
            const result = await response.json();
            setIsLoading(false);

            if (result?.status === 1) {
                setcard1(result.tags);
            } else if (result.VAL_ERR) {
                toast.error("Failed to retrieve departments");
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const addExam = async () => {
      try {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${token}`);

          const raw = JSON.stringify({
              "tag_id": selectedExams
          });

          const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
          };

          const response = await fetch(`${NOTIFY_URL}/participants/reels/add/tag`, requestOptions);
          const result = await response.json();

          if (result?.status === 1) {
              toast.success("Exam added successfully");
              getSaved();
              handleClose()
          } else {
              toast.error("Failed to add exam");
          }
      } catch (error) {
          console.error(error);
          toast.error("An error occurred while adding the exam.");
      }
  };
  
    const filteredQuizzes = catname.filter(res => 
        res.tagName && res.tagName.toLowerCase().includes(searchQuery.toLowerCase())
    );
            
    useEffect(() => {
        getSaved();
    }, []);

    console.log(card1, "nghfbdsa");
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div style={{ backgroundColor: "#6f42c1" }}>
                <div className="container">
                    <div className="row pt-3 d-flex  align-items-center" style={{ height: "10vh" }}>
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <IoMdArrowRoundBack onClick={() => Navigate("/")} style={{ fontSize: "22px", cursor: "pointer", color: "white" }} />
                        </div>
                        <div className="col-6 text-center">
                            <span style={{ fontSize: "1.5rem", fontWeight: "700", color: "white" }}
                                className="mb-0 heading " ><Span>Reels Tag</Span> </span>
                        </div>
                        <div className="col-3  d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn addbtn btn-light "
                                onClick={() => {
                                    NotSaved();
                                    handleShow();
                                }}
                            >
                                + <Span>Add</Span>
                            </button>
                        </div>

                        <Offcanvas  show={show} onHide={handleClose} placement="bottom"  style={{height:"70vh"}}>
                            <Offcanvas.Header>
                                <Offcanvas.Title
                                    className="d-flex justify-content-center mx-auto "
                                    onClick={handleClose}
                                >
                                    <div
                                        style={{
                                            width: "50px",
                                            height: "2px",
                                            background: "#E1E1E1",
                                        }}
                                    ></div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="pt-3" style={{ overflowY: "auto" }}>
                                <div className="d-flex flex-column ">
                                    <div className="flex-grow-0 ">
                                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                            <button disabled style={{ opacity: 0 }} className="save">Save</button>
                                            <h3><Span>Select Exams</Span> </h3>
                                            <button className="save"
                                            onClick={addExam}
                                            >
                                            Save
                                            </button>
                                        </div>

                                        <div className="px-4">
                                            <div className="center-data pt-2 flex-grow-0">
                                                <div
                                                    className="row align-items-center mx-1 "
                                                    style={{
                                                        backgroundColor: "#EFEFEF",
                                                        border: "none",
                                                        boxShadow: "4px 4px 4px #EFEFEF",
                                                        height: "50px",
                                                        borderRadius: "7px",
                                                        width: "500px",
                                                    }}
                                                >
                                                    <div className="col-3 d-flex justify-content-center mt-1">
                                                        <IoIosSearch color="#7E7E7E" size={24} />
                                                    </div>
                                                    <div className="col-9 mt-1" style={{ marginLeft: "-30px" }}>
                                                        <input
                                                            className="border-0 w-100"
                                                            placeholder="Search for Exams"
                                                            style={{
                                                                textDecoration: "none",
                                                                outline: "none",
                                                                backgroundColor: "#EFEFEF",
                                                                color: "#272727",
                                                            }}
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 " style={{ overflowY: "auto", overflowX: "hidden" }}>
                                        <div className="row pb-5 ">
                                            {(catname?.length === 0 && !isLoading) ? (
                                                <EmptyContainer
                                                    actionName="Reload"
                                                    action={() => NotSaved(page.current)}
                                                    message="No Data Found"
                                                />
                                            ) :
                                                catname?.map((res, key) => {
                                                    return (
                                                        <>
                                                            <div className="col-12 px-3 pt-2" key={key}>
                                                                <div className="border p-2 d-flex rounded ">
                                                                    <div className="flex-grow-1 d-flex gap-3">
                                                                        <div className="center-data notsaved flex-grow-0">
                                                                            <img src={IMAGE_URL + res.banner} alt="cat_image" style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                                borderRadius: '50%',
                                                                            }}></img>
                                                                        </div>
                                                                        <div className="flex-grow-1 center-y t2">
                                                                            <Span>{res?.tag_name}</Span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex-grow-0 center-data">
                                                                        <div
                                                                            onClick={() => handleClick(res)}
                                                                            className="px-3 py-0"
                                                                            style={{
                                                                                color: "#7E7E7E",
                                                                                backgroundColor:
                                                                                    selectedExams.includes(res._id)
                                                                                        ? "#2188E7"
                                                                                        : "#EFEFEF",
                                                                                fontSize: "2rem",
                                                                                borderRadius: "100%",
                                                                                border: "none",
                                                                                outline: 0,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {selectedExams.includes(res._id) ? (
                                                                                <FaCheck size={17} color="white" />
                                                                            ) : (
                                                                                <>+</>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </div>
            </div>
            <hr />

            <div className="flex-grow-1 container example " style={{ overflowY: "auto", overflowX: "hidden" }}>
                <div className="row pb-4 pt-2">
                    {(card1?.length === 0 && !isLoading) ? (
                        <EmptyContainer
                            actionName="Reload"
                            action={() => getSaved(page.current)}
                            message="No Data Found"
                        />
                    ) :
                        card1?.map((res, value) => {
                            return (
                                <>
                                    <div className="col-12 col-sm-6 col-md-12 p-2 d-flex " key={value}>
                                        <div
                                            className=" mx-3 py-4 d-flex rounded boxshadow card"
                                            onClick={() =>
                                                           { 
                                                            Navigate(`/reel/${res?._id}`)
                                               }}
                                            style={{ lineHeight: "1px", cursor: "pointer" }}
                                        >
                                            <div className=" d-flex gap-3 ">
                                                <div className=" flex-grow-0 notsaved">
                                                    <img src={IMAGE_URL + res.banner} style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '50%',
                                                    }}></img>
                                                </div>
                                                <div className="flex-grow-1 center-y t2">
                                                    <Span>{res.tag_name}</Span>
                                                
                                                </div>
                                                <div>
                                                    <IoIosArrowForward style={{ fontSize: "25px" }}  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    {isLoading && <LoaderComponent />}
                </div>
            </div>
            <Sidebar />
        </>
    )
}

export default ReelTagData
