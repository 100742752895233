import React, { useEffect, useState } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom';
import image4 from '../assets/image5.png';
import { GoDotFill } from "react-icons/go";
import { BasicServices } from '../../services/BasicServices';
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import { toast } from 'react-toastify';
import LoaderComponent from '../utils/LoaderComponent';
import Span from "../Translation/Translate";




const Participate = () => {

    const { id } = useParams();
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt;
    const Navigate = useNavigate();

    const [quizData, setQuizData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [datePart, timePart] = quizData?.sch_time?.split(" ");
    const getData = async () => {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
  
      fetch(
        `${QUIZMICRO}/participants/view/detail/of/trivia/quiz?id=${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
          if (result?.status === 1) {
            setQuizData(result?.data);
          } else if (result.status === "TOKEN_ERR") {
            // localStorage.removeItem("api_url");
            basic.setJwt("")

            Navigate("/Register");
            toast("Logged in other device! Please login again");
          } else {
            console.log(result?.Backend_Error);
          }
        })
        .catch((error) => console.error(error));
    };
  
  console.log("sdsa", quizData);

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
    {isLoading ? 
      <LoaderComponent />
     : 
     
       <div style={{ height: "100vh", width: "100vw" }}>
                <div className='border' style={{ height: "10vh",backgroundColor:"#6f42c1",color:"white" }} >
                    <div className='container'>
                        <div className='row mt-3'>
                            <div className='col-2 d-flex justify-content-center align-items-center' >
                                <IoArrowBack style={{ fontSize: "4vh" , cursor:"pointer"}} onClick={() => { Navigate("/free/trivia") }} />
                            </div>
                            <div className='col-8  text-center'>
                                <span style={{ fontSize: "1.2rem", fontWeight: "700" }} className="mb-0">
                                   <Span>Free Trivia Details</Span> 
                                </span>
                            </div>
                            <div className='col-2' >

                            </div>
                        </div>


                    </div>
                </div>
                <div style={{ height: "78vh", overflowY: "scroll" }}>
                    <div className='container'>
                        <div className=' mt-1 p-2' style={{ cursor: "pointer" }} onClick={() => { Navigate("/card/current") }}>
                            <span className='crd'><Span>Name of Exam</Span> </span>
                            <div className='d-flex align-items-center gap-2'>
                            <div
                                    style={{
                                      borderRadius: "50%",
                                      width: "50px",
                                      height:"50px",
                                      border: "1px solid #808080",
                                    }}
                                  >
                                    <img
                                      src={`${IMAGE_URL}${quizData?.image}`} 
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                <h6 className='some-txt'><Span>{quizData?.quiz_name}</Span> </h6>
                            </div>
                            

                        </div>
                        <hr></hr>

                        <div className='d-flex gap-1'>
                            <div>
                                <span className='question'><Span>Number of Questions </Span> : </span>
                            </div>
                            
                            <div>
                                <span className='question'>{quizData?.total_num_of_quest}</span>
                            </div>
                            
                        </div>
                       
                        {/* <div className='d-flex gap-1'>
                            <div>
                                <span className='question'><Span>Date </Span> : </span>
                            </div>
                            
                            <div>
                                <span className='question'>{datePart}</span>
                            </div>
                            <div>
                                <span className='question' style={{marginLeft: "20px"}}><Span>Time </Span> : </span>
                            </div>
                            
                            <div>
                                <span className='question'>{timePart}</span>
                            </div>
                        </div> */}

                        <div className='d-flex gap-1 mt-1'>
                            <div>
                                <span className='question'><Span>Time for Each Question</Span>    : </span>
                            </div>
                            <div>
                                <span className='question'>{quizData?.time_per_question} <Span>sec</Span></span>
                            </div>
                        </div>
                        <div className='d-flex gap-1 mt-1'>
                            <div>
                                <span className='question'><Span>Reward</Span>    : </span>
                            </div>
                            <div>
                                <span className='question'>{quizData?.reward} <Span></Span></span>
                            </div>
                        </div>

                        <hr></hr>

                        <div className=''>
                            <span className='question'><Span>Subjects</Span> </span>
                            {
                                quizData?.subjects?.map((res)=>{
                                    return(<>
                                    
                                    <div className='d-flex gap-2 align-items-center p-2 mt-1'>
                                <GoDotFill />
                                <span className='question'><Span>{res?.name}</Span> </span>
                                </div>
                                    
                                    
                                    </>)
                                })
                            }
                        </div>

                        <hr></hr>

                        <div>
                            <span className='question'><Span>General Rules Of Participation</Span> </span>
                            <div className=' container mt-2 d-flex flex-column gap-3 rule'>
                            {quizData?.rules?.map((res, index) => {
                              return (
                                <span>
                                  {index + 1}.<Span>{res}</Span> 
                                </span>
                              );
                            })}
                            </div>
                        </div>
                    </div>
                </div>


                <div className='border' style={{ height: "12vh" }}>
                <button className='agreestartButtonClass p-2 text-center w-100' onClick={()=>{Navigate(`/StartQuiz/${id}/trivia`)}}>
                   <Span>Agree & Start</Span> 
                      </button>
                </div>
            </div>
}
   </>
  )
}

export default Participate
