import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { BsFillPatchCheckFill } from "react-icons/bs";
import '../Wallet/Wallet.css'
import { FaLongArrowAltUp } from "react-icons/fa";
import { useNavigate, NavLink } from "react-router-dom";

import WalletApiService from "../../services/api/WalletApiService";
import { CgCloseO } from "react-icons/cg";
import { Context } from "../..";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";
import { toast } from "react-toastify";

const TransactionHistory = () => {
  const { handleShow } = useContext(Context);
  const navigate = useNavigate();
  const walletService = new WalletApiService();
  const [walletData, setWalletData] = useState({});
  const [wallethistory, setWallethistory] = useState([]);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const Navigate = useNavigate()
  const [page, setPage] = useState(1); // Initialize page state
  const [totalPages, setTotalPages] = useState(1);
  const observer = useRef(); // Create a ref for the observer

  // Use useCallback to create the observer and handle pagination
  const lastTransactionElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting ) {
        setPage(prevPage => prevPage + 1); // Increment page number when the last element is visible
      }
    });
    if (node) observer.current.observe(node); // Observe the last transaction element
  }, []);



 useEffect(() => {
    if (page <= totalPages) {
      walletService.getTransactions(page).then((res) => {
        if (res?.status === 1) {
          setWalletData(res);
          setWallethistory(prevHistory => [...prevHistory, ...res.transactions]); // Append new transactions to the existing state
          setTotalPages(res?.totalPages);
          console.log(res.transactions, "Wallet data");
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log("Error While Retrieving Transactions", res);
        }
      });
    }
  }, [page]); // Re-run effect when page changes


  


  return (
    <div>
    <div style={{backgroundColor:"#6f42c1",color:"white"}}>
    
    <div className="col-lg-12 col-md-12 col-lg-12 d-flex p-3"  >
      <div className="col-3" style={{ display: "flex", alignItems: "center" }}>

        <FaArrowLeftLong onClick={() => { Navigate(-1) }}
          style={{ display: "flex", alignItems: "center", cursor:"pointer" }}
        />


      </div>
     <div className="col-6">
      <h1
        style={{
          alignItems: "center",
          marginLeft: 27,
          fontFamily: "Work Sans",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "28.15px",
          textAlign: "center",
          color:"white",
          cursor:"pointer"
        }}
      >
       <Span>Transaction History</Span> 
      </h1>
      </div>
    </div>
    </div>
      <div className="container">
        <div className="row mt-3">
        </div>

        {wallethistory.length > 0 ? (
          wallethistory.map((item, index) => {
            // Attach ref to the last element
            if (index === wallethistory.length - 1) {
              return (
                <div
                  ref={lastTransactionElementRef} // Attach the ref to the last element
                  style={{ padding: 10, cursor: "pointer" }}
                  onClick={() => {
                    switch (item.success) {
                      case 0:
                        Navigate(`/failed/${item?._id}`, { state: { ...item } });
                        break;
                      case 1:
                        Navigate(`/success/${item?._id}`, { state: { ...item } });
                        break;
                      case -1:
                        Navigate(`/pending/${item?._id}`, { state: { ...item } });
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="card-container">
                        <div>
                          {item.success === 1 ? (
                            <div className="icon-wrapper">
                              {item.type === "debit" ? <FaLongArrowAltDown style={{ color: 'green' }} /> : <FaLongArrowAltUp style={{ color: 'green' }} />}
                            </div>
                          ) : item.success === -1 ? (
                            <div className="icon-wrapper1" style={{ backgroundColor: 'rgba(255, 165, 0,0.2)' }}>
                              {item.type === "debit" ? <FaLongArrowAltDown style={{ color: 'orange' }} /> : <FaLongArrowAltUp style={{ color: 'orange' }} />}
                            </div>
                          ) : (
                            <div className="icon-wrapper" style={{ backgroundColor: 'rgba(255, 239, 239, 1)' }}>
                              {item.type === "debit" ? <FaLongArrowAltDown style={{ color: 'red' }} /> : <FaLongArrowAltUp style={{ color: 'red' }} />}
                            </div>
                          )}
                        </div>
                        <div className="content-wrapper">
                          <h1 className="title">₹ {item.amount}</h1>
                          <p className="subtitle">{item.order_datetime}</p>
                        </div>
                        {item.success === 1 ? (
                          <div className="status-wrapper">
                            <div className="status-icon">
                              <BsFillPatchCheckFill style={{ color: 'rgba(18, 156, 115, 1)' }} />
                            </div>
                            <h1 className="status-title mt-2" style={{ color: 'rgba(18, 156, 115, 1)' }}><span>Success</span></h1>
                          </div>
                        ) : item.success === -1 ? (
                          <div className="status-wrapper">
                            <div className="status-icon">
                              <BsFillPatchCheckFill style={{ color: 'orange' }} />
                            </div>
                            <h1 className="status-title mt-2" style={{ color: 'orange' }}><span>Pending</span></h1>
                          </div>
                        ) : (
                          <div className="status-wrapper">
                            <div className="status-icon">
                              <CgCloseO style={{ color: 'red' }} />
                            </div>
                            <h1 className="status-title mt-2" style={{ color: 'red' }}><span>Failed</span></h1>
                          </div>
                        )}
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{ padding: 10, cursor: "pointer" }}
                  onClick={() => {
                    switch (item.success) {
                      case 0:
                        Navigate(`/failed/${item?._id}`, { state: { ...item } });
                        break;
                      case 1:
                        Navigate(`/success/${item?._id}`, { state: { ...item } });
                        break;
                      case -1:
                        Navigate(`/pending/${item?._id}`, { state: { ...item } });
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="card-container">
                        <div>
                          {item.success === 1 ? (
                            <div className="icon-wrapper">
                              {item.type === "debit" ? <FaLongArrowAltDown style={{ color: 'green' }} /> : <FaLongArrowAltUp style={{ color: 'green' }} />}
                            </div>
                          ) : item.success === -1 ? (
                            <div className="icon-wrapper1" style={{ backgroundColor: 'rgba(255, 165, 0,0.2)' }}>
                              {item.type === "debit" ? <FaLongArrowAltDown style={{ color: 'orange' }} /> : <FaLongArrowAltUp style={{ color: 'orange' }} />}
                            </div>
                          ) : (
                            <div className="icon-wrapper" style={{ backgroundColor: 'rgba(255, 239, 239, 1)' }}>
                              {item.type === "debit" ? <FaLongArrowAltDown style={{ color: 'red' }} /> : <FaLongArrowAltUp style={{ color: 'red' }} />}
                            </div>
                          )}
                        </div>
                        <div className="content-wrapper">
                          <h1 className="title">₹ {item.amount}</h1>
                          <p className="subtitle">{item.order_datetime}</p>
                        </div>
                        {item.success === 1 ? (
                          <div className="status-wrapper">
                            <div className="status-icon">
                              <BsFillPatchCheckFill style={{ color: 'rgba(18, 156, 115, 1)' }} />
                            </div>
                            <h1 className="status-title mt-2" style={{ color: 'rgba(18, 156, 115, 1)' }}><span>Success</span></h1>
                          </div>
                        ) : item.success === -1 ? (
                          <div className="status-wrapper">
                            <div className="status-icon">
                              <BsFillPatchCheckFill style={{ color: 'orange' }} />
                            </div>
                            <h1 className="status-title mt-2" style={{ color: 'orange' }}><span>Pending</span></h1>
                          </div>
                        ) : (
                          <div className="status-wrapper">
                            <div className="status-icon">
                              <CgCloseO style={{ color: 'red' }} />
                            </div>
                            <h1 className="status-title mt-2" style={{ color: 'red' }}><span>Failed</span></h1>
                          </div>
                        )}
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div className="row mt-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="justify-content-center">
                <p style={{ textAlign: 'center' }}><span>No wallet history data available</span></p>
              </div>
            </div>
          </div>
        )}


      </div>
    </div>
  );
};

export default TransactionHistory;
