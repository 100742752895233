import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { useQuery } from "@apollo/client";
import { Get_PublicRoom } from "../ApolloGraphSql/Queries";
import { GRAPHQL_URL } from "../../config/urls";
import { BasicServices } from "../../services/BasicServices";
import { ToastContainer, toast } from "react-toastify";
import Animation_sent from "../assets/Animation_sent.json";
import Lottie from "lottie-react";
import { RevolvingDot, RotatingTriangles } from "react-loader-spinner";
import { logDOM } from "@testing-library/react";

const Explore = () => {
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const [searchExplore, setSearchExplore] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [quizzes, setQuizzes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [joinId, setjoinId] = useState("");
  const [showLottie, setShowLottie] = useState(false);
  const { loading, error, data, refetch } = useQuery(Get_PublicRoom, {
    variables: { page: 1, search: "" },
  });

  const useIntersectionObserver = (options, cb) => {
    const observer = React.useRef(null);

    return React.useCallback((node) => {
      if (!node) {
        if (observer.current) {
          observer.current.disconnect();
        }
        return;
      }

      observer.current = new window.IntersectionObserver(cb, options);
      observer.current.observe(node);
    }, [totalPages, currentPage]);
  };

  const observerElement = useIntersectionObserver(
    { threshold: 1 },
    (entries) => {
      console.log(entries, "enteries", entries[0].isIntersecting);
      if (entries[0].isIntersecting) {
        loadMore();
      }
    }
  );

  useEffect(() => {
    if (data) {
      setQuizzes((prevQuizzes) =>
        currentPage === 1
          ? data.getPublicRoom.response
          : [...prevQuizzes, ...data.getPublicRoom.response]
      );
      setTotalPages(data.getPublicRoom.totalPages);
      // console.log(data.getPublicRoom);
      setjoinId(data.getPublicRoom._id);
    }
  }, [data, currentPage]);

  useEffect(() => {
    refetch({ page: 1, search: searchExplore });
    setCurrentPage(1);
  }, [searchExplore, refetch]);

  const loadMore = () => {
    // console.log(currentPage, totalPages);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      refetch({ page: currentPage + 1, search: searchExplore });
    }
  };

  if (loading && currentPage === 1)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <RevolvingDot
          visible={true}
          height="80"
          width="80"
          color="#8663e0"
          ariaLabel="revolving-dot-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );

  if (error) return <p>Error: {error.message}</p>;

  const joinRoom = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${GRAPHQL_URL}/participant/room/join/in/public/room`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          // toast(result.msg)
          refetch();
          setjoinId("");
          setShowLottie(true);
          setTimeout(() => {
            setShowLottie(false);
          }, 3000);
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          // Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  // console.log("currentPage", currentPage);

  return (
    <>
      <ToastContainer />
      {showLottie ? (
        <div className="center-data ">
          <Lottie
            animationData={Animation_sent}
            autoPlay={true}
            style={{ width: 400, height: 400 }}
          />
        </div>
      ) : (
        <div className=" d-flex flex-column">
          <div className="center-data pt-1 flex-grow-0 pb-2">
            <div
              className="row align-items-center mx-1 "
              style={{
                backgroundColor: "#EFEFEF",
                border: "none",
                boxShadow: "4px 4px 4px #EFEFEF",
                borderRadius: "7px",
                padding: "8px",
                width: "50%",
              }}
            >
              <div className="col-3 d-flex justify-content-center mt-1">
                <IoIosSearch color="#7E7E7E" size={24} />
              </div>
              <div className="col-9 mt-1">
                <input
                  className="border-0 w-100"
                  placeholder="Search for Room"
                  value={searchExplore}
                  onChange={(e) => {
                    setSearchExplore(e.target.value);
                  }}
                  style={{
                    textDecoration: "none",
                    outline: "none",
                    backgroundColor: "#EFEFEF",
                    color: "#272727",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="explore_card_height row flex-grow-1 mb-1"
            style={{ height: "62vh", overflowY: "auto" }}
          >
            {quizzes?.map((res, index) => {
              return (
                <>
                  <div className="col-12 col-md-6 col-lg-4 p-3" key={index + 1}>
                    <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                      <div className="d-flex gap-2">
                        <div className="flex-grow-1 center-y t3">
                          {res.room_name}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between pe-2">
                        <div className="d-flex gap-2 mb-2">
                          <span
                            style={{
                              color: "#8A8A8A",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            Members
                          </span>
                          <div style={{ fontSize: "15px", fontWeight: "600" }}>
                            <span style={{ color: "#2E2E2E" }}>
                              {res?.enrolled_participants_count}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex gap-2 mb-2">
                          <span
                            style={{
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            Public
                          </span>
                        </div>
                      </div>

                      <div
                        className="btn2 rounded center-data"
                        onClick={() => {
                          joinRoom(res._id);
                          setjoinId(res._id); // Assuming res._id is the room ID
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Send Request
                      </div>

                      {/* {error && <div>Error: {error}</div>} */}
                    </div>
                  </div>
                </>
              );
            })}
            <div
              ref={observerElement}
              className="d-flex justify-content-center my-3"
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Explore;
