import React from 'react'
import HomeData from '../Home/HomeData'
import { Navbar } from 'react-bootstrap'
import Bottom from '../Home/Bottom'
import StudyMaterial from './StudyMaterial'
import Sidebar from '../Home/Sidebar'

const Study = () => {
  return (
    <div style={{
      height: "100vh", width: "100vw", overflow: "hidden", flexDirection: "column",
      display: "flex", backgroundColor: "white"
    }} >

      <div style={{ height: "90vh", overflow: "hidden" }} className='d-flex flex-column' >
        <StudyMaterial />
      </div>
      <div className="example1" style={{ flex: "0 0 auto", backgroundColor: "#6f42c1", paddingTop: 0, color: "white" }}>
        <Bottom />
      </div>
    </div>
  )
}

export default Study
