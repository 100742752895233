import React, { useCallback, useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { MdOndemandVideo } from "react-icons/md";
import { SlNotebook } from "react-icons/sl";
import CourseApiService from "../../services/api/CourseApiService";
import { ToastContainer, toast } from "react-toastify";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import { BLOBURL } from "../../config/urls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { BasicServices } from "../../services/BasicServices";


const PaidCourses = () => {
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isMaterialLoading, setIsMaterialLoading] = useState(false);
  const [paidCourses, setPaidCourses] = useState([]);
  const [PaidHistory, setPaidHistory] = useState([]);
  const [videos, setVideos] = useState({});
  const [materials, setMaterials] = useState({});
  const [isBuyable, setIsBuyable] = useState(true);
  const courseServ = new CourseApiService();
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const observer = useRef();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  //todo: call this function on buy button.
  function showCourseDetails(event, course_id) {
    event.stopPropagation();
    courseServ
      .getCourseDetails(course_id)
      .then((res) => {
        if (res?.status === 1) {
          setModalData(res.sen_data);
          handleShow(true);
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsBuyable(true);
      });
    //todo: show popup and
  }

  function buyCourse(course_id) {
    setIsBuyable(false);
    courseServ
      .buyCourse(course_id)
      .then((res) => {
        if (res?.status === 1) {
          setPaidCourses((t) => {
            let cpyArr = [...t];
            cpyArr = cpyArr.filter((i) => i._id !== course_id);
            return cpyArr;
          });
          toast("Course Bought Sucessfully");
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsBuyable(true);
      })
      .finally(() => setShow(false));
  }


  const lastElementRef = useCallback((node) => {
    if (isCourseLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage) => prevPage + 1); // Increment page number when the last element is visible and not beyond total pages
      }
    });
    if (node) observer.current.observe(node); // Observe the last transaction element
  }, [isCourseLoading, page, totalPages]);

  

  function getVideosForCourse(course_id) {
    setIsVideoLoading(true);
    courseServ
      .getVideos(course_id)
      .then((res) => {
        console.log(res, "Free course videos");
        if (res?.status === 1) {
          setVideos((v) => {
            let t = { ...v };
            t[course_id] = res.videos;
            return t;
          });
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsVideoLoading(false);
      });
  }

  function getStudyMaterial(course_id, video_id) {
    setIsMaterialLoading(true);
    courseServ
      .getStudyMaterial(course_id, video_id)
      .then((res) => {
        if (res?.status === 1) {
          setMaterials((v) => {
            let t = { ...v };
            t[video_id] = res.study_materials;
            return t;
          });
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsMaterialLoading(false);
      });
  }

  function reloadFreeCourses(cou_id) {
    setIsCourseLoading(true);
    courseServ
      .getPaidCourses(cou_id)
      .then((res) => {
        if (res?.status == 1) {
          setPaidCourses(res.data);
          console.log(res, "sonu987657u7656");
          handleShow(true);
        } else if (res.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((error) => toast(error.message))
      .finally(() => {
        setIsCourseLoading(false);
      });
  }

  const handleBuyNowClick = (event) => {
    //this function is not for buying actually. it is for stoping the propogation
    event.stopPropagation();
    toast("Buy The Course First");
  };

  useEffect(() => {
    if(page == 1){

      setIsCourseLoading(true);
    }
    courseServ.getPaidCourses(page).then(res => {
      if (res?.status === 1) {
        // console.log('res', res)
        setPaidCourses((prevCourses) => [...prevCourses, ...res?.data]);
        setTotalPages(res.totalPages);
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("");
        Navigate("/Register");
        toast("Logged in other device! Please login again");
      } else {
        toast(res.Backend_Error);
      }
    }).catch((error) => toast(error.message)).finally(() => {
      setIsCourseLoading(false);
    });
  }, [page ]);


  return (
    <div className="container-fluid pb-3 px-3">
      <ToastContainer />
      <div className="row">
        <div className=" brown " style={{ height: "78vh", overflowY: "auto" }}>
          <div className="col-lg-12 col-sm-12 col-md-12 ">
            {isCourseLoading ? (
              <LoaderComponent />
            ) : paidCourses.length <= 0 ? (
              <EmptyContainer
                message={"No Paid Courses Found"}
                action={reloadFreeCourses}
                actionName={"Reload"}
              ></EmptyContainer>
            ) : (
              paidCourses.map((res, index) => (
                <Accordion ref={index === paidCourses.length - 1 ? lastElementRef : null} key={res._id} flush>
                  <Accordion.Item
                    eventKey={res._id}
                    style={{ marginTop: "5%" }}
                    onClick={() => {
                      getVideosForCourse(res._id);
                    }}
                  >
                    <Accordion.Header>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                              <h6
                                style={{ fontSize: "16px", fontWeight: "800" }}
                              >
                                {" "}
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    color: "#61a3ad",
                                  }}
                                >
                                  {index + 1}.
                                </span>{" "}
                                {res.cou_name}
                              </h6>

                              <button
                                type="button"
                                disabled={!isBuyable}
                                className="btn-btn p-2 mt-3"
                                onClick={(e) => {
                                  showCourseDetails(e, res._id);
                                }}
                                style={{
                                  backgroundColor: "#61a3ad",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "6px",
                                  paddingTop: "10%",
                                }}
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                          <div className="col-12">
                            <div
                              className=""
                              style={{ marginLeft: "30px", lineHeight: "10%" }}
                            >
                              <h6 style={{ color: "#6d92c2" }}>
                                Duration: {res.Duration}(s),{" "}
                                <span>
                                  {res.attachment_count} Attachment(s),{" "}
                                </span>{" "}
                                <span>{res.videos_count} Video(s)</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 col-md-6 col-sm-12">
                            <div className="row">
                              <div
                                className=" brown "
                                style={{
                                  height: "max-content",
                                  maxHeight: "40vh",
                                  overflowY: "auto",
                                }}
                              >
                                <div className="col-12">
                                  <div className="">
                                    {isVideoLoading && !videos[res._id] ? (
                                      <LoaderComponent></LoaderComponent>
                                    ) : (
                                      videos[res._id]?.map((video) => (
                                        <Accordion key={video._id} flush>
                                          <Accordion.Item
                                            eventKey={video._id}
                                            onClick={() => {
                                              getStudyMaterial(
                                                res._id,
                                                video._id
                                              );
                                            }}
                                          >
                                            <Accordion.Header>
                                              <div className="container">
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-6 col-sm-12">
                                                    <div className="row">
                                                      <div className="col-12">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                          <h6 className="d-flex gap-3">
                                                            <MdOndemandVideo
                                                              style={{
                                                                color:
                                                                  "#61a3ad",
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                            />
                                                            {video.title}
                                                          </h6>
                                                          <button
                                                            className="btn-btn p-2"
                                                            style={{
                                                              backgroundColor:
                                                                "#61a3ad",
                                                              color: "white",
                                                              border: "none",
                                                              borderRadius:
                                                                "6px",
                                                              paddingTop: "10%",
                                                            }}
                                                            onClick={
                                                              handleBuyNowClick
                                                            }
                                                          >
                                                            Play video
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="container">
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-6 col-sm-12">
                                                    <div className="row">
                                                      <div className="col-12">
                                                        {isMaterialLoading &&
                                                          !materials[
                                                          video._id
                                                          ] ? (
                                                          <LoaderComponent></LoaderComponent>
                                                        ) : (
                                                          materials[
                                                            video._id
                                                          ]?.map((item) => (
                                                            <div
                                                              key={item._id}
                                                              className="d-flex align-items-center justify-content-between"
                                                            >
                                                              <h6
                                                                className="d-flex gap-2"
                                                                style={{
                                                                  marginLeft:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <SlNotebook
                                                                  style={{
                                                                    color:
                                                                      "#61a3ad",
                                                                    fontSize:
                                                                      "20px",
                                                                  }}
                                                                />
                                                                {item.title}
                                                              </h6>
                                                              <button
                                                                className="btn-btn p-1"
                                                                style={{
                                                                  color:
                                                                    "white",
                                                                  border:
                                                                    "none",
                                                                  borderRadius:
                                                                    "6px",
                                                                  backgroundColor:
                                                                    "#60bdd6",
                                                                }}
                                                                onClick={
                                                                  handleBuyNowClick
                                                                }
                                                              >
                                                                <a
                                                                  onClick={
                                                                    handleBuyNowClick
                                                                  }
                                                                >
                                                                  Preview
                                                                </a>
                                                              </button>
                                                            </div>
                                                          ))
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      ))
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h3>{modalData.cou_name}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{
                height: "auto",
                width: "100%",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <img
                src={BLOBURL + modalData.banner}
                alt="aaa"
                style={{
                  width: "100%",
                  height: "30vw",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />
            </div>

            <div style={{ paddingTop: "20px" }}>
              <p>Duration : {modalData.Duration}</p>
              <p>
                <span style={{ fontWeight: 600 }}>Amount :</span>{" "}
                <span style={{ textDecoration: "line-through" }}>
                  ₹ {modalData.amount}
                </span>{" "}
                <span style={{ fontWeight: 600 }}>
                  Discount : <delet>{modalData.discount}%</delet>
                </span>
              </p>
              <p>
                {" "}
                <span style={{ fontWeight: 600 }}>Final Amount : </span> ₹{" "}
                {modalData.final_amount}{" "}
              </p>

              <p className="d-flex justify-content-between">
                <span style={{ fontWeight: 600 }}>
                  Videos : {modalData.total_vedios}
                </span>{" "}
                <span style={{ fontWeight: 600 }}>
                  Instructor Name : {modalData.instructor}
                </span>
              </p>
            </div>
          </div>
        </Modal.Body>
        <div
          style={{
            padding: "10px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ marginRight: "10px" }}
          >
            Back
          </Button>
          <Button variant="primary" onClick={() => buyCourse(modalData._id)}>
            Confirm
          </Button>
        </div>
      </Modal>

    </div>
  );
};

export default PaidCourses;
