import React, { useContext, useEffect, useState } from 'react'
import user from "../assets/user.webp"
import { NavLink, useNavigate } from 'react-router-dom';
import { IoNewspaperOutline } from "react-icons/io5";
import { GiCheckedShield } from "react-icons/gi";
import { LuFileLock } from "react-icons/lu";
import { FaAward, } from "react-icons/fa";

import { MdHistory } from "react-icons/md";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BLOBURL } from "../../config/urls";
import { Context } from '../..';
import { useMediaQuery } from 'react-responsive'
import { BasicServices } from '../../services/BasicServices';
import { FaBookReader } from "react-icons/fa";
import AuthenticationApiService from '../../services/api/AuthenticationApiService';
import { IoMdLogOut } from 'react-icons/io';
import Span from '../Translation/Translate';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FaSquareInstagram } from 'react-icons/fa6';
import { MdGroups } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { MdVideoLibrary } from "react-icons/md";  

const basic = new BasicServices()


const Sidebar = () => {
  const { show, handleClose } = useContext(Context)
  const [user_details, setuser_details] = useState({})
  const auth = new AuthenticationApiService()
  const { value, setValue } = useContext(Context);
  const [image, setImage] = useState()
  const [showModel, setShowModel] = useState(false);
  const handleClose1 = () => setShowModel(false);
  const handleShow = () => setShowModel(true);
  const [loading, setLoading] = useState(false);
  let name = basic.getLocalObject().name

  useEffect(() => {
    auth.getUserProfile().then(res => {
      // console.log(res, "image")
      if (res?.status === 1) {
        setImage(res?.user_details.image)
        setuser_details(res?.user_details)
      }
    })
  }, [])

  const Navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1145px)'
  })
  const logout = async () => {
    setLoading(true); // Show loader
    const response = await auth.logout();
    setLoading(false); // Hide loader after response

    if (response?.status === 1) {
      basic.setJwt("");
      // localStorage.removeItem('brainbucks_object');
      Navigate('/');
    } else {
      basic.setJwt("");
      Navigate('/Register');
      toast("Logged in expire! Please login again");
    }
  };

  return (
    <>
      <Modal show={showModel} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title><Span>Confirm Logout</Span> </Modal.Title>
        </Modal.Header>
        <Modal.Body><Span>Are you sure you want to logout?</Span> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            <Span>Cancel</Span>
          </Button>
        
            <Button variant="danger" onClick={logout} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : <span>Yes, Logout</span>}
            </Button>
  
        </Modal.Footer>
      </Modal>

      <Offcanvas
        className={`${isDesktopOrLaptop ? "w-25" : "w-75"}`}
        show={show}
        onHide={handleClose}
        backdrop={true}

      >

        <Offcanvas.Header
          closeButton
          className="d-flex justify-content-end"
          style={{ backgroundColor: "#6f42c1" }}
        >  <h6 style={{ opacity: "0.6", color: "white " }}><Span>Welcome</Span> </h6></Offcanvas.Header>

        <Offcanvas.Body className="position-relative" >
          {/* user details started  */}
          <div className="menuOffcanvasUserDetailsMain d-flex align-items-center gap-3 " style={{ position: "sticky" }} >
            <div style={{
              borderRadius: "50%",
              width: "80px",
              border: "1px solid #808080",
              height: "80px",


            }}>
              {
                (image && image !== "") ?
                  <img
                    src={BLOBURL + image}

                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    alt=''
                  />
                  :
                  <img
                    src={user}

                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    alt=''
                  />
              }

            </div>
            <div>

              <h3 style={{ cursor: "pointer" }} className='mt-1' onClick={() => { Navigate('/Profile'); handleClose() }}><Span>{name}</Span> </h3>
              <h6 onClick={() => { Navigate('/Profile'); handleClose() }} style={{ textTransform: 'capitalize', cursor: "pointer", overflowWrap: "break-word", width: "150px", opacity: "0.6" }}><Span>View Profile</Span> </h6>
            </div>
          </div>
          {/* user details end  */}

          {/* menu items started  */}
          <div className="menuItems d-flex mt-3 row-gap-4 align-items-start ms-2 flex-column justify-content-center">


            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/Dailyupdate"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <IoNewspaperOutline style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }}><Span>Daily updates</Span> </span></div>
            </div>
            {/*} <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/reel"); handleClose() }}>
            <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
              <FaPlayCircle style={{ fontSize: "25px" }} />
            </div>
            <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }}><Span>Reels</Span> </span></div>
          </div>*/}

            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/reelsview"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <MdVideoLibrary style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }}><Span>Brain Booster</Span> </span></div>
            </div>

            {/* Rooms Menu starts */}
            <div className="d-flex align-items-center gap-2" onClick={() => {
              Navigate("/rooms");
              setValue(0);
              handleClose();
            }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <MdGroups style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }}><Span>Rooms</Span> </span></div>
            </div>
            {/* Rooms menu end */}


            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/Rules"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <GiCheckedShield style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }}  ><span style={{ cursor: "pointer", opacity: "0.6" }} ><Span>Rules and Regulations</Span> </span></div>
            </div>


            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/Privacy"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <LuFileLock style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }}  ><Span>Privacy Policy</Span> </span></div>
            </div>


            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/myexams"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <FaAward style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }} ><Span>My Exams</Span> </span></div>
            </div>



            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/HistoryMain"); setValue(0); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <MdHistory style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }}><span style={{ cursor: "pointer", opacity: "0.6" }} ><Span>History</Span> </span></div>
            </div>

            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/coursehistory"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <FaBookReader style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }} ><Span>Course Plan History</Span> </span></div>
            </div>

            {/* Help & Support */}
            <div className="d-flex align-items-center gap-2" onClick={() => { Navigate("/support"); handleClose() }}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <MdSupportAgent style={{ fontSize: "25px" }} />
              </div>
              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }} ><Span>Help & Support</Span> </span></div>
            </div>

            <div className=" d-flex align-items-center gap-2" onClick={() => setShowModel(true)}>
              <div className="p-2" style={{ background: "#F6F6F6", borderRadius: "50%" }} >
                <IoMdLogOut
                  style={{ display: "flex", alignItems: "center", fontSize: "25px" }}
                />
              </div>

              <div style={{ textDecoration: "none", color: "black" }} ><span style={{ cursor: "pointer", opacity: "0.6" }} ><Span>Log Out</Span> </span></div>

            </div>

          </div>
          {/* menu items end  */}


        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Sidebar