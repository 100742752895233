import { gql } from "@apollo/client";

export const Get_PublicRoom = gql`
  query GetPublicRoom($page: Int!, $search: String) {
    getPublicRoom(page: $page, search: $search) {
      status
      error
      totalPages
      response {
        _id
        room_name
        enrolled_participants_count
      }
    }
  }
`;
export const Get_created_rooms = gql`
  query GetCreatedRooms {
    get_created_rooms {
      status
      error
      response {
        _id
        room_name
        enrolled_participants_count
        room_type
        room_hash
      }
    }
  }
`;
export const Get_pending_rooms = gql`
  query GetPendingRooms {
    get_pending_rooms {
      status
      error
      response {
        _id
        room_name
        enrolled_participants_count
        room_type
        room_hash
      }
    }
  }
`;

export const Get_joined_rooms = gql`
      query GetJoinedRooms {
       get_joined_rooms{
       status
        error
        response {
            _id
            room_name
            enrolled_participants_count
            room_type
            room_hash
        }
       }
      }
       `;
export const Get_Req_Participants_Quiz = gql`
  query GetParticipants($room_id: String!, $page: Int!) {
    get_req_participants_of_quiz(room_id: $room_id, page: $page) {
        status
        error
        totalPages
        response {
            _id
            name
            image
        }
    }
  }
`;

export const Get_Enroll_Participants_Quiz = gql`
  query GetEnrollParticipants($room_id: String!, $page: Int!) {
    get_enrl_participants_of_quiz(room_id: $room_id, page: $page) {
        status
        error
        totalPages
        response {
            _id
            name
            image
        }
    }
  }
`;
export const Get_Category_Fromfill = gql`
  query GetCategoryFromfill {
    get_category_fromfill {
        status
        error
        response {
            _id
            category_name
            image
        }
    }
  }
`;
export const Get_Sub_Category_Fromfill = gql`
 query GetSubCategoryFromfill($cat_id: String!) {
   get_sub_category_fromfill(cat_id: $cat_id) {
        status
        error
        response {
            _id
            sub_category_name
        }
    }
  }
 
`;
export const Get_Schedule_Quizes = gql`
 query GetScheduleQuizes($room_id: String!, $page: Int!) {
    schedule_quizes(room_id: $room_id, page: $page) {
        status
        error
        totalPages
        response {
            _id
            prize
            slots
            slot_aloted
            sch_time
            category_name
            category_image
            entryFees
            participants
            rewards
            room_name
            room_id
        }
    }
  }
 `;

export const Get_Live_Quizes = gql`
 query GetLiveQuizes($room_id: String!) {
     live_quizes(room_id: $room_id) {
        status
        error
        totalPages
        response {
            _id
            prize
            slots
            slot_aloted
            sch_time
            category_name
            category_image
            entryFees
            participants
            rewards
            room_name
            room_id
        }
    }
  }
 `;

 export const Get_History_Quizes = gql`
 query GetHistoryQuizes($room_id: String!, $page: Int!) {
     history_quizes(room_id: $room_id, page:$page) {
        status
        error
        totalPages
        response {
            _id
            prize
            slots
            slot_aloted
            sch_time
            category_name
            category_image
            entryFees
            participants
            rewards
            room_name
            room_id
            is_res_dec
            crontab_result_time
        }
    }
  }
 `;

 export const Get_RoomQuiz_Detail = gql`
 query GetRoomQuizDetail($roomquiz_id: String!) {
     view_detail_of_roomquiz(roomquiz_id: $roomquiz_id,) {
        status
        error
        response {
            _id
            prize
            slots
            slot_aloted
            sch_time
            category_name
            category_image
            entryFees
            participants
            rewards
            room_name
            room_id
            is_res_dec
        }
    }
  }
 `;