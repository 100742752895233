import React, { useCallback, useEffect, useRef, useState } from "react";
import Span from "../Translation/Translate";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import coin from "../assets/icons/coin.svg";
import calender from "../assets/icons/calender.svg";
import clock from "../assets/icons/clock.svg";
import ticket from "../assets/icons/ticket.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { SiWhatsapp } from "react-icons/si";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import { IMAGE_URL } from "../../config/urls";
import { MdGroups } from "react-icons/md";
import { Get_History_Quizes } from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";

const RoomsHistory = () => {
  const { Roomsid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const page = useRef(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [getHistoryQuizes, setHistoryQuizes] = useState([]);
  const Navigate = useNavigate();

  const { loading, data, error, refetch } = useQuery(Get_History_Quizes, {
    variables: { room_id: Roomsid, page: 1 },
  });
  // const getHistoryQuizes = data?.history_quizes?.response || []

  useEffect(() => {
    if (data) {
      setHistoryQuizes((prevQuizzes) =>
        currentPage === 1
          ? data.history_quizes.response
          : [...prevQuizzes, ...data.history_quizes.response]
      );
      setTotalPages(data.history_quizes.totalPages);
      // setjoinId(data.history_quizes._id);
    }
  }, [data, currentPage]);
  const loadMore = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      refetch({ page: currentPage + 1 });
    }
  }, [currentPage, totalPages, refetch]);
  useEffect(() => {
    refetch()
  }, [refetch])

  if (loading && currentPage === 1) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;


  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
      >
        <div style={{ backgroundColor: "#6f42c1" }}>
          <div className="top_rom_hist flex-grow-0 mx-3 mt-3 d-flex justify-content-between">
            <div className="">
              <IoMdArrowRoundBack
                onClick={() => Navigate(`/enterroom/${Roomsid}`)}
                style={{ fontSize: "22px", cursor: "pointer", color: "white" }}
              />
            </div>
            <div className="">
              <h4 style={{ color: "white" }}>
                <Span>Room History</Span>
              </h4>
            </div>
            <div className="">
              <FaHome
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Navigate("/");
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="bot_rom_history flex-grow-1"
          style={{ overflowY: "auto" }}
        >
          <div className="row mx-2">
            {getHistoryQuizes?.length === 0 && !loading ? (
              <EmptyContainer
                // actionName={<Span>Reload</Span>}
                // action={() => callApi(page.current)}
                message="No Data Found"
              />
            ) : (
              getHistoryQuizes?.map((res, index) => {
                const [datePart, timePart] = res?.sch_time.split(" ");
                return (
                  <>
                    <div
                      className="col-12 col-md-6 col-lg-4 p-3"
                      key={index + 1}
                    >
                      <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                        <div className="d-flex gap-2">
                          <div className="center-data flex-grow-0">
                            <img
                              src={`${IMAGE_URL}${res?.category_image}`}
                              alt="quiz_icon"
                              style={{ height: "30px", borderRadius: "50%" }}
                            />
                          </div>
                          <div className="flex-grow-1 center-y t3 rounded">
                            <Span>{res?.category_name}</Span>
                          </div>
                          <div
                            className=""
                            style={{
                              backgroundColor: "#dee8dea1",
                              padding: "3px 4px 0 6px",
                            }}
                          >
                            <span
                              style={{ color: "#2321218c", fontSize: "14px" }}
                            >
                              ROOMS
                            </span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 d-flex gap-2">
                            <span className="tgr">
                              <Span>Entry Fees</Span>
                            </span>
                            <img src={coin} alt="fees" />
                            <span className="tgo">{res?.entryFees}</span>
                          </div>
                          <div className="col-6 d-flex gap-2">
                            <img
                              src={calender}
                              color="#7E7E7E"
                              alt="calender"
                            />
                            <span className="tgr">{datePart}</span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 d-flex gap-2">
                            <span className="tgr">
                              <Span>Reward</Span>
                            </span>

                            <img src={coin} alt="prize" />
                            <span className="tgo">{res?.prize}</span>
                          </div>
                          <div className="col-6 d-flex gap-2">
                            <img src={clock} color="#7E7E7E" alt="clock" />
                            <span className="tgr">{timePart}</span>
                          </div>
                        </div>

                        {/* declare time  */}
          <div className="row">
            <div className="col-12 d-flex gap-2">
              <span className="tgr pe-2">
                <Span>Declared Time</Span>
              </span>
              <img src={calender} alt="time" color="#7E7E7E"></img>
              <span className="tgr">
                {res.crontab_result_time.split(" ")[0]}
              </span>
              <img src={clock} alt="time" color="#7E7E7E"></img>
              <span className="tgr">
                {res.crontab_result_time.slice(10, 19)}
              </span>
            </div>
            {/* <div className="col-4 d-flex gap-2">
                
            </div> */}
          </div>

                        <div>
                          <div className="d-flex gap-2 mb-2">
                            <MdGroups style={{ fontSize: "25px" }} />
                            <div
                              style={{ fontSize: "15px", fontWeight: "600" }}
                            >
                              <span>{res?.room_name}</span>
                            </div>
                          </div>

                          

                          <div className="d-flex gap-2 mb-2">
                            <img src={ticket} alt="ticket" />
                            <div style={{ fontSize: "14px" }}>
                              <span>{res?.slot_aloted}</span>/
                              <span style={{ color: "#2188E7" }}>
                                {res?.slots}
                              </span>
                            </div>
                          </div>

                          <ProgressBar
                            completed={res?.slot_aloted}
                            bgColor="linear-gradient(90.11deg, #59AFFF 1.72%, #2188E7 98%)"
                            height="10px"
                            isLabelVisible={false}
                            baseBgColor="#E4E9EC"
                            labelColor="#e80909"
                            animateOnRender
                            maxCompleted={res?.slots}
                          />
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          className="btnanst3 rounded center-data"
                          onClick={() => {
                            if (res?.is_res_dec == 1) {
                              Navigate(`/roomsresult/${res._id}`);
                            }
                          }}
                        >
                          <Span>
                            {res?.is_res_dec == 1
                              ? "View Result"
                              : "Please, Wait for result declaration"}
                          </Span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}

            {loading && <LoaderComponent />}

            <div className="center-data">
              {totalPages != page.current && getHistoryQuizes?.length != 0 && (
                <button
                  className="load-button center-data p-3 px-4 rounded mt-3"
                  onClick={loadMore}
                >
                  <Span>Load More</Span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomsHistory;
