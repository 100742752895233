import React, { useContext, useEffect, useRef, useState } from 'react'
import image4 from '../assets/image5.png';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { IMAGE_URL, QUIZMICRO } from '../../config/urls';
import EmptyContainer from "../utils/EmptyContainer";
import { BasicServices } from '../../services/BasicServices';
import LoaderComponent from "../utils/LoaderComponent";
import Span from "../Translation/Translate";


const MyExamQuiz = () => {
    const { examData, handleClose } = useContext(Context)
    const [isLoading, setIsLoading] = useState(false)
    const basic = new BasicServices();
    const page = useRef(1);
    const Navigate = useNavigate();
    console.log("data", examData);


    useEffect(() => {
        handleClose();
    }, [])
    return (
        <>
            <div style={{ height: "100vh", width: "100vw", }}>
                <div className='border' style={{ height: "10vh", color: "white", backgroundColor: "#6f42c1" }} >
                    <div className='container'>
                        <div className='row pt-3'>
                            <div className='col-3 d-flex justify-content-center align-items-center' >
                                <IoArrowBack style={{ fontSize: "4vh", cursor: "pointer" }} onClick={() => { Navigate("/") }} />
                            </div>
                            <div className='col-6  text-center'>
                                <span style={{ fontSize: "21px", fontWeight: "600" }} className="mb-0">
                                  <Span>My Exam</Span>  
                                </span>
                            </div>
                            <div className='col-3' >

                            </div>
                        </div>
                    </div>
                </div>
                <div className='container example' style={{ height: "85vh", overflowY: "scroll" }}>

                    <div className='container'>
                        <div className='row d-flex justify-content-around '>
                            {(examData?.length === 0 && !isLoading) ? (
                                <EmptyContainer
                                    actionName="Reload"
                                    action={() => examData(page.current)}
                                    message="No Data Found"
                                />
                            ) :
                                examData?.map((res) => {

                                    return (
                                        <div className='col-lg-5 card mt-3 boxshadow ' >
                                            <div className='d-flex flex-column align-items-center ' style={{ cursor: "pointer" }} onClick={() => Navigate(`/examquiz/${res?._id}`)}>
                                                <div className='notsaved'>
                                                    <img src={IMAGE_URL + res.image} style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '50%',
                                                    }}
                                                        alt='icon'>
                                                    </img>
                                                </div>
                                                <h6 className='some-txt text-center' style={{ fontSize: "20px" }}><Span>{res?.category_name}</Span> </h6>
                                                <h6 className='txx text-center' style={{ fontSize: "18px" }}><Span>Active Quizzes</Span> </h6>
                                                <h2 style={{ color: "red", textAlign: "center" }}>{res?.total_active_quiz}</h2>


                                            </div>


                                        </div>
                                    )
                                })
                            }
                            {isLoading && <LoaderComponent />}

                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default MyExamQuiz
