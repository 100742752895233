import React, { useEffect, useRef, useState } from 'react'
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { QUIZMICRO } from '../../config/urls';
import { toast } from 'react-toastify';
import { IMAGE_URL } from '../../config/urls';
import { BasicServices } from '../../services/BasicServices';
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { IoIosSearch } from 'react-icons/io';
import Span from '../Translation/Translate';


const Questionppr = () => {
    const Navigate = useNavigate();
    const [search, setSearch] = useState("");
    const basic = new BasicServices();
    const page = useRef(1)
    const token = basic.getLocalObject().jwt
    const { id, pdf_type, category_name } = useParams();
    console.log(id)
    const [subData, setsubData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getParticularExam = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);


            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${QUIZMICRO}/participants/get/materials/of/particular/exam/in/particular/pdftype?cat_id=${id}&pdf_type=${pdf_type}&search=${search}`, requestOptions)
            const result = await response.json()
            setIsLoading(false)
            if (result?.status == 1) {
                setsubData(result.data)
            } else if (result?.status == 0) {
                toast.error("failed")
            } else if (result.status === "TOKEN_ERR") {
                basic.setJwt("");
                Navigate("/Register");
                toast("Logged in other device! Please login again");
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        getParticularExam();

    }, [search])

    return (
        <>
            <div className='d-flex flex-column ' style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
                <div className='flex-grow-0' style={{ backgroundColor: "#6f42c1", color: "white" }}>
                    <div className=' border '>
                        <div className='row mt-2 '>
                            <div className='col-3  d-flex justify-content-center'>

                                <IoArrowBackOutline style={{ height: "5vh", marginLeft: "1rem", fontSize: "30px", cursor:"pointer" }} onClick={() => {
                                    Navigate(-1)
                                }} />

                            </div>
                            <div className='col-6 text-center'>
                                <h3 style={{ color: "white" }}><Span>Question Paper</Span> </h3>
                            </div>

                        </div>
                        <div className='row mt-2 d-flex justify-content-center  p-2'>
                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div
                                    className="row align-items-center mx-1 p-2"
                                    style={{
                                        backgroundColor: "#EFEFEF",
                                        borderRadius: "7px",

                                    }}
                                >
                                    <div className="col-3 d-flex justify-content-center mt-1">
                                        <IoIosSearch color="black" size={20} />
                                    </div>
                                    <div
                                        className="col-9 mt-1"
                                        style={{ marginLeft: "-30px" }}
                                    >
                                        <input
                                            className="border-0 w-100"
                                            placeholder="Search for previous question paper"
                                            style={{
                                                textDecoration: "none",
                                                outline: "none",
                                                backgroundColor: "#EFEFEF",
                                                color: "#272727",
                                            }}
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <div className='flex-grow-1' style={{ overflowY: "auto" }}>
                    <div className='container '>
                        <div className='row'>
                            {(subData?.length === 0 && !isLoading) ? (
                                <EmptyContainer
                                    actionName="Reload"
                                    action={() => getParticularExam(page.current)}
                                    message="No Data Found"
                                />
                            ) :
                                subData?.map((res) => {
                                    return (
                                        <div className='col-sm-6 col-md-4 flex-grow-1'>
                                            <div className='border  mt-2 p-3  '>
                                                <div>
                                                    <span
                                                        style={{
                                                            color: "#000",
                                                            fontWeight: 600,
                                                            fontStyle: "normal",
                                                            fontSize: "20px"

                                                        }}
                                                    ><Span>{res.display_name}</Span> </span>
                                                </div>

                                                <div>
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                            fontStyle: "normal",
                                                            fontSize: "20px"
                                                        }}
                                                    >
                                                        {res.totalQuestions}
                                                    </span>
                                                    <div>
                                                        <p className='mt-3' style={{ color: "#8A8A8A", fontWeight: "500" }}>{res.upload_date}</p>
                                                    </div>
                                                </div>
                                               
                                               
                                                <div className='row d-flex  justify-content-center mt-2'>
                                                    <div className='col-6 mt-2'>
                                                        <button className='btnn p-2 text-center w-100' onClick={() => { window.open(IMAGE_URL + res.filename) }} >
                                                          <Span>View</Span>  
                                                        </button>
                                                    </div>

                                                    <div className='col-6 mt-2'>
                                                        <button className='btn1 p-2 text-center w-100' onClick={() => {
                                                            const downloadUrl = IMAGE_URL + res.filename;
                                                            const downloadFileName = res.filename.substring(4); // Remove "pdf/" from the filename
                                                            fetch(downloadUrl)
                                                                .then(response => response.blob())
                                                                .then(blob => {
                                                                    const url = window.URL.createObjectURL(new Blob([blob]));
                                                                    const link = document.createElement('a');
                                                                    link.href = url;
                                                                    link.setAttribute('download', downloadFileName);
                                                                    document.body.appendChild(link);
                                                                    link.click();
                                                                    document.body.removeChild(link);
                                                                })
                                                                .catch(error => console.error('Error downloading the file:', error));
                                                        }}>
                                                           <Span>Download</Span> 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {isLoading && <LoaderComponent />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Questionppr
