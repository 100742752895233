function convertTimeToAMPM(timeString = "") {
    var timeParts = timeString.split(':');
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    var ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; 

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ':' + ' ' + ampm;
}
const convertTimeToAMPMwithDate = (datetimeString) => {
    const date = new Date(datetimeString);
    let hours = date?.getHours();
    let minutes = date?.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
  
module.exports = {
    convertTimeToAMPM,
    convertTimeToAMPMwithDate
}
