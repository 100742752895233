// import React from 'react'

// const Baacc = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default Baacc

import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import fardail from "../assets/fadrailbank.png";
import WalletApiService from "../../services/api/WalletApiService";
import { toast } from "react-toastify";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";
import { BsBank2 } from "react-icons/bs";

const Baacc = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  const walletService = new WalletApiService();
  const [isActive, setIsActive] = useState(true);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt

  function addAccount() {
    console.log("LDSK");
    setIsActive(false);

    walletService
      .sendOtp()
      .then((otpR) => {
        if (otpR?.status === 1) {
          
          console.log("OTP for Adding Bank", otpR);
            Navigate("/wallet/Acc", {
            replace: true,
            state: {
              ifsc: state.ifsc,
              accHolder: state.accHolder,
              accNumber: state.accNumber,
              bankName: state.bankName,
              otp:otpR?.otp
            },
          });
          toast(otpR?.msg)
          
        }  else if (otpR.status === "TOKEN_ERR") {
          // localStorage.removeItem("api_url");
                    basic.setJwt("")

          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } 
        
        else {
          toast(otpR.Backend_Error);
        }
      })
      .catch((err) => {
        toast(err.message);
        console.log(err);
      })
      .finally(() => setIsActive(true));
  }

  return (
    <>
      <div>
        <div>
          <div className="" style={{ height: "10vh" }}>
            <div className="container">
              <div className="row mt-2">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate(-1)}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2E2E2E",
                          }}
                        >
                         <Span> Name of Bank</Span>
                        </h6>
                        <div className="d-flex justify-content-start">
                          <BsBank2 style={{ width: "46px", height:"40px" }} />
                          <h6
                            className="pt-1"
                            style={{ fontWeight: "500", fontSize: "30px" }}
                          >
                            {" "}
                            <Span>{state.bankName}</Span>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                        <Span> Beneficiary Name</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span>{state.accHolder}</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                        <Span>Account Number</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          {state.accNumber}
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                        <Span>IFSC Code</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          {state.ifsc}
                        </h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className=" pt-5">
                        <div class="d-grid gap-2 pt-5">
                          <button
                            class="btn-btn p-2"
                            disabled={!isActive}
                            onClick={addAccount}
                            style={{
                              backgroundColor: "#701DDB",
                              borderRadius: "6px",
                              fontWeight: "600",
                              fontSize: "17px",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            type="button"
                          >
                            <Span>Confirm</Span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Baacc;
