import React, { useState } from 'react'
import sbi from "../assets/icons/sbi.svg";
import { FaArrowRight } from "react-icons/fa6";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import bulb from "../assets/bulb.gif"
import Pdf from './Pdf';
const Online = () => {
    const Navigate = useNavigate();
    const [value,setValue] = useState(0);
    return (
        <>
    
    <div style={{ flexDirection: "column", justifyContent: "center", }} >
                <div className='d-flex ' style={{ justifyContent: "center",hight:"30vh" }}>
                    <img src={bulb} style={{ width: "35vh" }}></img>
                </div>
                <div className=' d-flex' style={{ justifyContent: "center", }}>
                    <h4>
                        Learn from industry <br /> Expert Teachers,Live <br /> from your home ,for <br /> FREE...
                    </h4>
                </div>
               <div className='w-100  d-flex mt-2' style={{justifyContent:"center"}}>
               <button className='soon px-5 p-2 text-center' >
               Online Classes
           </button>
               </div>
            </div>
        </>
    )
}

export default Online
