import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../..';
import Sidebar from '../Home/Sidebar';
import { CiMenuFries } from 'react-icons/ci';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { SlCalender } from "react-icons/sl";
import { IoMdArrowRoundBack, IoMdArrowUp } from "react-icons/io";
import { IoMdArrowRoundDown } from "react-icons/io";
import HistoryApiService from '../../services/api/HistoryApiService';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from '../utils/LoaderComponent';
import { NavLink } from 'react-router-dom';
import FreeCourses from './FreeCourses';
import PaidCourses from './PaidCourses';
import EnrolledCourses from './EnrolledCourses';
import { FaHome } from 'react-icons/fa';
import { MdOutlineMenu } from 'react-icons/md';
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';

const Courses = () => {
  const { handleShow } = useContext(Context);
  const {value, setValue} = useContext(Context);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showUpArrow, setShowUpArrow] = useState(true); // State to toggle between up and down arrows
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [order, setOrder] = useState(0);
  const [historyData, setHistoryData] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const {handleClose} = useContext(Context)

  const Navigate = useNavigate();

  const historyService = new HistoryApiService();

  function loadMore(){
    if(currentPage>=totalPages && totalPages>0){
      return;
    }
    let p = currentPage+1;
    setIsLoading(true)
    historyService.getFullHistory(order, p).then((res) => {
      if (res?.status === 1) {
        setHistoryData([...res.properquizes,...historyData])
        setTotalPages(res.totalpages)
        setCurrentPage(p)
        console.log(res, "Free History More Data");
      }else if (res?.status == "TOKEN_ERR") {
        basic.setJwt("")
         Navigate("/Register");
         toast("Logged in other device! Please login again");
       }  
      else {
        console.log("Error While Reterieving More Data From Free History Data", res)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const toggleArrow = () => {
    setShowUpArrow(!showUpArrow)
  }

  useEffect(() =>{loadMore();handleClose()}, [])

  return (
    <>
      <div className='overflow-hidden d-flex flex-column ' style={{ height: "100vh", width: "100vw", }} >
        <div className='top_hist pt-2 pb-2 px-4 d-flex justify-content-between align-items-center' style={{background:"#6f42c1"}}>
          <div className='' >
          <IoMdArrowRoundBack onClick={() => Navigate('/')} style={{ fontSize: "22px" , cursor:"pointer",color:"white" }} />
          </div>
          <div className=''>
            <h4 className='text-white'>Courses</h4>
          </div>
          <div className='' >
        <NavLink to={'/'}>  <FaHome  style={{height:"30px",width:"30px",color:"white"}} /></NavLink>
          </div>


        </div>
        <div className='bot_hist ms-3 d-flex align-items-center'>
          <div className='d-flex overflow-auto '>
            <Box
              className="w-100 my-2 mx-md-2 "
              sx={{
                borderColor: "divider",
                borderBottom: "1px solid #E4E9EC",

              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Free Courses"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />

                <Tab
                  label="Paid Courses"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
                <Tab
                  label="Enrolled Courses"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
                {/* <Tab
                  label="Rooms"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                /> */}


              </Tabs>

            </Box>
          </div>

        </div>
        <div
          className="flex-grow-1 mb-2"
          style={{ overflowY: 'auto' }}
        >
          {value === 0 && <FreeCourses />}
          {value === 1 && <PaidCourses />}
          {value === 2 && <EnrolledCourses />}
          {/* {value === 4 && <RoomsHistory />} */}
        </div>

      </div>

      <Sidebar />
    </>
  )
}

export default Courses;