import React, { useContext, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { CiShare2 } from "react-icons/ci";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import { AiOutlineMail } from "react-icons/ai";
import CreateSheduleQuizz from "./CreateSheduleQuizz";
import CreateLiveQuizz from "./CreateLiveQuizz";
import { Context } from "../..";

const CreateEnterRoom = () => {

  const { Roomsid } = useParams();
  const { createdStoreData } = useContext(Context);
  const Navigate = useNavigate();
  const {value, setValue} = useContext(Context);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ background: "#701ddb", height: "100vh", width: "100vw", overflow: "hidden" }}
      >
        <div className="top_enter flex-grow-0" >
          {/* top menu */}
          <div className="d-flex p-4 justify-content-between">
            <div
              className=""
              style={{
                background: "#ffffff42",
                borderRadius: "50%",
                padding: "9px",
                cursor: "pointer",
              }}
              onClick={() => Navigate("/rooms")}
            >
              <FaArrowLeftLong style={{ color: "#fff", fontSize: "19px" }} />
            </div>

            {/* Wrapper div added with d-flex to align the "9+" and "Settings" divs horizontally */}
            <div className="d-flex">
              <div
                className="d-flex align-items-center rounded p-1 me-4"
                style={{ backgroundColor: "#ffffff42", cursor: "pointer" }}
                onClick={() => Navigate(`/roomnotification/${Roomsid}`)}
              >
                <AiOutlineMail style={{ fontSize: "20px", color: "#fff" }} />
                {/* ml-1 class to provide margin-left between icon and text */}
                <span className="ml-1" style={{ color: "#fff", fontSize: "18px", paddingLeft: "6px" }}>
                  9+
                </span>
              </div>
              {/* Added ml-2 class to provide margin-left and create a gap between the elements */}
              <div
                className="d-flex align-items-center rounded p-1 ml-2"
                style={{ backgroundColor: "#ffffff42", cursor: "pointer" }}
                onClick={() => Navigate(`/settings/${Roomsid}`)}

              >
                <IoMdSettings style={{ fontSize: "20px", color: "#fff" }} />
                {/* ml-1 class to provide margin-left between icon and text */}
                <span className="ml-1" style={{ color: "#fff", fontSize: "18px", paddingLeft: "6px" }} >
                  Settings
                </span>
              </div>
            </div>
          </div>


          {/* quizz data  */}
          <div className="ps-4 pt-4 ">
            <span
              className=""
              style={{ fontSize: "25px", color: "#fff", fontWeight: "600" }}
            >
              {createdStoreData.room_name}
            </span>
          </div>
          <div className="mt-2 ps-4">
            <span className="" style={{ color: "#fff" }}>
              Members <span style={{ color: "#04FBB1" }}>{createdStoreData.enrolled_participants_count}</span>
            </span>
          </div>
          <div className="d-flex  ps-4 pt-4">
            {/* <div
              className="col-3"
              style={{
                backgroundColor: "#ffffff42",
                borderRadius: "20px",
                padding: "3px 0 3px 5px",
              }}
            >
              <CiShare2 style={{ color: "#fff", fontSize: "17px" }} />{" "}
              <span style={{ color: "#fff", fontWeight: "600" }}>Invite</span>
            </div> */}
            <div className="col-3 ms-4" style={{ padding: "3px 0 3px 5px" }}>
              <span style={{ color: "#fff", fontWeight: "600", fontSize: "16px" }}>
                {createdStoreData.room_type == true ? (
                  <span>Public</span>) : (
                  <span>Private</span>
                )}
              </span>
            </div>
          </div>
          {/* tabs of quiz and particpiants */}
          <div className=" d-flex mx-4 mt-4 rounded">

            <div
              className="col-12 col-sm-12 col-md-8 col-lg-6 p-2 d-flex justify-content-center"

              style={{
                backgroundColor: "#0CBC8B",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "600",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => Navigate(`/schedulequiz/${Roomsid}/${createdStoreData?.room_name}`)}
            >
              <span style={{ fontSize: "16px" }}>+ Schedule Quiz</span>
            </div>
          </div>
        </div>

        {/* bottom quizz details starts  */}
        <div
          className="bottom_part_enter mt-3 flex-grow-1"
          style={{ backgroundColor: "#fff", borderRadius: "30px 30px 0 0", overflowY: "auto" }}
        >


          <div className="">
            <Box
              className="mx-md-2"
              sx={{
                borderColor: "divider",
                borderBottom: "1px solid #E4E9EC",
                position: "sticky",
                top: "0",
                backgroundColor: "#fff",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{ marginLeft: "25px" }}
              >
                <Tab
                  label={
                    <div>
                      Live Quizzes{" "}
                      {/* <span
                        className="p-1"
                        style={{
                          color: "white",
                          backgroundColor: "#D92828",
                          borderRadius: "50%",
                        }}
                      >
                        9+
                      </span> */}
                    </div>
                  }
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />

                <Tab
                  label={
                    <div>
                      Schedule Quizzes{" "}
                      {/* <span
                        className="p-1"
                        style={{
                          color: "white",
                          backgroundColor: "#D92828",
                          borderRadius: "50%",
                        }}
                      >
                        9+
                      </span> */}
                    </div>
                  }
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
              </Tabs>
            </Box>

            {value === 0 && <CreateLiveQuizz Roomsid={Roomsid} />}
            {value === 1 && <CreateSheduleQuizz Roomsid={Roomsid} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEnterRoom;
