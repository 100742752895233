import React, { useEffect, useRef, useState, useCallback } from "react";
import Reel from "./Reel";
import './reel.css';
import { IoMdArrowRoundBack } from "react-icons/io";
import Span from "../Translation/Translate";
import { useNavigate, useParams } from "react-router-dom";
import { BLOBURL } from "../../config/urls";
import { BasicServices } from '../../services/BasicServices';

function ReelContainer() {
  const shortContainerRef = useRef();
  const loadMoreRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const { id } = useParams();
  const [selectedTag, setSelectedTag] = useState([]);
  const [seenReelsId, setSeenReelsId] = useState([]);

  const fetchData = useCallback(async () => {
    if (page > totalPages && totalPages !== 0) return;

    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      tags: selectedTag,
      seen_reels: seenReelsId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://notify.brainbucks.co.in/participants/reels/get/random/reels`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === 1) {
        setData(prevData => [...prevData, ...result.reel]);
        setTotalPages(result.totalPages);
        setLoading(false);
      } else {
        console.error("Backend Error:", result?.Backend_Error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [page, token, selectedTag, seenReelsId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !loading) {
          setPage(prevPage => prevPage + 1);
        }
      },
      { threshold: 1 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading, page, totalPages]);

  const scrollToTop = () => {
    if (shortContainerRef.current) {
      shortContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "#6f42c1" }}>
        <div className="container">
          <div className="row pt-3 d-flex align-items-center" style={{ height: "10vh" }}>
            <div className="col-3 d-flex justify-content-center align-items-center">
              <IoMdArrowRoundBack onClick={() => navigate("/reelTag")} style={{ fontSize: "22px", cursor: "pointer", color: "white" }} />
            </div>
            <div className="col-6 text-center">
              <span style={{ fontSize: "1.5rem", fontWeight: "700", color: "white" }} className="mb-0 heading">
                <Span>Reels</Span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <section ref={shortContainerRef} className="short-container">
        {data?.map((short) => (
          <Reel key={short._id} short={short} />
        ))}
        <div ref={loadMoreRef} style={{ height: '20px' }}></div>
      </section>
      <div className="navigation-container">
        <div onClick={() => scrollToTop()} className="nav-up">
          <ion-icon name="arrow-up-outline"></ion-icon>
        </div>
        <div onClick={() => shortContainerRef.current.scrollTo(0, shortContainerRef.current.scrollTop + window.innerHeight)} className="nav-down">
          <ion-icon name="arrow-down-outline"></ion-icon>
        </div>
      </div>
      {loading && <p className="text-center">Loading more reels...</p>}
      {page > totalPages && <p className="text-center">Reels are finished</p>}
    </>
  );
}

export default ReelContainer;
