// import React from 'react'

// const Bankaccdetails = () => {
//   return (
//     <>

//     </>
//   )
// }

// export default Bankaccdetails
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Navigate, useNavigate } from "react-router-dom";
import fardail from "../assets/fadrailbank.png";
import Span from "../Translation/Translate";

const Bankaccdetails = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div>
        <div>
          <div className="" style={{ height: "10vh" }}>
            <div className="container">
              <div className="row mt-2">
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate(-1)}
                    style={{ fontSize: "22px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="">
                        <h6
                          className=""
                          style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            color: "#2E2E2E",
                          }}
                        >
                          ₹ 15,600
                        </h6>
                        <h6
                          className="pt-2"
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span>will be deposited in the following</Span> <br />{" "}
                          <Span>Bank Account</Span>{" "}
                        </h6>
                        <h6
                          className="pt-2"
                          style={{
                            fontWeight: "500",
                            fontSize: "12px",
                            color: "#D92828",
                          }}
                        >
                          <Span>
                            it can take upto 2 Business days for the payment
                          </Span>{" "}
                          <br /> <Span>to be credited</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span>Name of Bank</Span>
                        </h6>
                        <div className="d-flex justify-content-start">
                          <img src={fardail} alt="" style={{ width: "46px" }} />
                          <h6
                            className="pt-1"
                            style={{ fontWeight: "500", fontSize: "30px" }}
                          >
                            {" "}
                            <Span>Federal Bank</Span>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span>Beneficiary Name</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          <Span>Raghuveer Singh Prajapat</Span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span>Account Number</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          1234 4567 8901
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 pt-4">
                      <div className="">
                        <h6 style={{ fontSize: "16px", fontWeight: "500" }}>
                          <Span>IFSC Code</Span>
                        </h6>
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "22px",
                            color: "#2E2E2E",
                          }}
                        >
                          0000FDRB23
                        </h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className=" pt-4">
                        <div class="d-grid gap-2">
                          <button
                            class="btn-btn p-2"
                            onClick={() => {
                              Navigate("/Wallet/otp");
                            }}
                            style={{
                              backgroundColor: "#701DDB",
                              borderRadius: "6px",
                              fontWeight: "600",
                              fontSize: "17px",
                              color: "#FFFFFF",
                              border: "none",
                            }}
                            type="button"
                          >
                           <Span>Confirm</Span> 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Bankaccdetails;
