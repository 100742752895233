import React, { useContext, useEffect, useState } from "react";
import "../Register/Registration.css";
import { IoIosSearch } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import sbi from "../assets/icons/sbi.svg";
import AuthenticationApiService from "../../services/api/AuthenticationApiService";
import { BasicServices, BrainBucksObject } from "../../services/BasicServices";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { BLOBURL } from "../../config/urls";
import { toast, ToastContainer } from 'react-toastify';
import { width } from "@mui/system";
import Span from "../Translation/Translate";
import { Context } from '../..';
import { Spinner } from "react-bootstrap";
import { RevolvingDot } from "react-loader-spinner";




const Registration = () => {
  const Navigate = useNavigate()
  const [Click, setClick] = useState(0);
  const { handleClose } = useContext(Context);
  const [rerender, setRerender] = useState(true);
  const toggleRender = () => setRerender((prev) => !prev)
  const [searchQuery, setSearchQuery] = useState('');
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState(null);
  const [isOtpButtonClickable, setOtpButtonClickable] = useState(true);
  const [otpInput, setOtpInput] = useState("");
  const auth = new AuthenticationApiService();
  const basicService = new BasicServices();
  const brainBucksObject = new BrainBucksObject();
  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [timer, setTimer] = useState(60);
  const [loading, setIsLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const handleLanguageChange = (lang) => {
    localStorage.setItem("language", lang);
    window.location.reload();
  };



  /**
   * function to send otp to the entered number
   */
  // async function sendOtp() {
  //   console.log(phone);
  //   if (!confirmed) {
  //     console.log("Confirm The Checkbox first");
  //   }
  //   if (phone.length !== 10) {
  //     console.log("Phone Number must be 10 digits long");
  //     return;
  //   }

  //   setOtpButtonClickable(false);
  //   let otpResponse = await auth.sendOtp(phone);
  //   console.log(otpResponse, "otpResponse");
  //   if (otpResponse.status !== 1) {
  //     setOtpButtonClickable(true);
  //     console.log("OTP Didn't Get Sent");
  //     return;
  //   }

  //   setClick((prevClick) => prevClick + 1);
  //   setOtp(otpResponse.otp);
  //   setOtpButtonClickable(true);
  // }
  const sendOtp = async (isResending) => {
    console.log(phone);
    if (!phone) {
      toast('Please fill the phone number');
      console.log('Please fill in the phone number');
      return;
    }
    if (phone?.length !== 10) {
      toast('Phone number must be 10 digits long');
      console.log('Phone number must be 10 digits long');
      return;
    }
    if (!confirmed) {
      toast('Confirm the checkbox first');
      console.log('Confirm the checkbox first');
      return;
    }

    setOtpButtonClickable(false);
    setIsLoading(true); // Start loading

    try {
      let otpResponse = await auth.sendOtp(phone);
      console.log(otpResponse, "otpResponse");

      if (otpResponse?.status !== 1) {
        setOtpButtonClickable(true);
        console.log("OTP didn't get sent");
        toast('OTP did not get sent');
        return;
      }

      if (!isResending) {
        setClick((prevClick) => prevClick + 1);
      }
      setOtp(otpResponse.otp);
      toast('OTP sent successfully');
      setTimer(60); // Reset timer on successful OTP send
      setDisableResend(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast('An error occurred while sending OTP');
    } finally {
      setOtpButtonClickable(true);
      setIsLoading(false); // Stop loading
    }
  };
  const handleNextClick = () => {
    if (!gender) {
      toast("Please select at least one gender option.");
      return;
    }
    setClick((prevClick) => prevClick + 1);
  };
  // const registerUser = () => {
  //   const isAnyCategorySelected = filteredexams.some((exam) => exam.isSelected);
  //   if (!isAnyCategorySelected) {
  //     toast.error('Please select at least one category.');
  //     return;
  //   }
  //   // Continue with the registration process
  // };
  useEffect(() => {
    let localObject = basicService.getLocalObject();
    if (
      localObject &&
      localObject.number &&
      localObject.number !== "" &&
      localObject.token &&
      localObject.token !== ""
    ) {
      console.log("NODS", localObject);
      navigate("/", {
        replace: true
      });
    }
    
  }, []);
  // useEffect to fetch exams when Click is 5
  useEffect(() => {
    if (Click === 5) {
      getExams();
    }
  }, [Click]); // Depend on Click

  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   const handleKeyDown = (e) => {
  //     if ((e.ctrlKey || e.metaKey) && (e.key === 'c' || e.key === 'x' || e.key === 'v')) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  useEffect(() => {
    let interval = null;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      setDisableResend(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);
  /**
   * function to confirm if the otp is correct
   */
  async function confirmOtp() {
    if (!otpInput) {
      toast('Please Enter OTP');
      console.log('Please fill the phone number');
      return;
    }
    console.log("Entered otp", otpInput, "actual otp", otp);

    setConfirmLoading(true); // Start loading

    try {
      let res = await auth.verifyOtpAndRegister(phone, otpInput);
      if (res?.status === 1) {
        // console.log('toast', toast(res.msg));
        // toast(res.msg)
        console.log("Registeration Response: ", res);
        navigate("/", {
          replace: true
        });
      } else if (res?.status === 2) {
        // console.log('toast', toast(res.msg));
        // toast(res.msg)
        console.log("Add name and other details...");
        setClick((prevClick) => prevClick + 1);
      } else {
        console.log(res, "Registration error (otp phase)");
        toast(res?.Backend_Error);
      }
    } catch (error) {
      console.error('Error confirming OTP:', error);
      toast('An error occurred while confirming OTP');
    } finally {
      setConfirmLoading(false); // Stop loading
    }
  };

  async function getExams() {
    setCategoryLoading(true); // Assuming this is a state setter function to indicate loading
    try {
      let response = await auth.getExams();
      console.log("Exams API RESPONSE: ", response);
  
      if (response?.status === 1) {
        let catArray = [...response.categories];
        catArray.forEach((item) => {
          item.isSelected = false;
        });
        setExams(catArray.slice(0, 25)); // Show first 25 items
      } else {
        console.error("Exams couldn't be loaded: ", response);
      }
    } catch (error) {
      console.error("Error fetching exams: ", error);
    } finally {
      setCategoryLoading(false); // Assuming this is to reset the loading state
    }
  }
  

  const filteredexams = exams.filter(res => {
    return res.category_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  function handleClickOnCategory(index) {
    let catArray = [...exams];
    catArray[index].isSelected = !catArray[index].isSelected;
    setExams(catArray);
  }

  async function registerUser() {
    const isAnyCategorySelected = filteredexams.some((exam) => exam.isSelected);
    if (!isAnyCategorySelected) {
      toast('Please select at least one category.');
      return;
    }
    let categories = [];
    exams.forEach((item) => {
      if (item.isSelected) categories.push(item._id);
    });

    auth.registerUser(phone, name, gender, categories, otpInput).then((res) => {
      console.log("details", phone, name, gender, categories, otpInput );
      
      if (res?.status === 1) {
        navigate("/", {
          replace: true
        });
      } else {
        console.log("ERROR while Registering User: ", res);
      }
    });
  }

  // const handleKeyDown = (e) => {
  //   const key = e.key;
  //   if (key === 'Backspace' && otpInput.length > 0) {
  //     // Remove the last character from otpInput
  //     setOtpInput(otpInput.slice(0, -1));
  //   }
  // };

  const handleNextClick1 = () => {
    if (name.trim() === "") {
      toast("Name cannot be empty");
    } else {
      setClick((prevClick) => prevClick + 1);
    }
  };


  return (
    <>
      <ToastContainer />
      <div
        className="container-fluid page_color "
        style={{ display: Click === 0 ? "block" : "none" }}
      >
        {/* logo imgae */}
        <div className="container ">
          <div className="logo text-center mt-4">
            <img className="logo_img" src="./image/Group 206.png" alt="logo" />
          </div>
        </div>

        {/* chosse option */}
        <div className="container mt-5 no-select no-highlight">
          <div className="select_lang text-center">
            <div className="chose_option">
              <h3>Select Your Language</h3>
            </div>
            <div className="two_lang">
              <div className="hindi_option mt-4 mb-3 p-0">
                <div class="form-check radio_selsect d-flex align-items-center gap-3">
                  <input
                    class="form-check-input text-center"
                    type="radio"
                    name="flexRadioDefault"
                    onChange={() => handleLanguageChange('hi')}
                    checked={localStorage.getItem("language") == "hi"}
                  />
                  <label
                    class="form-check-label text-center"
                    for="flexRadioDefault1"
                  >
                    <h4 className="lang_tag mt-3">हिंदी</h4>
                  </label>
                </div>
              </div>

              <div className="english_option mb-4 p-0">
                <div class="form-check  d-flex align-items-center gap-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    onChange={() => handleLanguageChange('en')}
                    checked={localStorage.getItem("language") == "en"}
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    <h4 className="lang_tag mt-3">English</h4>
                  </label>
                </div>
              </div>
            </div>
            <div className="container mt-4">
              <button
                type="button"
                className="btn btn-light done_lang"
                onClick={() => {
                  setClick((prevClick) => prevClick + 1);
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
        {/* earth animation */}
        <div className="container text-center mt-3">
          <img
            className="earth_animat"
            src="./image/earthanimation.gif"
            alt="earth"
          />
        </div>
      </div>

      {/* enter mobile number */}
      <div
        className="m-0 g-0 p-0"
        style={{
          display: Click === 1 ? "block" : "none",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="d-flex flex-column no-select no-highlight"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="my-4 flex-grow-0">
            <div className="logo text-center">
              <img className="logo_img" src="./image/logo2.png" alt="logo" />
            </div>
          </div>

          <div className="container-fluid mbregister_colr flex-grow-1 d-flex flex-column align-items-center">
            <div className="container top_mbreg pt-3">
              <div className="crack_exam d-flex">
                <div className="col-7 me-2 exam_line">
                  <h4>
                    <span> Let's Clear The Exams,</span>
                    <br /> <span>Together</span> 👋{" "}
                  </h4>
                </div>
                {/* <div className="col-5 ms-2 d-flex align-items-center">
                  <img src="./image/right-arrow.png" alt="arrow" />
                </div> */}
              </div>
              <div className="container mb_whole">
                <div className="text-center mt-3 enter_no">
                  <p className="mx-0 my-0">
                    <span>Enter Your Mobile Number </span>
                  </p>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">+91</span>
                  <input
                    type="number"
                    className="form-control contact-num-input"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    value={phone}
                    maxLength="10"
                    onChange={(e) => {
                      if (e.target.value.length <= 10) setPhone(e.target.value);
                    }}
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
              <div className="form-check d-flex justify-content-center age_confirm">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="invalidCheck2"
                  required
                  checked={confirmed}
                  onChange={() => setConfirmed((p) => !p)}
                />
                <label className="form-check-label ms-1" htmlFor="invalidCheck2">
                  <span>
                   By Registering  I agree to {" "}
                    <span
                      style={{ cursor: "pointer" , textDecoration: "underline", color: "#fff"}}
                      onClick={() => {
                        Navigate("/Rules");
                      }}
                    > 
                       T&C
                    </span>{" "}
                    and{" "}
                    <span
                      style={{ cursor: "pointer" , textDecoration: "underline", color: "#fff"}}
                      onClick={() => {
                        Navigate("/Privacy");
                      }}
                    >
                      privacy policy
                    </span>{" "}
                  </span>
                </label>
              </div>
              <div className="container text-center mt-4">
                <button
                  type="button"
                  className="btn btn-light done_lang"
                  onClick={() => {
                    sendOtp(false);
                  }}
                  disabled={!isOtpButtonClickable ||  loading}
             
                >
                {loading ? <Spinner /> :  <span> Get OTP</span>} 
                </button>
              </div>
            </div>
            <div className="bot_img text-center mt-4">
              <img src="./image/Group 218.png" alt="botomimg" />
            </div>
          </div>
        </div>
      </div>


      {/* enter otp */}
      <div
        className="m-0 g-0 p-0"
        style={{
          display: Click === 2 ? "block" : "none",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="d-flex flex-column"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="my-4 flex-grow-0">
            <div className="logo text-center">
              <img className="logo_img" src="./image/logo2.png" alt="logo" />
            </div>
          </div>

          <div className="container-fluid mbregister_colr flex-grow-1">
            <div className="top_otpcont">
              <div className="enter_otp text-center">
                <h4 className="pt-5" style={{ color: "white" }}>
                  <Span>Enter OTP to Continue</Span>  {" "}
                  <img src="./image/right-arrow.png" alt="arrow" />
                </h4>
              </div>
              <div className="otp-container">
                <OTPInput
                  value={otpInput}
                  onChange={setOtpInput}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  // inputStyle="inputStyle"
                  focusStyle="focusStyle"
                  renderInput={(props) => <input {...props} type="number" class="otp-input"
                    style={{ border: "1px solid grey" }}
                  />}
                />
              </div>
              <div className="otp_sent text-center mt-4">
                <p style={{ color: "white" }}>
                  <span>
                    {!disableResend ? (
                      <span>
                        OTP Expired
                      </span>
                    ) : (

                      <span className="otp_colr" onClick={() => sendOtp(true)}>
                        OTP Expired in: {timer}s
                      </span>
                    )}
                  </span>
                  <br />
                  {/* <span className="otp_colr" style={{ color: "#FAFF10" }}>
                    Change Number
                  </span> */}
                </p>
              </div>
              <div className="container text-center mt-4">
                <button
                  type="button"
                  className="btn btn-light done_lang"
                  onClick={() => {
                     confirmOtp(); 
                     handleClose();
                      console.log("count" );
                      
                    }}
                  disabled={confirmLoading} // Disable button during loading
                >
                  {confirmLoading ? <Spinner /> : <span>Confirm</span>}
                </button>
              </div>
              <div className="container text-center pt-2">
                <p style={{ color: "white" }}>
                  <span>Did not receive OTP?{" "}</span>
                  <span style={{ color: "#FAFF10", cursor: "pointer" }}>

                    <span className="otp_colr" onClick={() => sendOtp(true)}>
                      Resend OTP
                    </span>
                  </span>
                </p>
              </div>
            </div>

            <div className="bot1_img text-center">
              <img src="./image/Group 218.png" alt="botomimg" />
            </div>
          </div>
        </div>
      </div>

      {/* enter your name */}
      <div
        className="container-fluid px-0"
        style={{ display: Click === 3 ? "block" : "none", overflow: "hidden" }}
      >
        <div className="container-fluid name_banner">
          <div className="container pt-3 back_tootp">
            {/* <img
              src="./image/back.png"
              alt="back"
              onClick={() => {
                setClick((prevClick) => prevClick - 1);
              }}
              style={{ cursor: "pointer" }}
            /> */}
          </div>
          <div className="container d-md-flex pt-3">
            <div className="col-md-2 mb-3">
              <h4 style={{ color: "white" }}>
                <Span>Welcome</Span>
                <br /> <Span>Together</Span>👋{" "}
              </h4>
            </div>
            <div className="col-10">
              <img
                src="./image/right-arrow.png"
                alt="arrow"
                className="d-md-none"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </div>
          <div className="container">
            <div className="col-2 new_famtext">
              <p><Span>Looks like you are new to our family</Span></p>
            </div>
            <div className="col-3"></div>
          </div>
          <div className="bot_nameimg">
            <img src="./image/name_bannerimg.gif" alt="name_banner" />
          </div>
        </div>
        <div className="container">
          <div className="mt-4">
            <h4 style={{ color: "#533e3e" }}><Span>Let's know each other</Span></h4>
          </div>
          <div className="mt-3">
            <p
              style={{
                marginBottom: "5px",
                fontSize: "large",
                fontWeight: "500",
                color: "#533e3e",
              }}
            >
              <Span>My Name is</Span>
            </p>
          </div>
          <div class="mb-3 name_enterinput">
            <input
              type="text"
              class="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ backgroundColor: "#e3e3e3db" }}
            />
          </div>
          <div className="">
            <button
              type="button"
              class="btn btn-primary next_butt"
              onClick={handleNextClick1}
              // onClick={() => { handleNextClick
              //   // setClick((prevClick) => prevClick + 1);
              // }}
            >
              <Span>Next</Span>
            </button>
          </div>
        </div>
      </div>

      {/* select gender */}
      <div
        className="container-fluid px-0"
        style={{ display: Click === 4 ? "block" : "none" }}
      >
        <div className="container-fluid name_banner">
          <div className="container pt-3 back_tootp">
            <img
              src="./image/back.png"
              alt="back"
              onClick={() => {
                setClick((prevClick) => prevClick - 1);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="container d-md-flex pt-3">
            <div className="col-md-2 mb-3">
              <h4 style={{ color: "white" }}>
                <Span>Welcome </Span>
                <br /> <Span>Together</Span>👋{" "}
              </h4>
            </div>
            <div className="col-10">
              <img
                src="./image/right-arrow.png"
                alt="arrow"
                className="d-md-none"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </div>
          <div className="container">
            <div className="col-2 new_famtext">
              <p><Span>Looks like you are new to our family</Span></p>
            </div>
            <div className="col-3"></div>
          </div>
          <div className="bot_nameimg">
            <img src="./image/name_bannerimg.gif" alt="name_banner" />
          </div>
        </div>
        <div className="container">
          <div className="mt-4">
            <h4 style={{ color: "#533e3e" }}><Span>Let's know each other</Span></h4>
          </div>
          <div className="mt-3">
            <p
              style={{
                marginBottom: "5px",
                fontSize: "large",
                fontWeight: "500",
                color: "#533e3e",
              }}
            >
              <Span>I am a</Span>
            </p>
          </div>
          <div className="container">
            <div className="row my-3">
              <div className="col-lg-6 col-md-12 col-sm-12 ">
                <div className="row">
                  <div
                    onClick={() => setGender("boy")}
                    className={[
                      "col-4 pt-2 d-flex my-10 flex-column align-items-center",
                      gender === "boy" && "active-gender",
                    ].join(" ")}
                  >
                    <img
                      className="ps-1 user_imgcat"
                      src="./image/man.png"
                      alt="man"
                    />
                    <p className="text-center"><Span>Men</Span></p>
                  </div>
                  <div
                    onClick={() => setGender("girl")}
                    className={[
                      "col-4 pt-2 d-flex flex-column align-items-center",
                      gender === "girl" && "active-gender",
                    ].join(" ")}
                  >
                    <img
                      className="pe-1 user_imgcat"
                      src="./image/woman.png"
                      alt="Woman"
                    />
                    <p className="text-center"><Span>Woman</Span></p>
                  </div>
                  <div
                    onClick={() => setGender("no say")}
                    className={[
                      "col-4 pt-2 d-flex flex-column align-items-center",
                      gender === "no say" && "active-gender",
                    ].join(" ")}
                  >
                    <img
                      className="ps-1 user_imgcat"
                      src="./image/user.png"
                      alt="No Say"
                    />
                    <p className="text-center"><Span>No Say</Span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3  col-md-0 col-sm-0 "></div>
              <div className="col-lg-3  col-md-0 col-sm-0 "></div>
            </div>
          </div>

          <div className="">
            <button style={{ width: '100%' }}
              type="button"
              class="btn btn-primary"
              onClick={handleNextClick}
            // onClick={() => {
            //   setClick((prevClick) => prevClick + 1);
            // }}
            >
              <Span>Next</Span>
            </button>
          </div>
        </div>
      </div>

      {/* Final Step */}
      
        <div
        className="final_steps  pt-4"
        style={{ display: Click === 5 ? "block" : "none", overflow: "hidden" }}
      >
        <div className="final_banner ps-4">
          <div className="back_tootp">
            <img
              src="./image/back.png"
              alt="back"
              onClick={() => {
                setClick((prevClick) => prevClick - 1);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="both_final">
            <div className="txt_final d-flex mt-3">
              <h4 className="pe-4" style={{ color: "white" }}>
                <Span> Final Step</Span>
              </h4>
              <img src="./image/right-arrow.png" alt="arrow" />
            </div>
            <div className="side_flowr">
              <img src="./image/Group 164.png" alt="flower" />
            </div>
          </div>
        </div>

        <div className="bot_selectcat px-4">
          <div className="center-data pt-4 flex-grow-0">
            <div
              className="row align-items-center mx-1 "
              style={{
                backgroundColor: "#EFEFEF",
                border: "none",
                boxShadow: "4px 4px 4px #EFEFEF",
                height: "50px",
                borderRadius: "7px",
                width: "500px",
              }}
            >
              <div className="col-3 d-flex justify-content-center mt-1">
                <IoIosSearch color="#7E7E7E" size={24} />
              </div>
              <div className="col-9 mt-1" style={{ marginLeft: "-30px" }}>
                <input
                  className="border-0 w-100"
                  placeholder="Search for Exams"
                  style={{
                    textDecoration: "none",
                    outline: "none",
                    backgroundColor: "#EFEFEF",
                    color: "#272727",
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            className="mt-3 row flex-grow-1  pb-5 "
            style={{ overflowY: "auto" }}
          >
            {categoryLoading ? (
           <div className="loader-container d-flex justify-content-center align-items-center" >
           <RevolvingDot
             visible={true}
             height="80"
             width="80"
             color="#8663e0"
             ariaLabel="revolving-dot-loading"
             wrapperStyle={{}}
             wrapperClass=""
           />
         </div>
            ): (
              <>
 {filteredexams?.map((res, index) => {
              return (
                <>
                  <div className="col-12 p-2">
                    <div className="border p-2 d-flex rounded">
                      <div className="flex-grow-1 d-flex gap-3">
                        <div className="center-data notsaved flex-grow-0" >
                          <img src={BLOBURL + res.image} alt="cat_image" style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '50%',
                          }}></img>
                        </div>
                        <div className="flex-grow-1 center-y t2">
                          <Span>  {res?.category_name}</Span>
                        </div>
                      </div>

                      <div className="flex-grow-0 center-data">
                        <div
                          onClick={() => handleClickOnCategory(index)}
                          className="px-3 py-0"
                          style={{
                            color: "#7E7E7E",
                            backgroundColor: res.isSelected
                              ? "#2188E7"
                              : "#EFEFEF",
                            fontSize: "2rem",
                            borderRadius: "100%",
                            border: "none",
                            outline: 0,
                            cursor: "pointer",
                          }}
                        >
                          {res.isSelected ? (
                            <FaCheck size={16} color="white" />
                          ) : (
                            <>+</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
              </>
            )}

           


          </div>
        </div>
        <div className="d-flex justify-content-center py-2  start_prep " style={{ position: "sticky" }}>
          <button
            type="button"
            class="btn btn-primary mb-4  next_butt1"
            onClick={registerUser}
          >
            <Span>Start Preparing</Span>
          </button>
        </div>
      </div>
  </>
  );
};

export default Registration;