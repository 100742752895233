import React, { createContext,  useState } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { ToastContainer } from "react-toastify";
import { ApolloProvider } from "@apollo/client";
import client from "./components/ApolloGraphSql/ApolloClient";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const Context = createContext();

const AppWrapper = () => {

  // sidebar
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [examData, setExamData] = useState([])
  const [transactionData,settransactionData] = useState({});
  const [chatting, setChatting] = useState([]);
  const [createdStoreData, setCreatedStoreData] = useState({});
  const [joinedStoreData, setJoinedStoreData] = useState({});
  const [isClosed, setIsClosed] = useState(null)
  const [value, setValue] = useState(0);
  const [tab, settab] = useState(1)
  
  return (
    <Context.Provider value={{
      show, handleClose , handleShow,examData,setExamData ,createdStoreData, setCreatedStoreData,joinedStoreData,setJoinedStoreData,transactionData,settransactionData, chatting, setChatting,value, setValue, tab, settab,isClosed, setIsClosed
    }} >
      <div className="backbgg">
      <BrowserRouter>
      <ApolloProvider client={client}>
      <App />
      </ApolloProvider>
      </BrowserRouter>
      </div>
    
    </Context.Provider>
  );
};

root.render(<AppWrapper />);

reportWebVitals();
