import React, { useCallback, useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { MdOndemandVideo } from "react-icons/md";
import { SlNotebook } from "react-icons/sl";
import CourseApiService from '../../services/api/CourseApiService';
import EmptyContainer from '../utils/EmptyContainer';
import LoaderComponent from '../utils/LoaderComponent';
import { ToastContainer, toast } from 'react-toastify';
import { BLOBURL } from '../../config/urls';
import { useNavigate } from 'react-router-dom';
import { BasicServices } from '../../services/BasicServices';

const FreeCourses = () => {
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isMaterialLoading, setIsMaterialLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [freeCourses, setFreeCourses] = useState([]);
  const [freeHistrory, setFreeHistory] = useState([])
  const [videos, setVideos] = useState({});
  const [materials, setMaterials] = useState({})
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const courseServ = new CourseApiService();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const observer = useRef();

 

  const lastCourseRef = useCallback((node) => {
    if (isCourseLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage) => prevPage + 1); // Increment page number when the last element is visible and not beyond total pages
      }
    });
    if (node) observer.current.observe(node); // Observe the last transaction element
  }, [isCourseLoading, page, totalPages]);

  useEffect(() => {
    
    setIsCourseLoading(true)
    courseServ.getFreeCourses(page).then(res => {
      if (res?.status === 1) {
        setFreeCourses((prevSpent) => [...prevSpent, ...res.data]);
        setFreeHistory((prevHistory) => [...prevHistory, ...res.data]);
        setTotalPages(res.totalPages); 
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
        navigate("/Register");
        toast("Logged in other device! Please login again");
      }
      else {
        toast(res.Backend_Error)
      }
    }).catch((error) => toast(error.message)).finally(() => {
      setIsCourseLoading(false)
    })
  }, [page])

  function getVideosForCourse(course_id) {
    setIsVideoLoading(true)
    courseServ.getVideos(course_id).then(res => {
      console.log(res, "Free course videos");
      if (res.status === 1) {
        setVideos(v => {
          let t = { ...v };
          t[course_id] = res.videos
          return t;
        })
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
        navigate("/Register");
        toast("Logged in other device! Please login again");
      }
      else {
        toast(res.Backend_Error)
      }
    }).catch((err) => toast(err.message)).finally(() => {
      setIsVideoLoading(false)
    })
  }

  function getStudyMaterial(course_id, video_id) {
    setIsMaterialLoading(true)
    courseServ.getStudyMaterial(course_id, video_id).then(res => {
      console.log("STUDY MATERIAL", res);
      if (res?.status === 1) {
        setMaterials(
          v => {
            let t = { ...v }
            t[video_id] = res.study_materials
            console.log(t, "Materials");
            return t;
          }
        )
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
        navigate("/Register");
        toast("Logged in other device! Please login again");
      }
      else {
        toast(res.Backend_Error)
      }
    })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsMaterialLoading(false)
      })
  }

  function reloadFreeCourses() {
    setIsCourseLoading(true)
    courseServ.getFreeCourses().then(res => {
      if (res?.status === 1) {
        setFreeCourses(res.data)
      } else if (res.status === "TOKEN_ERR") {
        basic.setJwt("")
        navigate("/Register");
        toast("Logged in other device! Please login again");
      }
      else {
        toast(res.Backend_Error)
      }
    }).catch((error) => toast(error.message)).finally(() => {
      setIsCourseLoading(false)
    })
  }

  const handleBuyNowClick = (event) => {
    event.stopPropagation();
  };

  console.log("getVideosForCourse", freeCourses);

  
  return (
    <div className="container-fluid p-3">
      <ToastContainer />

      <div className="row">
        <div className=' brown ' style={{ height: "78vh", overflowY: "auto" }}>
          <div className="col-lg-12 col-sm-12 col-md-12 ">
            {
              isCourseLoading ? <LoaderComponent /> :
                freeCourses.length <= 0 ?
                  <EmptyContainer message={"No Free Courses Found"} action={reloadFreeCourses} actionName={"Reload"}></EmptyContainer>
                  :
                  freeCourses.map((res, index) => (
                    <Accordion   ref={index === freeCourses.length - 1 ? lastCourseRef : null}  key={res._id} flush>
                      <Accordion.Item eventKey={res._id} style={{ marginTop: '5%' }} onClick={() => { getVideosForCourse(res?._id) }}>
                        <Accordion.Header>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <div className="">
                                  <h6 style={{ fontSize: "16px", fontWeight: "800" }}> <span style={{ fontSize: "20px", fontWeight: "600", color: '#61a3ad' }}>{index + 1}.</span> {res.cou_name}</h6>

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="" style={{ marginLeft: "30px", lineHeight: "10%" }}>
                                  <h6 style={{ color: "#61a3ad" }}>Duration: {res.Duration}(s), <span>{res.attachment_count} Attachment(s), </span> <span>{res.videos_count} Video(s)</span></h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="container">

                            <div className="row">
                              <div className="col-lg-12 col-md-6 col-sm-12">

                                <div className="row">
                                  <div className=' brown ' style={{ height: "max-content", maxHeight: "40vh", overflowY: "auto" }}>

                                    <div className="col-12">
                                      <div className="">
                                        {
                                          (isVideoLoading && !videos[res._id])
                                            ?
                                            <LoaderComponent></LoaderComponent>
                                            :
                                            videos[res._id]?.map(video =>
                                              <Accordion key={video._id} flush>
                                                <Accordion.Item eventKey={video._id} onClick={() => {
                                                  getStudyMaterial(res._id, video._id)
                                                }}>
                                                  <Accordion.Header>
                                                    <div className="container">
                                                      <div className="row">
                                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                                          <div className="row">
                                                            <div className="col-12">
                                                              <div className="d-flex align-items-center justify-content-between">
                                                                <h6 className='d-flex gap-3'><MdOndemandVideo style={{ color: "#61a3ad", fontSize: "20px" }} />{video.title}</h6>
                                                                <button className='btn-btn p-2' style={{ backgroundColor: "#61a3ad", color: "white", border: "none", borderRadius: "6px", paddingTop: "10%" }} onClick={() => navigate("/video", {
                                                                  state: {
                                                                    "course_id": res._id,
                                                                    "video_id": video._id
                                                                  }
                                                                })}>Play video</button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <div className="container">
                                                      <div className="row">
                                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                                          <div className="row">
                                                            <div className="col-12">
                                                              {
                                                                (isMaterialLoading && !materials[video._id])
                                                                  ?
                                                                  <LoaderComponent></LoaderComponent>
                                                                  :
                                                                  materials[video._id]?.map(item =>
                                                                    <div key={item._id} className="d-flex align-items-center justify-content-between pt-2">
                                                                      <h6 className='d-flex gap-2' style={{ marginLeft: "35px" }}><SlNotebook style={{ color: "#61a3ad", fontSize: "20px" }} />{item.title}</h6>
                                                                      <button className='btn-btn p-1' style={{ color: "white", border: "none", borderRadius: "6px", backgroundColor: "#60bdd6" }} onClick={handleBuyNowClick}><a href={BLOBURL + item.pdf_url} target='_blank'>Perview</a></button>
                                                                    </div>
                                                                  )
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>
                                            )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}


            {isCourseLoading && <LoaderComponent />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeCourses;