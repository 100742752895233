import React, { useCallback, useEffect, useRef, useState } from "react";
import coin from "../assets/icons/coin.svg";
import trophy from "../assets/icons/trophy.svg";
// import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import calender from "../assets/icons/calender.svg";
import clock from "../assets/icons/clock.svg";
import { BasicServices } from "../../services/BasicServices";
import EmptyContainer from "../utils/EmptyContainer";
import LoaderComponent from "../utils/LoaderComponent";
import { IMAGE_URL, QUIZMICRO } from "../../config/urls";
import Span from "../Translation/Translate";

const FreeTrivia = () => {
  const Navigate = useNavigate();
  const { exam_id } = useParams();
  const [triviaData, setTriviaData] = useState([]);

 

  const [isLoading , setIsLoading] = useState(false)
  const page  = useRef(1)
  const [totalPages, setTotalPages] = useState(1);

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  const observer = useRef();
  const bottomElementRef = useRef(null);


  const savedGetTrivia = async (cur_page) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZMICRO}/participants/particular/exam/trivia/quizes?category_id=${exam_id}&page=${cur_page}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result?.status === 1) {
          setTriviaData((datas) => [...datas, ...result?.trivia_quizes])
          setTotalPages(result?.totalpages)
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  const loadMore = useCallback(() => {
    if (page.current < totalPages && !isLoading) {
      page.current += 1;
      savedGetTrivia(page.current);
    }
  }, [isLoading, totalPages]);

  useEffect(() => {
    savedGetTrivia(page.current);
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    if (bottomElementRef.current) {
      observer.current.observe(bottomElementRef.current);
    }
  }, [loadMore, isLoading]);

  return (
    <>
      <div className="row">
        { (triviaData?.length  === 0 && !isLoading)? (
          <EmptyContainer 
          actionName="Reload"
          action={()=>savedGetTrivia(page.current)}
          message="No Data Found"
          />
        ) : (
          
          triviaData?.map((res, index) => {
            const [datePart, timePart] = res.sch_time.split(" ");
            return (
              <>
                <div className="col-12 col-md-6 col-lg-4 p-3" key={index + 1}>
                  <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                    <div className="d-flex gap-2">
                      <div className="center-data flex-grow-0">
                        <img
                          src={`${IMAGE_URL}${res?.banner}`}
                          alt="quiz_icon"
                          style={{ height: "30px", borderRadius: "50%" }}
                        />
                      </div>
                      <div className="flex-grow-1 center-y t3">
                      <Span>{res?.quiz_name}</Span>
                      </div>
                    </div>
  
                    <div className="row">
                      <div className="col-6 d-flex gap-2">
                        <span className="tgr"><Span>Fees</Span></span>
                        <img src={coin} alt="fees"></img>
                        <span className="tgo">0</span>
                      </div>
                      <div className="col-6 d-flex gap-2 justify-content-center">
                        <span className="tgr"><Span>Prize</Span></span>
                        <img src={coin} alt="prize"></img>
                        <span className="tgo">{res?.reward}</span>
                      </div>
                    </div>
  
                    <div className="row">
                     <div className="col-3 d-flex gap-2">
                      
                       
                       <img src={trophy} alt="trophy" />
                       <span className="tgo">{res?.reward}</span>
                     </div>
                     
                     <div className="col-4 d-flex gap-2">
                       <img src={clock} color="#7E7E7E" alt="clock" />
                       <span className="tgr">{timePart}</span>
                     </div>
                     <div className="col-5 d-flex gap-2">
                       <img src={calender} color="#7E7E7E" alt="clock" />
                       <span className="tgr">{datePart}</span>
                     </div>
                   </div>
  
                    <div
                      className="btn3 rounded center-data"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Navigate(`/card/current/trivia/${res?._id}`);
                      }}
                    >
                     <Span>Participate Now</Span> 
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
         {isLoading && <LoaderComponent />}

<div ref={bottomElementRef} style={{ height: '20px', marginBottom: '20px' }}></div>


      </div>
    </>
  );
};

export default FreeTrivia;
