import React, { useState } from 'react';
import { BasicServices } from '../../services/BasicServices';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { GRAPHQL_URL } from '../../config/urls';


const Private = () => {
    const [roomhash, setRoomHash] = useState("");
    const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const Navigate = useNavigate();

    // api to join private rooms

    const JoinPrivateRoom = async () => {
    
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
    
        const raw = JSON.stringify({
          "room_hash": roomhash
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch(`${GRAPHQL_URL}/participant/room/join/in/private/room`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result?.status == 1) {
              toast(result.msg)
             setRoomHash("");
              } 
              else if (result.status === "TOKEN_ERR") {
             basic.setJwt("")
              Navigate("/Register");
              toast("Logged in other device! Please login again");
            } 
             else {
              toast(result?.error);
            }
          })
          .catch((error) => console.error(error));
      };

   console.log("roomhash", roomhash);

    return (
        <>
        <ToastContainer />
        <div className='container p-4' style={{ textAlign: 'center' }}>
            <h3 style={{ fontSize: "16px", color: "#000", fontWeight: "600" }}>Enter Code</h3>
            <div >
                <input
                    type="text"
                    id="name"
                    value={roomhash}
                    onChange={(e) => setRoomHash(e.target.value)}
                    required
                    placeholder='Enter Hash Code'
                    style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        padding: "15px",
                        backgroundColor: "#F2F2F2",
                        border: "none",
                        borderRadius: "10px",
                        width: "50%"
                    }}
                />
            
            </div>
            <button type="button" className="btn btn-primary" style={{marginTop: "20px"}} onClick={JoinPrivateRoom}>+ Send Request</button>
        </div>
        </>

    )
}
// 
export default Private;