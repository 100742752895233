import React, { useContext, useEffect, useState } from "react";
import exit from "../assets/icons/exit.svg";
import "../Rooms/Rooms.css";
import Span from "../Translation/Translate";
import CreatedRooms from "./CreatedRooms";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import PendingRooms from "./PendingRooms";
import { Get_joined_rooms } from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";
import { GRAPHQL_URL } from "../../config/urls";
import { BasicServices } from "../../services/BasicServices";
import { ToastContainer, toast } from "react-toastify";
import { Context } from "../..";
import { FaRegCopy } from "react-icons/fa";
import { RevolvingDot } from "react-loader-spinner";

const MyRooms = ({ res }) => {
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRoomName, setSelectedRoomName] = useState("");
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const { setJoinedStoreData } = useContext(Context);
  const { tab, settab } = useContext(Context);
  // const [tab, settab] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(Get_joined_rooms);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
  <RevolvingDot
    visible={true}
    height="80"
    width="80"
    color="#8663e0"
    ariaLabel="revolving-dot-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
</div>;
  if (error) return <p> {error.message} </p>;

  const quizzes = data?.get_joined_rooms?.response;

  // api to delete created room
  const leaveRoom = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_id: selectedRoom,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${GRAPHQL_URL}/participant/room/exit/room`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          toast(result.msg);
          refetch();
          setSelectedRoom("");
          handleClose();
          setSelectedRoomName();
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };
  const copyToClipboard = ({ res }) => {
    console.log(res)
    navigator.clipboard.writeText(res?.room_hash).then(() => {
      alert('Hash copied to clipboard!');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="row d-flex flex-column ">
        <div className="center-data pt-1 flex-grow-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="row pb-2 pt-2">
                  <div className="col-4">
                    <div
                      onClick={() => settab(1)}
                      className="text-center p-2"
                      style={{
                        backgroundColor: tab === 1 ? "#000" : "#F2F2F2",
                        color: tab === 1 ? "white" : "blue",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <h5
                        className="text-center"
                        style={{ color: tab === 1 ? "white" : "black" }}
                      >
                        {" "}
                        <Span style={{ fontSize: "16px" }}>Joined</Span>
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      onClick={() => settab(2)}
                      className="text-center p-2"
                      style={{
                        backgroundColor: tab === 2 ? "#000" : "#F2F2F2",
                        color: tab == 2 ? "#FFFFFF" : "#8A8A8A",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <h5
                        className="pt-1 text-center"
                        style={{ color: tab === 2 ? "white" : "black" }}
                      >
                        {" "}
                        <Span style={{ fontSize: "16px" }}>Created</Span>
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      onClick={() => settab(3)}
                      className="text-center p-2"
                      style={{
                        backgroundColor: tab === 3 ? "#000" : "#F2F2F2",
                        color: tab === 3 ? "#FFFFFF" : "#8A8A8A",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <h5
                        className="pt-1 text-center"
                        style={{ color: tab === 3 ? "white" : "black" }}
                      >
                        {" "}
                        <Span style={{ fontSize: "16px" }}>Pending</Span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div
          className="room_card_height row flex-grow-1 mb-1"
          style={{ height: "62vh", overflowY: "auto" }}
        >
          {tab === 1 ? (
            <div className="row">
              {quizzes?.map((res, index) => {
                const copyToClipboard = () => {
                  navigator.clipboard.writeText(res.room_hash).then(() => {
                    toast('Hash copied to clipboard!');
                  }).catch(err => {
                    console.error('Could not copy text: ', err);
                  });
                };

                return (
                  <div className="col-12 col-md-6 col-lg-4 p-3" key={index}>
                    <div className="card2 p-2 d-flex flex-column gap-3 rounded">
                      <div className="d-flex justify-content-between gap-2">
                        <div className="center-y t3">{res.room_name}</div>
                        <div className="" style={{ fontWeight: "600" }}>
                          {res.room_type ? <span>Public</span> : <span>Private</span>}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pe-2">
                        <div className="d-flex gap-2 mb-2">
                          <span
                            style={{
                              color: "#8A8A8A",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            Members
                          </span>
                          <div style={{ fontSize: "15px", fontWeight: "600" }}>
                            <span style={{ color: "#129C73" }}>
                              {res.enrolled_participants_count}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{
                            color: "#8A8A8A",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          Room Hash Code:
                        </span>
                        {res?.room_type ? (
                          <span className="ps-2" style={{ color: "#129C73" }}>
                            NA
                          </span>
                        ) : (
                          <span className="ps-2" style={{ color: "#129C73" }}>
                            {res?.room_hash}
                            <FaRegCopy
                              style={{
                                marginLeft: "25px",
                                marginBottom: "10px",
                                cursor: "pointer"
                              }}
                              onClick={copyToClipboard}
                            />
                          </span>
                        )}
                      </div>
                      <div className="d-flex">
                        <div
                          className="btn2 rounded me-2 center-data"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setJoinedStoreData(res);
                            Navigate(`/enterroom/${res?._id}`);
                          }}
                        >
                          Enter Room
                        </div>
                        <div
                          className="btnr2 rounded ms-2 leave-rmdata"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleShow();
                            setSelectedRoom(res?._id);
                            setSelectedRoomName(res?.room_name);
                          }}
                        >
                          <img className="me-2" src={exit} alt="exit" />
                          Leave Room
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

          ) : tab === 2 ? (
            <CreatedRooms />
          ) : (
            <PendingRooms />
          )}

          {/* Modal for delete */}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Leave Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Leave{" "}
              <span style={{ fontWeight: "600" }}>{selectedRoomName}?</span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={leaveRoom}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        </div>
    
      </div>
    </>
  );
};

export default MyRooms;
