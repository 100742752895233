import React, { useEffect, useState } from "react";
import vector6 from "../assets/Vector6.png";
import "../Rooms/Rooms.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Get_Category_Fromfill,
  Get_Sub_Category_Fromfill,
} from "../ApolloGraphSql/Queries";
import { useQuery } from "@apollo/client";
import { GRAPHQL_URL, IMAGE_URL } from "../../config/urls";
import { BasicServices } from "../../services/BasicServices";
import { ToastContainer, toast } from "react-toastify";
import coin from "../assets/coin.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { LuTicket } from "react-icons/lu";
import { ProgressBar } from "react-bootstrap";
import { components } from 'react-select';



const ShechduleQuiz = ({ onSchedule }) => {
  const Navigate = useNavigate();
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const { Roomsid, RoomName } = useParams();
  const [subCategory, setSubCategory] = useState(null);
  const [exam, setexam] = useState(null);
  const [selectedExamId, setSelecetedId] = useState("");
  const [selectedSubCatId, setSelectedSubCatId] = useState("");
  const [numQuestions, setNumQuestions] = useState("");
  const [TimeperQuestion, setTimePerQuestion] = useState(10);
  const [Click, setClick] = useState(0);
  const [entryFees, setEntryFees] = useState("");
  const [quizDate, setQuizDate] = useState("");
  const [quizTime, setQuizTime] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedLobbyTime, setSelectedLobbyTime] = useState("");
  const [slotsAvailable, setSlotsAvailable] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [successData, setSuccessData] = useState("");
  const { data: catdata, refetch: refetchCategory } = useQuery(
    Get_Category_Fromfill
  );
  const { data: subCatData, refetch: refetchSubCategory } = useQuery(
    Get_Sub_Category_Fromfill,
    {
      variables: { cat_id: selectedExamId },
    }
  );
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    refetchCategory();
    refetchSubCategory();
  }, []);
  const examCategory = catdata?.get_category_fromfill?.response || [];
  const subCategoryOptions =
    subCatData?.get_sub_category_fromfill?.response || [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#282940",
      color: "#A1A2AD",
      border: "none",
      borderRadius: "4px",
      padding: "5px",
      fontSize: "16px",
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: "#A1A2AD",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#A1A2AD",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#282940",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "#282940",
    }),
  };

  const CustomOption = (props) => {

    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props?.data?.image}
            alt=""
            style={{ width: 25, height: 25, marginRight: 10, borderRadius: '50%' }}
          />
          <span>{props?.data?.label}</span>
        </div>
      </components.Option>
    );
  };
 
  const CustomSingleValue = (props) => {
    console.log('data', props)
    return (
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={`${IMAGE_URL}${props?.data?.image}`}
            alt=""
            style={{ width: 20, height: 20, marginRight: 10, borderRadius: '50%' }}
          />
          <span>{props.data.label}</span>
        </div>
      </components.SingleValue>
    );
  };

  const handleSelectChange = (selectedOption) => {
    setexam(selectedOption); // Set selected exam option
    setSelecetedId(selectedOption.value._id);
    setSelectedCategoryName(selectedOption.value.category_name);
    setSelectedImageUrl(selectedOption.value.image)
    refetchSubCategory();
  };
  const handleSubCatData = (selectedOption) => {
    setSubCategory(selectedOption); // Set selected exam option
    setSelectedSubCatId(selectedOption.value._id);
  };

  const handleTimePerQuestionChange = (e) => {
    const newValue = e.target.value;
    setTimePerQuestion(newValue);
  };


  const handleSelectLobbyTime = (selectedOption) => {
    setSelectedLobbyTime(selectedOption.value); // Set selected lobby time value
  };

  const combinedDateTime = `${quizDate.split("-").reverse().join("-")} ${quizTime}:00`;

  const handleClearStates = () => {
    setSubCategory(null);
    setexam(null);
    setSelecetedId("");
    setSelectedSubCatId("");
    setNumQuestions("");
    setTimePerQuestion(10);
    setEntryFees("");
    setQuizDate("");
    setQuizTime("");
    setSelectedCategoryName("");
    setSelectedLobbyTime("");
    setSlotsAvailable("");
  }

  //    api to create shedule quizz
  const createSheduleQuizz = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_id: Roomsid,
      room_name: RoomName,
      category_id: selectedExamId,
      category_name: selectedCategoryName,
      sub_cat_id: selectedSubCatId,
      total_num_of_quest: numQuestions,
      entryFees: entryFees,
      slots: slotsAvailable,
      time_per_question: TimeperQuestion,
      sch_time: combinedDateTime,
      lobby_time: selectedLobbyTime,
      category_image: selectedImageUrl,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${GRAPHQL_URL}/participant/room/quiz/create/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          toast(result?.msg);
          setSuccessData(result?.view_data)
          // Navigate(`/viewShedulequiz/${Roomsid}/${RoomName}`)
          setClick(3)
          handleClearStates();
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(result?.error);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (!exam) {
      toast("Please select an exam.");
      return;
    }
  
    if (!subCategory) {
      toast("Please select a subcategory.");
      return;
    }
  
    if (!numQuestions || isNaN(numQuestions) || numQuestions <= 0) {
      toast("Please enter a valid number of questions.");
      return;
    }
  
    if (!TimeperQuestion || isNaN(TimeperQuestion) || TimeperQuestion < 10 || TimeperQuestion > 60) {
      toast("Please select a valid time per question between 10 and 60 seconds.");
      return;
    }
    setClick(2);
  };
  const handleSubmit2 = (e) => {
    // e.preventDefault();
    if (!slotsAvailable || isNaN(slotsAvailable) || slotsAvailable < 10) {
      toast("Please enter a valid number of slots available (minimum 10).");
      return;
    }

    if (!entryFees || isNaN(entryFees) || entryFees < 10) {
      toast("Please enter a valid entry fee (minimum 10).");
      return;
    }

    if (!selectedLobbyTime) {
      toast("Please select a lobby time.");
      return;
    }

    if (!quizDate) {
      toast("Please select a quiz date.");
      return;
    }

    if (!quizTime) {
      toast("Please select a quiz time.");
      return;
    }
    createSheduleQuizz(2);
  };



  return (
    <>
      <ToastContainer />
      <div>
        <div
          className=""
          style={{
            display: Click === 0 ? "block" : "none",
            overflow: "hidden",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            className="d-flex flex-column"
            style={{
              background: "rgb(112, 29, 219)",
              height: "100vh",
              width: "100vw",
              overflow: "hidden",
            }}
          >
            <div
              className=""
              style={{
                // background: "#ffffff42",
                borderRadius: "50%",
                padding: "9px",
                cursor: "pointer",
              }}
              onClick={() => Navigate(`/createenterRoom/${Roomsid}`)}
            >
              <FaArrowLeftLong style={{ color: "#fff", fontSize: "19px" }} />
            </div>

            <img
              src={vector6}
              alt="Top Right"
              style={{ position: "absolute", top: "0px", right: "0px" }}
            />
            <div
              className="container"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                position: "relative",
                padding: "20px",
              }}
            >
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: " #fff",
                  // marginTop: "70px",
                  fontSize: "20px",
                  fontWeight: 600,
                  padding: "40px",
                }}
              >
                <p>
                  Schedule Quiz and <br /> compete with your friend
                </p>
              </div>
              <div
                className="quiz-scheduler-container"
                style={{
                  backgroundColor: "#212236",
                  width: "400px",
                  borderRadius: "8px",
                  margin: "auto",
                  color: "white",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                }}
              >
                <form
                  className="quiz-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  <div className="form-group">
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "16px",
                        marginTop: "5px",
                        padding: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <Select
                        isSearchable={true}
                        value={exam}
                        onChange={handleSelectChange}
                        options={examCategory.map((item) => ({
                          value: item,
                          label: item.category_name,
                          image: `${IMAGE_URL}${item.image}`
                        }))}
                        placeholder="Select Exam"
                        required
                        styles={customStyles}
                        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                      />
                    </label>
                  </div>



                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "16px",
                        marginTop: "5px",
                        padding: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <Select
                        value={subCategory}
                        onChange={handleSubCatData}
                        options={subCategoryOptions.map((item) => ({
                          value: item,
                          label: item?.sub_category_name,
                        }))}
                        placeholder="Select SubCategory"
                        styles={customStyles}
                        isSearchable
                        required
                      />
                    </label>
                  </div>
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type="text"
                        value={numQuestions}
                        onChange={(e) => setNumQuestions(e.target.value)}
                        required
                        placeholder="Enter number of questions"
                        className="input-field"
                        style={{
                          fontSize: "16px",
                          marginTop: "5px",
                          padding: "10px",
                          border: "none",
                          color: "#fff",
                          borderRadius: "4px",
                          backgroundColor: "#282940",
                        }}
                      />
                    </label>
                  </div>
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <div
                      className="d-flex mt-2"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "5px",
                        padding: "10px",
                        border: "none",
                        borderRadius: "4px",
                        fontSize: "16px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#A1A2AD",
                        }}
                      >
                        Select Time Per Question
                      </p>
                      <span>{TimeperQuestion} sec</span>
                    </div>
                    <span>
                      <input
                        className="w-100"
                        type="range"
                        min="10"
                        max="60"
                        value={TimeperQuestion}
                        onChange={handleTimePerQuestionChange}
                      />
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "12px",
              }}
            >
              <button
                className="proceed-button"
                style={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "8px",
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: 600,
                  width: "28%",
                }}
                onClick={() => handleSubmit() }
              >
                Proceed
              </button>
            </div>
          </div>
        </div>

        {Click === 2 && (
          <div
            className="backbgg"
            style={{
              // display: Click === 2 ? "block" : "none",
              overflow: "hidden",
              height: "100vh",
              width: "100vw",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                background: "rgb(112, 29, 219)",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
              }}
            >
              <div
                className=""
                style={{
                  // background: "#ffffff42",
                  borderRadius: "50%",
                  padding: "9px",
                  cursor: "pointer",
                }}
                onClick={() => setClick(0)}
              >
                <FaArrowLeftLong style={{ color: "#fff", fontSize: "19px" }} />
              </div>

              <img
                src={vector6}
                alt="Top Right"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              />

              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  position: "relative",
                  padding: "20px",
                }}
              >
                <div
                  className="header"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    color: " #fff",
                    marginTop: "70px",
                    fontSize: "20px",
                    fontWeight: 600,
                    padding: "5px",
                  }}
                >
                  <p>
                    Schedule Quiz and <br /> compete with your friend
                  </p>
                </div>
                <div
                  className="quiz-scheduler-container"
                  style={{
                    backgroundColor: "#212236",
                    width: "400px",
                    borderRadius: "8px",
                    margin: "auto",
                    color: "white",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <form
                    className="quiz-form"
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "15px",
                    }}
                  >
                    <div
                      className="form-group"
                      style={{ marginBottom: "15px" }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#A1A2AD",
                          fontSize: "16px",
                        }}
                      >
                        Slots Available
                        <input
                          type="text"
                          value={slotsAvailable}
                          onChange={(e) => setSlotsAvailable(e.target.value)}
                          required
                          placeholder="No. of slots available"
                          className="input-field"
                          style={{
                            fontSize: "16px",
                            marginTop: "5px",
                            padding: "10px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#282940",
                            color: "#fff",
                          }}
                        />
                      </label>
                      <span
                        style={{
                          float: "right",
                          fontSize: "14px",
                          color: "#A1A1AD",
                          margin: "3px",
                        }}
                      >
                        Minimum 10
                      </span>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "15px" }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#A1A2AD",
                          fontSize: "16px",
                        }}
                      >
                        Entry Fees
                        <input
                          type="text"
                          value={entryFees}
                          onChange={(e) => setEntryFees(e.target.value)}
                          required
                          // <img src={coin} style={{ height: "3vh" }} />
                          placeholder="Entry fees"
                          className="input-field"
                          style={{
                            fontSize: "16px",
                            marginTop: "5px",
                            padding: "10px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#282940",
                            color: "#fff",
                          }}
                        />
                      </label>
                      <span
                        style={{
                          float: "right",
                          fontSize: "14px",
                          color: "#A1A1AD",
                          margin: "3px",
                        }}
                      >
                        Minimum 10
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <Select

                          value={selectedLobbyTime ? { value: selectedLobbyTime, label: selectedLobbyTime } : null}
                          onChange={handleSelectLobbyTime}
                          options={[
                            { value: '5 mins', label: '5 mins' },
                            { value: '10 mins', label: '10 mins' },
                            { value: '15 mins', label: '15 mins' },
                            { value: '20 mins', label: '20 mins' },
                            { value: '30 mins', label: '30 mins' }
                          ]}
                          placeholder="Select Lobby Time"
                          required
                          styles={customStyles}
                        />
                      </label>
                    </div>


                    <div
                      className="form-group"
                      style={{ marginBottom: "15px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                       <label
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "10px",
                            color: "#A1A2AD",
                            fontSize: "16px",
                          }}
                        >
                          
                          Schedule Quiz
                          <input
                           style={{
                            color: "#A1A2AD",
                            fontSize: "16px",
                            marginTop: "5px",
                            padding: "10px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#282940",
                            color: "#fff",
                          }}
                            type="date"
                            value={quizDate}
                            min = {today}
                            onChange={(e) => setQuizDate(e.target.value)}
                            required
                            className="input-field"
                           
                          />
                          
                        </label>



                        <label
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <input
                            type="time"
                            value={quizTime}
                            onChange={(e) => setQuizTime(e.target.value)}
                            required
                            className="input-field"
                            style={{
                              color: "#A1A2AD",
                              fontSize: "16px",
                              marginTop: "30px", // Adjust marginTop to align with date input's label
                              padding: "10px",
                              border: "none",
                              borderRadius: "4px",
                              backgroundColor: "#282940",
                              color: "#fff",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "12px",
                }}
              >
                <button
                  className="proceed-button"
                  style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: 600,
                    width: "28%",
                  }}
                  onClick={handleSubmit2}
                >
                  + Proceed
                </button>
              </div>
            </div>
          </div>
        )}
        {Click === 3 && (
          <div
            className=""
            style={{
              display: Click === 3 ? "block" : "none",
              overflow: "hidden",
              height: "100vh",
              width: "100vw",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                background: "rgb(112, 29, 219)",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
              }}
            >
              <div
                className=""
                style={{
                  // background: "#ffffff42",
                  borderRadius: "50%",
                  padding: "9px",
                  cursor: "pointer",
                }}
                onClick={() => setClick(2)}
              >
                <FaArrowLeftLong style={{ color: "#fff", fontSize: "19px" }} />
              </div>
              <img
                src={vector6}
                alt="Top Right"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              />

              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  position: "relative",
                  padding: "20px",
                }}
              >
                <div
                  className="header"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    color: " #fff",
                    marginTop: "70px",
                    fontSize: "20px",
                    fontWeight: 600,
                    padding: "40px",
                  }}
                >
                  <p>
                    New Live Quiz  For {successData?.category_name}
                    <br /> Created Successfully
                  </p>
                </div>
                <div
                  className="p-2 "
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                //   onClick={() => Navigate(`/register/${res?._id}`)}
                >
                  <div className="mt-3 " style={{ padding: "10px" }}>
                    <div className="d-flex align-items-center gap-3 ">
                      <div
                        className="boxshadow"
                        style={{
                          borderRadius: "50%",
                          width: "52px",
                          height: "50px",
                        }}
                      >
                        <img
                          src={`${IMAGE_URL}${successData?.category_image}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                      </div>
                      {/* <img src={IMAGE_URL + res.banner} style={{ height: "7vh", border:"1px solid red" }} alt="SBI-PO Current Affairs" /> */}
                      <h6 className="some-txt">{successData?.category_name}</h6>
                    </div>
                    <div className="mt-2 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <h6 className="txx">Fees</h6>
                        <div className="d-flex gap-2">
                          <img
                            src={coin}
                            style={{ height: "3vh" }}
                            alt="Coin"
                          />
                          <h6 className="new-txt"> {successData?.entryFees}</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <MdOutlineCalendarMonth
                          className="txx"
                          style={{ fontSize: "20px" }}
                        />
                        <span className="txx">{successData.sch_time.split(' ')[0]}</span>
                      </div>
                    </div>
                    <div className="mt-2 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <h6 className="txx">Reward</h6>
                        <div className="d-flex gap-2">
                          <img
                            src={coin}
                            style={{ height: "3vh" }}
                            alt="Coin"
                          />
                          <h6 className="new-txt">0</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <FaRegClock
                          className="txx"
                          style={{ fontSize: "18px" }}
                        />
                        <span className="txx">
                          <span>{successData.sch_time.split(' ')[1]}</span>
                          {/* <Span>{timepart}</Span> */}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 d-flex align-items-center gap-2 ms-1">
                      <div>
                        <LuTicket style={{ fontSize: "3vh" }} />
                      </div>
                      <div className="d-flex mt-2">
                        <h6 className="alpha">
                          0/  {successData?.slots}
                        </h6>

                      </div>
                    </div>
                    <div className="mt-2">
                      <ProgressBar
                        completed={0}
                        bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                        height="10px"
                        isLabelVisible={false}
                        baseBgColor="#E4E9EC"
                        labelColor="#e80909"
                        animateOnRender
                        maxCompleted={successData?.slots}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                  }}
                >
                  <button
                    className="proceed-button"
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      border: "none",
                      borderRadius: "8px",
                      color: "#701DDB",
                      fontSize: "16px",
                      fontWeight: 600,
                      width: "100%",
                    }}
                    onClick={() => Navigate(`/createenterRoom/${Roomsid}`)}
                  >
                    View Quiz
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ShechduleQuiz;
