import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Offcanvas from "react-bootstrap/Offcanvas";
import gift from "../assets/icons/gift.svg";
import win1 from "../assets/win1.png";
import vec0 from "../assets/Vector0.png";
import vec1 from "../assets/Vector1.png";
import vec2 from "../assets/Vector2.png";
import vec3 from "../assets/Vector3.png";
import vec4 from "../assets/Vector4.png";
import vec5 from "../assets/Vector5.png";
import downarrow from "../assets/icons/arrow-down.svg";
import { useMediaQuery } from "react-responsive";
import { BasicServices } from "../../services/BasicServices";
import { GRAPHQL_URL, IMAGE_URL, QUIZMICRO } from "../../config/urls";
import { toast } from "react-toastify";
import user from "../assets/user.webp"
import { IoIosArrowUp } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../History/history.css";
import Span from "../Translation/Translate";

const RoomsResult = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  console.log(token)

  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function secondsToTimeString(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    let timeString = `${minutes} min : ${remainingSeconds} sec`;

    return timeString;
}

  function secondsToTimeString1(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    let timeString = `${minutes} : ${remainingSeconds}`;

    return timeString;
}

  const [topRanks , setTopRanks] = useState([])
  const [scoreboard , setScoreboard] = useState([])
  const [student , setStudent] = useState({})



  const [isExpanded, setIsExpanded] = useState(false);


  const viewResult = async () => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );

    const raw = JSON.stringify({
        "roomquiz_id": id
      });
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      
      fetch(`${GRAPHQL_URL}/participant/room/quiz/view/result/of/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
            setScoreboard(result?.scoreboard)
            setStudent(()=>{
                const obj = {...result}
                delete obj.status;
                delete obj.topRank;
                delete obj.scoreboard;
                return obj;
            })
            setTopRanks(result?.topRank)

        } else if (result.status === "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          navigate("/Register");
          toast.error("Logged in other device! Please login again");
        } else {
          // toast.error(result?.Backend_Error);
          handleShow();
        }
      })
      .catch((error) => console.error(error));
  };



  useEffect(()=>{
    viewResult()
   
  },[])


  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          backgroundColor: "#701DDB",
          color: "#FFFFFF",
          filter: show ? "blur(10px)" : "none",
        }}
      >
        <div
          style={{ height: "10vh", alignItems: "center" }}
          className="d-flex justify-content-between px-2 px-md-4"
        >
          <div className="arrowb center-data">
            <FaLongArrowAltLeft size={20} color="#FFFFFF"
            onClick={()=>navigate(-1)}
            />
          </div>

          <div className="center-y h4 m-0 text-white"><Span>My Result</Span></div>

          <div className="arrowb center-data" style={{ opacity: 0 }}>
            <FaLongArrowAltLeft size={20} color="#FFFFFF" />
          </div>
        </div>
        

{

isExpanded ? 

<div
          style={{ height: "90vh", width: "100vw" ,backgroundColor: "white", overflow:"hidden" ,border:"1px solid white" , borderRadius:"10px"}}
        >


            <div style={{height:"7%"}} className="center-data" >
              <img src={downarrow} onClick={()=>setIsExpanded(false)}  alt="close" style={{cursor:"pointer" , height:"13px"}} />
            </div>

            <div className="row" style={{overflowY:"auto" }}>

{
    scoreboard?.map((res,index)=>{
        return(<>
        


        <div className="col-12 p-3 px-md-5">
            <div className="border rounded p-2 ">
              <div className="d-flex gap-3">
                <div className="flex-grow-0 d-flex justify-content-around gap-2">
                  <div className="h3 m-0 center-data">{index+1}</div>
                  <div className="center-data">
                    <img
                      src={ `${IMAGE_URL}${res?.image}` || user}
                      style={{
                        height: "75px",
                        width: "75px",
                        borderRadius: "50%",
                        border: "2px solid white",
                      }}
                      alt=""
                    ></img>
                  </div>
                </div>

                <div className="flex-grow-1 center-y">
                  <div className="">
                    <h6
                      className="text-truncate"
                      style={{ maxWidth: isMobile && "150px" }}
                    >
                     <Span> {res?.stu_name}</Span>
                    </h6>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                      }}
                    >
                      <div style={{ color: "green" }}>{res?.marks}/{student?.totMarks}</div>
                      <div style={{ color: "pink" }}>{secondsToTimeString1(res?.submit_time_period)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        
        
        
        </>)
    })
}



            </div>



        </div>
:
<>
<div
          style={{ height: "70vh", alignItems: "center", width: "100vw" }}
          className="d-flex flex-column px-3 "
        >
          <div className="flex-grow-0 w-100 ">
            <div
              style={{
                border: "1px solid #FFFFFF",
                backgroundColor: "#FFFFFF17",
              }}
              className="rounded p-2"
            >
              <div
                className="d-flex justify-content-between"
                style={{ color: "white", fontWeight: 700 }}
              >
                <div className=" d-flex flex-column gap-2">
                  <div className="h6 m-0"><Span>Rank</Span></div>
                  <div className="d-flex gap-2">
                    <span className="h1 m-0">{student?.obtainRank}</span>
                    <span className="h1 m-0">/</span>
                    <span className="h1 m-0" style={{ color: "#FAFF10" }}>
                      {student?.totalRanks}
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-column gap-2">
                  <div className="h6 m-0"><Span>Score</Span></div>
                  <div className="d-flex gap-2">
                    <span className="h1 m-0"> {student?.obtainMarks}</span>
                    <span className="h1 m-0">/</span>
                    <span className="h1 m-0" style={{ color: "#FAFF10" }}>
                    {student?.totMarks}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="d-flex gap-3 my-2"
                style={{ color: "white", fontWeight: 700 }}
              >
                <div className="h6 m-0"><Span>Time</Span></div>
                <div className="d-flex gap-2" style={{ color: "#FAFF10" }}>
                  {secondsToTimeString(student?.submit_time_period)}
                </div>
              </div>

              <div className="vs center-data p-2 mb-2 rounded  text-black" 
                          onClick={() => {
                            navigate(`/roomsanswersheet/${id}`);
                          }}
                          style={{cursor:"pointer"}}
              >
               <Span>View Scorecard</Span> 
              </div>

              <div
                className="center-x gap-2 p-1  mb-2 rounded"
                style={{ backgroundColor: "#FAFF10" }}
              >
                <img src={gift} alt=""></img>
                <div className="center-data text-black">{student?.reward}</div>
              </div>
            </div>
          </div>

          <div className="flex-grow-1 w-100">
            <div className="row mt-2">



              <div className="col-4 mt-4">
{ topRanks[1] &&
<>
                <div className="center-data">
                  <img
                       src={topRanks[1]?.image ? `${IMAGE_URL}${topRanks[1]?.image}` : user}
                    style={{
                      height: "75px",
                      width:"75px",
                      borderRadius: "70%",
                      border: "2px solid white",
                    }}
                    alt=""
                  ></img>
                </div>
                <div
                  className="text-truncate h6 m-0 center-data text-truncate"
                  style={{ fontSize: isMobile && "12px" }}
                >
                 <Span>{topRanks[1].stu_name}</Span> 
                </div>

                <div className="center-data">
                  <div
                    className="center-data px-2 px-md-5"
                    style={{
                      backgroundColor: "#FFFFFF17",
                      width: "fit-content",
                    }}
                  >
                       {topRanks[1].marks}/{student?.totMarks}
                  </div>
                </div>

                <div className="center-data" style={{ position: "relative" }}>
                  <img src={vec1} style={{ height: "60px" }} alt=""></img>

                  <div className="h1" style={{ position: "absolute" }}>
                    2
                  </div>
                </div>
                <div className="center-data">
                  <img src={vec4} style={{ height: "30px" }}></img>
                </div>

                <div
                  className="d-flex h-100 mt-1"
                  style={{ alignItems: "stretch", justifyContent: "center" }}
                >
                  <div style={{ backgroundColor: "#FFFFFF30" }}>
                    <div
                      className="center-data px-2 px-md-5"
                      style={{
                        backgroundColor: "#FFFFFF17",
                        width: "fit-content",
                        opacity: 0,
                      }}
                    >
                      5/10
                    </div>
                  </div>
                </div>
                </>
                    }
              </div>




              <div className="col-4 mt-1">
              { topRanks[0] &&
<>
                <div className="center-data">
                  <img
                    src={topRanks[0]?.image ? `${IMAGE_URL}${topRanks[0]?.image}` : user}
                    style={{
                      height: "75px",
                      width: "75px",
                      borderRadius: "70%",
                      border: "2px solid white",
                    }}
                    alt=""
                  ></img>
                </div>
                <div
                  className="text-truncate h6 m-0 center-data"
                  style={{ fontSize: isMobile && "12px" }}
                >
                <Span> {topRanks[0].stu_name}</Span> 
                </div>

                <div className="center-data">
                  <div
                    className="center-data px-2 px-md-5"
                    style={{
                      backgroundColor: "#FFFFFF17",
                      width: "fit-content",
                    }}
                  >
                     {topRanks[0].marks}/{student?.totMarks}
                  </div>
                </div>

                <div className="center-data" style={{ position: "relative" }}>
                  <img src={vec0} style={{ height: "70px" }}></img>

                  <div className="h1" style={{ position: "absolute" }}>
                    1
                  </div>
                </div>
                <div className="center-data">
                  <img src={vec3} style={{ height: "30px" }}></img>
                </div>

                <div
                  className="d-flex h-100 mt-1"
                  style={{ alignItems: "stretch", justifyContent: "center" }}
                >
                  <div style={{ backgroundColor: "#FFFFFF30" }}>
                    <div
                      className="center-data px-2 px-md-5"
                      style={{
                        backgroundColor: "#FFFFFF17",
                        width: "fit-content",
                        opacity: 0,
                      }}
                    >
                      5/10
                    </div>
                  </div>
                </div>

              </>}
              </div>




              <div className="col-4 mt-5">
              { topRanks[2] &&
<>
                <div className="center-data">
                  <img
                  src={topRanks[2]?.image ? `${IMAGE_URL}${topRanks[2]?.image}` : user}
                    style={{
                      height: "75px",
                      width: "75px",
                      borderRadius: "70%",
                      border: "2px solid white",
                    }}
                    alt=""
                  ></img>
                </div>
                <div
                  className="text-truncate h6 m-0 center-data"
                  style={{ fontSize: isMobile && "12px" }}
                >
                  {topRanks[2].stu_name}
                </div>

                <div className="center-data">
                  <div
                    className="center-data px-2 px-md-5"
                    style={{
                      backgroundColor: "#FFFFFF17",
                      width: "fit-content",
                    }}
                  >
                        {topRanks[2].marks}/{student?.totMarks}
                  </div>
                </div>

                <div className="center-data" style={{ position: "relative" }}>
                  <img src={vec2} style={{ height: "50px" }}></img>

                  <div className="h1 mt-1" style={{ position: "absolute" }}>
                    3
                  </div>
                </div>
                <div className="center-data">
                  <img src={vec5} style={{ height: "30px" }}></img>
                </div>

                <div
                  className="d-flex h-100 mt-1"
                  style={{ alignItems: "stretch", justifyContent: "center" }}
                >
                  <div style={{ backgroundColor: "#FFFFFF30" }}>
                    <div
                      className="center-data px-2 px-md-5"
                      style={{
                        backgroundColor: "#FFFFFF17",
                        width: "fit-content",
                        opacity: 0,
                      }}
                    >
                      5/10
                    </div>
                  </div>
                </div>
                
              </>}
              </div>




            </div>
          </div>
        </div>

        <div
          style={{ height: "20vh", width: "100vw", backgroundColor: "white" , cursor:"pointer" , border:"1px solid white" , borderRadius:"10px", position: "relative"}}
          className="text-black"
         onClick={()=>setIsExpanded(true)}
        ><IoIosArrowUp  style={{position: "absolute",  left: "50%", top: "-3px",transform: "translateX(-50%)", fontSize: "25px"}} />
          <h5 className="text-center mb-1 pt-4 h5 " style={{    fontSize: "17px",
    fontWeight: "bold"}}><Span>Winner’s Leaderboard</Span></h5>

          <div className="w-100 px-3">
            <div className="border rounded p-2 ">
              <div className="d-flex gap-3">
                <div className="flex-grow-0 d-flex justify-content-around gap-2">
                  <div className="h3 m-0 center-data">1</div>
                  <div className="center-data">
                    <img
                       src={ `${IMAGE_URL}${scoreboard[0]?.image}` || user}
                      style={{
                        height: "70px",
                        width: "70px", 
                        borderRadius: "50%",
                        border: "2px solid white",
                      }}
                    ></img>
                  </div>
                </div>

                <div className="flex-grow-1 center-y">
                  <div className="">
                    <h6
                      className="text-truncate"
                      style={{ maxWidth: isMobile && "150px" }}
                    >
                      {scoreboard[0]?.stu_name}
                    </h6>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                      }}
                    >
                      <div style={{ color: "green" }}> {scoreboard[0]?.marks}/{student?.totMarks}</div>
                      <div style={{ color: "pink" }}>{secondsToTimeString1(scoreboard[0]?.submit_time_period)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</>
}








      </div>
     {/* Model Start for not attempted quiz */}
     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="blur-background"
       
      >
        <Modal.Header >
          <Modal.Title style={{fontWeight: "600"}}><Span>Not Attempted the Quiz</Span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontSize: "18px", fontWeight: "600"}}>
        <Span> You have not attempted this quiz.</Span> <br/>
        <Span> No data to show.</Span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>navigate(-1)}>
           <Span>Back</Span> 
          </Button>
        
        </Modal.Footer>
      </Modal>



    </>
  );
};

export default RoomsResult;
