import React, { useContext, useEffect, useState } from 'react';
import '../Wallet/Wallet.css';
import { BsCoin } from "react-icons/bs";
import { RxCheck, RxCheckCircled, RxCross2 } from "react-icons/rx";
import { SlCalender } from "react-icons/sl";
import { IoArrowBack } from "react-icons/io5";
import img2 from "../assets/vector.png"
import img from "../assets/upi.png"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import Span from '../Translation/Translate';
import { AUTHMICRO } from '../../config/urls';
import { toast } from 'react-toastify';
import { BasicServices } from '../../services/BasicServices';
import fad from "../assets/fadrailbank.png";

const Success = () => {
  const Navigate = useNavigate()
  const {id} = useParams();
  const { transactionData } = useContext(Context);
  const {state} = useLocation();
  const [allDetails, setAllDetails] = useState("");
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt

  const viewParticularDetail = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );
     
    const raw = JSON.stringify({
      "transaction_id": id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    

    fetch(
      `${AUTHMICRO}/sales/view/payment/details`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
         if (result?.status === 1) {
          setAllDetails(result?.sendata)
        } else if (result.status === "TOKEN_ERR") {
          basic.setJwt("");
          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    viewParticularDetail();
  }, [])

  return (
    <div>
      <div className='' style={{backgroundColor:"green"}}>
        <div className='text-center py-3 text-white ' style={{backgroundColor:"green", height:"30vh  "}}>
          <div className='d-flex justify-content-between'>
            <button className='text-start fs-2 btn' onClick={() => { Navigate(-1) }} style={{color:"white"}}><IoArrowBack /></button>
            <h2 className='text-center mx-auto' style={{color:"white"}}><Span>Transaction Details</Span></h2>
          </div>
          <p className='fs-2 '>
            <span><BsCoin className='bg-warning rounded mb-2' /></span>
            {allDetails?.amount}<span className="rupee-symbol ms-1">₹</span>
          </p>
          <p>
            <span><RxCheckCircled  className='mb-1'/> </span>
            {state.type.charAt(0).toString().toLocaleUpperCase()}{state.type.slice(1)} <Span>Successful</Span> </p>
        </div>
        <div className="transaction-wrapper ">

          <div className=' text-start text-secondary'>
            <p className='code0'><Span>Transaction ID</Span></p>
            <p className='code'>{allDetails?.order_id}</p>
            <p className='code1'><Span>Payment Type</Span>:</p>
            <p>
              {/* <img src={img} className='img-fluid' alt='' /> */}
              {allDetails?.type}</p>
            <p >
              <img src={img2} className='img-fluid px-2' alt='' />
              {allDetails?.order_datetime}</p>
          </div>
         
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="row pt-3 example"
                    style={{ height: "33vh", overflowY: "auto" }}
                  >
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 pb-3">
                                {allDetails?.type == "credit" && (
                                  <div
                                    className="border p-2"
                                    style={{ borderRadius: "5px" }}
                                  >
                                    <div className="d-flex justify-content-start align-items-center">
                                      <img
                                        src={fad}
                                        alt="bank logo"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          backgroundColor: "blue",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      <h6
                                        className="pt-1"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "25px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {allDetails?.bankdetails?.bank_name}
                                      </h6>
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "left",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Acc. Holder:{" "}
                                        </span>
                                        {
                                          allDetails?.bankdetails
                                            ?.acc_holder_name
                                        }
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Account Number:{" "}
                                        </span>
                                        {allDetails?.bankdetails?.bank_acc_no}
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Verification Status:{" "}
                                        </span>
                                        Success
                                      </h6>
                                    </div>
                                  </div>
                                )}

                               
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  )
}

export default Success