import Select from 'react-select';
import React, { useState } from "react";
import vector6 from "../assets/Vector6.png";
import coin from "../assets/coin.png";
import "../Rooms/Rooms.css";
import { display, fontSize, textAlign } from '@mui/system';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { FaRegClock, FaWhatsapp } from 'react-icons/fa';
import { ProgressBar } from 'react-bootstrap';
import { LuTicket } from 'react-icons/lu';


const ShechduleQuiz = ({ onSchedule }) => {
    const [subCategory, setSubCategory] = useState(null);
    const [exam, setexam] = useState(null);
    const [numQuestions, setNumQuestions] = useState('');
    const [timePerQuestion, setTimePerQuestion] = useState('');
    const [Click, setClick] = useState(0);
    const [entryFees, setEntryFees] = useState('');
    const [schedule, setSchedule] = useState('');
    const [quizDate, setQuizDate] = useState('');
    const [quizTime, setQuizTime] = useState('');
    const [slotsAvailable, setSlotsAvailable] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSchedule({
            subCategory,
            numQuestions,
            timePerQuestion,
        });
    };
    const subCategoryOptions = [
        { value: 'math', label: 'Math' },
        { value: 'science', label: 'Science' },
        { value: 'history', label: 'History' },
        { value: 'literature', label: 'Literature' },
        // Add more options as needed
    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#282940',
            color: '#A1A2AD',
            // border: '1px solid #ccc',
            border: "none",
            borderRadius: '4px',
            padding: '5px',
            fontSize: '16px',
            // Additional styles can go here
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#A1A2AD',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#A1A2AD',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#282940',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'gray' : '#282940',
            // color: 'white',
            // '&:hover': {
            //     backgroundColor: 'gray',
            // },
        }),
    };
    const examCategory = [
        { value: 'exam1', label: 'Exam' },
        { value: 'exam2', label: 'Exam' },
        { value: 'exam3', label: 'Exam' },
    ]
    return (
        <>
            <div className="backbgg" style={{
                display: Click === 1 ? "block" : "none",
                overflow: "hidden",
                height: "100vh",
                width: "100vw",
            }}>
                <div
                    className="d-flex flex-column"
                    style={{ background: 'rgb(112, 29, 219)', height: '100vh', width: '100vw', overflow: 'hidden' }}
                >

                    <img src={vector6} alt="Top Right" style={{
                        position: "absolute",
                        top: "0px", right: "0px"
                    }} />


                    <div className="container" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '20px',
                    }}>

                        <div className="header" style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            color: " #fff",
                            marginTop: "70px",
                            fontSize: "20px",
                            fontWeight: 600,
                            padding: "40px"
                        }}>
                            <p>Create Live Quiz and <br /> compete with your friend</p>
                        </div>
                        <div className="quiz-scheduler-container" style={{
                            backgroundColor: '#212236',
                            minWidth: '400px',
                            borderRadius: '8px',
                            margin: 'auto',
                            color: 'white',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                        }}>
                            <form className="quiz-form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                                <div className="form-group" >
                                    <label style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: "16px",
                                        marginTop: '5px',
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '4px',
                                        fontSize: '16px',
                                    }}>
                                        <Select
                                            value={exam}
                                            onChange={setexam}
                                            options={examCategory}
                                            placeholder="Select Exam"
                                            isSearchable
                                            required
                                            styles={customStyles}
                                        />
                                    </label>
                                </div>
                                <div className="form-group" style={{ marginBottom: "15px" }}>
                                    <label style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: "16px",
                                        marginTop: '5px',
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '4px',
                                        fontSize: '16px',
                                    }}>
                                        <Select
                                            value={subCategory}
                                            onChange={setSubCategory}
                                            options={subCategoryOptions}
                                            placeholder="Select SubCategory"
                                            styles={customStyles}
                                            isSearchable
                                            required
                                        />
                                    </label>
                                </div>
                                <div className="form-group" style={{ marginBottom: "15px" }}>
                                    <label style={{
                                        display: "flex",
                                        flexDirection: "column",

                                    }}>
                                        <input
                                            type="text"
                                            value={numQuestions}
                                            onChange={(e) => setNumQuestions(e.target.value)}
                                            required
                                            placeholder="Enter number of questions"
                                            className="input-field"
                                            style={{
                                                fontSize: "16px",
                                                marginTop: '5px',
                                                padding: '10px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                backgroundColor: "#282940",
                                                border: "none",
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="form-group" style={{ marginBottom: "15px" }}>
                                    <div
                                        className="d-flex mt-2"
                                        style={{
                                            justifyContent: "space-between", marginTop: '5px',
                                            padding: '10px',
                                            border: 'none',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                color: "#A1A2AD",

                                            }}
                                        >
                                            Select Time Per Question
                                        </p>
                                        <span>10 sec</span>
                                    </div>
                                    <span>
                                        <input
                                            className="w-100"
                                            type="range"
                                            min="10"
                                            max="60"

                                        // value={TimePerQuestion}
                                        // onChange={(e) => {
                                        //     setTimePerQuestion(e.target.value);
                                        // }}
                                        />
                                    </span>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "center", padding: "12px" }}>
                        <button className='proceed-button' style={{
                            backgroundColor: "#fff", padding: "10px", border: 'none',
                            borderRadius: '8px',
                            color: '#000',
                            fontSize: '20px',
                            fontWeight: 600,
                            width: '28%',
                        }}>Proceed</button>
                    </div>
                </div>

            </div>
            <div className="backbgg" style={{
                // display: Click === 2 ? "block" : "none",
                overflow: "hidden",
                height: "100vh",
                width: "100vw",
            }}>
                <div
                    className="d-flex flex-column"
                    style={{ background: 'rgb(112, 29, 219)', height: '100vh', width: '100vw', overflow: 'hidden' }}
                >

                    <img src={vector6} alt="Top Right" style={{
                        position: "absolute",
                        top: "0px", right: "0px"
                    }} />


                    <div className="container" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '20px',
                    }}>

                        <div className="header" style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            color: " #fff",
                            marginTop: "70px",
                            fontSize: "20px",
                            fontWeight: 600,
                            padding: "40px"
                        }}>
                            <p>Create Quiz and <br /> compete with your friend</p>
                        </div>
                        <div className="quiz-scheduler-container" style={{
                            backgroundColor: '#212236',
                            minWidth: '400px',
                            borderRadius: '8px',
                            margin: 'auto',
                            color: 'white',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                        }}>
                            <form className="quiz-form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", padding: "15px" }}>

                                <div className="form-group" style={{ marginBottom: '15px' }}>
                                    <label style={{ display: 'flex', flexDirection: 'column', color: "#A1A2AD", fontSize: "16px" }}>
                                        Slots Available
                                        <input
                                            type="text"
                                            value={slotsAvailable}
                                            onChange={(e) => setSlotsAvailable(e.target.value)}
                                            required
                                            placeholder="No. of slots available"
                                            className="input-field"
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '5px',
                                                padding: '10px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                backgroundColor: '#282940',
                                                color: '#fff'
                                            }}
                                        />
                                    </label>
                                    <span style={{
                                        float: "right",
                                        fontSize: "14px",
                                        color: "#A1A1AD",
                                        margin: "3px"
                                    }}>Minimum 10</span>
                                </div>

                                <div className="form-group" style={{ marginBottom: '15px' }}>
                                    <label style={{ display: 'flex', flexDirection: 'column', color: "#A1A2AD", fontSize: "16px" }}>
                                        Entry Fees

                                        <input
                                            type="text"
                                            value={entryFees}
                                            onChange={(e) => setEntryFees(e.target.value)}
                                            required
                                            // <img src={coin} style={{ height: "3vh" }} />
                                            placeholder="Entry fees"
                                            className="input-field"
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '5px',
                                                padding: '10px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                backgroundColor: '#282940',
                                                color: '#fff'
                                            }}
                                        />
                                        {/* <img
                                            src={coin}
                                            alt="Coin"
                                            style={{
                                                position: 'absolute',
                                                left: '10px', // Adjust as needed
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                height: '3vh',
                                                pointerEvents: 'none', // Prevents the image from blocking input field interaction
                                            }}
                                        /> */}
                                    </label>
                                    <span style={{
                                        float: "right",
                                        fontSize: "14px",
                                        color: "#A1A1AD",
                                        margin: "3px"
                                    }}>Minimum 10</span>
                                </div>

                                <div className="form-group" style={{ marginBottom: '15px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <label style={{ display: 'flex', flexDirection: 'column', marginRight: '10px', color: "#A1A2AD", fontSize: "16px" }}>
                                            Schedule Quiz
                                            <input
                                                type="date"
                                                value={quizDate}
                                                onChange={(e) => setQuizDate(e.target.value)}
                                                required
                                                className="input-field"
                                                style={{
                                                    color: "#A1A2AD",
                                                    fontSize: '16px',
                                                    marginTop: '5px',
                                                    padding: '10px',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#282940',
                                                    color: '#fff',
                                                }}
                                            />
                                        </label>
                                        <label style={{ display: 'flex', flexDirection: 'column' }}>
                                            <input
                                                type="time"
                                                value={quizTime}
                                                onChange={(e) => setQuizTime(e.target.value)}
                                                required
                                                className="input-field"
                                                style={{
                                                    color: "#A1A2AD",
                                                    fontSize: '16px',
                                                    marginTop: '30px', // Adjust marginTop to align with date input's label
                                                    padding: '10px',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#282940',
                                                    color: '#fff',
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>


                                {/* <div className="form-group" style={{ marginBottom: '15px' }}>
                                    <label style={{ display: 'flex', flexDirection: 'column' }}>
                                        <input
                                            type="time"
                                            value={quizTime}
                                            onChange={(e) => setQuizTime(e.target.value)}
                                            required
                                            className="input-field"
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '5px',
                                                padding: '10px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                backgroundColor: '#282940',
                                                color: '#fff'
                                            }}
                                        />
                                    </label>
                                </div> */}
                            </form>
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "center", padding: "12px" }}>
                        <button className='proceed-button' style={{
                            backgroundColor: "#fff", padding: "10px", border: 'none',
                            borderRadius: '8px',
                            color: '#000',
                            fontSize: '20px',
                            fontWeight: 600,
                            width: '28%',
                        }}>+ Shechdule Quiz</button>
                    </div>
                </div>

            </div>
            <div className="backbgg" style={{
                display: Click === 2 ? "block" : "none",
                overflow: "hidden",
                height: "100vh",
                width: "100vw",
            }}>
                <div
                    className="d-flex flex-column"
                    style={{ background: 'rgb(112, 29, 219)', height: '100vh', width: '100vw', overflow: 'hidden' }}
                >

                    <img src={vector6} alt="Top Right" style={{
                        position: "absolute",
                        top: "0px", right: "0px"
                    }} />


                    <div className="container" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '20px',
                    }}>

                        <div className="header" style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            color: " #fff",
                            marginTop: "70px",
                            fontSize: "20px",
                            fontWeight: 600,
                            padding: "40px"
                        }}>
                           
                            <p>New Live Quiz “SBI PO - Prelims”<br /> Created Successfully</p>
                        </div>
                        <div
                            className="p-2 "
                            style={{backgroundColor: "#fff" , borderRadius: "8px"}}
                        //   onClick={() => Navigate(`/register/${res?._id}`)}
                        >
                            <div
                                className="mt-3 "
                                style={{ padding: "10px" }}
                            >
                                <div className="d-flex align-items-center gap-3 ">
                                    <div
                                        className="boxshadow"
                                        style={{
                                            borderRadius: "50%",
                                            width: "52px",
                                            height: "50px",
                                        }}
                                    >
                                        <img
                                            // src={IMAGE_URL + res.banner}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </div>
                                    {/* <img src={IMAGE_URL + res.banner} style={{ height: "7vh", border:"1px solid red" }} alt="SBI-PO Current Affairs" /> */}
                                    <h6 className="some-txt">
                                   SBI-PO Current Affairs
                                    </h6>
                                </div>
                                <div className="mt-2 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="txx">
                                            Fees
                                        </h6>
                                        <div className="d-flex gap-2">
                                            <img
                                                src={coin}
                                                style={{ height: "3vh" }}
                                                alt="Coin"
                                            />
                                            <h6 className="new-txt">
                                                {" "}
                                                2560
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <MdOutlineCalendarMonth
                                            className="txx"
                                            style={{ fontSize: "20px" }}
                                        />
                                        <span className="txx">07-12-2024</span>
                                    </div>
                                </div>
                                <div className="mt-2 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="txx">
                                           Prize
                                        </h6>
                                        <div className="d-flex gap-2">
                                            <img
                                                src={coin}
                                                style={{ height: "3vh" }}
                                                alt="Coin"
                                            />
                                            <h6 className="new-txt">256000</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <FaRegClock
                                            className="txx"
                                            style={{ fontSize: "18px" }}
                                        />
                                        <span className="txx">
                                            <span>12:30</span>
                                            {/* <Span>{timepart}</Span> */}
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-2 d-flex align-items-center gap-2 ms-1">
                                    <div>
                                        <LuTicket style={{ fontSize: "3vh" }} />
                                    </div>
                                    <div className="d-flex mt-2">
                                        <h6 className="alpha">
                                            {/* {res.slot_aloted}{" "} */}
                                        </h6>
                                        {/* <h6> /{res.slots}</h6> */}
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <ProgressBar
                                        // completed={res.slot_aloted}
                                        bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                                        height="10px"
                                        isLabelVisible={false}
                                        baseBgColor="#E4E9EC"
                                        labelColor="#e80909"
                                        animateOnRender
                                        // maxCompleted={res.slots}
                                    />
                                </div>
                            </div>
                            {/* <div className="mt-3 d-flex justify-content-center">
                                <div
                                    className="registerButtonClass p-2 text-center w-100 mb-3"
                                  onClick={() =>
                                    Navigate(`/register/${res?._id}`)
                                  }
                                >
                                    <Span>Register Now</Span>
                                </div>
                            </div> */}
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", padding: "12px" }}>
                        <button className='proceed-button' style={{
                            backgroundColor: "#51C386", padding: "10px", border: 'none',
                            borderRadius: '8px',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 600,
                            width: '100%',
                        }}> <FaWhatsapp style={{marginRight: "8px", cursor: "pointer", fontSize: "20px"}}/> Invite Participants</button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", padding: "12px" }}>
                        <button className='proceed-button' style={{
                            backgroundColor: "#fff", padding: "10px", border: 'none',
                            borderRadius: '8px',
                            color: '#701DDB',
                            fontSize: '16px',
                            fontWeight: 600,
                            width: '100%',
                        }}>View Quiz</button>
                    </div>
                    </div>

                </div>
                </div>
            </>

            );
};

            export default ShechduleQuiz;
