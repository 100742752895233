import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import sec0 from "../assets/sec0.png";
import sec1 from "../assets/sec1.png";
import sec2 from "../assets/sec2.png";
import sec3 from "../assets/sec3.png";
import { BasicServices } from '../../services/BasicServices';
import { toast } from 'react-toastify';
import { GRAPHQL_URL, QUIZMICRO } from '../../config/urls';


const StartQuiz = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const imageUrls = [sec3, sec2, sec1, sec0];
    const basic = new BasicServices();
    const token = basic.getLocalObject().jwt

    const joinQuiz = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            `Bearer ${token}`
        );

        const raw = JSON.stringify({
            roomquiz_id: id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${GRAPHQL_URL}/participant/room/quiz/join/quiz`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.status === 1) {
                    localStorage.setItem('timer', String(result?.timeperiod - 10));
                    localStorage.setItem('total_time', String(result?.timeperiod - 10));
                    localStorage.setItem('total_que', String(result?.total_question));
                    localStorage.setItem('index', '1');
                    localStorage.setItem('slide', '0');
                    localStorage.setItem('result', '{}');
                    // localStorage.setItem('after_timer', '5');
                    localStorage.setItem('isBack', '0');

                } else if (result.status === "TOKEN_ERR") {
                    localStorage.removeItem("api_url");
                    navigate("/Register");
                    toast.error("Logged in other device! Please login again");
                } else {
                    toast.error(result?.error);
                    setTimeout(() => {
                        navigate('/')
                    }, 5000)
                }
            })
            .catch((error) => console.error(error));
    };


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => {
                if (prevIndex === imageUrls.length - 1) {
                    clearInterval(interval);
                    navigate(`/middlewarning/${id}`);
                    return prevIndex;
                } else {
                    return prevIndex + 1;
                }
            });
        }, 2000);

        return () => clearInterval(interval);
    }, [navigate]);


    useEffect(() => {
        joinQuiz()
    }, [])

    return (
        <div style={styles.container}>
            <img
                src={imageUrls[currentImageIndex]}
                alt={`Image ${currentImageIndex + 1}`}
                style={styles.image}
            />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'radial-gradient(109.49% 50% at 50% 50%, #281E97 0%, #000000 100%)'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
};

export default StartQuiz;
