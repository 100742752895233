import React, { useContext, useState, useEffect } from "react";
import { Context } from "../..";
import { CiMenuFries } from "react-icons/ci";
import homeicon from "../assets/icons/homeicon.svg";
import { MdOutlineMenu, MdOutlineTranslate } from "react-icons/md";
import Sidebar from "../Home/Sidebar";
import { Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import Span from "../Translation/Translate";
const Privacy = () => {
  const Navigate = useNavigate("");
  const { handleShow, handleClose } = useContext(Context);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showUpArrow, setShowUpArrow] = useState(true); // State to toggle between up and down arrows

  const toggleArrow = () => {
    setShowUpArrow(!showUpArrow);
  };
  useEffect(() => {
    handleClose();
  }, []);
  return (
    <>
      <div className="" style={{ backgroundColor: "#6f42c1", color: "white" }}>
        <div className="mx-3 p-3 d-flex  align-items-center ">
          <div className=" col-2">
            <IoMdArrowRoundBack
              onClick={() => Navigate(-1)}
              style={{ fontSize: "22px", cursor: "pointer" }}
            />
          </div>
          <div className=" col-8 d-flex justify-content-center 000 pt-2">
            <h4 style={{ color: "white" }}>
              <Span>Privacy Policy</Span>{" "}
            </h4>
          </div>
          <div className="col-2 justify-content-evenly d-flex gap-2 ">
            {/* <div className=" ">
              <MdOutlineTranslate
                style={{ fontSize: "20px", marginLeft: "10px" }}
              />
            </div> */}
            <div className=" ">
              <FaHome
                style={{ color: "white", fontSize: "1.5rem" }}
                onClick={() => {
                  Navigate("/");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Privacy Poliy Hedding */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          <Span>Privacy Policy of Brain Bucks App</Span>{" "}
        </h1>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          <Span>
            Tech Brain Bucks infosoft Private Limited operates the portal in
            India which offers live competitive exam preparation and fantasy
            quiz through the portal
          </Span>{" "}
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "11px",
          }}
        >
          <Span>
            Brainbucks and mobile application(s) (collectively refered to as the
            Portal) (Tech Brain Bucks Infosoft Private Limited referred to
            herein as "Brain Bucks" or "we"
          </Span>{" "}
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          <Span>
            {" "}
            Any person utilizing the Portal ("User" or "you or your or any of
            ite features including participation in the various contests Quiz
            shall be bound by this Privacy Policy Tech Brain Bucks Infosoft
            Private Limited respects the privacy of its Users and committed to
            protect it in all respects With a view
          </Span>
          .
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "11px",
          }}
        >
          <Span>
            {" "}
            To offer an enriching and holistic internet experience to its Users,
            Tech Brain Bucks Infosoft Private Limited offers a vast repository
            of Services Kindly take time to read the About Us section to know
            more about Tech Brain Bucks infosoft Private Limited Most of the
            Services are offered for free but you may need registration to
            participate in Tech Brain Bucks Infosoft Private Limited's online
            Quizzes. The information about the User is collected by Tech Brain
            Bucks infosoft Private Limited as (i) information supplied by users
            and (1) information automatically tracked during User's avigation on
            Tech Brain Bucks Infosoft Private
          </Span>
          .
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          <Span>
            Limited Before you submit any information to the Ports, please read
            this Privacy Policy for an explanation of how we will treat your
            personal information By using any part of the Portal, you consent to
            the collection, use, disclosure and transfer of your personal
            information for the purposes outlined in this Privacy Policy and to
            the collection. processing and maintenance of this information If
            you do not agree to this Privacy Policy, please do not use the
            Portal. Your use of any part of the portal indicates your use of
            privace policy,to the collection ,processing and maintenance of this
            information.If you don't agree to this privacy policy and to the
            collection ,use and disclosure of your personal information in
            accodance with this privacy p;oicy.While you have the option not to
            provide us with certain information,request tempoary suspension of
            collection of information or request deletion of information
            collected,kindly note that in such an event you may not able to take
            full advantage of the entire scope of features and services offered
            to you and we reserve the right not to provide you with our services
          </Span>{" "}
          .{" "}
        </p>
      </div>
      {/*Purpose and Usages start  */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          <Span>Purpose and usage</Span> :-
        </h1>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          1. <Span>Username</Span>{" "}
        </h1>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          2. <Span>Password</Span>{" "}
        </h1>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          3. <Span>Email address</Span>{" "}
        </h1>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          4. <Span>Date of birth</Span>{" "}
        </h1>
      </div>
      {/* Purpose and usages end */}
      {/* Purpose Main content Start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            In the cpurse of providng you with access to the services,and in
            order to provide you access to the features offered through the
            portal and to verify your identify,secure you account details ,you
            may givr us ther permission to capture ,record yout device
            information,operating system information,network
            information,location information .In certain instences,we may also
            collect servitve personal information("SPI") form you on the portak
            .SPI means such personal information which consist of infprmation
            relating to your physical ,physiological and mentail helth
            conditon;medical records and history; bimetric
            information,sexua;orientationand financial information,such as
            information regarding the payment instrument/modes used by you to
            make such payments,which may include cardholder name,credit/debit
            card number (in encrypted from) with expiration fate,banking
            details,wallet details etc.This information is presented to you at
            the time of making a pyment Expenditiously
          </Span>{" "}
          .
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            Except for any financial information that you choose to peocise
            while making payment for any services on the portal,Tech Brain Bucks
            Infosoft Private Limited does not collect any other SPI in the
            course of providing the services.Any SPI Collected by Tech Brsain
            Bucks Infosoft Private Limited Shall not be disclosed to anyThird
            party without your express consent,save as otherwise set out in this
            privacy policy or as provided in a separate wrotten agreement
            between Tech Brain Bucks Infosoft Private Limited shall not be
            disclosed to any thirth party without your express consentmsave as
            otherwise set out in this privacy policy or as provided otherwise
            set out in a seperate written agreement between Tech Brain Bucks
            infosoft Private Limited and you or as required by lae.It is
            clarified that this condition shall not apply to publicly available
            information,including spi,in relation to you on the portal
          </Span>{" "}
          .
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            In the course of providing the services,users nay invite other
            existing users or other users("invited users "),to participate in
            any of the services by providing that email address or facebook
            username of such users .Tech Brain Bucks Infosoft Private Limited
            (if such invited user is not an existing User)and participate in the
            Quize in relation to which such person was invited user in any od
            the Quiz Shall be subject to the terms of this Privacy policy and
            The Terms and conditions for the portail.The User heby repersents
            that the invited Users have consented and agrees to such disclossure
            to and use of their email address by tech Brain bucks infosoft
            Private Limited
          </Span>
          .
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            All required information is specific and based on the Kind Of
            Quiz/services the user wises to participate in or access,and will be
            utilzes for the purpose of services requested by the users enables
            us to imporve the services and provide you the most user-frinedly
            Game experience
          </Span>
          .{" "}
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          <Span>
            {" "}
            Tech Brain Bucks Infosoft private Limited may also shate such
            information with affilliates and third parties in limited
            circumstances,including for the purpose of providing services
            requested by the User,complying with legal process preventing fraud
            or imminent harm,and ensuring the security of our network and
            services
          </Span>{" "}
          .
        </p>
      </div>
      {/* purposual main content end */}
      {/* Disclosure Header Start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Disclosure/Sharing</Span>:-
        </h1>
        {/* disclosure heder end */}
      </div>
      {/* disclosuer sharing main content start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          <Span>
            Tech Brain Bucks Infosoft Private Limited may also share information
            as provided by you and data concerining usage of servoces and
            participation in the game with third party service providers engaged
            by Tech Infosoft Private Limited for purpose of data analytics or
            other similar purposes for the purpose of storage,imporving the
            services and helping Tech Brain Bucks Infosoft Private Limited Serve
            you better
          </Span>{" "}
          .
        </p>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            Where we propose to use your personal information (that is,
            information that is not otherwise publicly available) for any other
            purposes, we will ensure that we notify you first. You will also be
            given the opportunity to withhold or withdraw your consent for use
            other than as listed above.
          </Span>
          <br />
          
          <Span> By using the portal, you hereby expressly agree and grant consent to</Span>
          <Span>  the collection, use, and storage of this information by Tech Brain</Span>
          <Span> Bucks Infosoft Private Limited. Tech Brain Bucks Infosoft Private</Span>
          <Span> Limited reserves the right to share, disclose, and transfer</Span>
          <Span>information collected hereunder with its affiliates. In the event</Span>
          <Span> Tech Brain Bucks Infosoft Private Limited sells or transfers all or</Span>
          <Span> a portion of its business assets, consumer information may be one of</Span>
          <Span> the business assets that are shared, disclosed, or transferred as</Span>
          <Span> part of the transaction. You hereby expressly grant consent for the</Span>
          <Span> use of data concerning the usage of the services and participation</Span>
          <Span> in the quizzes with its commercial partners for marketing,</Span>
          <Span> promotional, and other related purposes. Furthermore, Tech Brain</Span>
          <Span> Bucks Infosoft Private Limited reserves the right to disclose</Span>
          <Span> personal information as required by law, in response to duly</Span>
          <Span> authorized legal processes, governmental requests, and as necessary</Span>
          <Span> to protect the rights and interests of Tech Brain Bucks Infosoft</Span>
          <Span> Private Limited.</Span>
          
          .
        </p>
      </div>
      {/* disclosure sharing main content end  */}
      {/* Use Of cookies heding start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Use of cookies</Span> :-
        </h1>
      </div>
      {/* Use ofcookies heding end */}
      {/* use of cookies content stat */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          <Span>
            To imporve the effectivness and isability of the portal for oir
            users,we use "cookies",or such similar electronic tools to collect
            infotmation to assign visitor a unique random number as a user
            identification (user ID) to understand the user's individual
            interests using the identified computer.uniess the user voluntatrily
            identified computer .unless the user voluntairly identifies
            himself/herself (e.g through registration)Tech Brain Bucks Infosoft
            private Limited has no way of knowing who the user is even if we
            assign a cookie to the user's computer.The only personal information
            supplied by the user.A cookie cannot read data off the user's hard
            drive Tech Brain bucks Infosoft private Limit's web serves
            automatically collect limited information including user's Ip
            address is a number that lets computers attached to the internet
            know where to send data to user-such as the web pages viewed by the
            users).The user's ip address does not identify the user
            personally.Tech Brain Bucks Infosoft Private Limited uses upon
            request ,to measure traffic within the portal and let adevertisers
            know the geographic locations from where Tech Brain Bucks Infosoft
            Private Limited's Visitors Come
          </Span>
          .
        </p>
      </div>
      {/* use of cookies content end */}
      {/* Links Start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Links</Span> :-
        </h1>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            Tech Bran Bucks infosoft Private Limited does'not include links to
            other websites
          </Span>
        </p>
      </div>
      {/* Links end */}
      {/* Security Procedures start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Security Procedures</Span> :-
        </h1>
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>All Information gather on Tech Brain Bucks Infosoft private Limited is</Span>
          <Span>secuerly stored within Tech Brain Bucks Infosoft Private</Span>
          <Span>Limited-contorlled database .The data is sotored on service. on</Span>
          <Span>services secured behind a firewall access to such services being</Span>
          <Span>password-protected and strictly limited based on need to-know</Span>
          <Span>basis.However ,we cannot guarantee the security of our data base,no</Span>
          <Span>security system is impenetrable.Thus,we cannot gaurantee the security</Span>
          <Span>of our database,nor can we gauarntee that information you supply will</Span>
          <Span>not be internet.Futher,any information you include in aposting to the</Span>
          <Span>discussion areas will be availaber to anyone with intenet access.By</Span>
          <Span>using the portal,you understand and agree that your information may be</Span>
          <Span>used in or transferred to countires other than india.Tech Brain Bucks</Span>
          <Span>Infosoft Private Limited Also Beliveves that the intenet is an</Span>
          <Span>evewr-evolving medium.We may peridically review from time to time and</Span>
          <Span>change out privacy policy to incorporate such future changes as may be</Span>
          <Span>cinsidered appropriate,without any notice toyou.our use of any</Span>
          <Span>onformation we gather will alaways be consistent with the policy under</Span>
          <Span>which the information was collected,reg=gardless of which the new</Span>
          <Span>policy may be. Any changes to our privacy policy will be posted on</Span>
          <Span>this page,so you are always aware of what information we collect,how</Span>
          <Span>we use it,how we stored it and under what circumstances we disclose it</Span>
          .
        </p>
      </div>
      {/* security Procedures end  */}
      {/* Payment Header start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Payment</Span> :-
        </h1>
      </div>
      {/* payment Header end */}
      {/* Payment Main content start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            We use Razorpay for processing payments.we/razorpay do not store
            your card data on their serves.The data is enceypted through the
            pyment card Industry Data Security standard (PCI-DSS) when
            processing payment
          </Span>
          .<br />
          <Span>
            Your purchase transcation data is only used as long as is necessary
            to complete your purchase transaction.After that is complete,your
            purchase transaction infromation is not saved.our payment getway
            adheres to the standards set by PCI_DASS as managed by the PCI
            Security Standards Council,whixh is a joint effort of brands like
            visa,MasterCard,American Express and Discover.PCI-DSS requirements
            help ensure the secure handling of credit card information by our
            store and its service providers.For more insight,you may also want
            to read terms and conditions of razorpay on
          </Span>{" "}
          https://razorpay.com
        </p>
      </div>
      {/*payment Main content end  */}
      {/* Advertising header start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Advertising:-
        </h1>
      </div>
      {/* Advertising header end */}
      {/* Advertsing Main Content Start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            {" "}
            When Tech Brain Bucks Infosoft private Limited Presents Infromation
            to it's is Usually in the from od a advertising on the portal --It
            is usaually in the from of aggregated statistics on traffic to
            various pages within our site.When you register with Tech Brain
            Bucks Infosoft Private Limited,We contact to provide features Which
            we belive you
          </Span>
          .<br />
          <Span>
            {" "}
            Several deceptive emails,websitesmblogs etc.claminh to be from or
            associated eith Tech Brain Bucks Infosoft Private Limited May or are
            circulating on the internet.These emails,websites,blogsetc.call the
            user requesting the user to provide login name,password etc.or
            inform the user that the user to provide login name,password
            etc.often include our logo ,photos,links,content or
            otherinformation.Some emails,Websites,blogs etc.call the user
            requestingthe user that the user has won a prize/gift or provide a
            method to cmmir illegal.unauthorized act or deed or request detailed
            personal information or a pyment of some kind .The sources and
            accompanying materials ae in no way associated with oen
            protection,we strongly recommend not responding to such emails or
            using these websites,blogs etc.We many use the information provided
            by you to Tech Brain Bucks Infosoft Private Limited,including Your
            email address or phone number,to contact you about the services
            availed by you or to inform you of our updated services if any
          </Span>{" "}
          .
        </p>
      </div>
      {/*Advertising Main COntent End */}
      {/* Conditions of use Header start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Conditons of Use</Span> :-
        </h1>
      </div>
      {/* conditions of use Header end  */}
      {/* Conditions Main content start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            TECH BRAIN BUCKS INFSOFT PRIVATE LIMITED DOSE NOT WARRSNT THAT THIS
            PORTAL,IT'S SERVICES,OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS
            FREE.TECH BRAIN BUCKS INFOSOFT PRIVATE LIMITED WILL NOT BE LIABLE
            FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE
            PROPATIAL,INCLUDING,BUT NOT LIMITED WILL NOT BE LIMITED TO
            COMPENSTORY,DIRECT ,INDIRECT,INCIDENTAL,PARTIES.IN NO EVENT WILL
            TECH BRAIN BUCKS INFOSOFT PROVATE LIMITED BE LIABLE FOR ANY DAMAGES
            WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR
          </Span>{" "}
          100.
        </p>
      </div>
      {/* Conditions main content end */}
      {/* Retention of data heading start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Retention of data</Span> :-
        </h1>
      </div>
      {/* Retention of data heading end */}
      {/* Retention of main data start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            you personal information may be retained and may continue to be used
            until;(i)the relevant purposes for th use of your information
            described in this privacy policy are no longer applicable ;and (ii)
            we ar no longer required by applicable law,regulations,contractual
            obligations or legitimate business purposes to retain your personal
            information and the retention of your personal information is not
            required for the establishment,exercise or defense of any legal
            claim
          </Span>{" "}
          .
        </p>
      </div>
      {/* Retention of main data end */}
      {/* User Account and Data deletion header start */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>User Account and Data deletion</Span> :-
        </h1>
      </div>
      {/* User Account and Data deletion header end */}
      {/* User Accont data deletation main content start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            Users are entitled to request Tech Brain Bucks Infosoft Private
            Limited to delete their User accounts and their personal information
            by sending an email with their written request to help@brainbucks.in
            We will do our best to respond promptly and in any event within one
            month of the following: • Our receipt of your written request; or •
            Our receipt of any further information we may ask you to provide to
            enable us to comply with your request, whichever is later. As an
            alternative to account deletion, by writing to help@brainbucks.in,
            you also have the option to request either: • (i) the suspension of
            your account, after which you will not be able to play paid contests
            on your User account but will continue to have access to other parts
            of the Portal; or • (ii) temporary deactivation of your account,
            where you will no longer be able to log into the Portal but which
            allows you to request reactivation of your account with all your
            account data. If you proceed with permanent deactivation of your
            account, you will lose access to the Portal and the Services,
            including any User data and personal information associated with
            your account. Users may request for account restoration within six
            (6) months from the date of notification of account deletion by Tech
            Brain Bucks Infosoft Private Limited by writing to
            help@brainbucks.in When you request deletion of your data, we follow
            a deletion process that ensures that your data is safely and
            completely removed from our servers or retained only in anonymised
            form. We try to ensure that our services protect information from
            accidental or malicious deletion. Because of this, there may be
            delays between when you request deletion and when copies are deleted
            from our active and backup systems
          </Span>
          .
        </p>
      </div>
      {/* user Account main data end  */}
      {/*Application Law and jurisdiction header start*/}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>Application Law and Jurisdiction</Span> :-
        </h1>
      </div>
      {/* Application Law and jurisdiction header end */}
      {/*  Application Law  main content start  */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            {" "}
            By visiting this Portal, you agree that the laws of the Republic of
            India without regard to its conflict of laws principles, govern this
            Privacy Policy and any dispute arising in respect hereof shall be
            subject to and governed by the dispute resolution process set out in
            the Terms and Conditions
          </Span>
          .
        </p>
      </div>
      {/*  Application Law  main content end */}
      {/* Upate information header start  */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>updating Information</Span> :-
        </h1>
      </div>
      {/* update information header end */}
      {/*Update information main content start*/}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            You will promptly notify Brain Bucks if there are any changes,
            updates or modifications to your information. Further, you may also
            review, update or modify your information and user preferences by
            logging into your Profile page on the app
          </Span>{" "}
          .
        </p>
      </div>
      {/* update information main content end */}
      {/* contact us header start  */}
      <div style={{ flex: "0 0 auto" }}>
        <h1
          style={{
            justifyContent: "center",
            marginTop: "33px",
            marginLeft: "11px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          contact Us:-
        </h1>
      </div>
      {/* contact us header end*/}
      {/* contact us main contact start */}
      <div style={{ flex: "0 0 auto" }}>
        <p
          style={{
            marginTop: "23px",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {" "}
          <Span>
            Any questions or clarifications with respect to this Policy or any
            complaints, comments, concerns or feedback can be sent to Tech Brain
            Bucks Infosoft Private Limited at: help@brainbucks.in or by normal/
            physical mail addressed to: Attn: Brainbucks Team Techno Brain Bucks
            infosoft Private Limited 101-103, Chanda Tower, opp. Grand Utsav
            Marriage Garden, Vaishali nagar, Jaipur 302021, — Rajasthan, India
          </Span>
        </p>
      </div>
      {/* contact us main contact end */}
      {/* end Section of Privacy Policy  */}
      {/* start Bootom Section */}

      {/* <div
        class="form-check"
        style={{ marginLeft: "23px", textDecoration: "none" }}
      >
        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
        <label
          class="form-check-label"
          for="exampleCheck1"
          style={{
            MarginLeft: "9px",
            justifyContent: "center",
            fontSize: "11px",
            margiTop: "23px",
            fontWeight: "600",
          }}
        >
          {" "}
          <Span>
            I hereby agree to the Privacy Policy of the Brain Bucks app
          </Span>{" "}
        </label>
      </div> */}
      <Sidebar />
    </>
  );
};

export default Privacy;
